{
    "addServer.add": "\u5b8c\u6210",
    "addServer.enterIp": "\u670d\u52a1\u5668\u5730\u5740",
    "addServer.enterName": "\u670d\u52a1\u5668\u540d\u79f0",
    "addServer.hideAddress": "\u9690\u85cf\u5730\u5740",
    "addServer.resourcePack": "\u670d\u52a1\u5668\u8d44\u6e90\u5305",
    "addServer.resourcePack.disabled": "\u7981\u7528",
    "addServer.resourcePack.enabled": "\u542f\u7528",
    "addServer.resourcePack.prompt": "\u8be2\u95ee",
    "addServer.title": "\u7f16\u8f91\u670d\u52a1\u5668\u4fe1\u606f",
    "advMode.allEntities": "\u7528\u201c@e\u201d\u6765\u4ee3\u8868\u5168\u90e8\u5b9e\u4f53",
    "advMode.allPlayers": "\u7528\u201c@a\u201d\u6765\u4ee3\u8868\u5168\u90e8\u73a9\u5bb6",
    "advMode.command": "\u63a7\u5236\u53f0\u547d\u4ee4",
    "advMode.mode": "\u6a21\u5f0f",
    "advMode.mode.auto": "\u5faa\u73af",
    "advMode.mode.autoexec.bat": "\u4fdd\u6301\u5f00\u542f",
    "advMode.mode.conditional": "\u6761\u4ef6\u5236\u7ea6",
    "advMode.mode.redstone": "\u8109\u51b2",
    "advMode.mode.redstoneTriggered": "\u7ea2\u77f3\u63a7\u5236",
    "advMode.mode.sequence": "\u8fde\u9501",
    "advMode.mode.unconditional": "\u4e0d\u53d7\u5236\u7ea6",
    "advMode.nearestPlayer": "\u7528\u201c@p\u201d\u6765\u4ee3\u8868\u6700\u8fd1\u7684\u73a9\u5bb6",
    "advMode.notAllowed": "\u5fc5\u987b\u662f\u5904\u4e8e\u521b\u9020\u6a21\u5f0f\u7684\u7ba1\u7406\u5458",
    "advMode.notEnabled": "\u547d\u4ee4\u65b9\u5757\u6ca1\u6709\u5728\u6b64\u670d\u52a1\u5668\u4e0a\u542f\u7528",
    "advMode.previousOutput": "\u4e0a\u4e00\u4e2a\u8f93\u51fa",
    "advMode.randomPlayer": "\u7528\u201c@r\u201d\u6765\u4ee3\u8868\u968f\u673a\u73a9\u5bb6",
    "advMode.self": "\u7528\u201c@s\u201d\u6765\u4ee3\u8868\u6267\u884c\u5b9e\u4f53",
    "advMode.setCommand": "\u8bbe\u7f6e\u6b64\u65b9\u5757\u7684\u63a7\u5236\u53f0\u547d\u4ee4",
    "advMode.setCommand.success": "\u6210\u529f\u8bbe\u7f6e\uff1a%s",
    "advMode.trackOutput": "\u8bb0\u5f55\u8f93\u51fa",
    "advMode.triggering": "\u89e6\u53d1\u65b9\u5f0f",
    "advMode.type": "\u7c7b\u578b",
    "advancement.advancementNotFound": "\u672a\u77e5\u7684\u8fdb\u5ea6\uff1a%s",
    "advancements.adventure.adventuring_time.description": "\u53d1\u73b0\u6240\u6709\u7684\u751f\u7269\u7fa4\u7cfb",
    "advancements.adventure.adventuring_time.title": "\u63a2\u7d22\u7684\u65f6\u5149",
    "advancements.adventure.arbalistic.description": "\u7528\u5f29\u4e00\u53d1\u51fb\u6740\u4e94\u53ea\u4e0d\u540c\u7684\u751f\u7269",
    "advancements.adventure.arbalistic.title": "\u52b2\u5f29\u624b",
    "advancements.adventure.bullseye.description": "\u4ece\u81f3\u5c1130\u7c73\u5916\u5c04\u4e2d\u6807\u9776\u7684\u9776\u5fc3",
    "advancements.adventure.bullseye.title": "\u6b63\u4e2d\u9776\u5fc3",
    "advancements.adventure.fall_from_world_height.description": "\u4ece\u4e16\u754c\u9876\u90e8\uff08\u5efa\u7b51\u9ad8\u5ea6\u9650\u5236\u5904\uff09\u81ea\u7531\u843d\u4f53\uff0c\u5760\u81f3\u4e16\u754c\u5e95\u90e8\u5e76\u5b58\u6d3b\u4e0b\u6765",
    "advancements.adventure.fall_from_world_height.title": "\u4e0a\u5929\u5165\u5730",
    "advancements.adventure.hero_of_the_village.description": "\u6210\u529f\u5728\u88ad\u51fb\u4e2d\u4fdd\u536b\u6751\u5e84",
    "advancements.adventure.hero_of_the_village.title": "\u6751\u5e84\u82f1\u96c4",
    "advancements.adventure.honey_block_slide.description": "\u8df3\u5165\u8702\u871c\u5757\u4ee5\u7f13\u89e3\u6454\u843d",
    "advancements.adventure.honey_block_slide.title": "\u80f6\u7740\u72b6\u6001",
    "advancements.adventure.kill_a_mob.description": "\u6740\u6b7b\u4efb\u610f\u654c\u5bf9\u6027\u602a\u7269",
    "advancements.adventure.kill_a_mob.title": "\u602a\u7269\u730e\u4eba",
    "advancements.adventure.kill_all_mobs.description": "\u6740\u6b7b\u6bcf\u4e00\u79cd\u654c\u5bf9\u6027\u602a\u7269",
    "advancements.adventure.kill_all_mobs.title": "\u8d44\u6df1\u602a\u7269\u730e\u4eba",
    "advancements.adventure.lightning_rod_with_villager_no_fire.description": "\u5728\u4e0d\u5f15\u53d1\u706b\u707e\u7684\u524d\u63d0\u4e0b\u4fdd\u62a4\u6751\u6c11\u514d\u53d7\u96f7\u51fb",
    "advancements.adventure.lightning_rod_with_villager_no_fire.title": "\u7535\u6d8c\u4fdd\u62a4\u5668",
    "advancements.adventure.ol_betsy.description": "\u7528\u5f29\u8fdb\u884c\u4e00\u6b21\u5c04\u51fb",
    "advancements.adventure.ol_betsy.title": "\u6263\u4e0b\u60ac\u5200",
    "advancements.adventure.play_jukebox_in_meadows.description": "\u7528\u5531\u7247\u673a\u7684\u97f3\u4e50\u58f0\u4e3a\u8349\u7538\u589e\u6dfb\u751f\u673a",
    "advancements.adventure.play_jukebox_in_meadows.title": "\u97f3\u4e50\u4e4b\u58f0",
    "advancements.adventure.root.description": "\u5192\u9669\u3001\u63a2\u7d22\u4e0e\u6218\u6597",
    "advancements.adventure.root.title": "\u5192\u9669",
    "advancements.adventure.shoot_arrow.description": "\u7528\u5f13\u7bad\u5c04\u70b9\u4ec0\u4e48",
    "advancements.adventure.shoot_arrow.title": "\u7784\u51c6\u76ee\u6807",
    "advancements.adventure.sleep_in_bed.description": "\u5728\u5e8a\u4e0a\u7761\u89c9\u4ee5\u6539\u53d8\u4f60\u7684\u91cd\u751f\u70b9",
    "advancements.adventure.sleep_in_bed.title": "\u751c\u871c\u7684\u68a6",
    "advancements.adventure.sniper_duel.description": "\u4ece50\u7c73\u5f00\u5916\u51fb\u6740\u4e00\u53ea\u9ab7\u9ac5",
    "advancements.adventure.sniper_duel.title": "\u72d9\u51fb\u624b\u7684\u5bf9\u51b3",
    "advancements.adventure.spyglass_at_dragon.description": "\u900f\u8fc7\u671b\u8fdc\u955c\u89c2\u5bdf\u672b\u5f71\u9f99",
    "advancements.adventure.spyglass_at_dragon.title": "\u90a3\u662f\u98de\u673a\u5417\uff1f",
    "advancements.adventure.spyglass_at_ghast.description": "\u900f\u8fc7\u671b\u8fdc\u955c\u89c2\u5bdf\u6076\u9b42",
    "advancements.adventure.spyglass_at_ghast.title": "\u90a3\u662f\u6c14\u7403\u5417\uff1f",
    "advancements.adventure.spyglass_at_parrot.description": "\u900f\u8fc7\u671b\u8fdc\u955c\u89c2\u5bdf\u9e66\u9e49",
    "advancements.adventure.spyglass_at_parrot.title": "\u90a3\u662f\u9e1f\u5417\uff1f",
    "advancements.adventure.summon_iron_golem.description": "\u53ec\u5524\u4e00\u53ea\u94c1\u5080\u5121\u6765\u5e2e\u5fd9\u5b88\u536b\u6751\u5e84",
    "advancements.adventure.summon_iron_golem.title": "\u62db\u52df\u63f4\u5175",
    "advancements.adventure.throw_trident.description": "\u5f80\u4ec0\u4e48\u4e1c\u897f\u6254\u51fa\u4e09\u53c9\u621f\u3002\n\u6ce8\uff1a\u522b\u628a\u4f60\u552f\u4e00\u7684\u6b66\u5668\u4e5f\u6296\u6389\u4e86\u3002",
    "advancements.adventure.throw_trident.title": "\u6296\u5305\u88b1",
    "advancements.adventure.totem_of_undying.description": "\u5229\u7528\u4e0d\u6b7b\u56fe\u817e\u9003\u79bb\u6b7b\u795e",
    "advancements.adventure.totem_of_undying.title": "\u8d85\u8d8a\u751f\u6b7b",
    "advancements.adventure.trade.description": "\u6210\u529f\u4e0e\u4e00\u540d\u6751\u6c11\u8fdb\u884c\u4ea4\u6613",
    "advancements.adventure.trade.title": "\u6210\u4ea4\uff01",
    "advancements.adventure.trade_at_world_height.description": "\u5728\u5efa\u7b51\u9ad8\u5ea6\u9650\u5236\u5904\u4e0e\u6751\u6c11\u4ea4\u6613",
    "advancements.adventure.trade_at_world_height.title": "\u661f\u9645\u5546\u4eba",
    "advancements.adventure.two_birds_one_arrow.description": "\u7528\u4e00\u652f\u7a7f\u900f\u7bad\u5c04\u6740\u4e24\u53ea\u5e7b\u7ffc",
    "advancements.adventure.two_birds_one_arrow.title": "\u4e00\u7bad\u53cc\u96d5",
    "advancements.adventure.very_very_frightening.description": "\u96f7\u51fb\u4e00\u540d\u6751\u6c11",
    "advancements.adventure.very_very_frightening.title": "\u9b54\u5973\u5ba1\u5224",
    "advancements.adventure.voluntary_exile.description": "\u6740\u6b7b\u4e00\u540d\u88ad\u51fb\u961f\u957f\u3002\n\u6216\u8bb8\u8be5\u8003\u8651\u6682\u65f6\u8fdc\u79bb\u6751\u5e84\u2026\u2026",
    "advancements.adventure.voluntary_exile.title": "\u81ea\u6211\u653e\u9010",
    "advancements.adventure.walk_on_powder_snow_with_leather_boots.description": "\u5728\u7ec6\u96ea\u4e0a\u884c\u8d70\u2026\u2026\u5e76\u4e14\u4e0d\u9677\u8fdb\u53bb",
    "advancements.adventure.walk_on_powder_snow_with_leather_boots.title": "\u8f7b\u529f\u96ea\u4e0a\u98d8",
    "advancements.adventure.whos_the_pillager_now.description": "\u8ba9\u63a0\u593a\u8005\u4e5f\u5c1d\u5c1d\u5f29\u7684\u6ecb\u5473",
    "advancements.adventure.whos_the_pillager_now.title": "\u73b0\u5728\u8c01\u624d\u662f\u63a0\u593a\u8005\uff1f",
    "advancements.empty": "\u8fd9\u91cc\u597d\u50cf\u4ec0\u4e48\u90fd\u6ca1\u6709\u2026\u2026",
    "advancements.end.dragon_breath.description": "\u7528\u73bb\u7483\u74f6\u6536\u96c6\u4e00\u4e9b\u9f99\u606f",
    "advancements.end.dragon_breath.title": "\u4f60\u9700\u8981\u6765\u70b9\u8584\u8377\u7cd6",
    "advancements.end.dragon_egg.description": "\u83b7\u5f97\u9f99\u86cb",
    "advancements.end.dragon_egg.title": "\u4e0b\u4e00\u4e16\u4ee3",
    "advancements.end.elytra.description": "\u627e\u5230\u9798\u7fc5",
    "advancements.end.elytra.title": "\u5929\u7a7a\u5373\u4e3a\u6781\u9650",
    "advancements.end.enter_end_gateway.description": "\u9003\u79bb\u8fd9\u5ea7\u5c9b\u5c7f",
    "advancements.end.enter_end_gateway.title": "\u8fdc\u7a0b\u6298\u8dc3",
    "advancements.end.find_end_city.description": "\u8fdb\u53bb\u5427\uff0c\u53c8\u80fd\u53d1\u751f\u4ec0\u4e48\u5462\uff1f",
    "advancements.end.find_end_city.title": "\u5728\u6e38\u620f\u5c3d\u5934\u7684\u57ce\u5e02",
    "advancements.end.kill_dragon.description": "\u795d\u541b\u597d\u8fd0",
    "advancements.end.kill_dragon.title": "\u89e3\u653e\u672b\u5730",
    "advancements.end.levitate.description": "\u5229\u7528\u6f5c\u5f71\u8d1d\u7684\u653b\u51fb\u5411\u4e0a\u6f02\u6d6e50\u4e2a\u65b9\u5757",
    "advancements.end.levitate.title": "\u8fd9\u4e0a\u9762\u7684\u98ce\u666f\u4e0d\u9519",
    "advancements.end.respawn_dragon.description": "\u590d\u6d3b\u672b\u5f71\u9f99",
    "advancements.end.respawn_dragon.title": "\u7ed3\u675f\u4e86\u2026\u518d\u4e00\u6b21\u2026",
    "advancements.end.root.description": "\u8fd8\u662f\u5f00\u59cb\u4e86\uff1f",
    "advancements.end.root.title": "\u672b\u5730",
    "advancements.husbandry.axolotl_in_a_bucket.description": "\u7528\u6876\u6355\u83b7\u4e00\u53ea\u7f8e\u897f\u8788",
    "advancements.husbandry.axolotl_in_a_bucket.title": "\u6700\u840c\u6355\u98df\u8005",
    "advancements.husbandry.balanced_diet.description": "\u5c1d\u904d\u5929\u4e0b\u98df\u6750\uff0c\u5373\u4fbf\u662f\u5bf9\u8eab\u4f53\u4e0d\u597d\u7684",
    "advancements.husbandry.balanced_diet.title": "\u5747\u8861\u996e\u98df",
    "advancements.husbandry.breed_all_animals.description": "\u7e41\u6b96\u6bcf\u79cd\u52a8\u7269\uff01",
    "advancements.husbandry.breed_all_animals.title": "\u6210\u53cc\u6210\u5bf9",
    "advancements.husbandry.breed_an_animal.description": "\u7e41\u6b96\u4e00\u5bf9\u52a8\u7269",
    "advancements.husbandry.breed_an_animal.title": "\u6211\u4ece\u54ea\u513f\u6765\uff1f",
    "advancements.husbandry.complete_catalogue.description": "\u9a6f\u670d\u6240\u6709\u79cd\u7c7b\u7684\u732b\uff01",
    "advancements.husbandry.complete_catalogue.title": "\u767e\u732b\u5168\u4e66",
    "advancements.husbandry.fishy_business.description": "\u9493\u5230\u4e00\u6761\u9c7c",
    "advancements.husbandry.fishy_business.title": "\u8165\u5473\u5341\u8db3\u7684\u751f\u610f",
    "advancements.husbandry.kill_axolotl_target.description": "\u4e0e\u7f8e\u897f\u8788\u5e76\u80a9\u4f5c\u6218\u5e76\u8d62\u5f97\u80dc\u5229",
    "advancements.husbandry.kill_axolotl_target.title": "\u53cb\u8c0a\u7684\u6cbb\u6108\u529b\uff01",
    "advancements.husbandry.make_a_sign_glow.description": "\u8ba9\u544a\u793a\u724c\u7684\u6587\u672c\u53d1\u5149",
    "advancements.husbandry.make_a_sign_glow.title": "\u773c\u524d\u4e00\u4eae\uff01",
    "advancements.husbandry.netherite_hoe.description": "\u7528\u4e0b\u754c\u5408\u91d1\u952d\u5347\u7ea7\u4e00\u628a\u9504\uff0c\u7136\u540e\u91cd\u65b0\u8003\u8651\u4f60\u7684\u4eba\u751f\u6289\u62e9",
    "advancements.husbandry.netherite_hoe.title": "\u7ec8\u6781\u5949\u732e",
    "advancements.husbandry.plant_seed.description": "\u79cd\u4e0b\u79cd\u5b50\uff0c\u89c1\u8bc1\u5b83\u7684\u6210\u957f",
    "advancements.husbandry.plant_seed.title": "\u5f00\u8352\u57a6\u5730",
    "advancements.husbandry.ride_a_boat_with_a_goat.description": "\u4e0e\u5c71\u7f8a\u540c\u8239\u5171\u6e21",
    "advancements.husbandry.ride_a_boat_with_a_goat.title": "\u7f8a\u5e06\u8d77\u822a\uff01",
    "advancements.husbandry.root.description": "\u4e16\u754c\u65e0\u5904\u6ca1\u6709\u670b\u53cb\u4e0e\u7f8e\u98df",
    "advancements.husbandry.root.title": "\u519c\u7267\u4e1a",
    "advancements.husbandry.safely_harvest_honey.description": "\u5229\u7528\u8425\u706b\u5728\u4e0d\u60ca\u52a8\u871c\u8702\u7684\u60c5\u51b5\u4e0b\u4ece\u8702\u5de2\u6536\u96c6\u8702\u871c",
    "advancements.husbandry.safely_harvest_honey.title": "\u4e0e\u8702\u5171\u821e",
    "advancements.husbandry.silk_touch_nest.description": "\u7528\u7cbe\u51c6\u91c7\u96c6\u79fb\u52a8\u4f4f\u77403\u53ea\u871c\u8702\u7684\u8702\u5de2",
    "advancements.husbandry.silk_touch_nest.title": "\u4e3e\u5de2\u642c\u8fc1",
    "advancements.husbandry.tactical_fishing.description": "\u4e0d\u7528\u9493\u9c7c\u7aff\u6293\u4f4f\u4e00\u6761\u9c7c\uff01",
    "advancements.husbandry.tactical_fishing.title": "\u6218\u672f\u6027\u9493\u9c7c",
    "advancements.husbandry.tame_an_animal.description": "\u9a6f\u670d\u4e00\u53ea\u52a8\u7269",
    "advancements.husbandry.tame_an_animal.title": "\u6c38\u6052\u7684\u4f19\u4f34",
    "advancements.husbandry.wax_off.description": "\u7ed9\u94dc\u5757\u8131\u8721\uff01",
    "advancements.husbandry.wax_off.title": "\u8131\u8721",
    "advancements.husbandry.wax_on.description": "\u5c06\u871c\u813e\u6d82\u5230\u94dc\u5757\u4e0a\uff01",
    "advancements.husbandry.wax_on.title": "\u6d82\u8721",
    "advancements.nether.all_effects.description": "\u540c\u65f6\u62e5\u6709\u6240\u6709\u72b6\u6001\u6548\u679c",
    "advancements.nether.all_effects.title": "\u4e3a\u4ec0\u4e48\u4f1a\u53d8\u6210\u8fd9\u6837\u5462\uff1f",
    "advancements.nether.all_potions.description": "\u540c\u65f6\u62e5\u6709\u6240\u6709\u836f\u6c34\u6548\u679c",
    "advancements.nether.all_potions.title": "\u72c2\u4e71\u7684\u9e21\u5c3e\u9152",
    "advancements.nether.brew_potion.description": "\u917f\u9020\u4e00\u74f6\u836f\u6c34",
    "advancements.nether.brew_potion.title": "\u672c\u5730\u917f\u9020\u5382",
    "advancements.nether.charge_respawn_anchor.description": "\u4e3a\u91cd\u751f\u951a\u5145\u6ee1\u80fd\u91cf",
    "advancements.nether.charge_respawn_anchor.title": "\u951a\u6ca1\u6709\u4e5d\u6761\u547d",
    "advancements.nether.create_beacon.description": "\u5efa\u9020\u5e76\u653e\u7f6e\u4e00\u5ea7\u4fe1\u6807",
    "advancements.nether.create_beacon.title": "\u5e26\u4fe1\u6807\u56de\u5bb6",
    "advancements.nether.create_full_beacon.description": "\u8ba9\u4e00\u5ea7\u4fe1\u6807\u53d1\u6325\u6700\u5927\u529f\u6548",
    "advancements.nether.create_full_beacon.title": "\u4fe1\u6807\u5de5\u7a0b\u5e08",
    "advancements.nether.distract_piglin.description": "\u7528\u91d1\u8d28\u7269\u54c1\u5438\u5f15\u732a\u7075",
    "advancements.nether.distract_piglin.title": "\u91d1\u5149\u95ea\u95ea",
    "advancements.nether.explore_nether.description": "\u63a2\u7d22\u6240\u6709\u4e0b\u754c\u751f\u7269\u7fa4\u7cfb",
    "advancements.nether.explore_nether.title": "\u70ed\u95e8\u666f\u70b9",
    "advancements.nether.fast_travel.description": "\u5229\u7528\u4e0b\u754c\u79fb\u52a8\u5bf9\u5e94\u4e3b\u4e16\u754c7\u5343\u7c73\u7684\u8ddd\u79bb",
    "advancements.nether.fast_travel.title": "\u66f2\u901f\u6ce1",
    "advancements.nether.find_bastion.description": "\u8fdb\u5165\u5821\u5792\u9057\u8ff9",
    "advancements.nether.find_bastion.title": "\u5149\u8f89\u5c81\u6708",
    "advancements.nether.find_fortress.description": "\u7528\u4f60\u7684\u65b9\u5f0f\u8fdb\u5165\u4e0b\u754c\u8981\u585e",
    "advancements.nether.find_fortress.title": "\u9634\u68ee\u7684\u8981\u585e",
    "advancements.nether.get_wither_skull.description": "\u83b7\u5f97\u51cb\u7075\u9ab7\u9ac5\u7684\u5934\u9885",
    "advancements.nether.get_wither_skull.title": "\u60ca\u609a\u6050\u6016\u9ab7\u9ac5\u5934",
    "advancements.nether.loot_bastion.description": "\u63a0\u593a\u5821\u5792\u9057\u8ff9\u91cc\u7684\u7bb1\u5b50",
    "advancements.nether.loot_bastion.title": "\u6218\u732a",
    "advancements.nether.netherite_armor.description": "\u83b7\u5f97\u4e00\u6574\u5957\u4e0b\u754c\u5408\u91d1\u76d4\u7532",
    "advancements.nether.netherite_armor.title": "\u6b8b\u9ab8\u88f9\u8eab",
    "advancements.nether.obtain_ancient_debris.description": "\u83b7\u5f97\u8fdc\u53e4\u6b8b\u9ab8",
    "advancements.nether.obtain_ancient_debris.title": "\u6df1\u85cf\u4e0d\u9732",
    "advancements.nether.obtain_blaze_rod.description": "\u8ba9\u70c8\u7130\u4eba\u4ece\u70c8\u7130\u68d2\u4e2d\u89e3\u653e\u5427",
    "advancements.nether.obtain_blaze_rod.title": "\u4e0e\u706b\u5171\u821e",
    "advancements.nether.obtain_crying_obsidian.description": "\u83b7\u5f97\u54ed\u6ce3\u7684\u9ed1\u66dc\u77f3",
    "advancements.nether.obtain_crying_obsidian.title": "\u8c01\u5728\u5207\u6d0b\u8471\uff1f",
    "advancements.nether.return_to_sender.description": "\u7528\u4e00\u56e2\u706b\u7403\u5e72\u6389\u4e00\u53ea\u6076\u9b42",
    "advancements.nether.return_to_sender.title": "\u89c1\u9b3c\u53bb\u5427",
    "advancements.nether.ride_strider.description": "\u624b\u6301\u8be1\u5f02\u83cc\u9493\u7aff\u9a91\u4e58\u70bd\u8db3\u517d",
    "advancements.nether.ride_strider.title": "\u753b\u8239\u6dfb\u8db3",
    "advancements.nether.ride_strider_in_overworld_lava.description": "\u5e26\u70bd\u8db3\u517d\u5728\u4e3b\u4e16\u754c\u7684\u7194\u5ca9\u6e56\u4e0a\u6765\u4e00\u573a\u957f\u2014\u2014\u9014\u65c5\u884c",
    "advancements.nether.ride_strider_in_overworld_lava.title": "\u6e29\u6696\u5982\u5bb6",
    "advancements.nether.root.description": "\u8bb0\u5f97\u5e26\u590f\u88c5",
    "advancements.nether.root.title": "\u4e0b\u754c",
    "advancements.nether.summon_wither.description": "\u53ec\u5524\u4e00\u53ea\u51cb\u7075",
    "advancements.nether.summon_wither.title": "\u51cb\u96f6\u5c71\u5e84",
    "advancements.nether.uneasy_alliance.description": "\u4ece\u4e0b\u754c\u6551\u51fa\u4e00\u53ea\u6076\u9b42\uff0c\u5c06\u5176\u5b89\u5168\u5730\u5e26\u5230\u4e3b\u4e16\u754c\u2026\u2026\u7136\u540e\u5e72\u6389\u5b83",
    "advancements.nether.uneasy_alliance.title": "\u8106\u5f31\u7684\u540c\u76df",
    "advancements.nether.use_lodestone.description": "\u5bf9\u7740\u78c1\u77f3\u4f7f\u7528\u6307\u5357\u9488",
    "advancements.nether.use_lodestone.title": "\u5929\u6daf\u5171\u6b64\u77f3",
    "advancements.sad_label": ":(",
    "advancements.story.cure_zombie_villager.description": "\u5f31\u5316\u5e76\u6cbb\u7597\u4e00\u540d\u50f5\u5c38\u6751\u6c11",
    "advancements.story.cure_zombie_villager.title": "\u50f5\u5c38\u79d1\u533b\u751f",
    "advancements.story.deflect_arrow.description": "\u7528\u76fe\u53cd\u5f39\u4e00\u4e2a\u5f39\u5c04\u7269",
    "advancements.story.deflect_arrow.title": "\u4e0d\u5403\u8fd9\u5957\uff0c\u8c22\u8c22",
    "advancements.story.enchant_item.description": "\u5728\u9644\u9b54\u53f0\u91cc\u9644\u9b54\u4e00\u6837\u7269\u54c1",
    "advancements.story.enchant_item.title": "\u9644\u9b54\u5e08",
    "advancements.story.enter_the_end.description": "\u8fdb\u5165\u672b\u5730\u4f20\u9001\u95e8",
    "advancements.story.enter_the_end.title": "\u7ed3\u675f\u4e86\uff1f",
    "advancements.story.enter_the_nether.description": "\u5efa\u9020\u3001\u542f\u52a8\u5e76\u8fdb\u5165\u4e00\u5ea7\u4e0b\u754c\u4f20\u9001\u95e8",
    "advancements.story.enter_the_nether.title": "\u52c7\u5f80\u76f4\u4e0b",
    "advancements.story.follow_ender_eye.description": "\u8ddf\u968f\u672b\u5f71\u4e4b\u773c",
    "advancements.story.follow_ender_eye.title": "\u9694\u5899\u6709\u773c",
    "advancements.story.form_obsidian.description": "\u83b7\u5f97\u4e00\u5757\u9ed1\u66dc\u77f3",
    "advancements.story.form_obsidian.title": "\u51b0\u6876\u6311\u6218",
    "advancements.story.iron_tools.description": "\u5347\u7ea7\u4f60\u7684\u9550",
    "advancements.story.iron_tools.title": "\u8fd9\u4e0d\u662f\u94c1\u9550\u4e48",
    "advancements.story.lava_bucket.description": "\u7528\u6876\u88c5\u70b9\u7194\u5ca9",
    "advancements.story.lava_bucket.title": "\u70ed\u817e\u817e\u7684",
    "advancements.story.mine_diamond.description": "\u83b7\u5f97\u94bb\u77f3",
    "advancements.story.mine_diamond.title": "\u94bb\u77f3\uff01",
    "advancements.story.mine_stone.description": "\u7528\u4f60\u7684\u65b0\u9550\u6316\u6398\u77f3\u5934",
    "advancements.story.mine_stone.title": "\u77f3\u5668\u65f6\u4ee3",
    "advancements.story.obtain_armor.description": "\u7528\u94c1\u76d4\u7532\u6765\u4fdd\u62a4\u4f60\u81ea\u5df1",
    "advancements.story.obtain_armor.title": "\u6574\u88c5\u4e0a\u9635",
    "advancements.story.root.description": "\u6e38\u620f\u7684\u6838\u5fc3\u4e0e\u6545\u4e8b",
    "advancements.story.root.title": "Minecraft",
    "advancements.story.shiny_gear.description": "\u94bb\u77f3\u76d4\u7532\u80fd\u6551\u4eba",
    "advancements.story.shiny_gear.title": "\u94bb\u77f3\u62a4\u4f53",
    "advancements.story.smelt_iron.description": "\u51b6\u70bc\u51fa\u4e00\u5757\u94c1\u952d",
    "advancements.story.smelt_iron.title": "\u6765\u786c\u7684",
    "advancements.story.upgrade_tools.description": "\u5236\u4f5c\u4e00\u628a\u66f4\u597d\u7684\u9550",
    "advancements.story.upgrade_tools.title": "\u83b7\u5f97\u5347\u7ea7",
    "advancements.toast.challenge": "\u6311\u6218\u5df2\u5b8c\u6210\uff01",
    "advancements.toast.goal": "\u76ee\u6807\u5df2\u8fbe\u6210\uff01",
    "advancements.toast.task": "\u8fdb\u5ea6\u5df2\u8fbe\u6210\uff01",
    "argument.anchor.invalid": "\u65e0\u6548\u7684\u5b9e\u4f53\u951a\u70b9%s",
    "argument.angle.incomplete": "\u4e0d\u5b8c\u6574\uff08\u9700\u89811\u4e2a\u89d2\u5ea6\uff09",
    "argument.angle.invalid": "\u65e0\u6548\u7684\u89d2\u5ea6",
    "argument.block.id.invalid": "\u672a\u77e5\u7684\u65b9\u5757\u7c7b\u578b'%s'",
    "argument.block.property.duplicate": "'%s'\u5c5e\u6027\u53ea\u80fd\u7ed9%s\u8bbe\u7f6e\u4e00\u6b21",
    "argument.block.property.invalid": "%1$s\u7684%3$s\u5c5e\u6027\u4e0d\u80fd\u88ab\u8bbe\u4e3a'%2$s'",
    "argument.block.property.novalue": "%s\u4e0a\u5fc5\u987b\u8981\u6709'%s'\u5c5e\u6027",
    "argument.block.property.unclosed": "\u65b9\u5757\u5c5e\u6027\u5e94\u4ee5]\u7ed3\u675f",
    "argument.block.property.unknown": "\u65b9\u5757%s\u6ca1\u6709\u5c5e\u6027'%s'",
    "argument.block.tag.disallowed": "\u65e0\u6cd5\u5728\u6b64\u4f7f\u7528\u6807\u7b7e\uff0c\u53ea\u5141\u8bb8\u4f7f\u7528\u5b9e\u9645\u7684\u65b9\u5757",
    "argument.color.invalid": "\u672a\u77e5\u7684\u989c\u8272'%s'",
    "argument.component.invalid": "\u65e0\u6548\u7684\u804a\u5929\u7ec4\u4ef6\uff1a %s",
    "argument.criteria.invalid": "\u672a\u77e5\u7684\u51c6\u5219\uff1a'%s'",
    "argument.dimension.invalid": "\u672a\u77e5\u7684\u7ef4\u5ea6'%s'",
    "argument.double.big": "\u53cc\u7cbe\u5ea6\u6d6e\u70b9\u578b\u6570\u636e\u4e0d\u80fd\u5927\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.double.low": "\u53cc\u7cbe\u5ea6\u6d6e\u70b9\u578b\u6570\u636e\u4e0d\u80fd\u5c0f\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.entity.invalid": "\u65e0\u6548\u7684\u540d\u79f0\u6216UUID",
    "argument.entity.notfound.entity": "\u672a\u627e\u5230\u5b9e\u4f53",
    "argument.entity.notfound.player": "\u672a\u627e\u5230\u73a9\u5bb6",
    "argument.entity.options.advancements.description": "\u73a9\u5bb6\u62e5\u6709\u7684\u8fdb\u5ea6",
    "argument.entity.options.distance.description": "\u4e0e\u5b9e\u4f53\u95f4\u7684\u8ddd\u79bb",
    "argument.entity.options.distance.negative": "\u8ddd\u79bb\u4e0d\u80fd\u4e3a\u8d1f",
    "argument.entity.options.dx.description": "\u4f4d\u4e8ex\u4e0ex+dx\u4e4b\u95f4\u7684\u5b9e\u4f53",
    "argument.entity.options.dy.description": "\u4f4d\u4e8ey\u4e0ey+dy\u4e4b\u95f4\u7684\u5b9e\u4f53",
    "argument.entity.options.dz.description": "\u4f4d\u4e8ez\u4e0ez+dz\u4e4b\u95f4\u7684\u5b9e\u4f53",
    "argument.entity.options.gamemode.description": "\u73a9\u5bb6\u7684\u6e38\u620f\u6a21\u5f0f",
    "argument.entity.options.inapplicable": "'%s'\u9009\u9879\u4e0d\u9002\u7528\u4e8e\u8fd9\u91cc",
    "argument.entity.options.level.description": "\u7ecf\u9a8c\u7b49\u7ea7",
    "argument.entity.options.level.negative": "\u7b49\u7ea7\u4e0d\u5e94\u8be5\u4e3a\u8d1f\u6570",
    "argument.entity.options.limit.description": "\u6700\u5927\u8fd4\u56de\u5b9e\u4f53\u6570",
    "argument.entity.options.limit.toosmall": "\u9650\u5236\u5fc5\u987b\u81f3\u5c11\u4e3a1",
    "argument.entity.options.mode.invalid": "\u65e0\u6548\u6216\u672a\u77e5\u7684\u6e38\u620f\u6a21\u5f0f'%s'",
    "argument.entity.options.name.description": "\u5b9e\u4f53\u540d\u79f0",
    "argument.entity.options.nbt.description": "\u5b9e\u4f53\u6240\u5e26\u7684NBT",
    "argument.entity.options.predicate.description": "\u81ea\u5b9a\u4e49\u6218\u5229\u54c1\u8868\u6761\u4ef6",
    "argument.entity.options.scores.description": "\u5b9e\u4f53\u7684\u5206\u6570",
    "argument.entity.options.sort.description": "\u5bf9\u5b9e\u4f53\u6392\u5e8f",
    "argument.entity.options.sort.irreversible": "\u65e0\u6548\u6216\u672a\u77e5\u7684\u6392\u5e8f\u7c7b\u578b'%s'",
    "argument.entity.options.tag.description": "\u5b9e\u4f53\u6240\u5e26\u7684\u6807\u7b7e",
    "argument.entity.options.team.description": "\u5b9e\u4f53\u6240\u5728\u7684\u961f\u4f0d",
    "argument.entity.options.type.description": "\u5b9e\u4f53\u7c7b\u578b",
    "argument.entity.options.type.invalid": "\u65e0\u6548\u6216\u672a\u77e5\u7684\u5b9e\u4f53\u7c7b\u578b'%s'",
    "argument.entity.options.unknown": "\u672a\u77e5\u7684\u9009\u9879'%s'",
    "argument.entity.options.unterminated": "\u9009\u9879\u7684\u65b9\u62ec\u53f7\u4e0d\u6210\u5bf9",
    "argument.entity.options.valueless": "\u9009\u9879'%s'\u9700\u8981\u6709\u503c",
    "argument.entity.options.x.description": "X\u8f74\u4f4d\u7f6e",
    "argument.entity.options.x_rotation.description": "\u5b9e\u4f53\u7684X\u8f74\u65cb\u8f6c\u89d2\u5ea6",
    "argument.entity.options.y.description": "Y\u8f74\u4f4d\u7f6e",
    "argument.entity.options.y_rotation.description": "\u5b9e\u4f53\u7684Y\u8f74\u65cb\u8f6c\u89d2\u5ea6",
    "argument.entity.options.z.description": "Z\u8f74\u4f4d\u7f6e",
    "argument.entity.selector.allEntities": "\u6240\u6709\u5b9e\u4f53",
    "argument.entity.selector.allPlayers": "\u6240\u6709\u73a9\u5bb6",
    "argument.entity.selector.missing": "\u7f3a\u5c11\u9009\u62e9\u5668\u7c7b\u578b",
    "argument.entity.selector.nearestPlayer": "\u8ddd\u79bb\u6700\u8fd1\u7684\u73a9\u5bb6",
    "argument.entity.selector.not_allowed": "\u4e0d\u80fd\u4f7f\u7528\u9009\u62e9\u5668",
    "argument.entity.selector.randomPlayer": "\u968f\u673a\u73a9\u5bb6",
    "argument.entity.selector.self": "\u5f53\u524d\u5b9e\u4f53",
    "argument.entity.selector.unknown": "\u672a\u77e5\u7684\u9009\u62e9\u5668\u7c7b\u578b'%s'",
    "argument.entity.toomany": "\u53ea\u5141\u8bb8\u4e00\u4e2a\u5b9e\u4f53\uff0c\u4f46\u63d0\u4f9b\u7684\u9009\u62e9\u5668\u5141\u8bb8\u591a\u4e2a\u5b9e\u4f53",
    "argument.float.big": "\u6d6e\u70b9\u578b\u6570\u636e\u4e0d\u80fd\u5927\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.float.low": "\u6d6e\u70b9\u578b\u6570\u636e\u4e0d\u80fd\u5c0f\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.id.invalid": "\u65e0\u6548\u7684ID",
    "argument.id.unknown": "\u672a\u77e5\u7684ID\uff1a%s",
    "argument.integer.big": "\u6574\u578b\u6570\u636e\u4e0d\u80fd\u5927\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.integer.low": "\u6574\u578b\u6570\u636e\u4e0d\u80fd\u5c0f\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.item.id.invalid": "\u672a\u77e5\u7684\u7269\u54c1'%s'",
    "argument.item.tag.disallowed": "\u65e0\u6cd5\u5728\u6b64\u4f7f\u7528\u6807\u7b7e\uff0c\u53ea\u5141\u8bb8\u4f7f\u7528\u5b9e\u9645\u7684\u7269\u54c1",
    "argument.literal.incorrect": "\u9700\u8981\u5b57\u9762\u91cf%s",
    "argument.long.big": "\u957f\u6574\u578b\u6570\u636e\u4e0d\u80fd\u5927\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.long.low": "\u957f\u6574\u578b\u6570\u636e\u4e0d\u80fd\u5c0f\u4e8e%s\uff0c\u4f46\u53d1\u73b0\u4e86%s",
    "argument.nbt.array.invalid": "\u65e0\u6548\u7684\u6570\u7ec4\u7c7b\u578b'%s'",
    "argument.nbt.array.mixed": "\u65e0\u6cd5\u5c06%s\u63d2\u5165%s",
    "argument.nbt.expected.key": "\u9700\u8981\u952e",
    "argument.nbt.expected.value": "\u9700\u8981\u503c",
    "argument.nbt.list.mixed": "\u65e0\u6cd5\u5c06%s\u63d2\u5165%s\u7684\u5217\u8868",
    "argument.nbt.trailing": "\u591a\u4f59\u7684\u5c3e\u968f\u6570\u636e",
    "argument.player.entities": "\u53ea\u6709\u73a9\u5bb6\u4f1a\u53d7\u6b64\u547d\u4ee4\u7684\u5f71\u54cd\uff0c\u4f46\u63d0\u4f9b\u7684\u9009\u62e9\u5668\u5305\u62ec\u5176\u5b83\u5b9e\u4f53",
    "argument.player.toomany": "\u53ea\u5141\u8bb8\u4e00\u540d\u73a9\u5bb6\uff0c\u4f46\u63d0\u4f9b\u7684\u9009\u62e9\u5668\u5141\u8bb8\u591a\u540d\u73a9\u5bb6",
    "argument.player.unknown": "\u8be5\u73a9\u5bb6\u4e0d\u5b58\u5728",
    "argument.pos.missing.double": "\u9700\u8981\u4e00\u4e2a\u5750\u6807",
    "argument.pos.missing.int": "\u9700\u8981\u4e00\u4e2a\u65b9\u5757\u7684\u4f4d\u7f6e",
    "argument.pos.mixed": "\u4e0d\u80fd\u6df7\u7528\u4e16\u754c\u4e0e\u5c40\u90e8\u5750\u6807\uff08\u5fc5\u987b\u5168\u90e8\u7528^\u6216\u90fd\u4e0d\u4f7f\u7528\uff09",
    "argument.pos.outofbounds": "\u8be5\u4f4d\u7f6e\u8d85\u51fa\u4e86\u5141\u8bb8\u7684\u8303\u56f4\u3002",
    "argument.pos.outofworld": "\u8be5\u4f4d\u7f6e\u5728\u8fd9\u4e16\u754c\u4e4b\u5916\uff01",
    "argument.pos.unloaded": "\u8be5\u4f4d\u7f6e\u5c1a\u672a\u88ab\u52a0\u8f7d",
    "argument.pos2d.incomplete": "\u4e0d\u5b8c\u6574\uff08\u9700\u89812\u4e2a\u5750\u6807\uff09",
    "argument.pos3d.incomplete": "\u4e0d\u5b8c\u6574\uff08\u9700\u89813\u4e2a\u5750\u6807\uff09",
    "argument.range.empty": "\u9700\u8981\u503c\u6216\u53d6\u503c\u8303\u56f4",
    "argument.range.ints": "\u53ea\u5141\u8bb8\u6574\u6570\uff0c\u4e0d\u5141\u8bb8\u5c0f\u6570",
    "argument.range.swapped": "\u6700\u5c0f\u503c\u4e0d\u80fd\u5927\u4e8e\u6700\u5927\u503c",
    "argument.rotation.incomplete": "\u4e0d\u5b8c\u6574\uff08\u9700\u89812\u4e2a\u5750\u6807\uff09",
    "argument.scoreHolder.empty": "\u627e\u4e0d\u5230\u4e0e\u5206\u6570\u5173\u8054\u7684\u6301\u6709\u8005",
    "argument.scoreboardDisplaySlot.invalid": "\u672a\u77e5\u7684\u663e\u793a\u4f4d\u7f6e'%s'",
    "argument.time.invalid_tick_count": "\u523b\u7684\u8ba1\u6570\u5fc5\u987b\u4e3a\u975e\u8d1f\u6570",
    "argument.time.invalid_unit": "\u65e0\u6548\u7684\u5355\u4f4d",
    "argument.uuid.invalid": "\u65e0\u6548\u7684UUID",
    "arguments.block.tag.unknown": "\u672a\u77e5\u7684\u65b9\u5757\u6807\u7b7e'%s'",
    "arguments.function.tag.unknown": "\u672a\u77e5\u7684\u51fd\u6570\u6807\u7b7e'%s'",
    "arguments.function.unknown": "\u672a\u77e5\u7684\u51fd\u6570%s",
    "arguments.item.overstacked": "%s\u53ea\u53ef\u4ee5\u5806\u53e0\u5230%s",
    "arguments.item.tag.unknown": "\u672a\u77e5\u7684\u7269\u54c1\u6807\u7b7e'%s'",
    "arguments.nbtpath.node.invalid": "\u65e0\u6548\u7684NBT\u8def\u5f84\u5143\u7d20",
    "arguments.nbtpath.nothing_found": "\u6ca1\u6709\u4e0e%s\u76f8\u5339\u914d\u7684\u5143\u7d20",
    "arguments.objective.notFound": "\u672a\u77e5\u7684\u8bb0\u5206\u9879'%s'",
    "arguments.objective.readonly": "\u8bb0\u5206\u9879'%s'\u4e3a\u53ea\u8bfb\u7c7b\u578b",
    "arguments.operation.div0": "\u4e0d\u80fd\u9664\u4ee5\u96f6",
    "arguments.operation.invalid": "\u65e0\u6548\u7684\u64cd\u4f5c",
    "arguments.swizzle.invalid": "\u65e0\u6548\u7684\u5750\u6807\u7ec4\u5408\uff0c\u9700\u8981'x'\u3001'y'\u548c'z'\u7684\u7ec4\u5408",
    "attribute.modifier.equals.0": "%s %s",
    "attribute.modifier.equals.1": "%s%% %s",
    "attribute.modifier.equals.2": "%s%% %s",
    "attribute.modifier.plus.0": "+%s %s",
    "attribute.modifier.plus.1": "+%s%% %s",
    "attribute.modifier.plus.2": "+%s%% %s",
    "attribute.modifier.take.0": "-%s %s",
    "attribute.modifier.take.1": "-%s%% %s",
    "attribute.modifier.take.2": "-%s%% %s",
    "attribute.name.generic.armor": "\u76d4\u7532",
    "attribute.name.generic.armor_toughness": "\u76d4\u7532\u97e7\u6027",
    "attribute.name.generic.attack_damage": "\u653b\u51fb\u4f24\u5bb3",
    "attribute.name.generic.attack_knockback": "\u51fb\u9000",
    "attribute.name.generic.attack_speed": "\u653b\u51fb\u901f\u5ea6",
    "attribute.name.generic.flying_speed": "\u98de\u884c\u901f\u5ea6",
    "attribute.name.generic.follow_range": "\u751f\u7269\u8ddf\u968f\u8ddd\u79bb",
    "attribute.name.generic.knockback_resistance": "\u51fb\u9000\u6297\u6027",
    "attribute.name.generic.luck": "\u5e78\u8fd0",
    "attribute.name.generic.max_health": "\u6700\u5927\u751f\u547d\u503c",
    "attribute.name.generic.movement_speed": "\u901f\u5ea6",
    "attribute.name.horse.jump_strength": "\u9a6c\u5339\u8df3\u8dc3\u80fd\u529b",
    "attribute.name.zombie.spawn_reinforcements": "\u50f5\u5c38\u589e\u63f4",
    "attribute.unknown": "\u672a\u77e5\u7684\u5c5e\u6027",
    "biome.minecraft.badlands": "\u6076\u5730",
    "biome.minecraft.bamboo_jungle": "\u7af9\u6797",
    "biome.minecraft.basalt_deltas": "\u7384\u6b66\u5ca9\u4e09\u89d2\u6d32",
    "biome.minecraft.beach": "\u6c99\u6ee9",
    "biome.minecraft.birch_forest": "\u6866\u6728\u68ee\u6797",
    "biome.minecraft.cold_ocean": "\u51b7\u6c34\u6d77\u6d0b",
    "biome.minecraft.crimson_forest": "\u7eef\u7ea2\u68ee\u6797",
    "biome.minecraft.dark_forest": "\u9ed1\u68ee\u6797",
    "biome.minecraft.deep_cold_ocean": "\u51b7\u6c34\u6df1\u6d77",
    "biome.minecraft.deep_frozen_ocean": "\u51b0\u51bb\u6df1\u6d77",
    "biome.minecraft.deep_lukewarm_ocean": "\u6e29\u6c34\u6df1\u6d77",
    "biome.minecraft.deep_ocean": "\u6df1\u6d77",
    "biome.minecraft.desert": "\u6c99\u6f20",
    "biome.minecraft.dripstone_caves": "\u6eb6\u6d1e",
    "biome.minecraft.end_barrens": "\u672b\u5730\u8352\u5c9b",
    "biome.minecraft.end_highlands": "\u672b\u5730\u9ad8\u5c9b",
    "biome.minecraft.end_midlands": "\u672b\u5730\u4e2d\u578b\u5c9b\u5c7f",
    "biome.minecraft.eroded_badlands": "\u88ab\u98ce\u8680\u7684\u6076\u5730",
    "biome.minecraft.flower_forest": "\u7e41\u82b1\u68ee\u6797",
    "biome.minecraft.forest": "\u68ee\u6797",
    "biome.minecraft.frozen_ocean": "\u51bb\u6d0b",
    "biome.minecraft.frozen_peaks": "\u51b0\u5c01\u5c71\u5cf0",
    "biome.minecraft.frozen_river": "\u51bb\u6cb3",
    "biome.minecraft.grove": "\u96ea\u6797",
    "biome.minecraft.ice_spikes": "\u51b0\u523a\u5e73\u539f",
    "biome.minecraft.jagged_peaks": "\u5c16\u5ced\u5c71\u5cf0",
    "biome.minecraft.jungle": "\u4e1b\u6797",
    "biome.minecraft.lukewarm_ocean": "\u6e29\u6c34\u6d77\u6d0b",
    "biome.minecraft.lush_caves": "\u7e41\u8302\u6d1e\u7a74",
    "biome.minecraft.meadow": "\u8349\u7538",
    "biome.minecraft.mushroom_fields": "\u8611\u83c7\u5c9b",
    "biome.minecraft.nether_wastes": "\u4e0b\u754c\u8352\u5730",
    "biome.minecraft.ocean": "\u6d77\u6d0b",
    "biome.minecraft.old_growth_birch_forest": "\u539f\u59cb\u6866\u6728\u68ee\u6797",
    "biome.minecraft.old_growth_pine_taiga": "\u539f\u59cb\u677e\u6728\u9488\u53f6\u6797",
    "biome.minecraft.old_growth_spruce_taiga": "\u539f\u59cb\u4e91\u6749\u9488\u53f6\u6797",
    "biome.minecraft.plains": "\u5e73\u539f",
    "biome.minecraft.river": "\u6cb3\u6d41",
    "biome.minecraft.savanna": "\u70ed\u5e26\u8349\u539f",
    "biome.minecraft.savanna_plateau": "\u70ed\u5e26\u9ad8\u539f",
    "biome.minecraft.small_end_islands": "\u672b\u5730\u5c0f\u578b\u5c9b\u5c7f",
    "biome.minecraft.snowy_beach": "\u79ef\u96ea\u7684\u6c99\u6ee9",
    "biome.minecraft.snowy_plains": "\u79ef\u96ea\u7684\u5e73\u539f",
    "biome.minecraft.snowy_slopes": "\u79ef\u96ea\u7684\u5c71\u5761",
    "biome.minecraft.snowy_taiga": "\u79ef\u96ea\u7684\u9488\u53f6\u6797",
    "biome.minecraft.soul_sand_valley": "\u7075\u9b42\u6c99\u5ce1\u8c37",
    "biome.minecraft.sparse_jungle": "\u7a00\u758f\u7684\u4e1b\u6797",
    "biome.minecraft.stony_peaks": "\u88f8\u5ca9\u5c71\u5cf0",
    "biome.minecraft.stony_shore": "\u77f3\u5cb8",
    "biome.minecraft.sunflower_plains": "\u5411\u65e5\u8475\u5e73\u539f",
    "biome.minecraft.swamp": "\u6cbc\u6cfd",
    "biome.minecraft.taiga": "\u9488\u53f6\u6797",
    "biome.minecraft.the_end": "\u672b\u5730",
    "biome.minecraft.the_void": "\u865a\u7a7a",
    "biome.minecraft.warm_ocean": "\u6696\u6c34\u6d77\u6d0b",
    "biome.minecraft.warped_forest": "\u8be1\u5f02\u68ee\u6797",
    "biome.minecraft.windswept_forest": "\u98ce\u88ad\u68ee\u6797",
    "biome.minecraft.windswept_gravelly_hills": "\u98ce\u88ad\u6c99\u783e\u4e18\u9675",
    "biome.minecraft.windswept_hills": "\u98ce\u88ad\u4e18\u9675",
    "biome.minecraft.windswept_savanna": "\u98ce\u88ad\u70ed\u5e26\u8349\u539f",
    "biome.minecraft.wooded_badlands": "\u7e41\u8302\u7684\u6076\u5730",
    "block.minecraft.acacia_button": "\u91d1\u5408\u6b22\u6728\u6309\u94ae",
    "block.minecraft.acacia_door": "\u91d1\u5408\u6b22\u6728\u95e8",
    "block.minecraft.acacia_fence": "\u91d1\u5408\u6b22\u6728\u6805\u680f",
    "block.minecraft.acacia_fence_gate": "\u91d1\u5408\u6b22\u6728\u6805\u680f\u95e8",
    "block.minecraft.acacia_leaves": "\u91d1\u5408\u6b22\u6811\u53f6",
    "block.minecraft.acacia_log": "\u91d1\u5408\u6b22\u539f\u6728",
    "block.minecraft.acacia_planks": "\u91d1\u5408\u6b22\u6728\u677f",
    "block.minecraft.acacia_pressure_plate": "\u91d1\u5408\u6b22\u6728\u538b\u529b\u677f",
    "block.minecraft.acacia_sapling": "\u91d1\u5408\u6b22\u6811\u82d7",
    "block.minecraft.acacia_sign": "\u91d1\u5408\u6b22\u6728\u544a\u793a\u724c",
    "block.minecraft.acacia_slab": "\u91d1\u5408\u6b22\u6728\u53f0\u9636",
    "block.minecraft.acacia_stairs": "\u91d1\u5408\u6b22\u6728\u697c\u68af",
    "block.minecraft.acacia_trapdoor": "\u91d1\u5408\u6b22\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.acacia_wall_sign": "\u5899\u4e0a\u7684\u91d1\u5408\u6b22\u6728\u544a\u793a\u724c",
    "block.minecraft.acacia_wood": "\u91d1\u5408\u6b22\u6728",
    "block.minecraft.activator_rail": "\u6fc0\u6d3b\u94c1\u8f68",
    "block.minecraft.air": "\u7a7a\u6c14",
    "block.minecraft.allium": "\u7ed2\u7403\u8471",
    "block.minecraft.amethyst_block": "\u7d2b\u6c34\u6676\u5757",
    "block.minecraft.amethyst_cluster": "\u7d2b\u6c34\u6676\u7c07",
    "block.minecraft.ancient_debris": "\u8fdc\u53e4\u6b8b\u9ab8",
    "block.minecraft.andesite": "\u5b89\u5c71\u5ca9",
    "block.minecraft.andesite_slab": "\u5b89\u5c71\u5ca9\u53f0\u9636",
    "block.minecraft.andesite_stairs": "\u5b89\u5c71\u5ca9\u697c\u68af",
    "block.minecraft.andesite_wall": "\u5b89\u5c71\u5ca9\u5899",
    "block.minecraft.anvil": "\u94c1\u7827",
    "block.minecraft.attached_melon_stem": "\u7ed3\u679c\u7684\u897f\u74dc\u830e",
    "block.minecraft.attached_pumpkin_stem": "\u7ed3\u679c\u7684\u5357\u74dc\u830e",
    "block.minecraft.azalea": "\u675c\u9e43\u82b1\u4e1b",
    "block.minecraft.azalea_leaves": "\u675c\u9e43\u6811\u53f6",
    "block.minecraft.azure_bluet": "\u84dd\u82b1\u7f8e\u8033\u8349",
    "block.minecraft.bamboo": "\u7af9\u5b50",
    "block.minecraft.bamboo_sapling": "\u7af9\u7b0b",
    "block.minecraft.banner.base.black": "\u9ed1\u5e95",
    "block.minecraft.banner.base.blue": "\u84dd\u5e95",
    "block.minecraft.banner.base.brown": "\u68d5\u5e95",
    "block.minecraft.banner.base.cyan": "\u9752\u5e95",
    "block.minecraft.banner.base.gray": "\u7070\u5e95",
    "block.minecraft.banner.base.green": "\u7eff\u5e95",
    "block.minecraft.banner.base.light_blue": "\u6de1\u84dd\u5e95",
    "block.minecraft.banner.base.light_gray": "\u6de1\u7070\u5e95",
    "block.minecraft.banner.base.lime": "\u9ec4\u7eff\u5e95",
    "block.minecraft.banner.base.magenta": "\u54c1\u7ea2\u5e95",
    "block.minecraft.banner.base.orange": "\u6a59\u5e95",
    "block.minecraft.banner.base.pink": "\u7c89\u7ea2\u5e95",
    "block.minecraft.banner.base.purple": "\u7d2b\u5e95",
    "block.minecraft.banner.base.red": "\u7ea2\u5e95",
    "block.minecraft.banner.base.white": "\u767d\u5e95",
    "block.minecraft.banner.base.yellow": "\u9ec4\u5e95",
    "block.minecraft.banner.border.black": "\u9ed1\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.blue": "\u84dd\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.brown": "\u68d5\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.cyan": "\u9752\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.gray": "\u7070\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.green": "\u7eff\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.light_blue": "\u6de1\u84dd\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.light_gray": "\u6de1\u7070\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.lime": "\u9ec4\u7eff\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.magenta": "\u54c1\u7ea2\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.orange": "\u6a59\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.pink": "\u7c89\u7ea2\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.purple": "\u7d2b\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.red": "\u7ea2\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.white": "\u767d\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.border.yellow": "\u9ec4\u8272\u65b9\u6846\u8fb9",
    "block.minecraft.banner.bricks.black": "\u9ed1\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.blue": "\u84dd\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.brown": "\u68d5\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.cyan": "\u9752\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.gray": "\u7070\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.green": "\u7eff\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.light_blue": "\u6de1\u84dd\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.light_gray": "\u6de1\u7070\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.lime": "\u9ec4\u7eff\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.magenta": "\u54c1\u7ea2\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.orange": "\u6a59\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.pink": "\u7c89\u7ea2\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.purple": "\u7d2b\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.red": "\u7ea2\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.white": "\u767d\u8272\u7816\u7eb9",
    "block.minecraft.banner.bricks.yellow": "\u9ec4\u8272\u7816\u7eb9",
    "block.minecraft.banner.circle.black": "\u9ed1\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.blue": "\u84dd\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.brown": "\u68d5\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.cyan": "\u9752\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.gray": "\u7070\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.green": "\u7eff\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.light_blue": "\u6de1\u84dd\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.light_gray": "\u6de1\u7070\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.lime": "\u9ec4\u7eff\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.magenta": "\u54c1\u7ea2\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.orange": "\u6a59\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.pink": "\u7c89\u7ea2\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.purple": "\u7d2b\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.red": "\u7ea2\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.white": "\u767d\u8272\u5706\u5f62",
    "block.minecraft.banner.circle.yellow": "\u9ec4\u8272\u5706\u5f62",
    "block.minecraft.banner.creeper.black": "\u9ed1\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.blue": "\u84dd\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.brown": "\u68d5\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.cyan": "\u9752\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.gray": "\u7070\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.green": "\u7eff\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.light_blue": "\u6de1\u84dd\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.light_gray": "\u6de1\u7070\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.lime": "\u9ec4\u7eff\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.magenta": "\u54c1\u7ea2\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.orange": "\u6a59\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.pink": "\u7c89\u7ea2\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.purple": "\u7d2b\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.red": "\u7ea2\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.white": "\u767d\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.creeper.yellow": "\u9ec4\u8272\u82e6\u529b\u6015\u76fe\u5fbd",
    "block.minecraft.banner.cross.black": "\u9ed1\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.blue": "\u84dd\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.brown": "\u68d5\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.cyan": "\u9752\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.gray": "\u7070\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.green": "\u7eff\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.light_blue": "\u6de1\u84dd\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.light_gray": "\u6de1\u7070\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.lime": "\u9ec4\u7eff\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.magenta": "\u54c1\u7ea2\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.orange": "\u6a59\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.pink": "\u7c89\u7ea2\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.purple": "\u7d2b\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.red": "\u7ea2\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.white": "\u767d\u659c\u5341\u5b57",
    "block.minecraft.banner.cross.yellow": "\u9ec4\u659c\u5341\u5b57",
    "block.minecraft.banner.curly_border.black": "\u9ed1\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.blue": "\u84dd\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.brown": "\u68d5\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.cyan": "\u9752\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.gray": "\u7070\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.green": "\u7eff\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.light_blue": "\u6de1\u84dd\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.light_gray": "\u6de1\u7070\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.lime": "\u9ec4\u7eff\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.magenta": "\u54c1\u7ea2\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.orange": "\u6a59\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.pink": "\u7c89\u7ea2\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.purple": "\u7d2b\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.red": "\u7ea2\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.white": "\u767d\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.curly_border.yellow": "\u9ec4\u8272\u6ce2\u7eb9\u8fb9",
    "block.minecraft.banner.diagonal_left.black": "\u9ed1\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.blue": "\u84dd\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.brown": "\u68d5\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.cyan": "\u9752\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.gray": "\u7070\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.green": "\u7eff\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.light_blue": "\u6de1\u84dd\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.light_gray": "\u6de1\u7070\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.lime": "\u9ec4\u7eff\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.magenta": "\u54c1\u7ea2\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.orange": "\u6a59\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.pink": "\u7c89\u7ea2\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.purple": "\u7d2b\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.red": "\u7ea2\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.white": "\u767d\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_left.yellow": "\u9ec4\u8272\u53f3\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.black": "\u9ed1\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.blue": "\u84dd\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.brown": "\u68d5\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.cyan": "\u9752\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.gray": "\u7070\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.green": "\u7eff\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.light_blue": "\u6de1\u84dd\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.light_gray": "\u6de1\u7070\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.lime": "\u9ec4\u7eff\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.magenta": "\u54c1\u7ea2\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.orange": "\u6a59\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.pink": "\u7c89\u7ea2\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.purple": "\u7d2b\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.red": "\u7ea2\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.white": "\u767d\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_right.yellow": "\u9ec4\u8272\u5de6\u4e0a\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.black": "\u9ed1\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.blue": "\u84dd\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.brown": "\u68d5\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.cyan": "\u9752\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.gray": "\u7070\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.green": "\u7eff\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.light_blue": "\u6de1\u84dd\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.light_gray": "\u6de1\u7070\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.lime": "\u9ec4\u7eff\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.magenta": "\u54c1\u7ea2\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.orange": "\u6a59\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.pink": "\u7c89\u7ea2\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.purple": "\u7d2b\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.red": "\u7ea2\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.white": "\u767d\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_left.yellow": "\u9ec4\u8272\u53f3\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.black": "\u9ed1\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.blue": "\u84dd\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.brown": "\u68d5\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.cyan": "\u9752\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.gray": "\u7070\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.green": "\u7eff\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.light_blue": "\u6de1\u84dd\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.light_gray": "\u6de1\u7070\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.lime": "\u9ec4\u7eff\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.magenta": "\u54c1\u7ea2\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.orange": "\u6a59\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.pink": "\u7c89\u7ea2\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.purple": "\u7d2b\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.red": "\u7ea2\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.white": "\u767d\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.diagonal_up_right.yellow": "\u9ec4\u8272\u5de6\u4e0b\u4e09\u89d2",
    "block.minecraft.banner.flower.black": "\u9ed1\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.blue": "\u84dd\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.brown": "\u68d5\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.cyan": "\u9752\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.gray": "\u7070\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.green": "\u7eff\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.light_blue": "\u6de1\u84dd\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.light_gray": "\u6de1\u7070\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.lime": "\u9ec4\u7eff\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.magenta": "\u54c1\u7ea2\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.orange": "\u6a59\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.pink": "\u7c89\u7ea2\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.purple": "\u7d2b\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.red": "\u7ea2\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.white": "\u767d\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.flower.yellow": "\u9ec4\u8272\u82b1\u6735\u76fe\u5fbd",
    "block.minecraft.banner.globe.black": "\u9ed1\u8272\u5730\u7403",
    "block.minecraft.banner.globe.blue": "\u84dd\u8272\u5730\u7403",
    "block.minecraft.banner.globe.brown": "\u68d5\u8272\u5730\u7403",
    "block.minecraft.banner.globe.cyan": "\u9752\u8272\u5730\u7403",
    "block.minecraft.banner.globe.gray": "\u7070\u8272\u5730\u7403",
    "block.minecraft.banner.globe.green": "\u7eff\u8272\u5730\u7403",
    "block.minecraft.banner.globe.light_blue": "\u6de1\u84dd\u8272\u5730\u7403",
    "block.minecraft.banner.globe.light_gray": "\u6de1\u7070\u8272\u5730\u7403",
    "block.minecraft.banner.globe.lime": "\u9ec4\u7eff\u8272\u5730\u7403",
    "block.minecraft.banner.globe.magenta": "\u54c1\u7ea2\u8272\u5730\u7403",
    "block.minecraft.banner.globe.orange": "\u6a59\u8272\u5730\u7403",
    "block.minecraft.banner.globe.pink": "\u7c89\u7ea2\u8272\u5730\u7403",
    "block.minecraft.banner.globe.purple": "\u7d2b\u8272\u5730\u7403",
    "block.minecraft.banner.globe.red": "\u7ea2\u8272\u5730\u7403",
    "block.minecraft.banner.globe.white": "\u767d\u8272\u5730\u7403",
    "block.minecraft.banner.globe.yellow": "\u9ec4\u8272\u5730\u7403",
    "block.minecraft.banner.gradient.black": "\u9ed1\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.blue": "\u84dd\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.brown": "\u68d5\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.cyan": "\u9752\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.gray": "\u7070\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.green": "\u7eff\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.light_blue": "\u6de1\u84dd\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.light_gray": "\u6de1\u7070\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.lime": "\u9ec4\u7eff\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.magenta": "\u54c1\u7ea2\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.orange": "\u6a59\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.pink": "\u7c89\u7ea2\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.purple": "\u7d2b\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.red": "\u7ea2\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.white": "\u767d\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient.yellow": "\u9ec4\u8272\u81ea\u4e0a\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.black": "\u9ed1\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.blue": "\u84dd\u8272\u81ea\u4e0b\u6e10\u53d8",
    "block.minecraft.banner.gradient_up.brown": "\u68d5\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.cyan": "\u9752\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.gray": "\u7070\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.green": "\u7eff\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.light_blue": "\u6de1\u84dd\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.light_gray": "\u6de1\u7070\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.lime": "\u9ec4\u7eff\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.magenta": "\u54c1\u7ea2\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.orange": "\u6a59\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.pink": "\u7c89\u7ea2\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.purple": "\u7d2b\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.red": "\u7ea2\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.white": "\u767d\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.gradient_up.yellow": "\u9ec4\u8272\u81ea\u4e0b\u6e10\u6de1",
    "block.minecraft.banner.half_horizontal.black": "\u9ed1\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.blue": "\u84dd\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.brown": "\u68d5\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.cyan": "\u9752\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.gray": "\u7070\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.green": "\u7eff\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.light_blue": "\u6de1\u84dd\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.light_gray": "\u6de1\u7070\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.lime": "\u9ec4\u7eff\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.magenta": "\u54c1\u7ea2\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.orange": "\u6a59\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.pink": "\u7c89\u7ea2\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.purple": "\u7d2b\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.red": "\u7ea2\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.white": "\u767d\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal.yellow": "\u9ec4\u8272\u4e0a\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.black": "\u9ed1\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.blue": "\u84dd\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.brown": "\u68d5\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.cyan": "\u9752\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.gray": "\u7070\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.green": "\u7eff\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.light_blue": "\u6de1\u84dd\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.light_gray": "\u6de1\u7070\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.lime": "\u9ec4\u7eff\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.magenta": "\u54c1\u7ea2\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.orange": "\u6a59\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.pink": "\u7c89\u7ea2\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.purple": "\u7d2b\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.red": "\u7ea2\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.white": "\u767d\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_horizontal_bottom.yellow": "\u9ec4\u8272\u4e0b\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.black": "\u9ed1\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.blue": "\u84dd\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.brown": "\u68d5\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.cyan": "\u9752\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.gray": "\u7070\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.green": "\u7eff\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.light_blue": "\u6de1\u84dd\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.light_gray": "\u6de1\u7070\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.lime": "\u9ec4\u7eff\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.magenta": "\u54c1\u7ea2\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.orange": "\u6a59\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.pink": "\u7c89\u7ea2\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.purple": "\u7d2b\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.red": "\u7ea2\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.white": "\u767d\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical.yellow": "\u9ec4\u8272\u53f3\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.black": "\u9ed1\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.blue": "\u84dd\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.brown": "\u68d5\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.cyan": "\u9752\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.gray": "\u7070\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.green": "\u7eff\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.light_blue": "\u6de1\u84dd\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.light_gray": "\u6de1\u7070\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.lime": "\u9ec4\u7eff\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.magenta": "\u54c1\u7ea2\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.orange": "\u6a59\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.pink": "\u7c89\u7ea2\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.purple": "\u7d2b\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.red": "\u7ea2\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.white": "\u767d\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.half_vertical_right.yellow": "\u9ec4\u8272\u5de6\u534a\u65b9\u5f62",
    "block.minecraft.banner.mojang.black": "\u9ed1\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.blue": "\u84dd\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.brown": "\u68d5\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.cyan": "\u9752\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.gray": "\u7070\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.green": "\u7eff\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.light_blue": "\u6de1\u84dd\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.light_gray": "\u6de1\u7070\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.lime": "\u9ec4\u7eff\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.magenta": "\u54c1\u7ea2\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.orange": "\u6a59\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.pink": "\u7c89\u7ea2\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.purple": "\u7d2b\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.red": "\u7ea2\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.white": "\u767d\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.mojang.yellow": "\u9ec4\u8272Mojang\u5fbd\u6807",
    "block.minecraft.banner.piglin.black": "\u9ed1\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.blue": "\u84dd\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.brown": "\u68d5\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.cyan": "\u9752\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.gray": "\u7070\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.green": "\u7eff\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.light_blue": "\u6de1\u84dd\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.light_gray": "\u6de1\u7070\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.lime": "\u9ec4\u7eff\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.magenta": "\u54c1\u7ea2\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.orange": "\u6a59\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.pink": "\u7c89\u7ea2\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.purple": "\u7d2b\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.red": "\u7ea2\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.white": "\u767d\u8272\u732a\u9f3b",
    "block.minecraft.banner.piglin.yellow": "\u9ec4\u8272\u732a\u9f3b",
    "block.minecraft.banner.rhombus.black": "\u9ed1\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.blue": "\u84dd\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.brown": "\u68d5\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.cyan": "\u9752\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.gray": "\u7070\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.green": "\u7eff\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.light_blue": "\u6de1\u84dd\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.light_gray": "\u6de1\u7070\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.lime": "\u9ec4\u7eff\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.magenta": "\u54c1\u7ea2\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.orange": "\u6a59\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.pink": "\u7c89\u7ea2\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.purple": "\u7d2b\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.red": "\u7ea2\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.white": "\u767d\u8272\u83f1\u5f62",
    "block.minecraft.banner.rhombus.yellow": "\u9ec4\u8272\u83f1\u5f62",
    "block.minecraft.banner.skull.black": "\u9ed1\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.blue": "\u84dd\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.brown": "\u68d5\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.cyan": "\u9752\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.gray": "\u7070\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.green": "\u7eff\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.light_blue": "\u6de1\u84dd\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.light_gray": "\u6de1\u7070\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.lime": "\u9ec4\u7eff\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.magenta": "\u54c1\u7ea2\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.orange": "\u6a59\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.pink": "\u7c89\u7ea2\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.purple": "\u7d2b\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.red": "\u7ea2\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.white": "\u767d\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.skull.yellow": "\u9ec4\u8272\u5934\u9885\u76fe\u5fbd",
    "block.minecraft.banner.small_stripes.black": "\u9ed1\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.blue": "\u84dd\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.brown": "\u68d5\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.cyan": "\u9752\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.gray": "\u7070\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.green": "\u7eff\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.light_blue": "\u6de1\u84dd\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.light_gray": "\u6de1\u7070\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.lime": "\u9ec4\u7eff\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.magenta": "\u54c1\u7ea2\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.orange": "\u6a59\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.pink": "\u7c89\u7ea2\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.purple": "\u7d2b\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.red": "\u7ea2\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.white": "\u767d\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.small_stripes.yellow": "\u9ec4\u7ad6\u6761\u7eb9",
    "block.minecraft.banner.square_bottom_left.black": "\u53f3\u5e95\u9ed1\u65b9",
    "block.minecraft.banner.square_bottom_left.blue": "\u53f3\u5e95\u84dd\u65b9",
    "block.minecraft.banner.square_bottom_left.brown": "\u53f3\u5e95\u68d5\u65b9",
    "block.minecraft.banner.square_bottom_left.cyan": "\u53f3\u5e95\u9752\u65b9",
    "block.minecraft.banner.square_bottom_left.gray": "\u53f3\u5e95\u7070\u65b9",
    "block.minecraft.banner.square_bottom_left.green": "\u53f3\u5e95\u7eff\u65b9",
    "block.minecraft.banner.square_bottom_left.light_blue": "\u53f3\u5e95\u6de1\u84dd\u65b9",
    "block.minecraft.banner.square_bottom_left.light_gray": "\u53f3\u5e95\u6de1\u7070\u65b9",
    "block.minecraft.banner.square_bottom_left.lime": "\u53f3\u5e95\u9ec4\u7eff\u65b9",
    "block.minecraft.banner.square_bottom_left.magenta": "\u53f3\u5e95\u54c1\u7ea2\u65b9",
    "block.minecraft.banner.square_bottom_left.orange": "\u53f3\u5e95\u6a59\u65b9",
    "block.minecraft.banner.square_bottom_left.pink": "\u53f3\u5e95\u7c89\u7ea2\u65b9",
    "block.minecraft.banner.square_bottom_left.purple": "\u53f3\u5e95\u7d2b\u65b9",
    "block.minecraft.banner.square_bottom_left.red": "\u53f3\u5e95\u7ea2\u65b9",
    "block.minecraft.banner.square_bottom_left.white": "\u53f3\u5e95\u767d\u65b9",
    "block.minecraft.banner.square_bottom_left.yellow": "\u53f3\u5e95\u9ec4\u65b9",
    "block.minecraft.banner.square_bottom_right.black": "\u5de6\u5e95\u9ed1\u65b9",
    "block.minecraft.banner.square_bottom_right.blue": "\u5de6\u5e95\u84dd\u65b9",
    "block.minecraft.banner.square_bottom_right.brown": "\u5de6\u5e95\u68d5\u65b9",
    "block.minecraft.banner.square_bottom_right.cyan": "\u5de6\u5e95\u9752\u65b9",
    "block.minecraft.banner.square_bottom_right.gray": "\u5de6\u5e95\u7070\u65b9",
    "block.minecraft.banner.square_bottom_right.green": "\u5de6\u5e95\u7eff\u65b9",
    "block.minecraft.banner.square_bottom_right.light_blue": "\u5de6\u5e95\u6de1\u84dd\u65b9",
    "block.minecraft.banner.square_bottom_right.light_gray": "\u5de6\u5e95\u6de1\u7070\u65b9",
    "block.minecraft.banner.square_bottom_right.lime": "\u5de6\u5e95\u9ec4\u7eff\u65b9",
    "block.minecraft.banner.square_bottom_right.magenta": "\u5de6\u5e95\u54c1\u7ea2\u65b9",
    "block.minecraft.banner.square_bottom_right.orange": "\u5de6\u5e95\u6a59\u65b9",
    "block.minecraft.banner.square_bottom_right.pink": "\u5de6\u5e95\u7c89\u7ea2\u65b9",
    "block.minecraft.banner.square_bottom_right.purple": "\u5de6\u5e95\u7d2b\u65b9",
    "block.minecraft.banner.square_bottom_right.red": "\u5de6\u5e95\u7ea2\u65b9",
    "block.minecraft.banner.square_bottom_right.white": "\u5de6\u5e95\u767d\u65b9",
    "block.minecraft.banner.square_bottom_right.yellow": "\u5de6\u5e95\u9ec4\u65b9",
    "block.minecraft.banner.square_top_left.black": "\u53f3\u9876\u9ed1\u65b9",
    "block.minecraft.banner.square_top_left.blue": "\u53f3\u9876\u84dd\u65b9",
    "block.minecraft.banner.square_top_left.brown": "\u53f3\u9876\u68d5\u65b9",
    "block.minecraft.banner.square_top_left.cyan": "\u53f3\u9876\u9752\u65b9",
    "block.minecraft.banner.square_top_left.gray": "\u53f3\u9876\u7070\u65b9",
    "block.minecraft.banner.square_top_left.green": "\u53f3\u9876\u7eff\u65b9",
    "block.minecraft.banner.square_top_left.light_blue": "\u53f3\u9876\u6de1\u84dd\u65b9",
    "block.minecraft.banner.square_top_left.light_gray": "\u53f3\u9876\u6de1\u7070\u65b9",
    "block.minecraft.banner.square_top_left.lime": "\u53f3\u9876\u9ec4\u7eff\u65b9",
    "block.minecraft.banner.square_top_left.magenta": "\u53f3\u9876\u54c1\u7ea2\u65b9",
    "block.minecraft.banner.square_top_left.orange": "\u53f3\u9876\u6a59\u65b9",
    "block.minecraft.banner.square_top_left.pink": "\u53f3\u9876\u7c89\u7ea2\u65b9",
    "block.minecraft.banner.square_top_left.purple": "\u53f3\u9876\u7d2b\u65b9",
    "block.minecraft.banner.square_top_left.red": "\u53f3\u9876\u7ea2\u65b9",
    "block.minecraft.banner.square_top_left.white": "\u53f3\u9876\u767d\u65b9",
    "block.minecraft.banner.square_top_left.yellow": "\u53f3\u9876\u9ec4\u65b9",
    "block.minecraft.banner.square_top_right.black": "\u5de6\u9876\u9ed1\u65b9",
    "block.minecraft.banner.square_top_right.blue": "\u5de6\u9876\u84dd\u65b9",
    "block.minecraft.banner.square_top_right.brown": "\u5de6\u9876\u68d5\u65b9",
    "block.minecraft.banner.square_top_right.cyan": "\u5de6\u9876\u9752\u65b9",
    "block.minecraft.banner.square_top_right.gray": "\u5de6\u9876\u7070\u65b9",
    "block.minecraft.banner.square_top_right.green": "\u5de6\u9876\u7eff\u65b9",
    "block.minecraft.banner.square_top_right.light_blue": "\u5de6\u9876\u6de1\u84dd\u65b9",
    "block.minecraft.banner.square_top_right.light_gray": "\u5de6\u9876\u6de1\u7070\u65b9",
    "block.minecraft.banner.square_top_right.lime": "\u5de6\u9876\u9ec4\u7eff\u65b9",
    "block.minecraft.banner.square_top_right.magenta": "\u5de6\u9876\u54c1\u7ea2\u65b9",
    "block.minecraft.banner.square_top_right.orange": "\u5de6\u9876\u6a59\u65b9",
    "block.minecraft.banner.square_top_right.pink": "\u5de6\u9876\u7c89\u7ea2\u65b9",
    "block.minecraft.banner.square_top_right.purple": "\u5de6\u9876\u7d2b\u65b9",
    "block.minecraft.banner.square_top_right.red": "\u5de6\u9876\u7ea2\u65b9",
    "block.minecraft.banner.square_top_right.white": "\u5de6\u9876\u767d\u65b9",
    "block.minecraft.banner.square_top_right.yellow": "\u5de6\u9876\u9ec4\u65b9",
    "block.minecraft.banner.straight_cross.black": "\u9ed1\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.blue": "\u84dd\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.brown": "\u68d5\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.cyan": "\u9752\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.gray": "\u7070\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.green": "\u7eff\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.light_blue": "\u6de1\u84dd\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.light_gray": "\u6de1\u7070\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.lime": "\u9ec4\u7eff\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.magenta": "\u54c1\u7ea2\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.orange": "\u6a59\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.pink": "\u7c89\u7ea2\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.purple": "\u7d2b\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.red": "\u7ea2\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.white": "\u767d\u6b63\u5341\u5b57",
    "block.minecraft.banner.straight_cross.yellow": "\u9ec4\u6b63\u5341\u5b57",
    "block.minecraft.banner.stripe_bottom.black": "\u5e95\u9ed1\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.blue": "\u5e95\u84dd\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.brown": "\u5e95\u68d5\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.cyan": "\u5e95\u9752\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.gray": "\u5e95\u7070\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.green": "\u5e95\u7eff\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.light_blue": "\u5e95\u6de1\u84dd\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.light_gray": "\u5e95\u6de1\u7070\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.lime": "\u5e95\u9ec4\u7eff\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.magenta": "\u5e95\u54c1\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.orange": "\u5e95\u6a59\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.pink": "\u5e95\u7c89\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.purple": "\u5e95\u7d2b\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.red": "\u5e95\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.white": "\u5e95\u767d\u6a2a\u6761",
    "block.minecraft.banner.stripe_bottom.yellow": "\u5e95\u9ec4\u6a2a\u6761",
    "block.minecraft.banner.stripe_center.black": "\u4e2d\u9ed1\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.blue": "\u4e2d\u84dd\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.brown": "\u4e2d\u68d5\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.cyan": "\u4e2d\u9752\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.gray": "\u4e2d\u7070\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.green": "\u4e2d\u7eff\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.light_blue": "\u4e2d\u6de1\u84dd\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.light_gray": "\u4e2d\u6de1\u7070\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.lime": "\u4e2d\u9ec4\u7eff\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.magenta": "\u4e2d\u54c1\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.orange": "\u4e2d\u6a59\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.pink": "\u4e2d\u7c89\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.purple": "\u4e2d\u7d2b\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.red": "\u4e2d\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.white": "\u4e2d\u767d\u7ad6\u6761",
    "block.minecraft.banner.stripe_center.yellow": "\u4e2d\u9ec4\u7ad6\u6761",
    "block.minecraft.banner.stripe_downleft.black": "\u5de6\u9ed1\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.blue": "\u5de6\u84dd\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.brown": "\u5de6\u68d5\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.cyan": "\u5de6\u9752\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.gray": "\u5de6\u7070\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.green": "\u5de6\u7eff\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.light_blue": "\u5de6\u6de1\u84dd\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.light_gray": "\u5de6\u6de1\u7070\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.lime": "\u5de6\u9ec4\u7eff\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.magenta": "\u5de6\u54c1\u7ea2\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.orange": "\u5de6\u6a59\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.pink": "\u5de6\u7c89\u7ea2\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.purple": "\u5de6\u7d2b\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.red": "\u5de6\u7ea2\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.white": "\u5de6\u767d\u659c\u6761",
    "block.minecraft.banner.stripe_downleft.yellow": "\u5de6\u9ec4\u659c\u6761",
    "block.minecraft.banner.stripe_downright.black": "\u53f3\u9ed1\u659c\u6761",
    "block.minecraft.banner.stripe_downright.blue": "\u53f3\u84dd\u659c\u6761",
    "block.minecraft.banner.stripe_downright.brown": "\u53f3\u68d5\u659c\u6761",
    "block.minecraft.banner.stripe_downright.cyan": "\u53f3\u9752\u659c\u6761",
    "block.minecraft.banner.stripe_downright.gray": "\u53f3\u7070\u659c\u6761",
    "block.minecraft.banner.stripe_downright.green": "\u53f3\u7eff\u659c\u6761",
    "block.minecraft.banner.stripe_downright.light_blue": "\u53f3\u6de1\u84dd\u659c\u6761",
    "block.minecraft.banner.stripe_downright.light_gray": "\u53f3\u6de1\u7070\u659c\u6761",
    "block.minecraft.banner.stripe_downright.lime": "\u53f3\u9ec4\u7eff\u659c\u6761",
    "block.minecraft.banner.stripe_downright.magenta": "\u53f3\u54c1\u7ea2\u659c\u6761",
    "block.minecraft.banner.stripe_downright.orange": "\u53f3\u6a59\u659c\u6761",
    "block.minecraft.banner.stripe_downright.pink": "\u53f3\u7c89\u7ea2\u659c\u6761",
    "block.minecraft.banner.stripe_downright.purple": "\u53f3\u7d2b\u659c\u6761",
    "block.minecraft.banner.stripe_downright.red": "\u53f3\u7ea2\u659c\u6761",
    "block.minecraft.banner.stripe_downright.white": "\u53f3\u767d\u659c\u6761",
    "block.minecraft.banner.stripe_downright.yellow": "\u53f3\u9ec4\u659c\u6761",
    "block.minecraft.banner.stripe_left.black": "\u53f3\u9ed1\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.blue": "\u53f3\u84dd\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.brown": "\u53f3\u68d5\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.cyan": "\u53f3\u9752\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.gray": "\u53f3\u7070\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.green": "\u53f3\u7eff\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.light_blue": "\u53f3\u6de1\u84dd\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.light_gray": "\u53f3\u6de1\u7070\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.lime": "\u53f3\u9ec4\u7eff\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.magenta": "\u53f3\u54c1\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.orange": "\u53f3\u6a59\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.pink": "\u53f3\u7c89\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.purple": "\u53f3\u7d2b\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.red": "\u53f3\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.white": "\u53f3\u767d\u7ad6\u6761",
    "block.minecraft.banner.stripe_left.yellow": "\u53f3\u9ec4\u7ad6\u6761",
    "block.minecraft.banner.stripe_middle.black": "\u4e2d\u9ed1\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.blue": "\u4e2d\u84dd\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.brown": "\u4e2d\u68d5\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.cyan": "\u4e2d\u9752\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.gray": "\u4e2d\u7070\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.green": "\u4e2d\u7eff\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.light_blue": "\u4e2d\u6de1\u84dd\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.light_gray": "\u4e2d\u6de1\u7070\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.lime": "\u4e2d\u9ec4\u7eff\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.magenta": "\u4e2d\u54c1\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.orange": "\u4e2d\u6a59\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.pink": "\u4e2d\u7c89\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.purple": "\u4e2d\u7d2b\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.red": "\u4e2d\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.white": "\u4e2d\u767d\u6a2a\u6761",
    "block.minecraft.banner.stripe_middle.yellow": "\u4e2d\u9ec4\u6a2a\u6761",
    "block.minecraft.banner.stripe_right.black": "\u5de6\u9ed1\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.blue": "\u5de6\u84dd\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.brown": "\u5de6\u68d5\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.cyan": "\u5de6\u9752\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.gray": "\u5de6\u7070\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.green": "\u5de6\u7eff\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.light_blue": "\u5de6\u6de1\u84dd\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.light_gray": "\u5de6\u6de1\u7070\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.lime": "\u5de6\u9ec4\u7eff\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.magenta": "\u5de6\u54c1\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.orange": "\u5de6\u6a59\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.pink": "\u5de6\u7c89\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.purple": "\u5de6\u7d2b\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.red": "\u5de6\u7ea2\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.white": "\u5de6\u767d\u7ad6\u6761",
    "block.minecraft.banner.stripe_right.yellow": "\u5de6\u9ec4\u7ad6\u6761",
    "block.minecraft.banner.stripe_top.black": "\u9876\u9ed1\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.blue": "\u9876\u84dd\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.brown": "\u9876\u68d5\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.cyan": "\u9876\u9752\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.gray": "\u9876\u7070\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.green": "\u9876\u7eff\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.light_blue": "\u9876\u6de1\u84dd\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.light_gray": "\u9876\u6de1\u7070\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.lime": "\u9876\u9ec4\u7eff\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.magenta": "\u9876\u54c1\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.orange": "\u9876\u6a59\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.pink": "\u9876\u7c89\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.purple": "\u9876\u7d2b\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.red": "\u9876\u7ea2\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.white": "\u9876\u767d\u6a2a\u6761",
    "block.minecraft.banner.stripe_top.yellow": "\u9876\u9ec4\u6a2a\u6761",
    "block.minecraft.banner.triangle_bottom.black": "\u5e95\u9ed1\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.blue": "\u5e95\u84dd\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.brown": "\u5e95\u68d5\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.cyan": "\u5e95\u9752\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.gray": "\u5e95\u7070\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.green": "\u5e95\u7eff\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.light_blue": "\u5e95\u6de1\u84dd\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.light_gray": "\u5e95\u6de1\u7070\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.lime": "\u5e95\u9ec4\u7eff\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.magenta": "\u5e95\u54c1\u7ea2\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.orange": "\u5e95\u6a59\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.pink": "\u5e95\u7c89\u7ea2\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.purple": "\u5e95\u7d2b\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.red": "\u5e95\u7ea2\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.white": "\u5e95\u767d\u4e09\u89d2",
    "block.minecraft.banner.triangle_bottom.yellow": "\u5e95\u9ec4\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.black": "\u9876\u9ed1\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.blue": "\u9876\u84dd\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.brown": "\u9876\u68d5\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.cyan": "\u9876\u9752\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.gray": "\u7070\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.green": "\u9876\u7eff\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.light_blue": "\u6de1\u84dd\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.light_gray": "\u9876\u6de1\u7070\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.lime": "\u9ec4\u7eff\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.magenta": "\u54c1\u7ea2\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.orange": "\u6a59\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.pink": "\u7c89\u7ea2\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.purple": "\u9876\u7d2b\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.red": "\u9876\u7ea2\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.white": "\u767d\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangle_top.yellow": "\u9ec4\u8272\u9876\u4e09\u89d2",
    "block.minecraft.banner.triangles_bottom.black": "\u9ed1\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.blue": "\u84dd\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.brown": "\u68d5\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.cyan": "\u9752\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.gray": "\u7070\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.green": "\u7eff\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.light_blue": "\u6de1\u84dd\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.light_gray": "\u6de1\u7070\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.lime": "\u9ec4\u7eff\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.magenta": "\u54c1\u7ea2\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.orange": "\u6a59\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.pink": "\u7c89\u7ea2\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.purple": "\u7d2b\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.red": "\u7ea2\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.white": "\u767d\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_bottom.yellow": "\u9ec4\u8272\u5e95\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.black": "\u9ed1\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.blue": "\u84dd\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.brown": "\u68d5\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.cyan": "\u9752\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.gray": "\u7070\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.green": "\u7eff\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.light_blue": "\u6de1\u84dd\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.light_gray": "\u6de1\u7070\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.lime": "\u9ec4\u7eff\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.magenta": "\u54c1\u7ea2\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.orange": "\u6a59\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.pink": "\u7c89\u7ea2\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.purple": "\u7d2b\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.red": "\u7ea2\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.white": "\u767d\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.banner.triangles_top.yellow": "\u9ec4\u8272\u9876\u6ce2\u7eb9",
    "block.minecraft.barrel": "\u6728\u6876",
    "block.minecraft.barrier": "\u5c4f\u969c",
    "block.minecraft.basalt": "\u7384\u6b66\u5ca9",
    "block.minecraft.beacon": "\u4fe1\u6807",
    "block.minecraft.beacon.primary": "\u4e3b\u6548\u679c",
    "block.minecraft.beacon.secondary": "\u8f85\u52a9\u6548\u679c",
    "block.minecraft.bed.no_sleep": "\u4f60\u53ea\u80fd\u5728\u591c\u95f4\u6216\u96f7\u66b4\u4e2d\u5165\u7720",
    "block.minecraft.bed.not_safe": "\u4f60\u73b0\u5728\u4e0d\u80fd\u4f11\u606f\uff0c\u5468\u56f4\u6709\u602a\u7269\u5728\u6e38\u8361",
    "block.minecraft.bed.obstructed": "\u8fd9\u5f20\u5e8a\u5df2\u88ab\u963b\u6321",
    "block.minecraft.bed.occupied": "\u8fd9\u5f20\u5e8a\u5df2\u88ab\u5360\u7528",
    "block.minecraft.bed.too_far_away": "\u4f60\u73b0\u5728\u4e0d\u80fd\u4f11\u606f\uff0c\u5e8a\u592a\u8fdc\u4e86",
    "block.minecraft.bedrock": "\u57fa\u5ca9",
    "block.minecraft.bee_nest": "\u8702\u5de2",
    "block.minecraft.beehive": "\u8702\u7bb1",
    "block.minecraft.beetroots": "\u751c\u83dc\u6839",
    "block.minecraft.bell": "\u949f",
    "block.minecraft.big_dripleaf": "\u5927\u578b\u5782\u6ef4\u53f6",
    "block.minecraft.big_dripleaf_stem": "\u5927\u578b\u5782\u6ef4\u53f6\u830e",
    "block.minecraft.birch_button": "\u767d\u6866\u6728\u6309\u94ae",
    "block.minecraft.birch_door": "\u767d\u6866\u6728\u95e8",
    "block.minecraft.birch_fence": "\u767d\u6866\u6728\u6805\u680f",
    "block.minecraft.birch_fence_gate": "\u767d\u6866\u6728\u6805\u680f\u95e8",
    "block.minecraft.birch_leaves": "\u767d\u6866\u6811\u53f6",
    "block.minecraft.birch_log": "\u767d\u6866\u539f\u6728",
    "block.minecraft.birch_planks": "\u767d\u6866\u6728\u677f",
    "block.minecraft.birch_pressure_plate": "\u767d\u6866\u6728\u538b\u529b\u677f",
    "block.minecraft.birch_sapling": "\u767d\u6866\u6811\u82d7",
    "block.minecraft.birch_sign": "\u767d\u6866\u6728\u544a\u793a\u724c",
    "block.minecraft.birch_slab": "\u767d\u6866\u6728\u53f0\u9636",
    "block.minecraft.birch_stairs": "\u767d\u6866\u6728\u697c\u68af",
    "block.minecraft.birch_trapdoor": "\u767d\u6866\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.birch_wall_sign": "\u5899\u4e0a\u7684\u767d\u6866\u6728\u544a\u793a\u724c",
    "block.minecraft.birch_wood": "\u767d\u6866\u6728",
    "block.minecraft.black_banner": "\u9ed1\u8272\u65d7\u5e1c",
    "block.minecraft.black_bed": "\u9ed1\u8272\u5e8a",
    "block.minecraft.black_candle": "\u9ed1\u8272\u8721\u70db",
    "block.minecraft.black_candle_cake": "\u63d2\u4e0a\u9ed1\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.black_carpet": "\u9ed1\u8272\u5730\u6bef",
    "block.minecraft.black_concrete": "\u9ed1\u8272\u6df7\u51dd\u571f",
    "block.minecraft.black_concrete_powder": "\u9ed1\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.black_glazed_terracotta": "\u9ed1\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.black_shulker_box": "\u9ed1\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.black_stained_glass": "\u9ed1\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.black_stained_glass_pane": "\u9ed1\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.black_terracotta": "\u9ed1\u8272\u9676\u74e6",
    "block.minecraft.black_wool": "\u9ed1\u8272\u7f8a\u6bdb",
    "block.minecraft.blackstone": "\u9ed1\u77f3",
    "block.minecraft.blackstone_slab": "\u9ed1\u77f3\u53f0\u9636",
    "block.minecraft.blackstone_stairs": "\u9ed1\u77f3\u697c\u68af",
    "block.minecraft.blackstone_wall": "\u9ed1\u77f3\u5899",
    "block.minecraft.blast_furnace": "\u9ad8\u7089",
    "block.minecraft.blue_banner": "\u84dd\u8272\u65d7\u5e1c",
    "block.minecraft.blue_bed": "\u84dd\u8272\u5e8a",
    "block.minecraft.blue_candle": "\u84dd\u8272\u8721\u70db",
    "block.minecraft.blue_candle_cake": "\u63d2\u4e0a\u84dd\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.blue_carpet": "\u84dd\u8272\u5730\u6bef",
    "block.minecraft.blue_concrete": "\u84dd\u8272\u6df7\u51dd\u571f",
    "block.minecraft.blue_concrete_powder": "\u84dd\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.blue_glazed_terracotta": "\u84dd\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.blue_ice": "\u84dd\u51b0",
    "block.minecraft.blue_orchid": "\u5170\u82b1",
    "block.minecraft.blue_shulker_box": "\u84dd\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.blue_stained_glass": "\u84dd\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.blue_stained_glass_pane": "\u84dd\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.blue_terracotta": "\u84dd\u8272\u9676\u74e6",
    "block.minecraft.blue_wool": "\u84dd\u8272\u7f8a\u6bdb",
    "block.minecraft.bone_block": "\u9aa8\u5757",
    "block.minecraft.bookshelf": "\u4e66\u67b6",
    "block.minecraft.brain_coral": "\u8111\u7eb9\u73ca\u745a",
    "block.minecraft.brain_coral_block": "\u8111\u7eb9\u73ca\u745a\u5757",
    "block.minecraft.brain_coral_fan": "\u8111\u7eb9\u73ca\u745a\u6247",
    "block.minecraft.brain_coral_wall_fan": "\u5899\u4e0a\u7684\u8111\u7eb9\u73ca\u745a\u6247",
    "block.minecraft.brewing_stand": "\u917f\u9020\u53f0",
    "block.minecraft.brick_slab": "\u7816\u53f0\u9636",
    "block.minecraft.brick_stairs": "\u7816\u697c\u68af",
    "block.minecraft.brick_wall": "\u7816\u5757\u5899",
    "block.minecraft.bricks": "\u7816\u5757",
    "block.minecraft.brown_banner": "\u68d5\u8272\u65d7\u5e1c",
    "block.minecraft.brown_bed": "\u68d5\u8272\u5e8a",
    "block.minecraft.brown_candle": "\u68d5\u8272\u8721\u70db",
    "block.minecraft.brown_candle_cake": "\u63d2\u4e0a\u68d5\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.brown_carpet": "\u68d5\u8272\u5730\u6bef",
    "block.minecraft.brown_concrete": "\u68d5\u8272\u6df7\u51dd\u571f",
    "block.minecraft.brown_concrete_powder": "\u68d5\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.brown_glazed_terracotta": "\u68d5\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.brown_mushroom": "\u68d5\u8272\u8611\u83c7",
    "block.minecraft.brown_mushroom_block": "\u68d5\u8272\u8611\u83c7\u65b9\u5757",
    "block.minecraft.brown_shulker_box": "\u68d5\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.brown_stained_glass": "\u68d5\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.brown_stained_glass_pane": "\u68d5\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.brown_terracotta": "\u68d5\u8272\u9676\u74e6",
    "block.minecraft.brown_wool": "\u68d5\u8272\u7f8a\u6bdb",
    "block.minecraft.bubble_column": "\u6c14\u6ce1\u67f1",
    "block.minecraft.bubble_coral": "\u6c14\u6ce1\u73ca\u745a",
    "block.minecraft.bubble_coral_block": "\u6c14\u6ce1\u73ca\u745a\u5757",
    "block.minecraft.bubble_coral_fan": "\u6c14\u6ce1\u73ca\u745a\u6247",
    "block.minecraft.bubble_coral_wall_fan": "\u5899\u4e0a\u7684\u6c14\u6ce1\u73ca\u745a\u6247",
    "block.minecraft.budding_amethyst": "\u7d2b\u6c34\u6676\u6bcd\u5ca9",
    "block.minecraft.cactus": "\u4ed9\u4eba\u638c",
    "block.minecraft.cake": "\u86cb\u7cd5",
    "block.minecraft.calcite": "\u65b9\u89e3\u77f3",
    "block.minecraft.campfire": "\u8425\u706b",
    "block.minecraft.candle": "\u8721\u70db",
    "block.minecraft.candle_cake": "\u63d2\u4e0a\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.carrots": "\u80e1\u841d\u535c",
    "block.minecraft.cartography_table": "\u5236\u56fe\u53f0",
    "block.minecraft.carved_pumpkin": "\u96d5\u523b\u8fc7\u7684\u5357\u74dc",
    "block.minecraft.cauldron": "\u70bc\u836f\u9505",
    "block.minecraft.cave_air": "\u6d1e\u7a74\u7a7a\u6c14",
    "block.minecraft.cave_vines": "\u6d1e\u7a74\u85e4\u8513",
    "block.minecraft.cave_vines_plant": "\u6d1e\u7a74\u85e4\u8513\u690d\u682a",
    "block.minecraft.chain": "\u9501\u94fe",
    "block.minecraft.chain_command_block": "\u8fde\u9501\u578b\u547d\u4ee4\u65b9\u5757",
    "block.minecraft.chest": "\u7bb1\u5b50",
    "block.minecraft.chipped_anvil": "\u5f00\u88c2\u7684\u94c1\u7827",
    "block.minecraft.chiseled_deepslate": "\u933e\u5236\u6df1\u677f\u5ca9",
    "block.minecraft.chiseled_nether_bricks": "\u933e\u5236\u4e0b\u754c\u7816\u5757",
    "block.minecraft.chiseled_polished_blackstone": "\u933e\u5236\u78e8\u5236\u9ed1\u77f3",
    "block.minecraft.chiseled_quartz_block": "\u933e\u5236\u77f3\u82f1\u5757",
    "block.minecraft.chiseled_red_sandstone": "\u933e\u5236\u7ea2\u7802\u5ca9",
    "block.minecraft.chiseled_sandstone": "\u933e\u5236\u7802\u5ca9",
    "block.minecraft.chiseled_stone_bricks": "\u933e\u5236\u77f3\u7816",
    "block.minecraft.chorus_flower": "\u7d2b\u9882\u82b1",
    "block.minecraft.chorus_plant": "\u7d2b\u9882\u690d\u682a",
    "block.minecraft.clay": "\u9ecf\u571f\u5757",
    "block.minecraft.coal_block": "\u7164\u70ad\u5757",
    "block.minecraft.coal_ore": "\u7164\u77ff\u77f3",
    "block.minecraft.coarse_dirt": "\u7802\u571f",
    "block.minecraft.cobbled_deepslate": "\u6df1\u677f\u5ca9\u5706\u77f3",
    "block.minecraft.cobbled_deepslate_slab": "\u6df1\u677f\u5ca9\u5706\u77f3\u53f0\u9636",
    "block.minecraft.cobbled_deepslate_stairs": "\u6df1\u677f\u5ca9\u5706\u77f3\u697c\u68af",
    "block.minecraft.cobbled_deepslate_wall": "\u6df1\u677f\u5ca9\u5706\u77f3\u5899",
    "block.minecraft.cobblestone": "\u5706\u77f3",
    "block.minecraft.cobblestone_slab": "\u5706\u77f3\u53f0\u9636",
    "block.minecraft.cobblestone_stairs": "\u5706\u77f3\u697c\u68af",
    "block.minecraft.cobblestone_wall": "\u5706\u77f3\u5899",
    "block.minecraft.cobweb": "\u8718\u86db\u7f51",
    "block.minecraft.cocoa": "\u53ef\u53ef\u679c",
    "block.minecraft.command_block": "\u547d\u4ee4\u65b9\u5757",
    "block.minecraft.comparator": "\u7ea2\u77f3\u6bd4\u8f83\u5668",
    "block.minecraft.composter": "\u5806\u80a5\u6876",
    "block.minecraft.conduit": "\u6f6e\u6d8c\u6838\u5fc3",
    "block.minecraft.copper_block": "\u94dc\u5757",
    "block.minecraft.copper_ore": "\u94dc\u77ff\u77f3",
    "block.minecraft.cornflower": "\u77e2\u8f66\u83ca",
    "block.minecraft.cracked_deepslate_bricks": "\u88c2\u7eb9\u6df1\u677f\u5ca9\u7816",
    "block.minecraft.cracked_deepslate_tiles": "\u88c2\u7eb9\u6df1\u677f\u5ca9\u74e6",
    "block.minecraft.cracked_nether_bricks": "\u88c2\u7eb9\u4e0b\u754c\u7816\u5757",
    "block.minecraft.cracked_polished_blackstone_bricks": "\u88c2\u7eb9\u78e8\u5236\u9ed1\u77f3\u7816",
    "block.minecraft.cracked_stone_bricks": "\u88c2\u7eb9\u77f3\u7816",
    "block.minecraft.crafting_table": "\u5de5\u4f5c\u53f0",
    "block.minecraft.creeper_head": "\u82e6\u529b\u6015\u7684\u5934",
    "block.minecraft.creeper_wall_head": "\u5899\u4e0a\u7684\u82e6\u529b\u6015\u5934\u9885",
    "block.minecraft.crimson_button": "\u7eef\u7ea2\u6728\u6309\u94ae",
    "block.minecraft.crimson_door": "\u7eef\u7ea2\u6728\u95e8",
    "block.minecraft.crimson_fence": "\u7eef\u7ea2\u6728\u6805\u680f",
    "block.minecraft.crimson_fence_gate": "\u7eef\u7ea2\u6728\u6805\u680f\u95e8",
    "block.minecraft.crimson_fungus": "\u7eef\u7ea2\u83cc",
    "block.minecraft.crimson_hyphae": "\u7eef\u7ea2\u83cc\u6838",
    "block.minecraft.crimson_nylium": "\u7eef\u7ea2\u83cc\u5ca9",
    "block.minecraft.crimson_planks": "\u7eef\u7ea2\u6728\u677f",
    "block.minecraft.crimson_pressure_plate": "\u7eef\u7ea2\u6728\u538b\u529b\u677f",
    "block.minecraft.crimson_roots": "\u7eef\u7ea2\u83cc\u7d22",
    "block.minecraft.crimson_sign": "\u7eef\u7ea2\u6728\u544a\u793a\u724c",
    "block.minecraft.crimson_slab": "\u7eef\u7ea2\u6728\u53f0\u9636",
    "block.minecraft.crimson_stairs": "\u7eef\u7ea2\u6728\u697c\u68af",
    "block.minecraft.crimson_stem": "\u7eef\u7ea2\u83cc\u67c4",
    "block.minecraft.crimson_trapdoor": "\u7eef\u7ea2\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.crimson_wall_sign": "\u5899\u4e0a\u7684\u7eef\u7ea2\u6728\u544a\u793a\u724c",
    "block.minecraft.crying_obsidian": "\u54ed\u6ce3\u7684\u9ed1\u66dc\u77f3",
    "block.minecraft.cut_copper": "\u5207\u5236\u94dc\u5757",
    "block.minecraft.cut_copper_slab": "\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.cut_copper_stairs": "\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.cut_red_sandstone": "\u5207\u5236\u7ea2\u7802\u5ca9",
    "block.minecraft.cut_red_sandstone_slab": "\u5207\u5236\u7ea2\u7802\u5ca9\u53f0\u9636",
    "block.minecraft.cut_sandstone": "\u5207\u5236\u7802\u5ca9",
    "block.minecraft.cut_sandstone_slab": "\u5207\u5236\u7802\u5ca9\u53f0\u9636",
    "block.minecraft.cyan_banner": "\u9752\u8272\u65d7\u5e1c",
    "block.minecraft.cyan_bed": "\u9752\u8272\u5e8a",
    "block.minecraft.cyan_candle": "\u9752\u8272\u8721\u70db",
    "block.minecraft.cyan_candle_cake": "\u63d2\u4e0a\u9752\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.cyan_carpet": "\u9752\u8272\u5730\u6bef",
    "block.minecraft.cyan_concrete": "\u9752\u8272\u6df7\u51dd\u571f",
    "block.minecraft.cyan_concrete_powder": "\u9752\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.cyan_glazed_terracotta": "\u9752\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.cyan_shulker_box": "\u9752\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.cyan_stained_glass": "\u9752\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.cyan_stained_glass_pane": "\u9752\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.cyan_terracotta": "\u9752\u8272\u9676\u74e6",
    "block.minecraft.cyan_wool": "\u9752\u8272\u7f8a\u6bdb",
    "block.minecraft.damaged_anvil": "\u635f\u574f\u7684\u94c1\u7827",
    "block.minecraft.dandelion": "\u84b2\u516c\u82f1",
    "block.minecraft.dark_oak_button": "\u6df1\u8272\u6a61\u6728\u6309\u94ae",
    "block.minecraft.dark_oak_door": "\u6df1\u8272\u6a61\u6728\u95e8",
    "block.minecraft.dark_oak_fence": "\u6df1\u8272\u6a61\u6728\u6805\u680f",
    "block.minecraft.dark_oak_fence_gate": "\u6df1\u8272\u6a61\u6728\u6805\u680f\u95e8",
    "block.minecraft.dark_oak_leaves": "\u6df1\u8272\u6a61\u6811\u6811\u53f6",
    "block.minecraft.dark_oak_log": "\u6df1\u8272\u6a61\u6728\u539f\u6728",
    "block.minecraft.dark_oak_planks": "\u6df1\u8272\u6a61\u6728\u6728\u677f",
    "block.minecraft.dark_oak_pressure_plate": "\u6df1\u8272\u6a61\u6728\u538b\u529b\u677f",
    "block.minecraft.dark_oak_sapling": "\u6df1\u8272\u6a61\u6811\u6811\u82d7",
    "block.minecraft.dark_oak_sign": "\u6df1\u8272\u6a61\u6728\u544a\u793a\u724c",
    "block.minecraft.dark_oak_slab": "\u6df1\u8272\u6a61\u6728\u53f0\u9636",
    "block.minecraft.dark_oak_stairs": "\u6df1\u8272\u6a61\u6728\u697c\u68af",
    "block.minecraft.dark_oak_trapdoor": "\u6df1\u8272\u6a61\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.dark_oak_wall_sign": "\u5899\u4e0a\u7684\u6df1\u8272\u6a61\u6728\u544a\u793a\u724c",
    "block.minecraft.dark_oak_wood": "\u6df1\u8272\u6a61\u6728",
    "block.minecraft.dark_prismarine": "\u6697\u6d77\u6676\u77f3",
    "block.minecraft.dark_prismarine_slab": "\u6697\u6d77\u6676\u77f3\u53f0\u9636",
    "block.minecraft.dark_prismarine_stairs": "\u6697\u6d77\u6676\u77f3\u697c\u68af",
    "block.minecraft.daylight_detector": "\u9633\u5149\u63a2\u6d4b\u5668",
    "block.minecraft.dead_brain_coral": "\u5931\u6d3b\u7684\u8111\u7eb9\u73ca\u745a",
    "block.minecraft.dead_brain_coral_block": "\u5931\u6d3b\u7684\u8111\u7eb9\u73ca\u745a\u5757",
    "block.minecraft.dead_brain_coral_fan": "\u5931\u6d3b\u7684\u8111\u7eb9\u73ca\u745a\u6247",
    "block.minecraft.dead_brain_coral_wall_fan": "\u5899\u4e0a\u7684\u5931\u6d3b\u8111\u7eb9\u73ca\u745a\u6247",
    "block.minecraft.dead_bubble_coral": "\u5931\u6d3b\u7684\u6c14\u6ce1\u73ca\u745a",
    "block.minecraft.dead_bubble_coral_block": "\u5931\u6d3b\u7684\u6c14\u6ce1\u73ca\u745a\u5757",
    "block.minecraft.dead_bubble_coral_fan": "\u5931\u6d3b\u7684\u6c14\u6ce1\u73ca\u745a\u6247",
    "block.minecraft.dead_bubble_coral_wall_fan": "\u5899\u4e0a\u7684\u5931\u6d3b\u6c14\u6ce1\u73ca\u745a\u6247",
    "block.minecraft.dead_bush": "\u67af\u840e\u7684\u704c\u6728",
    "block.minecraft.dead_fire_coral": "\u5931\u6d3b\u7684\u706b\u73ca\u745a",
    "block.minecraft.dead_fire_coral_block": "\u5931\u6d3b\u7684\u706b\u73ca\u745a\u5757",
    "block.minecraft.dead_fire_coral_fan": "\u5931\u6d3b\u7684\u706b\u73ca\u745a\u6247",
    "block.minecraft.dead_fire_coral_wall_fan": "\u5899\u4e0a\u7684\u5931\u6d3b\u706b\u73ca\u745a\u6247",
    "block.minecraft.dead_horn_coral": "\u5931\u6d3b\u7684\u9e7f\u89d2\u73ca\u745a",
    "block.minecraft.dead_horn_coral_block": "\u5931\u6d3b\u7684\u9e7f\u89d2\u73ca\u745a\u5757",
    "block.minecraft.dead_horn_coral_fan": "\u5931\u6d3b\u7684\u9e7f\u89d2\u73ca\u745a\u6247",
    "block.minecraft.dead_horn_coral_wall_fan": "\u5899\u4e0a\u7684\u5931\u6d3b\u9e7f\u89d2\u73ca\u745a\u6247",
    "block.minecraft.dead_tube_coral": "\u5931\u6d3b\u7684\u7ba1\u73ca\u745a",
    "block.minecraft.dead_tube_coral_block": "\u5931\u6d3b\u7684\u7ba1\u73ca\u745a\u5757",
    "block.minecraft.dead_tube_coral_fan": "\u5931\u6d3b\u7684\u7ba1\u73ca\u745a\u6247",
    "block.minecraft.dead_tube_coral_wall_fan": "\u5899\u4e0a\u7684\u5931\u6d3b\u7ba1\u73ca\u745a\u6247",
    "block.minecraft.deepslate": "\u6df1\u677f\u5ca9",
    "block.minecraft.deepslate_brick_slab": "\u6df1\u677f\u5ca9\u7816\u53f0\u9636",
    "block.minecraft.deepslate_brick_stairs": "\u6df1\u677f\u5ca9\u7816\u697c\u68af",
    "block.minecraft.deepslate_brick_wall": "\u6df1\u677f\u5ca9\u7816\u5899",
    "block.minecraft.deepslate_bricks": "\u6df1\u677f\u5ca9\u7816",
    "block.minecraft.deepslate_coal_ore": "\u6df1\u5c42\u7164\u77ff\u77f3",
    "block.minecraft.deepslate_copper_ore": "\u6df1\u5c42\u94dc\u77ff\u77f3",
    "block.minecraft.deepslate_diamond_ore": "\u6df1\u5c42\u94bb\u77f3\u77ff\u77f3",
    "block.minecraft.deepslate_emerald_ore": "\u6df1\u5c42\u7eff\u5b9d\u77f3\u77ff\u77f3",
    "block.minecraft.deepslate_gold_ore": "\u6df1\u5c42\u91d1\u77ff\u77f3",
    "block.minecraft.deepslate_iron_ore": "\u6df1\u5c42\u94c1\u77ff\u77f3",
    "block.minecraft.deepslate_lapis_ore": "\u6df1\u5c42\u9752\u91d1\u77f3\u77ff\u77f3",
    "block.minecraft.deepslate_redstone_ore": "\u6df1\u5c42\u7ea2\u77f3\u77ff\u77f3",
    "block.minecraft.deepslate_tile_slab": "\u6df1\u677f\u5ca9\u74e6\u53f0\u9636",
    "block.minecraft.deepslate_tile_stairs": "\u6df1\u677f\u5ca9\u74e6\u697c\u68af",
    "block.minecraft.deepslate_tile_wall": "\u6df1\u677f\u5ca9\u74e6\u5899",
    "block.minecraft.deepslate_tiles": "\u6df1\u677f\u5ca9\u74e6",
    "block.minecraft.detector_rail": "\u63a2\u6d4b\u94c1\u8f68",
    "block.minecraft.diamond_block": "\u94bb\u77f3\u5757",
    "block.minecraft.diamond_ore": "\u94bb\u77f3\u77ff\u77f3",
    "block.minecraft.diorite": "\u95ea\u957f\u5ca9",
    "block.minecraft.diorite_slab": "\u95ea\u957f\u5ca9\u53f0\u9636",
    "block.minecraft.diorite_stairs": "\u95ea\u957f\u5ca9\u697c\u68af",
    "block.minecraft.diorite_wall": "\u95ea\u957f\u5ca9\u5899",
    "block.minecraft.dirt": "\u6ce5\u571f",
    "block.minecraft.dirt_path": "\u571f\u5f84",
    "block.minecraft.dispenser": "\u53d1\u5c04\u5668",
    "block.minecraft.dragon_egg": "\u9f99\u86cb",
    "block.minecraft.dragon_head": "\u9f99\u9996",
    "block.minecraft.dragon_wall_head": "\u5899\u4e0a\u7684\u9f99\u9996",
    "block.minecraft.dried_kelp_block": "\u5e72\u6d77\u5e26\u5757",
    "block.minecraft.dripstone_block": "\u6ef4\u6c34\u77f3\u5757",
    "block.minecraft.dropper": "\u6295\u63b7\u5668",
    "block.minecraft.emerald_block": "\u7eff\u5b9d\u77f3\u5757",
    "block.minecraft.emerald_ore": "\u7eff\u5b9d\u77f3\u77ff\u77f3",
    "block.minecraft.enchanting_table": "\u9644\u9b54\u53f0",
    "block.minecraft.end_gateway": "\u672b\u5730\u6298\u8dc3\u95e8",
    "block.minecraft.end_portal": "\u672b\u5730\u4f20\u9001\u95e8",
    "block.minecraft.end_portal_frame": "\u672b\u5730\u4f20\u9001\u95e8\u6846\u67b6",
    "block.minecraft.end_rod": "\u672b\u5730\u70db",
    "block.minecraft.end_stone": "\u672b\u5730\u77f3",
    "block.minecraft.end_stone_brick_slab": "\u672b\u5730\u77f3\u7816\u53f0\u9636",
    "block.minecraft.end_stone_brick_stairs": "\u672b\u5730\u77f3\u7816\u697c\u68af",
    "block.minecraft.end_stone_brick_wall": "\u672b\u5730\u77f3\u7816\u5899",
    "block.minecraft.end_stone_bricks": "\u672b\u5730\u77f3\u7816",
    "block.minecraft.ender_chest": "\u672b\u5f71\u7bb1",
    "block.minecraft.exposed_copper": "\u6591\u9a73\u7684\u94dc\u5757",
    "block.minecraft.exposed_cut_copper": "\u6591\u9a73\u7684\u5207\u5236\u94dc\u5757",
    "block.minecraft.exposed_cut_copper_slab": "\u6591\u9a73\u7684\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.exposed_cut_copper_stairs": "\u6591\u9a73\u7684\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.farmland": "\u8015\u5730",
    "block.minecraft.fern": "\u8568",
    "block.minecraft.fire": "\u706b",
    "block.minecraft.fire_coral": "\u706b\u73ca\u745a",
    "block.minecraft.fire_coral_block": "\u706b\u73ca\u745a\u5757",
    "block.minecraft.fire_coral_fan": "\u706b\u73ca\u745a\u6247",
    "block.minecraft.fire_coral_wall_fan": "\u5899\u4e0a\u7684\u706b\u73ca\u745a\u6247",
    "block.minecraft.fletching_table": "\u5236\u7bad\u53f0",
    "block.minecraft.flower_pot": "\u82b1\u76c6",
    "block.minecraft.flowering_azalea": "\u76db\u5f00\u7684\u675c\u9e43\u82b1\u4e1b",
    "block.minecraft.flowering_azalea_leaves": "\u76db\u5f00\u7684\u675c\u9e43\u6811\u53f6",
    "block.minecraft.frosted_ice": "\u971c\u51b0",
    "block.minecraft.furnace": "\u7194\u7089",
    "block.minecraft.gilded_blackstone": "\u9576\u91d1\u9ed1\u77f3",
    "block.minecraft.glass": "\u73bb\u7483",
    "block.minecraft.glass_pane": "\u73bb\u7483\u677f",
    "block.minecraft.glow_lichen": "\u53d1\u5149\u5730\u8863",
    "block.minecraft.glowstone": "\u8367\u77f3",
    "block.minecraft.gold_block": "\u91d1\u5757",
    "block.minecraft.gold_ore": "\u91d1\u77ff\u77f3",
    "block.minecraft.granite": "\u82b1\u5c97\u5ca9",
    "block.minecraft.granite_slab": "\u82b1\u5c97\u5ca9\u53f0\u9636",
    "block.minecraft.granite_stairs": "\u82b1\u5c97\u5ca9\u697c\u68af",
    "block.minecraft.granite_wall": "\u82b1\u5c97\u5ca9\u5899",
    "block.minecraft.grass": "\u8349",
    "block.minecraft.grass_block": "\u8349\u65b9\u5757",
    "block.minecraft.gravel": "\u6c99\u783e",
    "block.minecraft.gray_banner": "\u7070\u8272\u65d7\u5e1c",
    "block.minecraft.gray_bed": "\u7070\u8272\u5e8a",
    "block.minecraft.gray_candle": "\u7070\u8272\u8721\u70db",
    "block.minecraft.gray_candle_cake": "\u63d2\u4e0a\u7070\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.gray_carpet": "\u7070\u8272\u5730\u6bef",
    "block.minecraft.gray_concrete": "\u7070\u8272\u6df7\u51dd\u571f",
    "block.minecraft.gray_concrete_powder": "\u7070\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.gray_glazed_terracotta": "\u7070\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.gray_shulker_box": "\u7070\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.gray_stained_glass": "\u7070\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.gray_stained_glass_pane": "\u7070\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.gray_terracotta": "\u7070\u8272\u9676\u74e6",
    "block.minecraft.gray_wool": "\u7070\u8272\u7f8a\u6bdb",
    "block.minecraft.green_banner": "\u7eff\u8272\u65d7\u5e1c",
    "block.minecraft.green_bed": "\u7eff\u8272\u5e8a",
    "block.minecraft.green_candle": "\u7eff\u8272\u8721\u70db",
    "block.minecraft.green_candle_cake": "\u63d2\u4e0a\u7eff\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.green_carpet": "\u7eff\u8272\u5730\u6bef",
    "block.minecraft.green_concrete": "\u7eff\u8272\u6df7\u51dd\u571f",
    "block.minecraft.green_concrete_powder": "\u7eff\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.green_glazed_terracotta": "\u7eff\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.green_shulker_box": "\u7eff\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.green_stained_glass": "\u7eff\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.green_stained_glass_pane": "\u7eff\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.green_terracotta": "\u7eff\u8272\u9676\u74e6",
    "block.minecraft.green_wool": "\u7eff\u8272\u7f8a\u6bdb",
    "block.minecraft.grindstone": "\u7802\u8f6e",
    "block.minecraft.hanging_roots": "\u5782\u6839",
    "block.minecraft.hay_block": "\u5e72\u8349\u5757",
    "block.minecraft.heavy_weighted_pressure_plate": "\u91cd\u8d28\u6d4b\u91cd\u538b\u529b\u677f",
    "block.minecraft.honey_block": "\u8702\u871c\u5757",
    "block.minecraft.honeycomb_block": "\u871c\u813e\u5757",
    "block.minecraft.hopper": "\u6f0f\u6597",
    "block.minecraft.horn_coral": "\u9e7f\u89d2\u73ca\u745a",
    "block.minecraft.horn_coral_block": "\u9e7f\u89d2\u73ca\u745a\u5757",
    "block.minecraft.horn_coral_fan": "\u9e7f\u89d2\u73ca\u745a\u6247",
    "block.minecraft.horn_coral_wall_fan": "\u5899\u4e0a\u7684\u9e7f\u89d2\u73ca\u745a\u6247",
    "block.minecraft.ice": "\u51b0",
    "block.minecraft.infested_chiseled_stone_bricks": "\u88ab\u866b\u8680\u7684\u933e\u5236\u77f3\u7816",
    "block.minecraft.infested_cobblestone": "\u88ab\u866b\u8680\u7684\u5706\u77f3",
    "block.minecraft.infested_cracked_stone_bricks": "\u88ab\u866b\u8680\u7684\u88c2\u7eb9\u77f3\u7816",
    "block.minecraft.infested_deepslate": "\u88ab\u866b\u8680\u7684\u6df1\u677f\u5ca9",
    "block.minecraft.infested_mossy_stone_bricks": "\u88ab\u866b\u8680\u7684\u82d4\u77f3\u7816",
    "block.minecraft.infested_stone": "\u88ab\u866b\u8680\u7684\u77f3\u5934",
    "block.minecraft.infested_stone_bricks": "\u88ab\u866b\u8680\u7684\u77f3\u7816",
    "block.minecraft.iron_bars": "\u94c1\u680f\u6746",
    "block.minecraft.iron_block": "\u94c1\u5757",
    "block.minecraft.iron_door": "\u94c1\u95e8",
    "block.minecraft.iron_ore": "\u94c1\u77ff\u77f3",
    "block.minecraft.iron_trapdoor": "\u94c1\u6d3b\u677f\u95e8",
    "block.minecraft.jack_o_lantern": "\u5357\u74dc\u706f",
    "block.minecraft.jigsaw": "\u62fc\u56fe\u65b9\u5757",
    "block.minecraft.jukebox": "\u5531\u7247\u673a",
    "block.minecraft.jungle_button": "\u4e1b\u6797\u6728\u6309\u94ae",
    "block.minecraft.jungle_door": "\u4e1b\u6797\u6728\u95e8",
    "block.minecraft.jungle_fence": "\u4e1b\u6797\u6728\u6805\u680f",
    "block.minecraft.jungle_fence_gate": "\u4e1b\u6797\u6728\u6805\u680f\u95e8",
    "block.minecraft.jungle_leaves": "\u4e1b\u6797\u6811\u53f6",
    "block.minecraft.jungle_log": "\u4e1b\u6797\u539f\u6728",
    "block.minecraft.jungle_planks": "\u4e1b\u6797\u6728\u677f",
    "block.minecraft.jungle_pressure_plate": "\u4e1b\u6797\u6728\u538b\u529b\u677f",
    "block.minecraft.jungle_sapling": "\u4e1b\u6797\u6811\u82d7",
    "block.minecraft.jungle_sign": "\u4e1b\u6797\u6728\u544a\u793a\u724c",
    "block.minecraft.jungle_slab": "\u4e1b\u6797\u6728\u53f0\u9636",
    "block.minecraft.jungle_stairs": "\u4e1b\u6797\u6728\u697c\u68af",
    "block.minecraft.jungle_trapdoor": "\u4e1b\u6797\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.jungle_wall_sign": "\u5899\u4e0a\u7684\u4e1b\u6797\u6728\u544a\u793a\u724c",
    "block.minecraft.jungle_wood": "\u4e1b\u6797\u6728",
    "block.minecraft.kelp": "\u6d77\u5e26",
    "block.minecraft.kelp_plant": "\u6d77\u5e26\u690d\u682a",
    "block.minecraft.ladder": "\u68af\u5b50",
    "block.minecraft.lantern": "\u706f\u7b3c",
    "block.minecraft.lapis_block": "\u9752\u91d1\u77f3\u5757",
    "block.minecraft.lapis_ore": "\u9752\u91d1\u77f3\u77ff\u77f3",
    "block.minecraft.large_amethyst_bud": "\u5927\u578b\u7d2b\u6676\u82bd",
    "block.minecraft.large_fern": "\u5927\u578b\u8568",
    "block.minecraft.lava": "\u7194\u5ca9",
    "block.minecraft.lava_cauldron": "\u88c5\u6709\u7194\u5ca9\u7684\u70bc\u836f\u9505",
    "block.minecraft.lectern": "\u8bb2\u53f0",
    "block.minecraft.lever": "\u62c9\u6746",
    "block.minecraft.light": "\u5149",
    "block.minecraft.light_blue_banner": "\u6de1\u84dd\u8272\u65d7\u5e1c",
    "block.minecraft.light_blue_bed": "\u6de1\u84dd\u8272\u5e8a",
    "block.minecraft.light_blue_candle": "\u6de1\u84dd\u8272\u8721\u70db",
    "block.minecraft.light_blue_candle_cake": "\u63d2\u4e0a\u6de1\u84dd\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.light_blue_carpet": "\u6de1\u84dd\u8272\u5730\u6bef",
    "block.minecraft.light_blue_concrete": "\u6de1\u84dd\u8272\u6df7\u51dd\u571f",
    "block.minecraft.light_blue_concrete_powder": "\u6de1\u84dd\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.light_blue_glazed_terracotta": "\u6de1\u84dd\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.light_blue_shulker_box": "\u6de1\u84dd\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.light_blue_stained_glass": "\u6de1\u84dd\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.light_blue_stained_glass_pane": "\u6de1\u84dd\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.light_blue_terracotta": "\u6de1\u84dd\u8272\u9676\u74e6",
    "block.minecraft.light_blue_wool": "\u6de1\u84dd\u8272\u7f8a\u6bdb",
    "block.minecraft.light_gray_banner": "\u6de1\u7070\u8272\u65d7\u5e1c",
    "block.minecraft.light_gray_bed": "\u6de1\u7070\u8272\u5e8a",
    "block.minecraft.light_gray_candle": "\u6de1\u7070\u8272\u8721\u70db",
    "block.minecraft.light_gray_candle_cake": "\u63d2\u4e0a\u6de1\u7070\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.light_gray_carpet": "\u6de1\u7070\u8272\u5730\u6bef",
    "block.minecraft.light_gray_concrete": "\u6de1\u7070\u8272\u6df7\u51dd\u571f",
    "block.minecraft.light_gray_concrete_powder": "\u6de1\u7070\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.light_gray_glazed_terracotta": "\u6de1\u7070\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.light_gray_shulker_box": "\u6de1\u7070\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.light_gray_stained_glass": "\u6de1\u7070\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.light_gray_stained_glass_pane": "\u6de1\u7070\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.light_gray_terracotta": "\u6de1\u7070\u8272\u9676\u74e6",
    "block.minecraft.light_gray_wool": "\u6de1\u7070\u8272\u7f8a\u6bdb",
    "block.minecraft.light_weighted_pressure_plate": "\u8f7b\u8d28\u6d4b\u91cd\u538b\u529b\u677f",
    "block.minecraft.lightning_rod": "\u907f\u96f7\u9488",
    "block.minecraft.lilac": "\u4e01\u9999",
    "block.minecraft.lily_of_the_valley": "\u94c3\u5170",
    "block.minecraft.lily_pad": "\u7761\u83b2",
    "block.minecraft.lime_banner": "\u9ec4\u7eff\u8272\u65d7\u5e1c",
    "block.minecraft.lime_bed": "\u9ec4\u7eff\u8272\u5e8a",
    "block.minecraft.lime_candle": "\u9ec4\u7eff\u8272\u8721\u70db",
    "block.minecraft.lime_candle_cake": "\u63d2\u4e0a\u9ec4\u7eff\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.lime_carpet": "\u9ec4\u7eff\u8272\u5730\u6bef",
    "block.minecraft.lime_concrete": "\u9ec4\u7eff\u8272\u6df7\u51dd\u571f",
    "block.minecraft.lime_concrete_powder": "\u9ec4\u7eff\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.lime_glazed_terracotta": "\u9ec4\u7eff\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.lime_shulker_box": "\u9ec4\u7eff\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.lime_stained_glass": "\u9ec4\u7eff\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.lime_stained_glass_pane": "\u9ec4\u7eff\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.lime_terracotta": "\u9ec4\u7eff\u8272\u9676\u74e6",
    "block.minecraft.lime_wool": "\u9ec4\u7eff\u8272\u7f8a\u6bdb",
    "block.minecraft.lodestone": "\u78c1\u77f3",
    "block.minecraft.loom": "\u7ec7\u5e03\u673a",
    "block.minecraft.magenta_banner": "\u54c1\u7ea2\u8272\u65d7\u5e1c",
    "block.minecraft.magenta_bed": "\u54c1\u7ea2\u8272\u5e8a",
    "block.minecraft.magenta_candle": "\u54c1\u7ea2\u8272\u8721\u70db",
    "block.minecraft.magenta_candle_cake": "\u63d2\u4e0a\u54c1\u7ea2\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.magenta_carpet": "\u54c1\u7ea2\u8272\u5730\u6bef",
    "block.minecraft.magenta_concrete": "\u54c1\u7ea2\u8272\u6df7\u51dd\u571f",
    "block.minecraft.magenta_concrete_powder": "\u54c1\u7ea2\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.magenta_glazed_terracotta": "\u54c1\u7ea2\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.magenta_shulker_box": "\u54c1\u7ea2\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.magenta_stained_glass": "\u54c1\u7ea2\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.magenta_stained_glass_pane": "\u54c1\u7ea2\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.magenta_terracotta": "\u54c1\u7ea2\u8272\u9676\u74e6",
    "block.minecraft.magenta_wool": "\u54c1\u7ea2\u8272\u7f8a\u6bdb",
    "block.minecraft.magma_block": "\u5ca9\u6d46\u5757",
    "block.minecraft.medium_amethyst_bud": "\u4e2d\u578b\u7d2b\u6676\u82bd",
    "block.minecraft.melon": "\u897f\u74dc",
    "block.minecraft.melon_stem": "\u897f\u74dc\u830e",
    "block.minecraft.moss_block": "\u82d4\u85d3\u5757",
    "block.minecraft.moss_carpet": "\u82d4\u85d3\u5730\u6bef",
    "block.minecraft.mossy_cobblestone": "\u82d4\u77f3",
    "block.minecraft.mossy_cobblestone_slab": "\u82d4\u77f3\u53f0\u9636",
    "block.minecraft.mossy_cobblestone_stairs": "\u82d4\u77f3\u697c\u68af",
    "block.minecraft.mossy_cobblestone_wall": "\u82d4\u77f3\u5899",
    "block.minecraft.mossy_stone_brick_slab": "\u82d4\u77f3\u7816\u53f0\u9636",
    "block.minecraft.mossy_stone_brick_stairs": "\u82d4\u77f3\u7816\u697c\u68af",
    "block.minecraft.mossy_stone_brick_wall": "\u82d4\u77f3\u7816\u5899",
    "block.minecraft.mossy_stone_bricks": "\u82d4\u77f3\u7816",
    "block.minecraft.moving_piston": "\u79fb\u52a8\u7684\u6d3b\u585e",
    "block.minecraft.mushroom_stem": "\u8611\u83c7\u67c4",
    "block.minecraft.mycelium": "\u83cc\u4e1d",
    "block.minecraft.nether_brick_fence": "\u4e0b\u754c\u7816\u6805\u680f",
    "block.minecraft.nether_brick_slab": "\u4e0b\u754c\u7816\u53f0\u9636",
    "block.minecraft.nether_brick_stairs": "\u4e0b\u754c\u7816\u697c\u68af",
    "block.minecraft.nether_brick_wall": "\u4e0b\u754c\u7816\u5899",
    "block.minecraft.nether_bricks": "\u4e0b\u754c\u7816\u5757",
    "block.minecraft.nether_gold_ore": "\u4e0b\u754c\u91d1\u77ff\u77f3",
    "block.minecraft.nether_portal": "\u4e0b\u754c\u4f20\u9001\u95e8",
    "block.minecraft.nether_quartz_ore": "\u4e0b\u754c\u77f3\u82f1\u77ff\u77f3",
    "block.minecraft.nether_sprouts": "\u4e0b\u754c\u82d7",
    "block.minecraft.nether_wart": "\u4e0b\u754c\u75a3",
    "block.minecraft.nether_wart_block": "\u4e0b\u754c\u75a3\u5757",
    "block.minecraft.netherite_block": "\u4e0b\u754c\u5408\u91d1\u5757",
    "block.minecraft.netherrack": "\u4e0b\u754c\u5ca9",
    "block.minecraft.note_block": "\u97f3\u7b26\u76d2",
    "block.minecraft.oak_button": "\u6a61\u6728\u6309\u94ae",
    "block.minecraft.oak_door": "\u6a61\u6728\u95e8",
    "block.minecraft.oak_fence": "\u6a61\u6728\u6805\u680f",
    "block.minecraft.oak_fence_gate": "\u6a61\u6728\u6805\u680f\u95e8",
    "block.minecraft.oak_leaves": "\u6a61\u6811\u6811\u53f6",
    "block.minecraft.oak_log": "\u6a61\u6728\u539f\u6728",
    "block.minecraft.oak_planks": "\u6a61\u6728\u6728\u677f",
    "block.minecraft.oak_pressure_plate": "\u6a61\u6728\u538b\u529b\u677f",
    "block.minecraft.oak_sapling": "\u6a61\u6811\u6811\u82d7",
    "block.minecraft.oak_sign": "\u6a61\u6728\u544a\u793a\u724c",
    "block.minecraft.oak_slab": "\u6a61\u6728\u53f0\u9636",
    "block.minecraft.oak_stairs": "\u6a61\u6728\u697c\u68af",
    "block.minecraft.oak_trapdoor": "\u6a61\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.oak_wall_sign": "\u5899\u4e0a\u7684\u6a61\u6728\u544a\u793a\u724c",
    "block.minecraft.oak_wood": "\u6a61\u6728",
    "block.minecraft.observer": "\u4fa6\u6d4b\u5668",
    "block.minecraft.obsidian": "\u9ed1\u66dc\u77f3",
    "block.minecraft.ominous_banner": "\u707e\u5384\u65d7\u5e1c",
    "block.minecraft.orange_banner": "\u6a59\u8272\u65d7\u5e1c",
    "block.minecraft.orange_bed": "\u6a59\u8272\u5e8a",
    "block.minecraft.orange_candle": "\u6a59\u8272\u8721\u70db",
    "block.minecraft.orange_candle_cake": "\u63d2\u4e0a\u6a59\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.orange_carpet": "\u6a59\u8272\u5730\u6bef",
    "block.minecraft.orange_concrete": "\u6a59\u8272\u6df7\u51dd\u571f",
    "block.minecraft.orange_concrete_powder": "\u6a59\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.orange_glazed_terracotta": "\u6a59\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.orange_shulker_box": "\u6a59\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.orange_stained_glass": "\u6a59\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.orange_stained_glass_pane": "\u6a59\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.orange_terracotta": "\u6a59\u8272\u9676\u74e6",
    "block.minecraft.orange_tulip": "\u6a59\u8272\u90c1\u91d1\u9999",
    "block.minecraft.orange_wool": "\u6a59\u8272\u7f8a\u6bdb",
    "block.minecraft.oxeye_daisy": "\u6ee8\u83ca",
    "block.minecraft.oxidized_copper": "\u6c27\u5316\u7684\u94dc\u5757",
    "block.minecraft.oxidized_cut_copper": "\u6c27\u5316\u7684\u5207\u5236\u94dc\u5757",
    "block.minecraft.oxidized_cut_copper_slab": "\u6c27\u5316\u7684\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.oxidized_cut_copper_stairs": "\u6c27\u5316\u7684\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.packed_ice": "\u6d6e\u51b0",
    "block.minecraft.peony": "\u7261\u4e39",
    "block.minecraft.petrified_oak_slab": "\u77f3\u5316\u6a61\u6728\u53f0\u9636",
    "block.minecraft.pink_banner": "\u7c89\u7ea2\u8272\u65d7\u5e1c",
    "block.minecraft.pink_bed": "\u7c89\u7ea2\u8272\u5e8a",
    "block.minecraft.pink_candle": "\u7c89\u7ea2\u8272\u8721\u70db",
    "block.minecraft.pink_candle_cake": "\u63d2\u4e0a\u7c89\u7ea2\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.pink_carpet": "\u7c89\u7ea2\u8272\u5730\u6bef",
    "block.minecraft.pink_concrete": "\u7c89\u7ea2\u8272\u6df7\u51dd\u571f",
    "block.minecraft.pink_concrete_powder": "\u7c89\u7ea2\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.pink_glazed_terracotta": "\u7c89\u7ea2\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.pink_shulker_box": "\u7c89\u7ea2\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.pink_stained_glass": "\u7c89\u7ea2\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.pink_stained_glass_pane": "\u7c89\u7ea2\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.pink_terracotta": "\u7c89\u7ea2\u8272\u9676\u74e6",
    "block.minecraft.pink_tulip": "\u7c89\u7ea2\u8272\u90c1\u91d1\u9999",
    "block.minecraft.pink_wool": "\u7c89\u7ea2\u8272\u7f8a\u6bdb",
    "block.minecraft.piston": "\u6d3b\u585e",
    "block.minecraft.piston_head": "\u6d3b\u585e\u5934",
    "block.minecraft.player_head": "\u73a9\u5bb6\u5934\u9885",
    "block.minecraft.player_head.named": "%s\u7684\u5934",
    "block.minecraft.player_wall_head": "\u5899\u4e0a\u7684\u73a9\u5bb6\u5934\u9885",
    "block.minecraft.podzol": "\u7070\u5316\u571f",
    "block.minecraft.pointed_dripstone": "\u6ef4\u6c34\u77f3\u9525",
    "block.minecraft.polished_andesite": "\u78e8\u5236\u5b89\u5c71\u5ca9",
    "block.minecraft.polished_andesite_slab": "\u78e8\u5236\u5b89\u5c71\u5ca9\u53f0\u9636",
    "block.minecraft.polished_andesite_stairs": "\u78e8\u5236\u5b89\u5c71\u5ca9\u697c\u68af",
    "block.minecraft.polished_basalt": "\u78e8\u5236\u7384\u6b66\u5ca9",
    "block.minecraft.polished_blackstone": "\u78e8\u5236\u9ed1\u77f3",
    "block.minecraft.polished_blackstone_brick_slab": "\u78e8\u5236\u9ed1\u77f3\u7816\u53f0\u9636",
    "block.minecraft.polished_blackstone_brick_stairs": "\u78e8\u5236\u9ed1\u77f3\u7816\u697c\u68af",
    "block.minecraft.polished_blackstone_brick_wall": "\u78e8\u5236\u9ed1\u77f3\u7816\u5899",
    "block.minecraft.polished_blackstone_bricks": "\u78e8\u5236\u9ed1\u77f3\u7816",
    "block.minecraft.polished_blackstone_button": "\u78e8\u5236\u9ed1\u77f3\u6309\u94ae",
    "block.minecraft.polished_blackstone_pressure_plate": "\u78e8\u5236\u9ed1\u77f3\u538b\u529b\u677f",
    "block.minecraft.polished_blackstone_slab": "\u78e8\u5236\u9ed1\u77f3\u53f0\u9636",
    "block.minecraft.polished_blackstone_stairs": "\u78e8\u5236\u9ed1\u77f3\u697c\u68af",
    "block.minecraft.polished_blackstone_wall": "\u78e8\u5236\u9ed1\u77f3\u5899",
    "block.minecraft.polished_deepslate": "\u78e8\u5236\u6df1\u677f\u5ca9",
    "block.minecraft.polished_deepslate_slab": "\u78e8\u5236\u6df1\u677f\u5ca9\u53f0\u9636",
    "block.minecraft.polished_deepslate_stairs": "\u78e8\u5236\u6df1\u677f\u5ca9\u697c\u68af",
    "block.minecraft.polished_deepslate_wall": "\u78e8\u5236\u6df1\u677f\u5ca9\u5899",
    "block.minecraft.polished_diorite": "\u78e8\u5236\u95ea\u957f\u5ca9",
    "block.minecraft.polished_diorite_slab": "\u78e8\u5236\u95ea\u957f\u5ca9\u53f0\u9636",
    "block.minecraft.polished_diorite_stairs": "\u78e8\u5236\u95ea\u957f\u5ca9\u697c\u68af",
    "block.minecraft.polished_granite": "\u78e8\u5236\u82b1\u5c97\u5ca9",
    "block.minecraft.polished_granite_slab": "\u78e8\u5236\u82b1\u5c97\u5ca9\u53f0\u9636",
    "block.minecraft.polished_granite_stairs": "\u78e8\u5236\u82b1\u5c97\u5ca9\u697c\u68af",
    "block.minecraft.poppy": "\u865e\u7f8e\u4eba",
    "block.minecraft.potatoes": "\u9a6c\u94c3\u85af",
    "block.minecraft.potted_acacia_sapling": "\u91d1\u5408\u6b22\u6811\u82d7\u76c6\u683d",
    "block.minecraft.potted_allium": "\u7ed2\u7403\u8471\u76c6\u683d",
    "block.minecraft.potted_azalea_bush": "\u675c\u9e43\u82b1\u4e1b\u76c6\u683d",
    "block.minecraft.potted_azure_bluet": "\u84dd\u82b1\u7f8e\u8033\u8349\u76c6\u683d",
    "block.minecraft.potted_bamboo": "\u7af9\u5b50\u76c6\u683d",
    "block.minecraft.potted_birch_sapling": "\u767d\u6866\u6811\u82d7\u76c6\u683d",
    "block.minecraft.potted_blue_orchid": "\u5170\u82b1\u76c6\u683d",
    "block.minecraft.potted_brown_mushroom": "\u68d5\u8272\u8611\u83c7\u76c6\u683d",
    "block.minecraft.potted_cactus": "\u4ed9\u4eba\u638c\u76c6\u683d",
    "block.minecraft.potted_cornflower": "\u77e2\u8f66\u83ca\u76c6\u683d",
    "block.minecraft.potted_crimson_fungus": "\u7eef\u7ea2\u83cc\u76c6\u683d",
    "block.minecraft.potted_crimson_roots": "\u7eef\u7ea2\u83cc\u7d22\u76c6\u683d",
    "block.minecraft.potted_dandelion": "\u84b2\u516c\u82f1\u76c6\u683d",
    "block.minecraft.potted_dark_oak_sapling": "\u6df1\u8272\u6a61\u6811\u6811\u82d7\u76c6\u683d",
    "block.minecraft.potted_dead_bush": "\u67af\u840e\u7684\u704c\u6728\u76c6\u683d",
    "block.minecraft.potted_fern": "\u8568\u76c6\u683d",
    "block.minecraft.potted_flowering_azalea_bush": "\u76db\u5f00\u7684\u675c\u9e43\u82b1\u4e1b\u76c6\u683d",
    "block.minecraft.potted_jungle_sapling": "\u4e1b\u6797\u6811\u82d7\u76c6\u683d",
    "block.minecraft.potted_lily_of_the_valley": "\u94c3\u5170\u76c6\u683d",
    "block.minecraft.potted_oak_sapling": "\u6a61\u6811\u6811\u82d7\u76c6\u683d",
    "block.minecraft.potted_orange_tulip": "\u6a59\u8272\u90c1\u91d1\u9999\u76c6\u683d",
    "block.minecraft.potted_oxeye_daisy": "\u6ee8\u83ca\u76c6\u683d",
    "block.minecraft.potted_pink_tulip": "\u7c89\u7ea2\u8272\u90c1\u91d1\u9999\u76c6\u683d",
    "block.minecraft.potted_poppy": "\u865e\u7f8e\u4eba\u76c6\u683d",
    "block.minecraft.potted_red_mushroom": "\u7ea2\u8272\u8611\u83c7\u76c6\u683d",
    "block.minecraft.potted_red_tulip": "\u7ea2\u8272\u90c1\u91d1\u9999\u76c6\u683d",
    "block.minecraft.potted_spruce_sapling": "\u4e91\u6749\u6811\u82d7\u76c6\u683d",
    "block.minecraft.potted_warped_fungus": "\u8be1\u5f02\u83cc\u76c6\u683d",
    "block.minecraft.potted_warped_roots": "\u8be1\u5f02\u83cc\u7d22\u76c6\u683d",
    "block.minecraft.potted_white_tulip": "\u767d\u8272\u90c1\u91d1\u9999\u76c6\u683d",
    "block.minecraft.potted_wither_rose": "\u51cb\u96f6\u73ab\u7470\u76c6\u683d",
    "block.minecraft.powder_snow": "\u7ec6\u96ea",
    "block.minecraft.powder_snow_cauldron": "\u88c5\u6709\u7ec6\u96ea\u7684\u70bc\u836f\u9505",
    "block.minecraft.powered_rail": "\u5145\u80fd\u94c1\u8f68",
    "block.minecraft.prismarine": "\u6d77\u6676\u77f3",
    "block.minecraft.prismarine_brick_slab": "\u6d77\u6676\u77f3\u7816\u53f0\u9636",
    "block.minecraft.prismarine_brick_stairs": "\u6d77\u6676\u77f3\u7816\u697c\u68af",
    "block.minecraft.prismarine_bricks": "\u6d77\u6676\u77f3\u7816",
    "block.minecraft.prismarine_slab": "\u6d77\u6676\u77f3\u53f0\u9636",
    "block.minecraft.prismarine_stairs": "\u6d77\u6676\u77f3\u697c\u68af",
    "block.minecraft.prismarine_wall": "\u6d77\u6676\u77f3\u5899",
    "block.minecraft.pumpkin": "\u5357\u74dc",
    "block.minecraft.pumpkin_stem": "\u5357\u74dc\u830e",
    "block.minecraft.purple_banner": "\u7d2b\u8272\u65d7\u5e1c",
    "block.minecraft.purple_bed": "\u7d2b\u8272\u5e8a",
    "block.minecraft.purple_candle": "\u7d2b\u8272\u8721\u70db",
    "block.minecraft.purple_candle_cake": "\u63d2\u4e0a\u7d2b\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.purple_carpet": "\u7d2b\u8272\u5730\u6bef",
    "block.minecraft.purple_concrete": "\u7d2b\u8272\u6df7\u51dd\u571f",
    "block.minecraft.purple_concrete_powder": "\u7d2b\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.purple_glazed_terracotta": "\u7d2b\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.purple_shulker_box": "\u7d2b\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.purple_stained_glass": "\u7d2b\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.purple_stained_glass_pane": "\u7d2b\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.purple_terracotta": "\u7d2b\u8272\u9676\u74e6",
    "block.minecraft.purple_wool": "\u7d2b\u8272\u7f8a\u6bdb",
    "block.minecraft.purpur_block": "\u7d2b\u73c0\u5757",
    "block.minecraft.purpur_pillar": "\u7d2b\u73c0\u67f1",
    "block.minecraft.purpur_slab": "\u7d2b\u73c0\u53f0\u9636",
    "block.minecraft.purpur_stairs": "\u7d2b\u73c0\u697c\u68af",
    "block.minecraft.quartz_block": "\u77f3\u82f1\u5757",
    "block.minecraft.quartz_bricks": "\u77f3\u82f1\u7816",
    "block.minecraft.quartz_pillar": "\u77f3\u82f1\u67f1",
    "block.minecraft.quartz_slab": "\u77f3\u82f1\u53f0\u9636",
    "block.minecraft.quartz_stairs": "\u77f3\u82f1\u697c\u68af",
    "block.minecraft.rail": "\u94c1\u8f68",
    "block.minecraft.raw_copper_block": "\u7c97\u94dc\u5757",
    "block.minecraft.raw_gold_block": "\u7c97\u91d1\u5757",
    "block.minecraft.raw_iron_block": "\u7c97\u94c1\u5757",
    "block.minecraft.red_banner": "\u7ea2\u8272\u65d7\u5e1c",
    "block.minecraft.red_bed": "\u7ea2\u8272\u5e8a",
    "block.minecraft.red_candle": "\u7ea2\u8272\u8721\u70db",
    "block.minecraft.red_candle_cake": "\u63d2\u4e0a\u7ea2\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.red_carpet": "\u7ea2\u8272\u5730\u6bef",
    "block.minecraft.red_concrete": "\u7ea2\u8272\u6df7\u51dd\u571f",
    "block.minecraft.red_concrete_powder": "\u7ea2\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.red_glazed_terracotta": "\u7ea2\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.red_mushroom": "\u7ea2\u8272\u8611\u83c7",
    "block.minecraft.red_mushroom_block": "\u7ea2\u8272\u8611\u83c7\u65b9\u5757",
    "block.minecraft.red_nether_brick_slab": "\u7ea2\u8272\u4e0b\u754c\u7816\u53f0\u9636",
    "block.minecraft.red_nether_brick_stairs": "\u7ea2\u8272\u4e0b\u754c\u7816\u697c\u68af",
    "block.minecraft.red_nether_brick_wall": "\u7ea2\u8272\u4e0b\u754c\u7816\u5899",
    "block.minecraft.red_nether_bricks": "\u7ea2\u8272\u4e0b\u754c\u7816\u5757",
    "block.minecraft.red_sand": "\u7ea2\u6c99",
    "block.minecraft.red_sandstone": "\u7ea2\u7802\u5ca9",
    "block.minecraft.red_sandstone_slab": "\u7ea2\u7802\u5ca9\u53f0\u9636",
    "block.minecraft.red_sandstone_stairs": "\u7ea2\u7802\u5ca9\u697c\u68af",
    "block.minecraft.red_sandstone_wall": "\u7ea2\u7802\u5ca9\u5899",
    "block.minecraft.red_shulker_box": "\u7ea2\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.red_stained_glass": "\u7ea2\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.red_stained_glass_pane": "\u7ea2\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.red_terracotta": "\u7ea2\u8272\u9676\u74e6",
    "block.minecraft.red_tulip": "\u7ea2\u8272\u90c1\u91d1\u9999",
    "block.minecraft.red_wool": "\u7ea2\u8272\u7f8a\u6bdb",
    "block.minecraft.redstone_block": "\u7ea2\u77f3\u5757",
    "block.minecraft.redstone_lamp": "\u7ea2\u77f3\u706f",
    "block.minecraft.redstone_ore": "\u7ea2\u77f3\u77ff\u77f3",
    "block.minecraft.redstone_torch": "\u7ea2\u77f3\u706b\u628a",
    "block.minecraft.redstone_wall_torch": "\u5899\u4e0a\u7684\u7ea2\u77f3\u706b\u628a",
    "block.minecraft.redstone_wire": "\u7ea2\u77f3\u7ebf",
    "block.minecraft.repeater": "\u7ea2\u77f3\u4e2d\u7ee7\u5668",
    "block.minecraft.repeating_command_block": "\u5faa\u73af\u578b\u547d\u4ee4\u65b9\u5757",
    "block.minecraft.respawn_anchor": "\u91cd\u751f\u951a",
    "block.minecraft.rooted_dirt": "\u7f20\u6839\u6ce5\u571f",
    "block.minecraft.rose_bush": "\u73ab\u7470\u4e1b",
    "block.minecraft.sand": "\u6c99\u5b50",
    "block.minecraft.sandstone": "\u7802\u5ca9",
    "block.minecraft.sandstone_slab": "\u7802\u5ca9\u53f0\u9636",
    "block.minecraft.sandstone_stairs": "\u7802\u5ca9\u697c\u68af",
    "block.minecraft.sandstone_wall": "\u7802\u5ca9\u5899",
    "block.minecraft.scaffolding": "\u811a\u624b\u67b6",
    "block.minecraft.sculk_sensor": "\u6f5c\u58f0\u4f20\u611f\u5668",
    "block.minecraft.sea_lantern": "\u6d77\u6676\u706f",
    "block.minecraft.sea_pickle": "\u6d77\u6ce1\u83dc",
    "block.minecraft.seagrass": "\u6d77\u8349",
    "block.minecraft.set_spawn": "\u5df2\u8bbe\u7f6e\u91cd\u751f\u70b9",
    "block.minecraft.shroomlight": "\u83cc\u5149\u4f53",
    "block.minecraft.shulker_box": "\u6f5c\u5f71\u76d2",
    "block.minecraft.skeleton_skull": "\u9ab7\u9ac5\u5934\u9885",
    "block.minecraft.skeleton_wall_skull": "\u5899\u4e0a\u7684\u9ab7\u9ac5\u5934\u9885",
    "block.minecraft.slime_block": "\u9ecf\u6db2\u5757",
    "block.minecraft.small_amethyst_bud": "\u5c0f\u578b\u7d2b\u6676\u82bd",
    "block.minecraft.small_dripleaf": "\u5c0f\u578b\u5782\u6ef4\u53f6",
    "block.minecraft.smithing_table": "\u953b\u9020\u53f0",
    "block.minecraft.smoker": "\u70df\u718f\u7089",
    "block.minecraft.smooth_basalt": "\u5e73\u6ed1\u7384\u6b66\u5ca9",
    "block.minecraft.smooth_quartz": "\u5e73\u6ed1\u77f3\u82f1\u5757",
    "block.minecraft.smooth_quartz_slab": "\u5e73\u6ed1\u77f3\u82f1\u53f0\u9636",
    "block.minecraft.smooth_quartz_stairs": "\u5e73\u6ed1\u77f3\u82f1\u697c\u68af",
    "block.minecraft.smooth_red_sandstone": "\u5e73\u6ed1\u7ea2\u7802\u5ca9",
    "block.minecraft.smooth_red_sandstone_slab": "\u5e73\u6ed1\u7ea2\u7802\u5ca9\u53f0\u9636",
    "block.minecraft.smooth_red_sandstone_stairs": "\u5e73\u6ed1\u7ea2\u7802\u5ca9\u697c\u68af",
    "block.minecraft.smooth_sandstone": "\u5e73\u6ed1\u7802\u5ca9",
    "block.minecraft.smooth_sandstone_slab": "\u5e73\u6ed1\u7802\u5ca9\u53f0\u9636",
    "block.minecraft.smooth_sandstone_stairs": "\u5e73\u6ed1\u7802\u5ca9\u697c\u68af",
    "block.minecraft.smooth_stone": "\u5e73\u6ed1\u77f3\u5934",
    "block.minecraft.smooth_stone_slab": "\u5e73\u6ed1\u77f3\u53f0\u9636",
    "block.minecraft.snow": "\u96ea",
    "block.minecraft.snow_block": "\u96ea\u5757",
    "block.minecraft.soul_campfire": "\u7075\u9b42\u8425\u706b",
    "block.minecraft.soul_fire": "\u7075\u9b42\u706b",
    "block.minecraft.soul_lantern": "\u7075\u9b42\u706f\u7b3c",
    "block.minecraft.soul_sand": "\u7075\u9b42\u6c99",
    "block.minecraft.soul_soil": "\u7075\u9b42\u571f",
    "block.minecraft.soul_torch": "\u7075\u9b42\u706b\u628a",
    "block.minecraft.soul_wall_torch": "\u5899\u4e0a\u7684\u7075\u9b42\u706b\u628a",
    "block.minecraft.spawn.not_valid": "\u4f60\u7684\u5e8a\u6216\u5df2\u5145\u80fd\u7684\u91cd\u751f\u951a\u4e0d\u5b58\u5728\u6216\u5df2\u88ab\u963b\u6321",
    "block.minecraft.spawner": "\u5237\u602a\u7b3c",
    "block.minecraft.sponge": "\u6d77\u7ef5",
    "block.minecraft.spore_blossom": "\u5b62\u5b50\u82b1",
    "block.minecraft.spruce_button": "\u4e91\u6749\u6728\u6309\u94ae",
    "block.minecraft.spruce_door": "\u4e91\u6749\u6728\u95e8",
    "block.minecraft.spruce_fence": "\u4e91\u6749\u6728\u6805\u680f",
    "block.minecraft.spruce_fence_gate": "\u4e91\u6749\u6728\u6805\u680f\u95e8",
    "block.minecraft.spruce_leaves": "\u4e91\u6749\u6811\u53f6",
    "block.minecraft.spruce_log": "\u4e91\u6749\u539f\u6728",
    "block.minecraft.spruce_planks": "\u4e91\u6749\u6728\u677f",
    "block.minecraft.spruce_pressure_plate": "\u4e91\u6749\u6728\u538b\u529b\u677f",
    "block.minecraft.spruce_sapling": "\u4e91\u6749\u6811\u82d7",
    "block.minecraft.spruce_sign": "\u4e91\u6749\u6728\u544a\u793a\u724c",
    "block.minecraft.spruce_slab": "\u4e91\u6749\u6728\u53f0\u9636",
    "block.minecraft.spruce_stairs": "\u4e91\u6749\u6728\u697c\u68af",
    "block.minecraft.spruce_trapdoor": "\u4e91\u6749\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.spruce_wall_sign": "\u5899\u4e0a\u7684\u4e91\u6749\u6728\u544a\u793a\u724c",
    "block.minecraft.spruce_wood": "\u4e91\u6749\u6728",
    "block.minecraft.sticky_piston": "\u9ecf\u6027\u6d3b\u585e",
    "block.minecraft.stone": "\u77f3\u5934",
    "block.minecraft.stone_brick_slab": "\u77f3\u7816\u53f0\u9636",
    "block.minecraft.stone_brick_stairs": "\u77f3\u7816\u697c\u68af",
    "block.minecraft.stone_brick_wall": "\u77f3\u7816\u5899",
    "block.minecraft.stone_bricks": "\u77f3\u7816",
    "block.minecraft.stone_button": "\u77f3\u5934\u6309\u94ae",
    "block.minecraft.stone_pressure_plate": "\u77f3\u8d28\u538b\u529b\u677f",
    "block.minecraft.stone_slab": "\u77f3\u53f0\u9636",
    "block.minecraft.stone_stairs": "\u77f3\u697c\u68af",
    "block.minecraft.stonecutter": "\u5207\u77f3\u673a",
    "block.minecraft.stripped_acacia_log": "\u53bb\u76ae\u91d1\u5408\u6b22\u539f\u6728",
    "block.minecraft.stripped_acacia_wood": "\u53bb\u76ae\u91d1\u5408\u6b22\u6728",
    "block.minecraft.stripped_birch_log": "\u53bb\u76ae\u767d\u6866\u539f\u6728",
    "block.minecraft.stripped_birch_wood": "\u53bb\u76ae\u767d\u6866\u6728",
    "block.minecraft.stripped_crimson_hyphae": "\u53bb\u76ae\u7eef\u7ea2\u83cc\u6838",
    "block.minecraft.stripped_crimson_stem": "\u53bb\u76ae\u7eef\u7ea2\u83cc\u67c4",
    "block.minecraft.stripped_dark_oak_log": "\u53bb\u76ae\u6df1\u8272\u6a61\u6728\u539f\u6728",
    "block.minecraft.stripped_dark_oak_wood": "\u53bb\u76ae\u6df1\u8272\u6a61\u6728",
    "block.minecraft.stripped_jungle_log": "\u53bb\u76ae\u4e1b\u6797\u539f\u6728",
    "block.minecraft.stripped_jungle_wood": "\u53bb\u76ae\u4e1b\u6797\u6728",
    "block.minecraft.stripped_oak_log": "\u53bb\u76ae\u6a61\u6728\u539f\u6728",
    "block.minecraft.stripped_oak_wood": "\u53bb\u76ae\u6a61\u6728",
    "block.minecraft.stripped_spruce_log": "\u53bb\u76ae\u4e91\u6749\u539f\u6728",
    "block.minecraft.stripped_spruce_wood": "\u53bb\u76ae\u4e91\u6749\u6728",
    "block.minecraft.stripped_warped_hyphae": "\u53bb\u76ae\u8be1\u5f02\u83cc\u6838",
    "block.minecraft.stripped_warped_stem": "\u53bb\u76ae\u8be1\u5f02\u83cc\u67c4",
    "block.minecraft.structure_block": "\u7ed3\u6784\u65b9\u5757",
    "block.minecraft.structure_void": "\u7ed3\u6784\u7a7a\u4f4d",
    "block.minecraft.sugar_cane": "\u7518\u8517",
    "block.minecraft.sunflower": "\u5411\u65e5\u8475",
    "block.minecraft.sweet_berry_bush": "\u751c\u6d46\u679c\u4e1b",
    "block.minecraft.tall_grass": "\u9ad8\u8349\u4e1b",
    "block.minecraft.tall_seagrass": "\u9ad8\u6d77\u8349",
    "block.minecraft.target": "\u6807\u9776",
    "block.minecraft.terracotta": "\u9676\u74e6",
    "block.minecraft.tinted_glass": "\u906e\u5149\u73bb\u7483",
    "block.minecraft.tnt": "TNT",
    "block.minecraft.torch": "\u706b\u628a",
    "block.minecraft.trapped_chest": "\u9677\u9631\u7bb1",
    "block.minecraft.tripwire": "\u7eca\u7ebf",
    "block.minecraft.tripwire_hook": "\u7eca\u7ebf\u94a9",
    "block.minecraft.tube_coral": "\u7ba1\u73ca\u745a",
    "block.minecraft.tube_coral_block": "\u7ba1\u73ca\u745a\u5757",
    "block.minecraft.tube_coral_fan": "\u7ba1\u73ca\u745a\u6247",
    "block.minecraft.tube_coral_wall_fan": "\u5899\u4e0a\u7684\u7ba1\u73ca\u745a\u6247",
    "block.minecraft.tuff": "\u51dd\u7070\u5ca9",
    "block.minecraft.turtle_egg": "\u6d77\u9f9f\u86cb",
    "block.minecraft.twisting_vines": "\u7f20\u6028\u85e4",
    "block.minecraft.twisting_vines_plant": "\u7f20\u6028\u85e4\u690d\u682a",
    "block.minecraft.vine": "\u85e4\u8513",
    "block.minecraft.void_air": "\u865a\u7a7a\u7a7a\u6c14",
    "block.minecraft.wall_torch": "\u5899\u4e0a\u7684\u706b\u628a",
    "block.minecraft.warped_button": "\u8be1\u5f02\u6728\u6309\u94ae",
    "block.minecraft.warped_door": "\u8be1\u5f02\u6728\u95e8",
    "block.minecraft.warped_fence": "\u8be1\u5f02\u6728\u6805\u680f",
    "block.minecraft.warped_fence_gate": "\u8be1\u5f02\u6728\u6805\u680f\u95e8",
    "block.minecraft.warped_fungus": "\u8be1\u5f02\u83cc",
    "block.minecraft.warped_hyphae": "\u8be1\u5f02\u83cc\u6838",
    "block.minecraft.warped_nylium": "\u8be1\u5f02\u83cc\u5ca9",
    "block.minecraft.warped_planks": "\u8be1\u5f02\u6728\u677f",
    "block.minecraft.warped_pressure_plate": "\u8be1\u5f02\u6728\u538b\u529b\u677f",
    "block.minecraft.warped_roots": "\u8be1\u5f02\u83cc\u7d22",
    "block.minecraft.warped_sign": "\u8be1\u5f02\u6728\u544a\u793a\u724c",
    "block.minecraft.warped_slab": "\u8be1\u5f02\u6728\u53f0\u9636",
    "block.minecraft.warped_stairs": "\u8be1\u5f02\u6728\u697c\u68af",
    "block.minecraft.warped_stem": "\u8be1\u5f02\u83cc\u67c4",
    "block.minecraft.warped_trapdoor": "\u8be1\u5f02\u6728\u6d3b\u677f\u95e8",
    "block.minecraft.warped_wall_sign": "\u5899\u4e0a\u7684\u8be1\u5f02\u6728\u544a\u793a\u724c",
    "block.minecraft.warped_wart_block": "\u8be1\u5f02\u75a3\u5757",
    "block.minecraft.water": "\u6c34",
    "block.minecraft.water_cauldron": "\u88c5\u6709\u6c34\u7684\u70bc\u836f\u9505",
    "block.minecraft.waxed_copper_block": "\u6d82\u8721\u94dc\u5757",
    "block.minecraft.waxed_cut_copper": "\u6d82\u8721\u5207\u5236\u94dc\u5757",
    "block.minecraft.waxed_cut_copper_slab": "\u6d82\u8721\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.waxed_cut_copper_stairs": "\u6d82\u8721\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.waxed_exposed_copper": "\u6591\u9a73\u7684\u6d82\u8721\u94dc\u5757",
    "block.minecraft.waxed_exposed_cut_copper": "\u6591\u9a73\u7684\u6d82\u8721\u5207\u5236\u94dc\u5757",
    "block.minecraft.waxed_exposed_cut_copper_slab": "\u6591\u9a73\u7684\u6d82\u8721\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.waxed_exposed_cut_copper_stairs": "\u6591\u9a73\u7684\u6d82\u8721\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.waxed_oxidized_copper": "\u6c27\u5316\u7684\u6d82\u8721\u94dc\u5757",
    "block.minecraft.waxed_oxidized_cut_copper": "\u6c27\u5316\u7684\u6d82\u8721\u5207\u5236\u94dc\u5757",
    "block.minecraft.waxed_oxidized_cut_copper_slab": "\u6c27\u5316\u7684\u6d82\u8721\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.waxed_oxidized_cut_copper_stairs": "\u6c27\u5316\u7684\u6d82\u8721\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.waxed_weathered_copper": "\u9508\u8680\u7684\u6d82\u8721\u94dc\u5757",
    "block.minecraft.waxed_weathered_cut_copper": "\u9508\u8680\u7684\u6d82\u8721\u5207\u5236\u94dc\u5757",
    "block.minecraft.waxed_weathered_cut_copper_slab": "\u9508\u8680\u7684\u6d82\u8721\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.waxed_weathered_cut_copper_stairs": "\u9508\u8680\u7684\u6d82\u8721\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.weathered_copper": "\u9508\u8680\u7684\u94dc\u5757",
    "block.minecraft.weathered_cut_copper": "\u9508\u8680\u7684\u5207\u5236\u94dc\u5757",
    "block.minecraft.weathered_cut_copper_slab": "\u9508\u8680\u7684\u5207\u5236\u94dc\u53f0\u9636",
    "block.minecraft.weathered_cut_copper_stairs": "\u9508\u8680\u7684\u5207\u5236\u94dc\u697c\u68af",
    "block.minecraft.weeping_vines": "\u5782\u6cea\u85e4",
    "block.minecraft.weeping_vines_plant": "\u5782\u6cea\u85e4\u690d\u682a",
    "block.minecraft.wet_sponge": "\u6e7f\u6d77\u7ef5",
    "block.minecraft.wheat": "\u5c0f\u9ea6",
    "block.minecraft.white_banner": "\u767d\u8272\u65d7\u5e1c",
    "block.minecraft.white_bed": "\u767d\u8272\u5e8a",
    "block.minecraft.white_candle": "\u767d\u8272\u8721\u70db",
    "block.minecraft.white_candle_cake": "\u63d2\u4e0a\u767d\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.white_carpet": "\u767d\u8272\u5730\u6bef",
    "block.minecraft.white_concrete": "\u767d\u8272\u6df7\u51dd\u571f",
    "block.minecraft.white_concrete_powder": "\u767d\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.white_glazed_terracotta": "\u767d\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.white_shulker_box": "\u767d\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.white_stained_glass": "\u767d\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.white_stained_glass_pane": "\u767d\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.white_terracotta": "\u767d\u8272\u9676\u74e6",
    "block.minecraft.white_tulip": "\u767d\u8272\u90c1\u91d1\u9999",
    "block.minecraft.white_wool": "\u767d\u8272\u7f8a\u6bdb",
    "block.minecraft.wither_rose": "\u51cb\u96f6\u73ab\u7470",
    "block.minecraft.wither_skeleton_skull": "\u51cb\u7075\u9ab7\u9ac5\u5934\u9885",
    "block.minecraft.wither_skeleton_wall_skull": "\u5899\u4e0a\u7684\u51cb\u7075\u9ab7\u9ac5\u5934\u9885",
    "block.minecraft.yellow_banner": "\u9ec4\u8272\u65d7\u5e1c",
    "block.minecraft.yellow_bed": "\u9ec4\u8272\u5e8a",
    "block.minecraft.yellow_candle": "\u9ec4\u8272\u8721\u70db",
    "block.minecraft.yellow_candle_cake": "\u63d2\u4e0a\u9ec4\u8272\u8721\u70db\u7684\u86cb\u7cd5",
    "block.minecraft.yellow_carpet": "\u9ec4\u8272\u5730\u6bef",
    "block.minecraft.yellow_concrete": "\u9ec4\u8272\u6df7\u51dd\u571f",
    "block.minecraft.yellow_concrete_powder": "\u9ec4\u8272\u6df7\u51dd\u571f\u7c89\u672b",
    "block.minecraft.yellow_glazed_terracotta": "\u9ec4\u8272\u5e26\u91c9\u9676\u74e6",
    "block.minecraft.yellow_shulker_box": "\u9ec4\u8272\u6f5c\u5f71\u76d2",
    "block.minecraft.yellow_stained_glass": "\u9ec4\u8272\u67d3\u8272\u73bb\u7483",
    "block.minecraft.yellow_stained_glass_pane": "\u9ec4\u8272\u67d3\u8272\u73bb\u7483\u677f",
    "block.minecraft.yellow_terracotta": "\u9ec4\u8272\u9676\u74e6",
    "block.minecraft.yellow_wool": "\u9ec4\u8272\u7f8a\u6bdb",
    "block.minecraft.zombie_head": "\u50f5\u5c38\u7684\u5934",
    "block.minecraft.zombie_wall_head": "\u5899\u4e0a\u7684\u50f5\u5c38\u5934\u9885",
    "book.byAuthor": "%1$s \u8457",
    "book.editTitle": "\u8f93\u5165\u4e66\u540d\uff1a",
    "book.finalizeButton": "\u7f72\u540d\u5e76\u5173\u95ed",
    "book.finalizeWarning": "\u6ce8\u610f\uff01\u5728\u4f60\u7f72\u540d\u540e\uff0c\u5b83\u5c06\u4e0d\u80fd\u518d\u88ab\u4fee\u6539\u3002",
    "book.generation.0": "\u539f\u7a3f",
    "book.generation.1": "\u539f\u7a3f\u7684\u526f\u672c",
    "book.generation.2": "\u526f\u672c\u7684\u526f\u672c",
    "book.generation.3": "\u7834\u70c2\u4e0d\u582a",
    "book.invalid.tag": "* \u65e0\u6548\u7684\u4e66\u672c\u6807\u7b7e *",
    "book.pageIndicator": "\u9875\u9762\uff1a%1$s/%2$s",
    "book.signButton": "\u7f72\u540d",
    "build.tooHigh": "\u5efa\u7b51\u9ad8\u5ea6\u9650\u5236\u662f%s",
    "chat.cannotSend": "\u65e0\u6cd5\u53d1\u9001\u804a\u5929\u6d88\u606f",
    "chat.coordinates": "%s, %s, %s",
    "chat.coordinates.tooltip": "\u70b9\u6b64\u4f20\u9001",
    "chat.copy": "\u590d\u5236\u5230\u526a\u8d34\u677f",
    "chat.copy.click": "\u5355\u51fb\u590d\u5236\u5230\u526a\u5207\u677f",
    "chat.disabled.launcher": "\u804a\u5929\u5728\u542f\u52a8\u5668\u9009\u9879\u4e2d\u88ab\u7981\u7528\u3002\u65e0\u6cd5\u53d1\u9001\u6d88\u606f",
    "chat.disabled.options": "\u804a\u5929\u5728\u5ba2\u6237\u7aef\u9009\u9879\u4e2d\u88ab\u7981\u7528",
    "chat.disabled.profile": "\u804a\u5929\u5728\u8d26\u6237\u8bbe\u7f6e\u4e2d\u88ab\u7981\u7528\u3002\u65e0\u6cd5\u53d1\u9001\u6d88\u606f",
    "chat.editBox": "\u804a\u5929",
    "chat.link.confirm": "\u4f60\u786e\u5b9a\u4f60\u8981\u6253\u5f00\u4ee5\u4e0b\u7f51\u9875\uff1f",
    "chat.link.confirmTrusted": "\u4f60\u60f3\u8981\u6253\u5f00\u8fd9\u4e2a\u94fe\u63a5\u6216\u590d\u5236\u5230\u4f60\u7684\u526a\u8d34\u677f\u5417\uff1f",
    "chat.link.open": "\u5728\u6d4f\u89c8\u5668\u4e2d\u6253\u5f00",
    "chat.link.warning": "\u6c38\u8fdc\u4e0d\u8981\u6253\u5f00\u4ece\u4f60\u4e0d\u4fe1\u4efb\u7684\u4eba\u5f97\u5230\u7684\u94fe\u63a5\uff01",
    "chat.queue": "[+%s\u884c\u5f85\u53d1\u9001]",
    "chat.square_brackets": "[%s]",
    "chat.type.admin": "[%s: %s]",
    "chat.type.advancement.challenge": "%s\u5b8c\u6210\u4e86\u6311\u6218%s",
    "chat.type.advancement.goal": "%s\u8fbe\u6210\u4e86\u76ee\u6807%s",
    "chat.type.advancement.task": "%s\u53d6\u5f97\u4e86\u8fdb\u5ea6%s",
    "chat.type.announcement": "[%s] %s",
    "chat.type.emote": "* %s %s",
    "chat.type.team.hover": "\u53d1\u9001\u961f\u4f0d\u6d88\u606f",
    "chat.type.team.sent": "-> %s <%s> %s",
    "chat.type.team.text": "%s <%s> %s",
    "chat.type.text": "<%s> %s",
    "chat.type.text.narrate": "%s\u8bf4%s",
    "chat_screen.message": "\u8981\u53d1\u9001\u7684\u6d88\u606f\uff1a%s",
    "chat_screen.title": "\u804a\u5929\u754c\u9762",
    "chat_screen.usage": "\u8f93\u5165\u6d88\u606f\u5e76\u6309Enter\u952e\u53d1\u9001",
    "clear.failed.multiple": "\u672a\u80fd\u4ece%s\u540d\u73a9\u5bb6\u627e\u5230\u4efb\u4f55\u7269\u54c1",
    "clear.failed.single": "\u672a\u80fd\u4ece\u73a9\u5bb6%s\u627e\u5230\u4efb\u4f55\u7269\u54c1",
    "color.minecraft.black": "\u9ed1\u8272",
    "color.minecraft.blue": "\u84dd\u8272",
    "color.minecraft.brown": "\u68d5\u8272",
    "color.minecraft.cyan": "\u9752\u8272",
    "color.minecraft.gray": "\u7070\u8272",
    "color.minecraft.green": "\u7eff\u8272",
    "color.minecraft.light_blue": "\u6de1\u84dd\u8272",
    "color.minecraft.light_gray": "\u6de1\u7070\u8272",
    "color.minecraft.lime": "\u9ec4\u7eff\u8272",
    "color.minecraft.magenta": "\u54c1\u7ea2\u8272",
    "color.minecraft.orange": "\u6a59\u8272",
    "color.minecraft.pink": "\u7c89\u7ea2\u8272",
    "color.minecraft.purple": "\u7d2b\u8272",
    "color.minecraft.red": "\u7ea2\u8272",
    "color.minecraft.white": "\u767d\u8272",
    "color.minecraft.yellow": "\u9ec4\u8272",
    "command.context.here": "<--[\u6b64\u5904]",
    "command.context.parse_error": "\u5728\u7b2c%2$s\u4e2a\u5b57\u7b26\u5904\u53d1\u73b0%1$s\uff1a%3$s",
    "command.exception": "\u65e0\u6cd5\u89e3\u6790\u547d\u4ee4\uff1a%s",
    "command.expected.separator": "\u53c2\u6570\u540e\u5e94\u6709\u7a7a\u683c\u5206\u9694\uff0c\u4f46\u53d1\u73b0\u4e86\u7d27\u90bb\u7684\u6570\u636e",
    "command.failed": "\u8bd5\u56fe\u6267\u884c\u8be5\u547d\u4ee4\u65f6\u51fa\u73b0\u610f\u5916\u9519\u8bef",
    "command.unknown.argument": "\u9519\u8bef\u7684\u547d\u4ee4\u53c2\u6570",
    "command.unknown.command": "\u672a\u77e5\u6216\u4e0d\u5b8c\u6574\u7684\u547d\u4ee4\uff0c\u9519\u8bef\u89c1\u4e0b",
    "commands.advancement.advancementNotFound": "\u6ca1\u6709\u627e\u5230\u540d\u4e3a'%s'\u7684\u8fdb\u5ea6",
    "commands.advancement.criterionNotFound": "\u8fdb\u5ea6%1$s\u5e76\u4e0d\u5305\u542b\u6761\u4ef6'%2$s'",
    "commands.advancement.grant.criterion.to.many.failure": "\u65e0\u6cd5\u5c06\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'\u8d4b\u4e88%3$s\u540d\u73a9\u5bb6\uff0c\u56e0\u4e3a\u4ed6\u4eec\u5df2\u8fbe\u6210\u6b64\u6761\u4ef6",
    "commands.advancement.grant.criterion.to.many.success": "\u5df2\u5c06\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'\u8d4b\u4e88%3$s\u540d\u73a9\u5bb6",
    "commands.advancement.grant.criterion.to.one.failure": "\u65e0\u6cd5\u5c06\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'\u8d4b\u4e88%3$s\uff0c\u56e0\u4e3a\u8be5\u73a9\u5bb6\u5df2\u8fbe\u6210\u6b64\u6761\u4ef6",
    "commands.advancement.grant.criterion.to.one.success": "\u5df2\u5c06\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'\u8d4b\u4e88%3$s",
    "commands.advancement.grant.many.to.many.failure": "\u65e0\u6cd5\u5c06%s\u9879\u8fdb\u5ea6\u8d4b\u4e88%s\u540d\u73a9\u5bb6\uff0c\u56e0\u4e3a\u4ed6\u4eec\u5df2\u8fbe\u6210\u8fd9\u4e9b\u8fdb\u5ea6",
    "commands.advancement.grant.many.to.many.success": "\u5df2\u5c06%s\u9879\u8fdb\u5ea6\u8d4b\u4e88%s\u540d\u73a9\u5bb6",
    "commands.advancement.grant.many.to.one.failure": "\u65e0\u6cd5\u5c06%s\u9879\u8fdb\u5ea6\u8d4b\u4e88%s\uff0c\u56e0\u4e3a\u8be5\u73a9\u5bb6\u5df2\u8fbe\u6210\u8fd9\u4e9b\u8fdb\u5ea6",
    "commands.advancement.grant.many.to.one.success": "\u5df2\u5c06%s\u9879\u8fdb\u5ea6\u8d4b\u4e88%s",
    "commands.advancement.grant.one.to.many.failure": "\u65e0\u6cd5\u5c06\u8fdb\u5ea6%s\u8d4b\u4e88%s\u540d\u73a9\u5bb6\uff0c\u56e0\u4e3a\u4ed6\u4eec\u5df2\u8fbe\u6210\u6b64\u8fdb\u5ea6",
    "commands.advancement.grant.one.to.many.success": "\u5df2\u5c06\u8fdb\u5ea6%s\u8d4b\u4e88%s\u540d\u73a9\u5bb6",
    "commands.advancement.grant.one.to.one.failure": "\u65e0\u6cd5\u5c06\u8fdb\u5ea6%s\u8d4b\u4e88%s\uff0c\u56e0\u4e3a\u8be5\u73a9\u5bb6\u5df2\u8fbe\u6210\u6b64\u8fdb\u5ea6",
    "commands.advancement.grant.one.to.one.success": "\u5df2\u5c06\u8fdb\u5ea6%s\u8d4b\u4e88%s",
    "commands.advancement.revoke.criterion.to.many.failure": "\u65e0\u6cd5\u64a4\u9500%3$s\u540d\u73a9\u5bb6\u5173\u4e8e\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'\uff0c\u56e0\u4e3a\u8be5\u73a9\u5bb6\u5e76\u672a\u8fbe\u6210\u6b64\u6761\u4ef6",
    "commands.advancement.revoke.criterion.to.many.success": "\u5df2\u64a4\u9500%3$s\u540d\u73a9\u5bb6\u5173\u4e8e\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'",
    "commands.advancement.revoke.criterion.to.one.failure": "\u65e0\u6cd5\u64a4\u9500%3$s\u5173\u4e8e\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'\uff0c\u56e0\u4e3a\u8be5\u73a9\u5bb6\u5e76\u672a\u8fbe\u6210\u6b64\u6761\u4ef6",
    "commands.advancement.revoke.criterion.to.one.success": "\u5df2\u64a4\u9500%3$s\u5173\u4e8e\u8fdb\u5ea6%2$s\u7684\u8fbe\u6210\u6761\u4ef6'%1$s'",
    "commands.advancement.revoke.many.to.many.failure": "\u65e0\u6cd5\u64a4\u9500%2$s\u540d\u73a9\u5bb6\u7684%1$s\u9879\u8fdb\u5ea6\uff0c\u56e0\u4e3a\u4ed6\u4eec\u5e76\u672a\u8fbe\u6210\u6b64\u8fdb\u5ea6",
    "commands.advancement.revoke.many.to.many.success": "\u5df2\u64a4\u9500%2$s\u540d\u73a9\u5bb6\u7684%1$s\u9879\u8fdb\u5ea6",
    "commands.advancement.revoke.many.to.one.failure": "\u65e0\u6cd5\u64a4\u9500%2$s\u7684%1$s\u9879\u8fdb\u5ea6\uff0c\u56e0\u4e3a\u8be5\u73a9\u5bb6\u5e76\u672a\u8fbe\u6210\u8fd9\u4e9b\u8fdb\u5ea6",
    "commands.advancement.revoke.many.to.one.success": "\u5df2\u64a4\u9500%2$s\u7684%1$s\u9879\u8fdb\u5ea6",
    "commands.advancement.revoke.one.to.many.failure": "\u65e0\u6cd5\u64a4\u9500%2$s\u540d\u73a9\u5bb6\u7684\u8fdb\u5ea6%1$s\uff0c\u56e0\u4e3a\u4ed6\u4eec\u5e76\u672a\u8fbe\u6210\u6b64\u8fdb\u5ea6",
    "commands.advancement.revoke.one.to.many.success": "\u5df2\u64a4\u9500%2$s\u540d\u73a9\u5bb6\u7684\u8fdb\u5ea6%1$s",
    "commands.advancement.revoke.one.to.one.failure": "\u65e0\u6cd5\u64a4\u9500%2$s\u7684\u8fdb\u5ea6%1$s\uff0c\u56e0\u4e3a\u8be5\u73a9\u5bb6\u5e76\u672a\u8fbe\u6210\u6b64\u8fdb\u5ea6",
    "commands.advancement.revoke.one.to.one.success": "\u5df2\u64a4\u9500%2$s\u7684\u8fdb\u5ea6%1$s",
    "commands.attribute.base_value.get.success": "\u5b9e\u4f53%2$s\u7684\u5c5e\u6027%1$s\u7684\u57fa\u503c\u4e3a%3$s",
    "commands.attribute.base_value.set.success": "\u5b9e\u4f53%2$s\u7684\u5c5e\u6027%1$s\u7684\u57fa\u503c\u5df2\u8bbe\u7f6e\u4e3a%3$s",
    "commands.attribute.failed.entity": "%s\u4e0d\u662f\u6b64\u547d\u4ee4\u7684\u6709\u6548\u5b9e\u4f53",
    "commands.attribute.failed.modifier_already_present": "\u5b9e\u4f53%3$s\u7684\u5c5e\u6027%2$s\u5df2\u5b58\u5728\u4fee\u9970\u7b26%1$s",
    "commands.attribute.failed.no_attribute": "\u5b9e\u4f53%s\u6ca1\u6709\u5c5e\u6027%s",
    "commands.attribute.failed.no_modifier": "\u5b9e\u4f53%2$s\u7684\u5c5e\u6027%1$s\u65e0\u4fee\u9970\u7b26%3$s",
    "commands.attribute.modifier.add.success": "\u4e3a\u5b9e\u4f53%3$s\u7684\u5c5e\u6027%2$s\u6dfb\u52a0\u4e86\u4fee\u9970\u7b26%1$s",
    "commands.attribute.modifier.remove.success": "\u4e3a\u5b9e\u4f53%3$s\u7684\u5c5e\u6027%2$s\u79fb\u9664\u4e86\u4fee\u9970\u7b26%1$s",
    "commands.attribute.modifier.value.get.success": "\u5b9e\u4f53%3$s\u7684\u5c5e\u6027%2$s\u4e2d\u4fee\u9970\u7b26%1$s\u503c\u4e3a%4$s",
    "commands.attribute.value.get.success": "\u5b9e\u4f53%2$s\u7684\u5c5e\u6027%1$s\u7684\u503c\u4e3a%3$s",
    "commands.ban.failed": "\u65e0\u53d8\u5316\uff0c\u8be5\u73a9\u5bb6\u5df2\u88ab\u5c01\u7981",
    "commands.ban.success": "\u5df2\u5c01\u7981%s\uff1a%s",
    "commands.banip.failed": "\u65e0\u53d8\u5316\uff0c\u8be5IP\u5730\u5740\u5df2\u88ab\u5c01\u7981",
    "commands.banip.info": "\u6b64\u6b21\u5c01\u7981\u6d89\u53ca%s\u540d\u73a9\u5bb6\uff1a%s",
    "commands.banip.invalid": "\u65e0\u6548\u7684IP\u5730\u5740\u6216\u672a\u77e5\u7684\u73a9\u5bb6",
    "commands.banip.success": "\u5df2\u5c01\u7981IP\u5730\u5740%s\uff1a%s",
    "commands.banlist.entry": "%s\u88ab%s\u5c01\u7981\uff1a%s",
    "commands.banlist.list": "\u5171\u6709%s\u6761\u5c01\u7981\uff1a",
    "commands.banlist.none": "\u6ca1\u6709\u73a9\u5bb6\u88ab\u5c01\u7981",
    "commands.bossbar.create.failed": "ID\u4e3a'%s'\u7684Boss\u680f\u5df2\u7ecf\u5b58\u5728",
    "commands.bossbar.create.success": "\u5df2\u521b\u5efa\u81ea\u5b9a\u4e49Boss\u680f%s",
    "commands.bossbar.get.max": "\u81ea\u5b9a\u4e49Boss\u680f%s\u7684\u6700\u5927\u503c\u4e3a%s",
    "commands.bossbar.get.players.none": "\u81ea\u5b9a\u4e49Boss\u680f%s\u76ee\u524d\u6ca1\u6709\u5728\u7ebf\u73a9\u5bb6",
    "commands.bossbar.get.players.some": "\u81ea\u5b9a\u4e49Boss\u680f%s\u5f53\u524d\u5728\u7ebf\u7684%s\u540d\u73a9\u5bb6\u6709\uff1a%s",
    "commands.bossbar.get.value": "\u81ea\u5b9a\u4e49Boss\u680f%s\u7684\u6570\u503c\u4e3a%s",
    "commands.bossbar.get.visible.hidden": "\u81ea\u5b9a\u4e49Boss\u680f%s\u73b0\u4e3a\u9690\u85cf",
    "commands.bossbar.get.visible.visible": "\u81ea\u5b9a\u4e49Boss\u680f%s\u73b0\u4e3a\u53ef\u89c1",
    "commands.bossbar.list.bars.none": "\u65e0\u8fd0\u884c\u4e2d\u7684\u81ea\u5b9a\u4e49Boss\u680f",
    "commands.bossbar.list.bars.some": "\u6709%s\u9879\u8fd0\u884c\u4e2d\u7684\u81ea\u5b9a\u4e49Boss\u680f\uff1a%s",
    "commands.bossbar.remove.success": "\u5df2\u79fb\u9664\u81ea\u5b9a\u4e49Boss\u680f%s",
    "commands.bossbar.set.color.success": "\u5df2\u66f4\u6539\u81ea\u5b9a\u4e49Boss\u680f%s\u7684\u989c\u8272",
    "commands.bossbar.set.color.unchanged": "\u65e0\u53d8\u5316\uff0c\u8fd9\u672c\u5c31\u662f\u8fd9\u4e2aBoss\u680f\u7684\u989c\u8272",
    "commands.bossbar.set.max.success": "\u81ea\u5b9a\u4e49Boss\u680f%s\u7684\u6700\u5927\u503c\u5df2\u6539\u4e3a%s",
    "commands.bossbar.set.max.unchanged": "\u65e0\u53d8\u5316\uff0c\u8fd9\u672c\u5c31\u662f\u8fd9\u4e2aBoss\u680f\u7684\u6700\u5927\u503c",
    "commands.bossbar.set.name.success": "\u5df2\u91cd\u547d\u540d\u81ea\u5b9a\u4e49Boss\u680f%s",
    "commands.bossbar.set.name.unchanged": "\u65e0\u53d8\u5316\uff0c\u8fd9\u672c\u5c31\u662f\u8fd9\u4e2aBoss\u680f\u7684\u540d\u79f0",
    "commands.bossbar.set.players.success.none": "\u81ea\u5b9a\u4e49Boss\u680f%s\u5728\u5f53\u4e0b\u6ca1\u6709\u5728\u7ebf\u73a9\u5bb6",
    "commands.bossbar.set.players.success.some": "\u81ea\u5b9a\u4e49Boss\u680f%s\u5728\u5f53\u4e0b\u5728\u7ebf\u7684%s\u540d\u73a9\u5bb6\u6709\uff1a%s",
    "commands.bossbar.set.players.unchanged": "\u65e0\u53d8\u5316\uff0c\u8fd9\u4e9b\u73a9\u5bb6\u5df2\u7ecf\u5728Boss\u680f\u4e0a\uff0c\u6ca1\u6709\u73a9\u5bb6\u9700\u8981\u88ab\u6dfb\u52a0\u6216\u79fb\u9664",
    "commands.bossbar.set.style.success": "\u5df2\u6539\u53d8\u81ea\u5b9a\u4e49Boss\u680f%s\u7684\u6837\u5f0f",
    "commands.bossbar.set.style.unchanged": "\u65e0\u53d8\u5316\uff0c\u8fd9\u672c\u5c31\u662f\u8fd9\u4e2aBoss\u680f\u7684\u6837\u5f0f",
    "commands.bossbar.set.value.success": "\u81ea\u5b9a\u4e49Boss\u680f%s\u7684\u503c\u5df2\u6539\u4e3a%s",
    "commands.bossbar.set.value.unchanged": "\u65e0\u53d8\u5316\uff0c\u8fd9\u672c\u5c31\u662f\u8fd9\u4e2aBoss\u680f\u7684\u503c",
    "commands.bossbar.set.visibility.unchanged.hidden": "\u65e0\u53d8\u5316\uff0c\u8be5Boss\u680f\u539f\u5df2\u9690\u85cf",
    "commands.bossbar.set.visibility.unchanged.visible": "\u65e0\u53d8\u5316\uff0c\u8be5Boss\u680f\u539f\u5df2\u53ef\u89c1",
    "commands.bossbar.set.visible.success.hidden": "\u5df2\u5c06\u81ea\u5b9a\u4e49Boss\u680f%s\u6539\u4e3a\u9690\u85cf",
    "commands.bossbar.set.visible.success.visible": "\u5df2\u5c06\u81ea\u5b9a\u4e49Boss\u680f%s\u6539\u4e3a\u53ef\u89c1",
    "commands.bossbar.unknown": "\u4e0d\u5b58\u5728ID\u4e3a'%s'\u7684Boss\u680f",
    "commands.clear.success.multiple": "\u5df2\u79fb\u9664%2$s\u540d\u73a9\u5bb6\u7684%1$s\u4e2a\u7269\u54c1",
    "commands.clear.success.single": "\u5df2\u79fb\u9664\u73a9\u5bb6%2$s\u7684%1$s\u4e2a\u7269\u54c1",
    "commands.clear.test.multiple": "\u5df2\u5728%2$s\u540d\u73a9\u5bb6\u8eab\u4e0a\u627e\u5230%1$s\u4e2a\u5339\u914d\u7684\u7269\u54c1",
    "commands.clear.test.single": "\u5df2\u5728\u73a9\u5bb6%2$s\u8eab\u4e0a\u627e\u5230%1$s\u4e2a\u5339\u914d\u7684\u7269\u54c1",
    "commands.clone.failed": "\u6ca1\u6709\u65b9\u5757\u88ab\u590d\u5236",
    "commands.clone.overlap": "\u6e90\u533a\u57df\u548c\u76ee\u6807\u533a\u57df\u4e0d\u80fd\u91cd\u53e0",
    "commands.clone.success": "\u5df2\u6210\u529f\u590d\u5236%s\u4e2a\u65b9\u5757",
    "commands.clone.toobig": "\u6307\u5b9a\u533a\u57df\u5185\u7684\u65b9\u5757\u592a\u591a\uff08\u6700\u5927\u503c\u4e3a%s\uff0c\u6307\u5b9a\u503c\u4e3a%s\uff09",
    "commands.data.block.get": "%2$s, %3$s, %4$s\u7684%1$s\u5728\u4e58\u4ee5\u500d\u7387%5$s\u540e\u7684\u503c\u662f%6$s",
    "commands.data.block.invalid": "\u76ee\u6807\u65b9\u5757\u4e0d\u662f\u65b9\u5757\u5b9e\u4f53",
    "commands.data.block.modified": "\u5df2\u4fee\u6539%s, %s, %s\u5904\u7684\u65b9\u5757\u6570\u636e",
    "commands.data.block.query": "%s, %s, %s\u62e5\u6709\u4ee5\u4e0b\u65b9\u5757\u6570\u636e\uff1a%s",
    "commands.data.entity.get": "%2$s\u7684%1$s\u5728\u4e58\u4ee5\u500d\u7387%3$s\u540e\u7684\u503c\u662f%4$s",
    "commands.data.entity.invalid": "\u65e0\u6cd5\u4fee\u6539\u73a9\u5bb6\u6570\u636e",
    "commands.data.entity.modified": "\u5df2\u4fee\u6539%s\u7684\u5b9e\u4f53\u6570\u636e",
    "commands.data.entity.query": "%s\u62e5\u6709\u4ee5\u4e0b\u5b9e\u4f53\u6570\u636e\uff1a%s",
    "commands.data.get.invalid": "\u65e0\u6cd5\u83b7\u53d6%s\uff0c\u53ea\u63a5\u53d7\u6570\u5b57\u6807\u7b7e",
    "commands.data.get.multiple": "\u8be5\u53c2\u6570\u53ea\u63a5\u53d7\u5355\u4e2aNBT\u503c",
    "commands.data.get.unknown": "\u65e0\u6cd5\u83b7\u53d6%s\uff0c\u6807\u7b7e\u4e0d\u5b58\u5728",
    "commands.data.merge.failed": "\u65e0\u53d8\u5316\uff0c\u6240\u6307\u5b9a\u7684\u5c5e\u6027\u5df2\u6709\u8fd9\u4e9b\u503c",
    "commands.data.modify.expected_list": "\u9700\u8981\u5217\u8868\uff0c\u5374\u53d1\u73b0\u4e86\uff1a%s",
    "commands.data.modify.expected_object": "\u9700\u8981\u5bf9\u8c61\uff0c\u5374\u53d1\u73b0\u4e86\uff1a%s",
    "commands.data.modify.invalid_index": "\u65e0\u6548\u7684\u5217\u8868\u7d22\u5f15\uff1a%s",
    "commands.data.storage.get": "\u5728\u5b58\u50a8%2$s\u4e2d\u7684%1$s\u5728\u4e58\u4ee5\u500d\u7387%3$s\u540e\u7684\u503c\u662f%4$s",
    "commands.data.storage.modified": "\u5df2\u4fee\u6539\u5b58\u50a8%s",
    "commands.data.storage.query": "\u5b58\u50a8%s\u542b\u6709\u4ee5\u4e0b\u5185\u5bb9\uff1a%s",
    "commands.datapack.disable.failed": "\u6570\u636e\u5305'%s'\u5e76\u672a\u542f\u7528\uff01",
    "commands.datapack.enable.failed": "\u6570\u636e\u5305'%s'\u539f\u5df2\u542f\u7528\uff01",
    "commands.datapack.list.available.none": "\u5df2\u65e0\u66f4\u591a\u53ef\u7528\u7684\u6570\u636e\u5305",
    "commands.datapack.list.available.success": "\u5171\u6709%s\u4e2a\u6570\u636e\u5305\u53ef\u7528\uff1a%s",
    "commands.datapack.list.enabled.none": "\u6ca1\u6709\u542f\u7528\u4e2d\u7684\u6570\u636e\u5305",
    "commands.datapack.list.enabled.success": "\u5df2\u542f\u7528%s\u4e2a\u6570\u636e\u5305\uff1a%s",
    "commands.datapack.modify.disable": "\u6b63\u5728\u7981\u7528\u6570\u636e\u5305%s",
    "commands.datapack.modify.enable": "\u6b63\u5728\u542f\u7528\u6570\u636e\u5305%s",
    "commands.datapack.unknown": "\u672a\u77e5\u7684\u6570\u636e\u5305'%s'",
    "commands.debug.alreadyRunning": "\u523b\u5206\u6790\u5668\u539f\u5df2\u5f00\u542f",
    "commands.debug.function.noRecursion": "\u65e0\u6cd5\u4ece\u51fd\u6570\u5185\u90e8\u5f00\u59cb\u8ffd\u8e2a",
    "commands.debug.function.success.multiple": "\u5df2\u8ffd\u8e2a%2$s\u4e2a\u51fd\u6570\u5185\u7684%1$s\u6761\u547d\u4ee4\u81f3\u8f93\u51fa\u6587\u4ef6%3$s",
    "commands.debug.function.success.single": "\u5df2\u8ffd\u8e2a\u51fd\u6570'%2$s'\u5185\u7684%1$s\u6761\u547d\u4ee4\u81f3\u8f93\u51fa\u6587\u4ef6%3$s",
    "commands.debug.function.traceFailed": "\u8ffd\u8e2a\u51fd\u6570\u5931\u8d25",
    "commands.debug.notRunning": "\u5c1a\u672a\u542f\u52a8\u523b\u5206\u6790\u5668",
    "commands.debug.started": "\u5df2\u5f00\u59cb\u523b\u5206\u6790",
    "commands.debug.stopped": "\u5df2\u505c\u6b62\u523b\u5206\u6790\uff0c\u7528\u65f6%s\u79d2\u548c%s\u523b\uff08\u6bcf\u79d2%s\u523b\uff09",
    "commands.defaultgamemode.success": "\u9ed8\u8ba4\u6e38\u620f\u6a21\u5f0f\u73b0\u5728\u4e3a%s",
    "commands.deop.failed": "\u65e0\u53d8\u5316\uff0c\u6b64\u73a9\u5bb6\u4e0d\u662f\u7ba1\u7406\u5458",
    "commands.deop.success": "%s\u4e0d\u518d\u662f\u670d\u52a1\u5668\u7ba1\u7406\u5458\u4e86",
    "commands.difficulty.failure": "\u96be\u5ea6\u672a\u53d8\uff0c\u5b83\u539f\u6765\u5c31\u662f%s",
    "commands.difficulty.query": "\u76ee\u524d\u96be\u5ea6\u4e3a%s",
    "commands.difficulty.success": "\u96be\u5ea6\u5df2\u88ab\u8bbe\u7f6e\u4e3a%s",
    "commands.drop.no_held_items": "\u8be5\u5b9e\u4f53\u65e0\u6cd5\u6301\u6709\u4efb\u4f55\u7269\u54c1",
    "commands.drop.no_loot_table": "\u5b9e\u4f53%s\u6ca1\u6709\u6218\u5229\u54c1\u8868",
    "commands.drop.success.multiple": "\u6389\u843d\u4e86%s\u4e2a\u7269\u54c1",
    "commands.drop.success.multiple_with_table": "\u4ece\u6218\u5229\u54c1\u8868%2$s\u4e2d\u6389\u843d\u4e86%1$s\u4e2a\u7269\u54c1",
    "commands.drop.success.single": "\u6389\u843d\u4e86%s\u4e2a%s",
    "commands.drop.success.single_with_table": "\u4ece\u6218\u5229\u54c1\u8868%3$s\u4e2d\u6389\u843d\u4e86%1$s\u4e2a%2$s",
    "commands.effect.clear.everything.failed": "\u5bf9\u8c61\u6ca1\u6709\u53ef\u4ee5\u79fb\u9664\u7684\u6548\u679c",
    "commands.effect.clear.everything.success.multiple": "\u5df2\u79fb\u9664%s\u4e2a\u5bf9\u8c61\u7684\u6240\u6709\u6548\u679c",
    "commands.effect.clear.everything.success.single": "\u5df2\u79fb\u9664%s\u7684\u6240\u6709\u6548\u679c",
    "commands.effect.clear.specific.failed": "\u5bf9\u8c61\u6ca1\u6709\u6307\u5b9a\u6548\u679c",
    "commands.effect.clear.specific.success.multiple": "\u5df2\u79fb\u9664%2$s\u4e2a\u5bf9\u8c61\u7684%1$s\u6548\u679c",
    "commands.effect.clear.specific.success.single": "\u5df2\u79fb\u9664%2$s\u7684%1$s\u6548\u679c",
    "commands.effect.give.failed": "\u65e0\u6cd5\u5e94\u7528\u6b64\u6548\u679c\uff08\u5bf9\u8c61\u5bf9\u6548\u679c\u514d\u75ab\uff0c\u6216\u5df2\u6709\u66f4\u5f3a\u7684\u6548\u679c\uff09",
    "commands.effect.give.success.multiple": "\u5df2\u5c06%s\u6548\u679c\u5e94\u7528\u4e8e%s\u4e2a\u5bf9\u8c61",
    "commands.effect.give.success.single": "\u5df2\u5c06%s\u6548\u679c\u5e94\u7528\u4e8e%s",
    "commands.enchant.failed": "\u65e0\u53d8\u5316\uff0c\u5bf9\u8c61\u672a\u6301\u6709\u7269\u54c1\u6216\u5176\u4e0d\u652f\u6301\u6b64\u9b54\u5492",
    "commands.enchant.failed.entity": "%s\u4e0d\u662f\u6b64\u547d\u4ee4\u7684\u6709\u6548\u5b9e\u4f53",
    "commands.enchant.failed.incompatible": "%s\u4e0d\u652f\u6301\u6b64\u9b54\u5492",
    "commands.enchant.failed.itemless": "%s\u672a\u624b\u6301\u4efb\u4f55\u7269\u54c1",
    "commands.enchant.failed.level": "%s\u9ad8\u4e8e\u8be5\u9b54\u5492\u652f\u6301\u7684\u6700\u9ad8\u7b49\u7ea7%s",
    "commands.enchant.success.multiple": "\u5df2\u5c06%s\u9b54\u5492\u5e94\u7528\u4e8e%s\u4e2a\u5b9e\u4f53",
    "commands.enchant.success.single": "\u5df2\u5c06%s\u9b54\u5492\u5e94\u7528\u4e8e%s\u7684\u7269\u54c1\u4e0a",
    "commands.execute.blocks.toobig": "\u6307\u5b9a\u533a\u57df\u5185\u7684\u65b9\u5757\u592a\u591a\uff08\u6700\u5927\u503c\u4e3a%s\uff0c\u6307\u5b9a\u503c\u4e3a%s\uff09",
    "commands.execute.conditional.fail": "\u6d4b\u8bd5\u5931\u8d25",
    "commands.execute.conditional.fail_count": "\u6d4b\u8bd5\u5931\u8d25\uff0c\u8ba1\u6570\uff1a%s",
    "commands.execute.conditional.pass": "\u6d4b\u8bd5\u901a\u8fc7",
    "commands.execute.conditional.pass_count": "\u6d4b\u8bd5\u901a\u8fc7\uff0c\u8ba1\u6570\uff1a%s",
    "commands.experience.add.levels.success.multiple": "\u5df2\u7ed9\u4e88%2$s\u540d\u73a9\u5bb6%1$s\u7ea7\u7ecf\u9a8c",
    "commands.experience.add.levels.success.single": "\u5df2\u7ed9\u4e88%2$s %1$s\u7ea7\u7ecf\u9a8c",
    "commands.experience.add.points.success.multiple": "\u5df2\u7ed9\u4e88%2$s\u540d\u73a9\u5bb6%1$s\u70b9\u7ecf\u9a8c",
    "commands.experience.add.points.success.single": "\u5df2\u7ed9\u4e88%2$s %1$s\u70b9\u7ecf\u9a8c",
    "commands.experience.query.levels": "%s\u62e5\u6709%s\u7ea7\u7ecf\u9a8c",
    "commands.experience.query.points": "%s\u62e5\u6709%s\u70b9\u7ecf\u9a8c\u503c",
    "commands.experience.set.levels.success.multiple": "\u5df2\u5c06%2$s\u540d\u73a9\u5bb6\u7684\u7ecf\u9a8c\u7b49\u7ea7\u8bbe\u4e3a%1$s",
    "commands.experience.set.levels.success.single": "\u5df2\u5c06%2$s\u7684\u7ecf\u9a8c\u7b49\u7ea7\u8bbe\u4e3a%1$s",
    "commands.experience.set.points.invalid": "\u65e0\u6cd5\u5c06\u6b64\u73a9\u5bb6\u7684\u7ecf\u9a8c\u503c\u8bbe\u7f6e\u4e3a\u8d85\u8fc7\u73b0\u6709\u7b49\u7ea7\u7684\u6700\u5927\u503c",
    "commands.experience.set.points.success.multiple": "\u5df2\u5c06%2$s\u540d\u73a9\u5bb6\u7684\u7ecf\u9a8c\u503c\u8bbe\u4e3a%1$s",
    "commands.experience.set.points.success.single": "\u5df2\u5c06%2$s\u7684\u7ecf\u9a8c\u503c\u8bbe\u4e3a%1$s",
    "commands.fill.failed": "\u6ca1\u6709\u65b9\u5757\u88ab\u586b\u5145",
    "commands.fill.success": "\u6210\u529f\u5730\u586b\u5145\u4e86%s\u4e2a\u65b9\u5757",
    "commands.fill.toobig": "\u6307\u5b9a\u533a\u57df\u5185\u7684\u65b9\u5757\u592a\u591a\uff08\u6700\u5927\u503c\u4e3a%s\uff0c\u6307\u5b9a\u503c\u4e3a%s\uff09",
    "commands.forceload.added.failure": "\u6ca1\u6709\u88ab\u6807\u8bb0\u4e3a\u5f3a\u5236\u52a0\u8f7d\u7684\u533a\u5757",
    "commands.forceload.added.multiple": "\u5df2\u5c06%2$s\u4e2d\u7684%3$s\u81f3%4$s\u95f4\u7684%1$s\u4e2a\u533a\u5757\u6807\u8bb0\u4e3a\u5f3a\u5236\u52a0\u8f7d",
    "commands.forceload.added.none": "\u5728%s\u4e2d\u672a\u627e\u5230\u5f3a\u5236\u52a0\u8f7d\u7684\u533a\u5757",
    "commands.forceload.added.single": "\u5df2\u5c06%2$s\u4e2d\u7684\u533a\u5757%1$s\u6807\u8bb0\u4e3a\u5f3a\u5236\u52a0\u8f7d",
    "commands.forceload.list.multiple": "\u5728%2$s\u5185\u627e\u5230%1$s\u4e2a\u5f3a\u5236\u52a0\u8f7d\u7684\u533a\u5757\uff1a%3$s",
    "commands.forceload.list.single": "\u5728%s\u5185\u627e\u5230\u4e00\u4e2a\u5f3a\u5236\u52a0\u8f7d\u7684\u533a\u5757\uff1a%s",
    "commands.forceload.query.failure": "\u5728%2$s\u4e2d%1$s\u5185\u7684\u533a\u5757\u672a\u88ab\u6807\u8bb0\u4e3a\u5f3a\u5236\u52a0\u8f7d",
    "commands.forceload.query.success": "\u5728%2$s\u4e2d%1$s\u5185\u7684\u533a\u5757\u88ab\u6807\u8bb0\u4e3a\u5f3a\u5236\u52a0\u8f7d",
    "commands.forceload.removed.all": "\u5df2\u89e3\u9664\u6807\u8bb0%s\u5185\u6240\u6709\u7684\u5f3a\u5236\u52a0\u8f7d\u533a\u5757",
    "commands.forceload.removed.failure": "\u6ca1\u6709\u5f3a\u5236\u52a0\u8f7d\u7684\u533a\u5757\u88ab\u79fb\u9664",
    "commands.forceload.removed.multiple": "\u5df2\u5c06%2$s\u4e2d\u7684%3$s\u81f3%4$s\u95f4\u7684%1$s\u4e2a\u533a\u5757\u53d6\u6d88\u5f3a\u5236\u52a0\u8f7d",
    "commands.forceload.removed.single": "\u5df2\u5c06%2$s\u4e2d\u7684\u533a\u5757%1$s\u89e3\u9664\u5f3a\u5236\u52a0\u8f7d",
    "commands.forceload.toobig": "\u6307\u5b9a\u533a\u57df\u5185\u533a\u5757\u8fc7\u591a\uff08\u6700\u5927\u503c\u4e3a%s\uff0c\u6307\u5b9a\u503c\u4e3a%s\uff09",
    "commands.function.success.multiple": "\u5df2\u6267\u884c%2$s\u4e2a\u51fd\u6570\u4e2d\u7684%1$s\u6761\u547d\u4ee4",
    "commands.function.success.single": "\u5df2\u6267\u884c\u51fd\u6570%2$s\u4e2d\u7684%1$s\u6761\u547d\u4ee4",
    "commands.gamemode.success.other": "\u5c06%s\u7684\u6e38\u620f\u6a21\u5f0f\u6539\u4e3a%s",
    "commands.gamemode.success.self": "\u4f60\u7684\u6e38\u620f\u6a21\u5f0f\u5df2\u88ab\u8bbe\u7f6e\u4e3a%s ",
    "commands.gamerule.query": "\u6e38\u620f\u89c4\u5219%s\u76ee\u524d\u4e3a\uff1a%s",
    "commands.gamerule.set": "\u6e38\u620f\u89c4\u5219%s\u5df2\u88ab\u8bbe\u4e3a\uff1a%s",
    "commands.give.failed.toomanyitems": "\u6700\u591a\u53ea\u80fd\u7ed9\u4e88%s\u4e2a%s",
    "commands.give.success.multiple": "\u5df2\u5c06%s\u4e2a%s\u7ed9\u4e88%s\u540d\u73a9\u5bb6",
    "commands.give.success.single": "\u5df2\u5c06%s\u4e2a%s\u7ed9\u4e88%s",
    "commands.help.failed": "\u672a\u77e5\u7684\u547d\u4ee4\u6216\u6743\u9650\u4e0d\u8db3",
    "commands.item.block.set.success": "\u5df2\u7528%4$s\u66ff\u6362\u4e86\u4f4d\u4e8e%1$s, %2$s, %3$s\u7684\u7269\u54c1\u683c",
    "commands.item.entity.set.success.multiple": "\u5df2\u7528%2$s\u66ff\u6362\u4e86%1$s\u4e2a\u5b9e\u4f53\u7684\u7269\u54c1\u683c",
    "commands.item.entity.set.success.single": "\u5df2\u7528%2$s\u66ff\u6362\u4e86%1$s\u7684\u7269\u54c1\u683c",
    "commands.item.source.no_such_slot": "\u6765\u6e90\u6ca1\u6709%s\u680f\u4f4d",
    "commands.item.source.not_a_container": "\u5728\u6765\u6e90\u4f4d\u7f6e%s, %s, %s\u4e0a\u7684\u4e0d\u662f\u4e00\u4e2a\u5bb9\u5668",
    "commands.item.target.no_changed.known_item": "\u6ca1\u6709\u5728%2$s\u680f\u4f4d\u63a5\u53d7\u4e86\u7269\u54c1%1$s\u7684\u76ee\u6807",
    "commands.item.target.no_changes": "\u6ca1\u6709\u5728%s\u680f\u4f4d\u63a5\u53d7\u4e86\u7269\u54c1\u7684\u76ee\u6807",
    "commands.item.target.no_such_slot": "\u76ee\u6807\u6ca1\u6709%s\u680f\u4f4d",
    "commands.item.target.not_a_container": "\u5728\u76ee\u6807\u4f4d\u7f6e%s, %s, %s\u4e0a\u7684\u4e0d\u662f\u4e00\u4e2a\u5bb9\u5668",
    "commands.jfr.dump.failed": "\u65e0\u6cd5\u8f6c\u50a8JFR\u8bb0\u5f55\uff1a%s",
    "commands.jfr.start.failed": "\u65e0\u6cd5\u5f00\u59cbJFR\u5206\u6790",
    "commands.jfr.started": "\u5df2\u5f00\u59cbJFR\u5206\u6790",
    "commands.jfr.stopped": "JFR\u5206\u6790\u5df2\u7ed3\u675f\uff0c\u5df2\u8f6c\u50a8\u81f3%s",
    "commands.kick.success": "\u5df2\u8e22\u51fa%s\uff1a%s",
    "commands.kill.success.multiple": "\u6740\u6b7b\u4e86%s\u4e2a\u5b9e\u4f53",
    "commands.kill.success.single": "\u5df2\u6e05\u9664%s",
    "commands.list.nameAndId": "%s\uff08%s\uff09",
    "commands.list.players": "\u5f53\u524d\u5171\u6709%s\u540d\u73a9\u5bb6\u5728\u7ebf\uff08\u6700\u5927\u73a9\u5bb6\u6570\u4e3a%s\uff09\uff1a%s",
    "commands.locate.failed": "\u65e0\u6cd5\u5728\u9644\u8fd1\u627e\u5230\u8be5\u7ed3\u6784",
    "commands.locate.success": "\u6700\u8fd1\u7684%s\u4f4d\u4e8e%s\uff08%s\u4e2a\u65b9\u5757\u5916\uff09",
    "commands.locatebiome.invalid": "\u6ca1\u6709\u7c7b\u578b\u4e3a\u201c%s\u201d\u7684\u751f\u7269\u7fa4\u7cfb",
    "commands.locatebiome.notFound": "\u65e0\u6cd5\u5728\u5408\u7406\u8ddd\u79bb\u5185\u627e\u5230\u7c7b\u578b\u4e3a\u201c%s\u201d\u7684\u751f\u7269\u7fa4\u7cfb",
    "commands.locatebiome.success": "\u6700\u8fd1\u7684%s\u4f4d\u4e8e%s\uff08%s\u4e2a\u65b9\u5757\u5916\uff09",
    "commands.message.display.incoming": "%s\u6084\u6084\u5730\u5bf9\u4f60\u8bf4\uff1a%s",
    "commands.message.display.outgoing": "\u4f60\u6084\u6084\u5730\u5bf9%s\u8bf4\uff1a%s",
    "commands.op.failed": "\u65e0\u53d8\u5316\uff0c\u8be5\u73a9\u5bb6\u5df2\u662f\u7ba1\u7406\u5458",
    "commands.op.success": "\u5df2\u5c06%s\u8bbe\u4e3a\u670d\u52a1\u5668\u7ba1\u7406\u5458",
    "commands.pardon.failed": "\u65e0\u53d8\u5316\uff0c\u8be5\u73a9\u5bb6\u672a\u88ab\u5c01\u7981",
    "commands.pardon.success": "\u5df2\u89e3\u5c01%s",
    "commands.pardonip.failed": "\u65e0\u53d8\u5316\uff0c\u8be5IP\u5730\u5740\u672a\u88ab\u5c01\u7981",
    "commands.pardonip.invalid": "\u65e0\u6548\u7684IP\u5730\u5740",
    "commands.pardonip.success": "\u5df2\u89e3\u5c01IP\u5730\u5740%s",
    "commands.particle.failed": "\u8be5\u7c92\u5b50\u65e0\u6cd5\u88ab\u4efb\u4f55\u73a9\u5bb6\u770b\u89c1",
    "commands.particle.success": "\u6b63\u5728\u663e\u793a\u7c92\u5b50%s",
    "commands.perf.alreadyRunning": "\u6027\u80fd\u5206\u6790\u5668\u539f\u5df2\u5f00\u542f",
    "commands.perf.notRunning": "\u6027\u80fd\u5206\u6790\u5668\u5c1a\u672a\u542f\u52a8",
    "commands.perf.reportFailed": "\u751f\u6210\u8c03\u8bd5\u62a5\u544a\u5931\u8d25",
    "commands.perf.reportSaved": "\u5df2\u5728%s\u751f\u6210\u8c03\u8bd5\u62a5\u544a",
    "commands.perf.started": "\u5df2\u5f00\u59cb\u65f6\u957f\u4e3a10\u79d2\u7684\u6027\u80fd\u5206\u6790\u6d4b\u8bd5\uff08\u4f7f\u7528'/perf stop'\u4ee5\u63d0\u524d\u7ed3\u675f\uff09",
    "commands.perf.stopped": "\u5df2\u505c\u6b62\u6027\u80fd\u5206\u6790\uff0c\u7528\u65f6%s\u79d2\u548c%s\u523b\uff08\u6bcf\u79d2%s\u523b\uff09",
    "commands.playsound.failed": "\u58f0\u97f3\u8fc7\u8fdc\u800c\u65e0\u6cd5\u88ab\u542c\u89c1",
    "commands.playsound.success.multiple": "\u5df2\u5c06\u58f0\u97f3%s\u64ad\u653e\u7ed9%s\u540d\u73a9\u5bb6",
    "commands.playsound.success.single": "\u5df2\u5c06\u58f0\u97f3%s\u64ad\u653e\u7ed9%s",
    "commands.publish.alreadyPublished": "\u5df2\u5b58\u5728\u5f00\u653e\u4e8e%s\u7aef\u53e3\u7684\u591a\u4eba\u6e38\u620f",
    "commands.publish.failed": "\u65e0\u6cd5\u5efa\u7acb\u672c\u5730\u6e38\u620f",
    "commands.publish.started": "\u672c\u5730\u6e38\u620f\u5df2\u5728\u7aef\u53e3%s\u4e0a\u5f00\u542f",
    "commands.publish.success": "\u591a\u4eba\u6e38\u620f\u5df2\u5728%s\u7aef\u53e3\u4e0a\u5f00\u542f",
    "commands.recipe.give.failed": "\u6ca1\u6709\u65b0\u914d\u65b9\u88ab\u89e3\u9501",
    "commands.recipe.give.success.multiple": "\u5df2\u4e3a%2$s\u540d\u73a9\u5bb6\u89e3\u9501\u4e86%1$s\u6761\u914d\u65b9",
    "commands.recipe.give.success.single": "\u5df2\u4e3a%2$s\u89e3\u9501\u4e86%1$s\u6761\u914d\u65b9",
    "commands.recipe.take.failed": "\u6ca1\u6709\u53ef\u9057\u5fd8\u7684\u914d\u65b9",
    "commands.recipe.take.success.multiple": "\u5df2\u5265\u593a%2$s\u540d\u73a9\u5bb6\u7684%1$s\u6761\u914d\u65b9",
    "commands.recipe.take.success.single": "\u5df2\u5265\u593a%2$s\u7684%1$s\u6761\u914d\u65b9",
    "commands.reload.failure": "\u91cd\u65b0\u52a0\u8f7d\u5931\u8d25\uff0c\u4fdd\u7559\u539f\u6709\u6570\u636e",
    "commands.reload.success": "\u91cd\u65b0\u52a0\u8f7d\u4e2d\uff01",
    "commands.save.alreadyOff": "\u5df2\u7ecf\u5173\u95ed\u4e16\u754c\u4fdd\u5b58",
    "commands.save.alreadyOn": "\u5df2\u7ecf\u6253\u5f00\u4e16\u754c\u4fdd\u5b58",
    "commands.save.disabled": "\u81ea\u52a8\u4fdd\u5b58\u5df2\u7981\u7528",
    "commands.save.enabled": "\u81ea\u52a8\u4fdd\u5b58\u5df2\u542f\u7528",
    "commands.save.failed": "\u65e0\u6cd5\u4fdd\u5b58\u6e38\u620f\uff08\u786c\u76d8\u7a7a\u95f4\u662f\u5426\u8db3\u591f\uff1f\uff09",
    "commands.save.saving": "\u6b63\u5728\u4fdd\u5b58\u6e38\u620f\uff08\u8fd9\u53ef\u80fd\u9700\u8981\u4e00\u4e9b\u65f6\u95f4\uff01\uff09",
    "commands.save.success": "\u6e38\u620f\u5df2\u4fdd\u5b58",
    "commands.schedule.cleared.failure": "\u6ca1\u6709ID\u4e3a%s\u7684\u8ba1\u5212",
    "commands.schedule.cleared.success": "\u5df2\u79fb\u9664%s\u4e2aID\u4e3a%s\u7684\u8ba1\u5212",
    "commands.schedule.created.function": "\u5df2\u5c06\u51fd\u6570'%s'\u8ba1\u5212\u5728%s\u523b\u540e\uff0c\u5373\u6e38\u620f\u65f6\u95f4%s\u65f6\u6267\u884c",
    "commands.schedule.created.tag": "\u5df2\u5c06\u6807\u7b7e'%s'\u8ba1\u5212\u5728%s\u523b\u540e\uff0c\u5373\u6e38\u620f\u65f6\u95f4%s\u65f6\u6267\u884c",
    "commands.schedule.same_tick": "\u65e0\u6cd5\u5c06\u51fd\u6570\u8ba1\u5212\u5728\u5f53\u524d\u523b",
    "commands.scoreboard.objectives.add.duplicate": "\u5df2\u7ecf\u5b58\u5728\u540c\u540d\u8bb0\u5206\u9879",
    "commands.scoreboard.objectives.add.success": "\u521b\u5efa\u4e86\u65b0\u7684\u8bb0\u5206\u9879%s",
    "commands.scoreboard.objectives.display.alreadyEmpty": "\u65e0\u53d8\u5316\uff0c\u8be5\u663e\u793a\u4f4d\u7f6e\u672c\u5c31\u662f\u7a7a\u7684",
    "commands.scoreboard.objectives.display.alreadySet": "\u65e0\u53d8\u5316\uff0c\u8be5\u663e\u793a\u4f4d\u7f6e\u5df2\u7ecf\u5b58\u5728\u8be5\u8bb0\u5206\u9879",
    "commands.scoreboard.objectives.display.cleared": "\u6e05\u7a7a\u4e86\u663e\u793a\u4f4d\u7f6e%s\u7684\u6240\u6709\u8bb0\u5206\u9879",
    "commands.scoreboard.objectives.display.set": "\u5df2\u5c06\u663e\u793a\u4f4d\u7f6e%s\u8bbe\u7f6e\u4e3a\u5c55\u793a\u8bb0\u5206\u9879%s",
    "commands.scoreboard.objectives.list.empty": "\u4e0d\u5b58\u5728\u4efb\u4f55\u8bb0\u5206\u9879",
    "commands.scoreboard.objectives.list.success": "\u5171\u6709%s\u4e2a\u8bb0\u5206\u9879\uff1a%s",
    "commands.scoreboard.objectives.modify.displayname": "\u5df2\u5c06%s\u7684\u663e\u793a\u540d\u79f0\u66f4\u6539\u4e3a%s",
    "commands.scoreboard.objectives.modify.rendertype": "\u5df2\u66f4\u6539\u8bb0\u5206\u9879%s\u7684\u6e32\u67d3\u7c7b\u578b",
    "commands.scoreboard.objectives.remove.success": "\u79fb\u9664\u4e86\u8bb0\u5206\u9879%s",
    "commands.scoreboard.players.add.success.multiple": "\u5c06%3$s\u4e2a\u5b9e\u4f53\u7684%2$s\u589e\u52a0\u4e86%1$s",
    "commands.scoreboard.players.add.success.single": "\u5c06%3$s\u7684%2$s\u589e\u52a0\u4e86%1$s\uff08\u73b0\u5728\u662f%4$s\uff09",
    "commands.scoreboard.players.enable.failed": "\u65e0\u53d8\u5316\uff0c\u89e6\u53d1\u5668\u539f\u5df2\u5f00\u542f",
    "commands.scoreboard.players.enable.invalid": "\u53ea\u80fd\u542f\u7528trigger\u7c7b\u8bb0\u5206\u9879",
    "commands.scoreboard.players.enable.success.multiple": "\u4e3a%2$s\u4e2a\u5b9e\u4f53\u542f\u7528\u4e86\u89e6\u53d1\u5668%1$s",
    "commands.scoreboard.players.enable.success.single": "\u5df2\u5c06\u89e6\u53d1\u5668%s\u5bf9%s\u542f\u7528",
    "commands.scoreboard.players.get.null": "\u65e0\u6cd5\u83b7\u53d6%2$s\u7684%1$s\u7684\u503c\uff0c\u5176\u5c1a\u672a\u88ab\u8d4b\u503c",
    "commands.scoreboard.players.get.success": "%s\u62e5\u6709%s %s",
    "commands.scoreboard.players.list.empty": "\u6ca1\u6709\u6b63\u88ab\u8ffd\u8e2a\u7684\u5b9e\u4f53",
    "commands.scoreboard.players.list.entity.empty": "%s\u65e0\u53ef\u663e\u793a\u7684\u5206\u6570",
    "commands.scoreboard.players.list.entity.entry": "%s\uff1a%s",
    "commands.scoreboard.players.list.entity.success": "%s\u62e5\u6709%s\u9879\u5206\u6570\uff1a",
    "commands.scoreboard.players.list.success": "\u5171\u6709%s\u4e2a\u6b63\u88ab\u8ffd\u8e2a\u7684\u5b9e\u4f53\uff1a%s",
    "commands.scoreboard.players.operation.success.multiple": "\u66f4\u65b0\u4e86%2$s\u4e2a\u5b9e\u4f53\u7684%1$s",
    "commands.scoreboard.players.operation.success.single": "\u5df2\u5c06%2$s\u7684%1$s\u8bbe\u4e3a%3$s",
    "commands.scoreboard.players.remove.success.multiple": "\u5c06%3$s\u4e2a\u5b9e\u4f53\u7684%2$s\u5206\u6570\u51cf\u5c11%1$s",
    "commands.scoreboard.players.remove.success.single": "\u5c06%3$s\u7684%2$s\u51cf\u5c11\u4e86%1$s\uff08\u73b0\u5728\u662f%4$s\uff09",
    "commands.scoreboard.players.reset.all.multiple": "\u91cd\u7f6e\u4e86%s\u4e2a\u5b9e\u4f53\u7684\u6240\u6709\u5206\u6570",
    "commands.scoreboard.players.reset.all.single": "\u91cd\u7f6e\u4e86%s\u7684\u6240\u6709\u5206\u6570",
    "commands.scoreboard.players.reset.specific.multiple": "\u91cd\u7f6e\u4e86%2$s\u4e2a\u5b9e\u4f53\u7684%1$s",
    "commands.scoreboard.players.reset.specific.single": "\u91cd\u7f6e\u4e86%2$s\u7684%1$s",
    "commands.scoreboard.players.set.success.multiple": "\u5df2\u5c06%2$s\u4e2a\u5b9e\u4f53\u7684%1$s\u8bbe\u4e3a%3$s",
    "commands.scoreboard.players.set.success.single": "\u5df2\u5c06%2$s\u7684%1$s\u5206\u6570\u8bbe\u4e3a%3$s",
    "commands.seed.success": "\u79cd\u5b50: %s",
    "commands.setblock.failed": "\u65e0\u6cd5\u653e\u7f6e\u65b9\u5757",
    "commands.setblock.success": "\u66f4\u6539\u4e86\u4f4d\u4e8e%s, %s, %s\u7684\u65b9\u5757",
    "commands.setidletimeout.success": "\u73a9\u5bb6\u7684\u95f2\u7f6e\u9650\u65f6\u73b0\u5728\u4e3a%s\u5206\u949f",
    "commands.setworldspawn.success": "\u5df2\u5c06\u4e16\u754c\u7684\u51fa\u751f\u70b9\u8bbe\u7f6e\u4e3a%s, %s, %s [%s]",
    "commands.spawnpoint.success.multiple": "\u5df2\u5c06%6$s\u540d\u73a9\u5bb6\u5728%5$s\u7684\u51fa\u751f\u70b9\u8bbe\u4e3a%1$s, %2$s, %3$s [%4$s]",
    "commands.spawnpoint.success.single": "\u5df2\u5c06%6$s\u5728%5$s\u7684\u51fa\u751f\u70b9\u8bbe\u4e3a%1$s, %2$s, %3$s [%4$s]",
    "commands.spectate.not_spectator": "%s\u5c1a\u672a\u5904\u4e8e\u65c1\u89c2\u6a21\u5f0f",
    "commands.spectate.self": "\u4e0d\u80fd\u65c1\u89c2\u81ea\u5df1",
    "commands.spectate.success.started": "\u6b63\u5728\u65c1\u89c2%s",
    "commands.spectate.success.stopped": "\u4e0d\u518d\u65c1\u89c2\u5b9e\u4f53",
    "commands.spreadplayers.failed.entities": "%1$s\u4e2a\u5b9e\u4f53\u672a\u80fd\u56f4\u7ed5%2$s, %3$s\u5206\u6563\uff08\u7a7a\u95f4\u8fc7\u5c0f\u800c\u5b9e\u4f53\u8fc7\u591a\uff0c\u8bf7\u5c06\u5206\u6563\u95f4\u8ddd\u8bbe\u4e3a%4$s\u4ee5\u4e0b\uff09",
    "commands.spreadplayers.failed.teams": "%1$s\u652f\u961f\u4f0d\u672a\u80fd\u56f4\u7ed5%2$s, %3$s\u5206\u6563\uff08\u7a7a\u95f4\u8fc7\u5c0f\u800c\u5b9e\u4f53\u8fc7\u591a\uff0c\u8bf7\u5c06\u5206\u6563\u95f4\u8ddd\u8bbe\u4e3a%4$s\u4ee5\u4e0b\uff09",
    "commands.spreadplayers.success.entities": "\u5df2\u5c06%1$s\u540d\u73a9\u5bb6\u56f4\u7ed5%2$s, %3$s\u5206\u6563\uff0c\u5e73\u5747\u8ddd\u79bb\u4e3a%4$s\u4e2a\u65b9\u5757",
    "commands.spreadplayers.success.teams": "\u5df2\u5c06%1$s\u652f\u961f\u4f0d\u56f4\u7ed5%2$s, %3$s\u5206\u6563\uff0c\u5e73\u5747\u8ddd\u79bb\u4e3a%4$s\u4e2a\u65b9\u5757",
    "commands.stop.stopping": "\u6b63\u5728\u5173\u95ed\u670d\u52a1\u5668",
    "commands.stopsound.success.source.any": "\u5df2\u505c\u6b62\u64ad\u653e\u6240\u6709'%s'\u7684\u97f3\u6548",
    "commands.stopsound.success.source.sound": "\u5df2\u505c\u6b62\u8d44\u6e90\u4e3a'%2$s'\u7684\u58f0\u97f3'%1$s'",
    "commands.stopsound.success.sourceless.any": "\u5df2\u505c\u6b62\u6240\u6709\u58f0\u97f3",
    "commands.stopsound.success.sourceless.sound": "\u5df2\u505c\u6b62\u58f0\u97f3'%s'",
    "commands.summon.failed": "\u65e0\u6cd5\u53ec\u5524\u5b9e\u4f53",
    "commands.summon.failed.uuid": "UUID\u91cd\u590d\uff0c\u65e0\u6cd5\u53ec\u5524\u5b9e\u4f53",
    "commands.summon.invalidPosition": "\u65e0\u6548\u7684\u53ec\u5524\u5750\u6807",
    "commands.summon.success": "\u53ec\u5524\u4e86\u65b0\u7684%s",
    "commands.tag.add.failed": "\u5bf9\u8c61\u5df2\u62e5\u6709\u6b64\u6807\u7b7e\u6216\u62e5\u6709\u8fc7\u591a\u6807\u7b7e",
    "commands.tag.add.success.multiple": "\u5df2\u4e3a%2$s\u4e2a\u5b9e\u4f53\u6dfb\u52a0\u4e86\u6807\u7b7e'%1$s'",
    "commands.tag.add.success.single": "\u5df2\u4e3a%2$s\u6dfb\u52a0\u4e86\u6807\u7b7e'%1$s'",
    "commands.tag.list.multiple.empty": "%s\u4e2a\u5b9e\u4f53\u6ca1\u6709\u4efb\u4f55\u6807\u7b7e",
    "commands.tag.list.multiple.success": "%s\u4e2a\u5b9e\u4f53\u62e5\u6709\u5171\u8ba1%s\u9879\u6807\u7b7e\uff1a%s",
    "commands.tag.list.single.empty": "%s\u6ca1\u6709\u6807\u7b7e",
    "commands.tag.list.single.success": "%s\u62e5\u6709%s\u4e2a\u6807\u7b7e\uff1a%s",
    "commands.tag.remove.failed": "\u5bf9\u8c61\u6ca1\u6709\u8fd9\u4e2a\u6807\u7b7e",
    "commands.tag.remove.success.multiple": "\u5df2\u79fb\u9664%2$s\u4e2a\u5b9e\u4f53\u7684\u6807\u7b7e'%1$s'",
    "commands.tag.remove.success.single": "\u5df2\u79fb\u9664%2$s\u7684\u6807\u7b7e'%1$s'",
    "commands.team.add.duplicate": "\u5df2\u7ecf\u5b58\u5728\u540c\u540d\u961f\u4f0d",
    "commands.team.add.success": "\u5df2\u521b\u5efa\u961f\u4f0d%s",
    "commands.team.empty.success": "\u5df2\u5c06%s\u540d\u6210\u5458\u4ece\u961f\u4f0d%s\u4e2d\u79fb\u9664",
    "commands.team.empty.unchanged": "\u65e0\u53d8\u5316\uff0c\u8be5\u961f\u4f0d\u672c\u5c31\u662f\u7a7a\u7684",
    "commands.team.join.success.multiple": "\u5df2\u5c06%s\u540d\u6210\u5458\u52a0\u5165\u961f\u4f0d%s",
    "commands.team.join.success.single": "\u5df2\u5c06%s\u52a0\u5165\u961f\u4f0d%s",
    "commands.team.leave.success.multiple": "\u5df2\u5c06%s\u540d\u6210\u5458\u4ece\u6240\u6709\u961f\u4f0d\u4e2d\u79fb\u9664",
    "commands.team.leave.success.single": "\u5df2\u5c06%s\u4ece\u6240\u6709\u961f\u4f0d\u4e2d\u79fb\u9664",
    "commands.team.list.members.empty": "\u961f\u4f0d%s\u4e2d\u6ca1\u6709\u6210\u5458",
    "commands.team.list.members.success": "\u961f\u4f0d%s\u542b\u6709%s\u540d\u6210\u5458\uff1a%s",
    "commands.team.list.teams.empty": "\u6ca1\u6709\u961f\u4f0d\u5b58\u5728",
    "commands.team.list.teams.success": "\u5171\u6709%s\u652f\u961f\u4f0d\uff1a%s",
    "commands.team.option.collisionRule.success": "\u961f\u4f0d%s\u7684\u78b0\u649e\u89c4\u5219\u73b0\u5728\u662f\u201c%s\u201d",
    "commands.team.option.collisionRule.unchanged": "\u65e0\u53d8\u5316\uff0c\u78b0\u649e\u89c4\u5219\u5df2\u7ecf\u662f\u6b64\u503c",
    "commands.team.option.color.success": "\u961f\u4f0d%s\u7684\u989c\u8272\u5df2\u66f4\u65b0\u4e3a%s",
    "commands.team.option.color.unchanged": "\u65e0\u53d8\u5316\uff0c\u6b64\u961f\u4f0d\u672c\u5c31\u4e3a\u6b64\u989c\u8272",
    "commands.team.option.deathMessageVisibility.success": "\u961f\u4f0d%s\u7684\u6b7b\u4ea1\u6d88\u606f\u53ef\u89c1\u6027\u73b0\u5728\u4e3a\u201c%s\u201d",
    "commands.team.option.deathMessageVisibility.unchanged": "\u65e0\u53d8\u5316\uff0c\u6b7b\u4ea1\u6d88\u606f\u7684\u663e\u793a\u8bbe\u5b9a\u5df2\u7ecf\u662f\u6b64\u503c",
    "commands.team.option.friendlyfire.alreadyDisabled": "\u65e0\u53d8\u5316\uff0c\u53cb\u519b\u4f24\u5bb3\u672c\u5c31\u5728\u6b64\u961f\u4f0d\u4e0a\u7981\u7528",
    "commands.team.option.friendlyfire.alreadyEnabled": "\u65e0\u53d8\u5316\uff0c\u53cb\u519b\u4f24\u5bb3\u672c\u5c31\u5728\u6b64\u961f\u4f0d\u4e0a\u542f\u7528",
    "commands.team.option.friendlyfire.disabled": "\u5df2\u7981\u7528\u961f\u4f0d%s\u7684\u53cb\u519b\u4f24\u5bb3",
    "commands.team.option.friendlyfire.enabled": "\u5df2\u542f\u7528\u961f\u4f0d%s\u7684\u53cb\u519b\u4f24\u5bb3",
    "commands.team.option.name.success": "\u5df2\u66f4\u65b0\u961f\u4f0d%s\u7684\u540d\u79f0",
    "commands.team.option.name.unchanged": "\u65e0\u53d8\u5316\u3002\u6b64\u961f\u4f0d\u672c\u5c31\u4e3a\u8be5\u540d\u79f0\u3002",
    "commands.team.option.nametagVisibility.success": "\u961f\u4f0d%s\u7684\u540d\u79f0\u6807\u7b7e\u53ef\u89c1\u6027\u73b0\u5728\u4e3a\u201c%s\u201d",
    "commands.team.option.nametagVisibility.unchanged": "\u65e0\u53d8\u5316\uff0c\u540d\u79f0\u680f\u7684\u663e\u793a\u8bbe\u5b9a\u5df2\u7ecf\u662f\u6b64\u503c",
    "commands.team.option.prefix.success": "\u961f\u4f0d\u524d\u7f00\u5df2\u8bbe\u4e3a%s",
    "commands.team.option.seeFriendlyInvisibles.alreadyDisabled": "\u65e0\u53d8\u5316\uff0c\u6b64\u961f\u4f0d\u672c\u5c31\u4e0d\u53ef\u770b\u89c1\u9690\u8eab\u7684\u961f\u53cb",
    "commands.team.option.seeFriendlyInvisibles.alreadyEnabled": "\u65e0\u53d8\u5316\uff0c\u6b64\u961f\u4f0d\u672c\u5c31\u53ef\u4ee5\u770b\u89c1\u9690\u8eab\u7684\u961f\u53cb",
    "commands.team.option.seeFriendlyInvisibles.disabled": "\u961f\u4f0d%s\u4e0d\u518d\u53ef\u4ee5\u770b\u89c1\u9690\u8eab\u7684\u961f\u53cb\u4e86",
    "commands.team.option.seeFriendlyInvisibles.enabled": "\u961f\u4f0d%s\u73b0\u5728\u53ef\u4ee5\u770b\u89c1\u9690\u8eab\u7684\u961f\u53cb\u4e86",
    "commands.team.option.suffix.success": "\u961f\u4f0d\u540e\u7f00\u5df2\u8bbe\u4e3a%s",
    "commands.team.remove.success": "\u79fb\u9664\u4e86\u961f\u4f0d%s",
    "commands.teammsg.failed.noteam": "\u4f60\u5fc5\u987b\u5728\u4e00\u652f\u961f\u4f0d\u5185\u624d\u80fd\u53d1\u51fa\u961f\u4f0d\u6d88\u606f",
    "commands.teleport.invalidPosition": "\u65e0\u6548\u7684\u4f20\u9001\u5750\u6807",
    "commands.teleport.success.entity.multiple": "\u5df2\u4f20\u9001%s\u4e2a\u5b9e\u4f53\u81f3%s",
    "commands.teleport.success.entity.single": "\u5df2\u5c06%s\u4f20\u9001\u81f3%s",
    "commands.teleport.success.location.multiple": "\u5df2\u4f20\u9001%s\u4e2a\u5b9e\u4f53\u81f3%s, %s, %s",
    "commands.teleport.success.location.single": "\u5df2\u5c06%s\u4f20\u9001\u5230 %s, %s, %s",
    "commands.time.query": "\u76ee\u524d\u65f6\u95f4\u4e3a%s",
    "commands.time.set": "\u5c06\u65f6\u95f4\u8bbe\u4e3a%s",
    "commands.title.cleared.multiple": "\u5df2\u6e05\u9664%s\u540d\u73a9\u5bb6\u7684\u6807\u9898",
    "commands.title.cleared.single": "\u5df2\u6e05\u9664%s\u7684\u6807\u9898",
    "commands.title.reset.multiple": "\u5df2\u91cd\u7f6e%s\u540d\u73a9\u5bb6\u7684\u6807\u9898\u8bbe\u7f6e",
    "commands.title.reset.single": "\u5df2\u91cd\u7f6e%s\u7684\u6807\u9898\u8bbe\u7f6e",
    "commands.title.show.actionbar.multiple": "\u6b63\u5728\u5411%s\u540d\u73a9\u5bb6\u663e\u793a\u65b0\u7684\u5feb\u6377\u680f\u6807\u9898",
    "commands.title.show.actionbar.single": "\u6b63\u5728\u5411%s\u663e\u793a\u65b0\u7684\u5feb\u6377\u680f\u6807\u9898",
    "commands.title.show.subtitle.multiple": "\u6b63\u5728\u5411%s\u540d\u73a9\u5bb6\u663e\u793a\u65b0\u7684\u526f\u6807\u9898",
    "commands.title.show.subtitle.single": "\u6b63\u5728\u5411%s\u663e\u793a\u65b0\u7684\u526f\u6807\u9898",
    "commands.title.show.title.multiple": "\u6b63\u5728\u5411%s\u540d\u73a9\u5bb6\u663e\u793a\u65b0\u7684\u6807\u9898",
    "commands.title.show.title.single": "\u6b63\u5728\u5411%s\u663e\u793a\u65b0\u7684\u6807\u9898",
    "commands.title.times.multiple": "\u5df2\u66f4\u6539%s\u540d\u73a9\u5bb6\u7684\u6807\u9898\u663e\u793a\u65f6\u95f4",
    "commands.title.times.single": "\u5df2\u66f4\u6539%s\u7684\u6807\u9898\u663e\u793a\u65f6\u95f4",
    "commands.trigger.add.success": "\u5df2\u89e6\u53d1%s\uff08\u6570\u503c\u5df2\u589e\u52a0%s\uff09",
    "commands.trigger.failed.invalid": "\u4f60\u53ea\u80fd\u89e6\u53d1'trigger'\u7c7b\u578b\u7684\u8bb0\u5206\u9879",
    "commands.trigger.failed.unprimed": "\u4f60\u5c1a\u65e0\u6cd5\u89e6\u53d1\u8fd9\u4e2a\u8bb0\u5206\u9879",
    "commands.trigger.set.success": "\u5df2\u89e6\u53d1%s\uff08\u6570\u503c\u5df2\u8bbe\u4e3a%s\uff09",
    "commands.trigger.simple.success": "\u5df2\u89e6\u53d1%s",
    "commands.weather.set.clear": "\u5929\u6c14\u5df2\u8bbe\u4e3a\u6674\u5929",
    "commands.weather.set.rain": "\u5929\u6c14\u5df2\u8bbe\u4e3a\u96e8\u5929",
    "commands.weather.set.thunder": "\u5929\u6c14\u5df2\u8bbe\u4e3a\u96f7\u96e8",
    "commands.whitelist.add.failed": "\u73a9\u5bb6\u5df2\u5728\u767d\u540d\u5355\u5185",
    "commands.whitelist.add.success": "\u5df2\u5c06%s\u52a0\u5165\u767d\u540d\u5355",
    "commands.whitelist.alreadyOff": "\u767d\u540d\u5355\u539f\u5df2\u5173\u95ed",
    "commands.whitelist.alreadyOn": "\u767d\u540d\u5355\u539f\u5df2\u5f00\u542f",
    "commands.whitelist.disabled": "\u767d\u540d\u5355\u5df2\u5173\u95ed",
    "commands.whitelist.enabled": "\u767d\u540d\u5355\u5df2\u5f00\u542f",
    "commands.whitelist.list": "\u767d\u540d\u5355\u4e2d\u5171\u6709%s\u540d\u73a9\u5bb6\uff1a%s",
    "commands.whitelist.none": "\u767d\u540d\u5355\u4e2d\u6ca1\u6709\u73a9\u5bb6",
    "commands.whitelist.reloaded": "\u5df2\u91cd\u65b0\u8bfb\u53d6\u767d\u540d\u5355",
    "commands.whitelist.remove.failed": "\u73a9\u5bb6\u4e0d\u5728\u767d\u540d\u5355\u5185",
    "commands.whitelist.remove.success": "\u5df2\u5c06%s\u79fb\u51fa\u767d\u540d\u5355",
    "commands.worldborder.center.failed": "\u65e0\u53d8\u5316\uff0c\u4e16\u754c\u8fb9\u754c\u4e2d\u5fc3\u70b9\u5df2\u7ecf\u5728\u8be5\u5904",
    "commands.worldborder.center.success": "\u5df2\u5c06\u4e16\u754c\u8fb9\u754c\u7684\u4e2d\u5fc3\u8bbe\u4e3a%s, %s",
    "commands.worldborder.damage.amount.failed": "\u65e0\u53d8\u5316\uff0c\u4e16\u754c\u8fb9\u754c\u4f24\u5bb3\u5df2\u7ecf\u662f\u6b64\u503c",
    "commands.worldborder.damage.amount.success": "\u5df2\u5c06\u4e16\u754c\u8fb9\u754c\u7684\u4f24\u5bb3\u8bbe\u7f6e\u4e3a%s\u6bcf\u79d2\u6bcf\u4e2a\u65b9\u5757",
    "commands.worldborder.damage.buffer.failed": "\u65e0\u53d8\u5316\uff0c\u4e16\u754c\u8fb9\u754c\u4f24\u5bb3\u7f13\u51b2\u533a\u5df2\u7ecf\u662f\u6b64\u8ddd\u79bb",
    "commands.worldborder.damage.buffer.success": "\u5df2\u5c06\u4e16\u754c\u8fb9\u754c\u4f24\u5bb3\u7f13\u51b2\u533a\u8bbe\u7f6e\u4e3a%s\u4e2a\u65b9\u5757",
    "commands.worldborder.get": "\u5f53\u524d\u7684\u4e16\u754c\u8fb9\u754c\u5bbd\u5ea6\u4e3a%s\u4e2a\u65b9\u5757",
    "commands.worldborder.set.failed.big": "\u4e16\u754c\u8fb9\u754c\u7684\u5bbd\u5ea6\u4e0d\u80fd\u5927\u4e8e%s\u683c",
    "commands.worldborder.set.failed.far": "\u4e16\u754c\u8fb9\u754c\u7684\u4f4d\u7f6e\u4e0d\u80fd\u8fdc\u4e8e%s\u683c",
    "commands.worldborder.set.failed.nochange": "\u65e0\u53d8\u5316\uff0c\u4e16\u754c\u8fb9\u754c\u5df2\u7ecf\u662f\u6b64\u5927\u5c0f",
    "commands.worldborder.set.failed.small": "\u4e16\u754c\u8fb9\u754c\u7684\u5bbd\u5ea6\u4e0d\u80fd\u5c0f\u4e8e1\u683c",
    "commands.worldborder.set.grow": "\u6b63\u5728\u5c06\u4e16\u754c\u8fb9\u754c\u7684\u5bbd\u5ea6\u6269\u5927\u4e3a%s\u4e2a\u65b9\u5757\uff0c\u65f6\u95f4%s\u79d2",
    "commands.worldborder.set.immediate": "\u5df2\u5c06\u4e16\u754c\u8fb9\u754c\u7684\u5bbd\u5ea6\u8bbe\u4e3a%s",
    "commands.worldborder.set.shrink": "\u6b63\u5728\u5c06\u4e16\u754c\u8fb9\u754c\u7684\u5bbd\u5ea6\u7f29\u5c0f\u4e3a%s\u4e2a\u65b9\u5757\uff0c\u65f6\u95f4%s\u79d2",
    "commands.worldborder.warning.distance.failed": "\u65e0\u53d8\u5316\uff0c\u4e16\u754c\u8fb9\u754c\u4f24\u5bb3\u8b66\u544a\u533a\u5df2\u7ecf\u662f\u6b64\u8ddd\u79bb",
    "commands.worldborder.warning.distance.success": "\u5df2\u5c06\u4e16\u754c\u8fb9\u754c\u8b66\u544a\u8ddd\u79bb\u8bbe\u7f6e\u4e3a%s\u4e2a\u65b9\u5757",
    "commands.worldborder.warning.time.failed": "\u65e0\u53d8\u5316\uff0c\u4e16\u754c\u8fb9\u754c\u8b66\u544a\u65f6\u95f4\u5df2\u7ecf\u662f\u6b64\u65f6\u957f",
    "commands.worldborder.warning.time.success": "\u5df2\u5c06\u4e16\u754c\u8fb9\u754c\u8b66\u544a\u65f6\u95f4\u8bbe\u7f6e\u4e3a%s\u79d2",
    "connect.aborted": "\u8fde\u63a5\u4e2d\u65ad",
    "connect.authorizing": "\u767b\u5f55\u4e2d\u2026",
    "connect.connecting": "\u6b63\u5728\u8fde\u63a5\u5230\u670d\u52a1\u5668\u2026",
    "connect.encrypting": "\u901a\u8baf\u52a0\u5bc6\u4e2d\u2026",
    "connect.failed": "\u65e0\u6cd5\u8fde\u63a5\u81f3\u670d\u52a1\u5668",
    "connect.joining": "\u52a0\u5165\u4e16\u754c\u4e2d\u2026",
    "connect.negotiating": "\u8fde\u63a5\u534f\u5546\u4e2d\u2026",
    "container.barrel": "\u6728\u6876",
    "container.beacon": "\u4fe1\u6807",
    "container.blast_furnace": "\u9ad8\u7089",
    "container.brewing": "\u917f\u9020\u53f0",
    "container.cartography_table": "\u5236\u56fe\u53f0",
    "container.chest": "\u7bb1\u5b50",
    "container.chestDouble": "\u5927\u578b\u7bb1\u5b50",
    "container.crafting": "\u5408\u6210",
    "container.creative": "\u7269\u54c1\u9009\u680f",
    "container.dispenser": "\u53d1\u5c04\u5668",
    "container.dropper": "\u6295\u63b7\u5668",
    "container.enchant": "\u9644\u9b54",
    "container.enchant.clue": "%s\u2026\uff1f",
    "container.enchant.lapis.many": "\u82b1\u8d39\uff1a%s\u9897\u9752\u91d1\u77f3",
    "container.enchant.lapis.one": "\u82b1\u8d39\uff1a1\u9897\u9752\u91d1\u77f3",
    "container.enchant.level.many": "+ %s\u7ea7\u7ecf\u9a8c",
    "container.enchant.level.one": "+ 1\u7ea7\u7ecf\u9a8c",
    "container.enchant.level.requirement": "\u7b49\u7ea7\u8981\u6c42\uff1a%s",
    "container.enderchest": "\u672b\u5f71\u7bb1",
    "container.furnace": "\u7194\u7089",
    "container.grindstone_title": "\u4fee\u590d\u548c\u795b\u9b54",
    "container.hopper": "\u6f0f\u6597",
    "container.inventory": "\u7269\u54c1\u680f",
    "container.isLocked": "%s\u5df2\u88ab\u4e0a\u9501\uff01",
    "container.lectern": "\u8bb2\u53f0",
    "container.loom": "\u7ec7\u5e03\u673a",
    "container.repair": "\u4fee\u590d\u548c\u547d\u540d",
    "container.repair.cost": "\u9644\u9b54\u82b1\u8d39\uff1a%1$s",
    "container.repair.expensive": "\u8fc7\u4e8e\u6602\u8d35\uff01",
    "container.shulkerBox": "\u6f5c\u5f71\u76d2",
    "container.shulkerBox.more": "\u8fd8\u6709%s\u9879\u672a\u663e\u793a\u2026",
    "container.smoker": "\u70df\u718f\u7089",
    "container.spectatorCantOpen": "\u65e0\u6cd5\u6253\u5f00\uff1a\u6218\u5229\u54c1\u5c1a\u672a\u751f\u6210\u3002",
    "container.stonecutter": "\u5207\u77f3\u673a",
    "container.upgrade": "\u5347\u7ea7\u88c5\u5907",
    "controls.keybinds": "\u6309\u952e\u7ed1\u5b9a\u2026",
    "controls.keybinds.title": "\u6309\u952e\u7ed1\u5b9a",
    "controls.reset": "\u91cd\u7f6e",
    "controls.resetAll": "\u91cd\u7f6e\u6309\u952e",
    "controls.title": "\u6309\u952e\u63a7\u5236",
    "createWorld.customize.buffet.biome": "\u8bf7\u9009\u62e9\u4e00\u79cd\u751f\u7269\u7fa4\u7cfb",
    "createWorld.customize.buffet.title": "\u81ea\u5b9a\u4e49\u81ea\u9009\u4e16\u754c",
    "createWorld.customize.custom.baseSize": "\u57fa\u51c6\u6df1\u5ea6\u5927\u5c0f",
    "createWorld.customize.custom.biomeDepthOffset": "\u751f\u7269\u7fa4\u7cfb\u6df1\u5ea6\u8865\u507f",
    "createWorld.customize.custom.biomeDepthWeight": "\u751f\u7269\u7fa4\u7cfb\u6df1\u5ea6\u6bd4\u91cd",
    "createWorld.customize.custom.biomeScaleOffset": "\u751f\u7269\u7fa4\u7cfb\u89c4\u6a21\u8865\u507f",
    "createWorld.customize.custom.biomeScaleWeight": "\u751f\u7269\u7fa4\u7cfb\u89c4\u6a21\u6bd4\u91cd",
    "createWorld.customize.custom.biomeSize": "\u751f\u7269\u7fa4\u7cfb\u89c4\u6a21",
    "createWorld.customize.custom.center": "\u4e2d\u5fc3\u9ad8\u5ea6",
    "createWorld.customize.custom.confirm1": "\u8fd9\u5c06\u8986\u76d6\u4f60\u7684\u5f53\u524d",
    "createWorld.customize.custom.confirm2": "\u8bbe\u7f6e\u4e14\u4e0d\u80fd\u6062\u590d\u3002",
    "createWorld.customize.custom.confirmTitle": "\u8b66\u544a\uff01",
    "createWorld.customize.custom.coordinateScale": "\u5e73\u9762\u6bd4\u4f8b",
    "createWorld.customize.custom.count": "\u751f\u6210\u5c1d\u8bd5\u6b21\u6570",
    "createWorld.customize.custom.defaults": "\u9ed8\u8ba4",
    "createWorld.customize.custom.depthNoiseScaleExponent": "\u9ad8\u5ea6\u5dee\u5f02\u6307\u6570",
    "createWorld.customize.custom.depthNoiseScaleX": "X\u8f74\u9ad8\u5ea6\u5dee\u5f02\u503c",
    "createWorld.customize.custom.depthNoiseScaleZ": "Z\u8f74\u9ad8\u5ea6\u5dee\u5f02\u503c",
    "createWorld.customize.custom.dungeonChance": "\u5730\u7262\u6570\u91cf",
    "createWorld.customize.custom.fixedBiome": "\u751f\u7269\u7fa4\u7cfb",
    "createWorld.customize.custom.heightScale": "\u9ad8\u5ea6\u6bd4\u4f8b",
    "createWorld.customize.custom.lavaLakeChance": "\u7194\u5ca9\u6e56\u5bc6\u5ea6",
    "createWorld.customize.custom.lowerLimitScale": "\u89c4\u6a21\u4e0b\u9650",
    "createWorld.customize.custom.mainNoiseScaleX": "\u4e3b\u5730\u5f62\u5dee\u5f02\u503c X",
    "createWorld.customize.custom.mainNoiseScaleY": "\u4e3b\u5730\u5f62\u5dee\u5f02\u503c Y",
    "createWorld.customize.custom.mainNoiseScaleZ": "\u4e3b\u5730\u5f62\u5dee\u5f02\u503c Z",
    "createWorld.customize.custom.maxHeight": "\u6700\u9ad8\u9ad8\u5ea6",
    "createWorld.customize.custom.minHeight": "\u6700\u4f4e\u9ad8\u5ea6",
    "createWorld.customize.custom.next": "\u4e0b\u4e00\u9875",
    "createWorld.customize.custom.page0": "\u57fa\u672c\u8bbe\u7f6e",
    "createWorld.customize.custom.page1": "\u77ff\u7269\u8bbe\u7f6e",
    "createWorld.customize.custom.page2": "\u9ad8\u7ea7\u8bbe\u7f6e\uff08\u4ec5\u9650\u9ad8\u7ea7\u7528\u6237\uff01\uff09",
    "createWorld.customize.custom.page3": "\u989d\u5916\u9ad8\u7ea7\u8bbe\u7f6e\uff08\u4ec5\u9650\u9ad8\u7ea7\u7528\u6237\uff01\uff09",
    "createWorld.customize.custom.preset.caveChaos": "\u6df7\u6c8c\u6d1e\u7a74",
    "createWorld.customize.custom.preset.caveDelight": "\u63a2\u6d1e\u8005\u7684\u559c\u60a6",
    "createWorld.customize.custom.preset.drought": "\u65f1\u5730",
    "createWorld.customize.custom.preset.goodLuck": "\u795d\u4f60\u597d\u8fd0",
    "createWorld.customize.custom.preset.isleLand": "\u7a7a\u5c9b",
    "createWorld.customize.custom.preset.mountains": "\u5c71\u8109\u72c2\u9b54",
    "createWorld.customize.custom.preset.waterWorld": "\u6c34\u4e16\u754c",
    "createWorld.customize.custom.presets": "\u9884\u8bbe",
    "createWorld.customize.custom.presets.title": "\u81ea\u5b9a\u4e49\u4e16\u754c\u9884\u8bbe",
    "createWorld.customize.custom.prev": "\u4e0a\u4e00\u9875",
    "createWorld.customize.custom.randomize": "\u968f\u673a",
    "createWorld.customize.custom.riverSize": "\u6cb3\u7684\u89c4\u6a21",
    "createWorld.customize.custom.seaLevel": "\u6d77\u5e73\u9762",
    "createWorld.customize.custom.size": "\u751f\u6210\u89c4\u6a21",
    "createWorld.customize.custom.spread": "\u6269\u6563\u9ad8\u5ea6",
    "createWorld.customize.custom.stretchY": "\u9ad8\u5ea6\u4f38\u5c55",
    "createWorld.customize.custom.upperLimitScale": "\u89c4\u6a21\u4e0a\u9650",
    "createWorld.customize.custom.useCaves": "\u6d1e\u7a74",
    "createWorld.customize.custom.useDungeons": "\u5730\u7262",
    "createWorld.customize.custom.useLavaLakes": "\u7194\u5ca9\u6e56",
    "createWorld.customize.custom.useLavaOceans": "\u7194\u5ca9\u6d77",
    "createWorld.customize.custom.useMansions": "\u6797\u5730\u5e9c\u90b8",
    "createWorld.customize.custom.useMineShafts": "\u5e9f\u5f03\u77ff\u4e95",
    "createWorld.customize.custom.useMonuments": "\u6d77\u5e95\u795e\u6bbf",
    "createWorld.customize.custom.useOceanRuins": "\u6d77\u5e95\u5e9f\u589f",
    "createWorld.customize.custom.useRavines": "\u5ce1\u8c37",
    "createWorld.customize.custom.useStrongholds": "\u8981\u585e",
    "createWorld.customize.custom.useTemples": "\u795e\u6bbf",
    "createWorld.customize.custom.useVillages": "\u6751\u5e84",
    "createWorld.customize.custom.useWaterLakes": "\u6e56\u6cca",
    "createWorld.customize.custom.waterLakeChance": "\u6e56\u6cca\u5bc6\u5ea6",
    "createWorld.customize.flat.height": "\u9ad8\u5ea6",
    "createWorld.customize.flat.layer": "%s",
    "createWorld.customize.flat.layer.bottom": "\u5e95\u5c42 - %s",
    "createWorld.customize.flat.layer.top": "\u9876\u5c42 - %s",
    "createWorld.customize.flat.removeLayer": "\u79fb\u9664\u5c42\u9762",
    "createWorld.customize.flat.tile": "\u6b64\u5c42\u7684\u6750\u6599",
    "createWorld.customize.flat.title": "\u81ea\u5b9a\u4e49\u8d85\u5e73\u5766\u4e16\u754c",
    "createWorld.customize.preset.bottomless_pit": "\u65e0\u5e95\u6df1\u6e0a",
    "createWorld.customize.preset.classic_flat": "\u7ecf\u5178\u5e73\u5766",
    "createWorld.customize.preset.desert": "\u6c99\u6f20",
    "createWorld.customize.preset.overworld": "\u4e3b\u4e16\u754c",
    "createWorld.customize.preset.redstone_ready": "\u7ea2\u77f3\u4ff1\u5907",
    "createWorld.customize.preset.snowy_kingdom": "\u96ea\u4e4b\u738b\u56fd",
    "createWorld.customize.preset.the_void": "\u865a\u7a7a",
    "createWorld.customize.preset.tunnelers_dream": "\u6316\u6398\u5de5\u7684\u68a6\u60f3",
    "createWorld.customize.preset.water_world": "\u6c34\u4e16\u754c",
    "createWorld.customize.presets": "\u9884\u8bbe",
    "createWorld.customize.presets.list": "\u53e6\u5916\uff0c\u8fd9\u91cc\u662f\u4e9b\u6211\u4eec\u65e9\u671f\u5236\u4f5c\u597d\u7684\uff01",
    "createWorld.customize.presets.select": "\u4f7f\u7528\u9884\u8bbe",
    "createWorld.customize.presets.share": "\u60f3\u8981\u4e0e\u522b\u4eba\u5206\u4eab\u4f60\u7684\u9884\u8bbe\u65b9\u6848\u5417\uff1f\u4f7f\u7528\u4e0b\u9762\u7684\u8f93\u5165\u6846\u5427\uff01",
    "createWorld.customize.presets.title": "\u9009\u62e9\u4e00\u79cd\u9884\u8bbe",
    "createWorld.preparing": "\u6b63\u5728\u51c6\u5907\u751f\u6210\u4e16\u754c\u2026",
    "dataPack.title": "\u9009\u62e9\u6570\u636e\u5305",
    "dataPack.validation.back": "\u8fd4\u56de",
    "dataPack.validation.failed": "\u6570\u636e\u5305\u9a8c\u8bc1\u5931\u8d25\uff01",
    "dataPack.validation.reset": "\u91cd\u7f6e\u4e3a\u9ed8\u8ba4",
    "dataPack.validation.working": "\u6b63\u5728\u9a8c\u8bc1\u5df2\u9009\u7684\u6570\u636e\u5305\u2026",
    "dataPack.vanilla.description": "Minecraft\u7684\u9ed8\u8ba4\u6570\u636e\u5305",
    "datapackFailure.safeMode": "\u5b89\u5168\u6a21\u5f0f",
    "datapackFailure.title": "\u76ee\u524d\u9009\u4e2d\u7684\u6570\u636e\u5305\u4e2d\u51fa\u73b0\u4e86\u9519\u8bef\uff0c\u5bfc\u81f4\u4e16\u754c\u65e0\u6cd5\u52a0\u8f7d\u3002\n\u4f60\u53ef\u4ee5\u5c1d\u8bd5\u53ea\u8f7d\u5165\u539f\u7248\u6570\u636e\u5305\uff08\u201c\u5b89\u5168\u6a21\u5f0f\u201d\uff09\u6216\u56de\u5230\u6807\u9898\u754c\u9762\u624b\u52a8\u4fee\u590d\u8be5\u95ee\u9898\u3002",
    "death.attack.anvil": "%1$s\u88ab\u5760\u843d\u7684\u94c1\u7827\u538b\u6241\u4e86",
    "death.attack.anvil.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u88ab\u6389\u843d\u7684\u94c1\u7827\u7838\u6241\u4e86",
    "death.attack.arrow": "%1$s\u88ab%2$s\u5c04\u6740",
    "death.attack.arrow.item": "%1$s\u88ab%2$s\u7528%3$s\u5c04\u6740",
    "death.attack.badRespawnPoint.link": "\u523b\u610f\u7684\u6e38\u620f\u8bbe\u8ba1",
    "death.attack.badRespawnPoint.message": "%1$s\u88ab%2$s\u6740\u6b7b\u4e86",
    "death.attack.cactus": "%1$s\u88ab\u6233\u6b7b\u4e86",
    "death.attack.cactus.player": "%1$s\u5728\u8bd5\u56fe\u9003\u79bb%2$s\u65f6\u649e\u4e0a\u4e86\u4ed9\u4eba\u638c",
    "death.attack.cramming": "%1$s\u56e0\u88ab\u8fc7\u5ea6\u6324\u538b\u800c\u6b7b",
    "death.attack.cramming.player": "%1$s\u88ab%2$s\u7838\u6b7b\u4e86",
    "death.attack.dragonBreath": "%1$s\u88ab\u9f99\u4e4b\u5410\u606f\u6240\u70e4\u7126",
    "death.attack.dragonBreath.player": "%1$s\u88ab%2$s\u7684\u9f99\u606f\u70e4\u719f\u4e86",
    "death.attack.drown": "%1$s\u6df9\u6b7b\u4e86",
    "death.attack.drown.player": "%1$s\u5728\u8bd5\u56fe\u9003\u79bb%2$s\u65f6\u6df9\u6b7b\u4e86",
    "death.attack.dryout": "%1$s\u56e0\u8131\u6c34\u800c\u6b7b",
    "death.attack.dryout.player": "%1$s\u5728\u8bd5\u56fe\u9003\u79bb%2$s\u65f6\u56e0\u8131\u6c34\u800c\u6b7b",
    "death.attack.even_more_magic": "%1$s\u88ab\u4e0d\u4e3a\u4eba\u77e5\u7684\u9b54\u6cd5\u6740\u6b7b\u4e86",
    "death.attack.explosion": "%1$s\u7206\u70b8\u4e86",
    "death.attack.explosion.player": "%1$s\u88ab%2$s\u70b8\u6b7b\u4e86",
    "death.attack.explosion.player.item": "%1$s\u88ab%2$s\u7528%3$s\u70b8\u6b7b\u4e86",
    "death.attack.fall": "%1$s\u843d\u5730\u8fc7\u731b",
    "death.attack.fall.player": "%1$s\u5728\u9003\u79bb%2$s\u65f6\u843d\u5730\u8fc7\u731b",
    "death.attack.fallingBlock": "%1$s\u88ab\u5760\u843d\u7684\u65b9\u5757\u538b\u6241\u4e86",
    "death.attack.fallingBlock.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u88ab\u6389\u843d\u7684\u65b9\u5757\u7838\u6241\u4e86",
    "death.attack.fallingStalactite": "%1$s\u88ab\u5760\u843d\u7684\u949f\u4e73\u77f3\u523a\u7a7f\u4e86",
    "death.attack.fallingStalactite.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u88ab\u5760\u843d\u7684\u949f\u4e73\u77f3\u523a\u7a7f\u4e86",
    "death.attack.fireball": "%1$s\u88ab%2$s\u7528\u706b\u7403\u70e7\u6b7b\u4e86",
    "death.attack.fireball.item": "%1$s\u88ab%2$s\u7528%3$s\u53d1\u5c04\u7684\u706b\u7403\u70e7\u6b7b\u4e86",
    "death.attack.fireworks": "%1$s\u968f\u7740\u4e00\u58f0\u5de8\u54cd\u6d88\u5931\u4e86",
    "death.attack.fireworks.item": "%1$s\u968f\u7740%2$s\u7528%3$s\u53d1\u5c04\u7684\u70df\u82b1\u53d1\u51fa\u7684\u5de8\u54cd\u6d88\u5931\u4e86",
    "death.attack.fireworks.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u968f\u7740\u4e00\u58f0\u5de8\u54cd\u4e2d\u6d88\u5931\u4e86",
    "death.attack.flyIntoWall": "%1$s\u611f\u53d7\u5230\u4e86\u52a8\u80fd",
    "death.attack.flyIntoWall.player": "%1$s\u5728\u8bd5\u56fe\u9003\u79bb%2$s\u65f6\u611f\u53d7\u5230\u4e86\u52a8\u80fd",
    "death.attack.freeze": "%1$s\u88ab\u51bb\u6b7b\u4e86",
    "death.attack.freeze.player": "%1$s\u88ab%2$s\u51bb\u6b7b\u4e86",
    "death.attack.generic": "%1$s\u6b7b\u4e86",
    "death.attack.generic.player": "%1$s\u6b7b\u4e8e%2$s",
    "death.attack.hotFloor": "%1$s\u53d1\u73b0\u4e86\u5730\u677f\u662f\u7194\u5ca9\u505a\u7684",
    "death.attack.hotFloor.player": "%1$s\u56e0%2$s\u800c\u6b65\u5165\u5371\u9669\u4e4b\u5730",
    "death.attack.inFire": "%1$s\u6d74\u706b\u711a\u8eab",
    "death.attack.inFire.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u4e0d\u614e\u8d70\u5165\u4e86\u706b\u4e2d",
    "death.attack.inWall": "%1$s\u5728\u5899\u91cc\u7a92\u606f\u800c\u4ea1",
    "death.attack.inWall.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u7a92\u606f\u800c\u4ea1",
    "death.attack.indirectMagic": "%1$s\u88ab%2$s\u4f7f\u7528\u7684\u9b54\u6cd5\u6740\u6b7b\u4e86",
    "death.attack.indirectMagic.item": "%1$s\u88ab%2$s\u7528%3$s\u6740\u6b7b\u4e86",
    "death.attack.lava": "%1$s\u8bd5\u56fe\u5728\u7194\u5ca9\u91cc\u6e38\u6cf3",
    "death.attack.lava.player": "%1$s\u5728\u9003\u79bb%2$s\u65f6\u8bd5\u56fe\u5728\u7194\u5ca9\u91cc\u6e38\u6cf3",
    "death.attack.lightningBolt": "%1$s\u88ab\u95ea\u7535\u51fb\u4e2d",
    "death.attack.lightningBolt.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u88ab\u95ea\u7535\u51fb\u4e2d",
    "death.attack.magic": "%1$s\u88ab\u9b54\u6cd5\u6740\u6b7b\u4e86",
    "death.attack.magic.player": "%1$s\u5728\u8bd5\u56fe\u9003\u79bb%2$s\u65f6\u88ab\u9b54\u6cd5\u6740\u6b7b\u4e86",
    "death.attack.message_too_long": "\u62b1\u6b49\uff01\u6d88\u606f\u592a\u957f\uff0c\u65e0\u6cd5\u5b8c\u6574\u663e\u793a\u3002\u622a\u65ad\u540e\u7684\u6d88\u606f\uff1a%s",
    "death.attack.mob": "%1$s\u88ab%2$s\u6740\u6b7b\u4e86",
    "death.attack.mob.item": "%1$s\u88ab%2$s\u7528%3$s\u6740\u6b7b\u4e86",
    "death.attack.onFire": "%1$s\u88ab\u70e7\u6b7b\u4e86",
    "death.attack.onFire.player": "%1$s\u5728\u8bd5\u56fe\u4e0e%2$s\u6218\u6597\u65f6\u88ab\u70e4\u5f97\u9165\u8106",
    "death.attack.outOfWorld": "%1$s\u6389\u51fa\u4e86\u8fd9\u4e2a\u4e16\u754c",
    "death.attack.outOfWorld.player": "%1$s\u4e0e%2$s\u4e0d\u5171\u6234\u5929",
    "death.attack.player": "%1$s\u88ab%2$s\u6740\u6b7b\u4e86",
    "death.attack.player.item": "%1$s\u88ab%2$s\u7528%3$s\u6740\u6b7b\u4e86",
    "death.attack.stalagmite": "%1$s\u88ab\u77f3\u7b0b\u523a\u7a7f\u4e86",
    "death.attack.stalagmite.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u88ab\u77f3\u7b0b\u523a\u7a7f\u4e86",
    "death.attack.starve": "%1$s\u997f\u6b7b\u4e86",
    "death.attack.starve.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u997f\u6b7b\u4e86",
    "death.attack.sting": "%1$s\u88ab\u86f0\u6b7b\u4e86",
    "death.attack.sting.player": "%1$s\u88ab%2$s\u86f0\u6b7b\u4e86",
    "death.attack.sweetBerryBush": "%1$s\u88ab\u751c\u6d46\u679c\u4e1b\u523a\u6b7b\u4e86",
    "death.attack.sweetBerryBush.player": "%1$s\u5728\u8bd5\u56fe\u9003\u79bb%2$s\u65f6\u88ab\u751c\u6d46\u679c\u4e1b\u523a\u6b7b\u4e86",
    "death.attack.thorns": "%1$s\u5728\u8bd5\u56fe\u4f24\u5bb3%2$s\u65f6\u88ab\u6740",
    "death.attack.thorns.item": "%1$s\u5728\u8bd5\u56fe\u4f24\u5bb3%2$s\u65f6\u88ab%3$s\u6740\u6b7b",
    "death.attack.thrown": "%1$s\u88ab%2$s\u7ed9\u7838\u6b7b\u4e86",
    "death.attack.thrown.item": "%1$s\u88ab%2$s\u7528%3$s\u7ed9\u7838\u6b7b\u4e86",
    "death.attack.trident": "%1$s\u88ab%2$s\u523a\u7a7f\u4e86",
    "death.attack.trident.item": "%1$s\u88ab%2$s\u7528%3$s\u523a\u7a7f\u4e86",
    "death.attack.wither": "%1$s\u51cb\u96f6\u4e86",
    "death.attack.wither.player": "%1$s\u5728\u4e0e%2$s\u6218\u6597\u65f6\u51cb\u96f6\u4e86",
    "death.attack.witherSkull": "%1$s\u88ab%2$s\u53d1\u5c04\u7684\u5934\u9885\u5c04\u4e2d\u4e86",
    "death.fell.accident.generic": "%1$s\u4ece\u9ad8\u5904\u6454\u4e86\u4e0b\u6765",
    "death.fell.accident.ladder": "%1$s\u4ece\u68af\u5b50\u4e0a\u6454\u4e86\u4e0b\u6765",
    "death.fell.accident.other_climbable": "%1$s\u5728\u6500\u722c\u65f6\u6454\u4e86\u4e0b\u6765",
    "death.fell.accident.scaffolding": "%1$s\u4ece\u811a\u624b\u67b6\u4e0a\u6454\u4e86\u4e0b\u6765",
    "death.fell.accident.twisting_vines": "%1$s\u4ece\u7f20\u6028\u85e4\u4e0a\u6454\u4e86\u4e0b\u6765",
    "death.fell.accident.vines": "%1$s\u4ece\u85e4\u8513\u4e0a\u6454\u4e86\u4e0b\u6765",
    "death.fell.accident.weeping_vines": "%1$s\u4ece\u5782\u6cea\u85e4\u4e0a\u6454\u4e86\u4e0b\u6765",
    "death.fell.assist": "%1$s\u56e0\u4e3a%2$s\u6ce8\u5b9a\u8981\u6454\u6b7b",
    "death.fell.assist.item": "%1$s\u56e0\u4e3a%2$s\u4f7f\u7528\u4e86%3$s\u6ce8\u5b9a\u8981\u6454\u6b7b",
    "death.fell.finish": "%1$s\u6454\u4f24\u5f97\u592a\u91cd\u5e76\u88ab%2$s\u5b8c\u7ed3\u4e86\u751f\u547d",
    "death.fell.finish.item": "%1$s\u6454\u4f24\u5f97\u592a\u91cd\u5e76\u88ab%2$s\u7528%3$s\u5b8c\u7ed3\u4e86\u751f\u547d",
    "death.fell.killer": "%1$s\u6ce8\u5b9a\u8981\u6454\u6b7b",
    "deathScreen.quit.confirm": "\u4f60\u786e\u5b9a\u8981\u9000\u51fa\u5417\uff1f",
    "deathScreen.respawn": "\u91cd\u751f",
    "deathScreen.score": "\u5206\u6570",
    "deathScreen.spectate": "\u65c1\u89c2\u4e16\u754c",
    "deathScreen.title": "\u4f60\u6b7b\u4e86\uff01",
    "deathScreen.title.hardcore": "\u6e38\u620f\u7ed3\u675f\uff01",
    "deathScreen.titleScreen": "\u6807\u9898\u754c\u9762",
    "debug.advanced_tooltips.help": "F3 + H = \u663e\u793a\u9ad8\u7ea7\u63d0\u793a\u6846",
    "debug.advanced_tooltips.off": "\u9ad8\u7ea7\u63d0\u793a\u6846\uff1a\u9690\u85cf",
    "debug.advanced_tooltips.on": "\u9ad8\u7ea7\u63d0\u793a\u6846\uff1a\u663e\u793a",
    "debug.chunk_boundaries.help": "F3 + G = \u663e\u793a\u533a\u5757\u8fb9\u754c",
    "debug.chunk_boundaries.off": "\u533a\u5757\u8fb9\u754c\uff1a\u9690\u85cf",
    "debug.chunk_boundaries.on": "\u533a\u5757\u8fb9\u754c\uff1a\u663e\u793a",
    "debug.clear_chat.help": "F3 + D = \u6e05\u7a7a\u804a\u5929\u8bb0\u5f55",
    "debug.copy_location.help": "F3 + C = \u7528/tp\u547d\u4ee4\u7684\u5f62\u5f0f\u590d\u5236\u4f60\u7684\u4f4d\u7f6e\uff0c\u6309\u4f4fF3 + C\u4f7f\u6e38\u620f\u5d29\u6e83",
    "debug.copy_location.message": "\u5750\u6807\u5df2\u590d\u5236\u5230\u526a\u8d34\u677f",
    "debug.crash.message": "F3 + C\u5df2\u88ab\u6309\u4e0b\u3002\u9664\u975e\u91ca\u653e\u6309\u952e\u5426\u5219\u8fd9\u5c06\u8ba9\u6e38\u620f\u5d29\u6e83\u3002",
    "debug.crash.warning": "\u5728%s\u79d2\u540e\u5d29\u6e83\u2026",
    "debug.creative_spectator.error": "\u4f60\u6ca1\u6709\u5207\u6362\u6e38\u620f\u6a21\u5f0f\u7684\u6743\u9650",
    "debug.creative_spectator.help": "F3 + N = \u5728\u4e0a\u4e00\u4e2a\u6a21\u5f0f\u548c\u65c1\u89c2\u6a21\u5f0f\u95f4\u5207\u6362",
    "debug.cycle_renderdistance.help": "F3 + F = \u5728\u6e32\u67d3\u8ddd\u79bb\u95f4\u5faa\u73af\uff08\u6309Shift\u6765\u53cd\u5411\u5faa\u73af\uff09",
    "debug.cycle_renderdistance.message": "\u6e32\u67d3\u8ddd\u79bb\uff1a%s",
    "debug.gamemodes.error": "\u4f60\u6ca1\u6709\u6743\u9650\u6253\u5f00\u6e38\u620f\u6a21\u5f0f\u5207\u6362\u5668",
    "debug.gamemodes.help": "F3 + F4 = \u6253\u5f00\u6e38\u620f\u6a21\u5f0f\u5207\u6362\u5668",
    "debug.gamemodes.press_f4": "[ F4 ]",
    "debug.gamemodes.select_next": "%s \u4e0b\u4e00\u4e2a",
    "debug.help.help": "F3 + Q = \u663e\u793a\u6b64\u5217\u8868",
    "debug.help.message": "\u6309\u952e\u8bbe\u7f6e\uff1a",
    "debug.inspect.client.block": "\u5ba2\u6237\u7aef\u65b9\u5757\u6570\u636e\u5df2\u590d\u5236\u5230\u526a\u8d34\u677f",
    "debug.inspect.client.entity": "\u5ba2\u6237\u7aef\u5b9e\u4f53\u6570\u636e\u5df2\u590d\u5236\u5230\u526a\u8d34\u677f",
    "debug.inspect.help": "F3 + I = \u5c06\u5b9e\u4f53\u6216\u65b9\u5757\u7684\u6570\u636e\u590d\u5236\u5230\u526a\u8d34\u677f",
    "debug.inspect.server.block": "\u670d\u52a1\u7aef\u65b9\u5757\u6570\u636e\u5df2\u590d\u5236\u5230\u526a\u8d34\u677f",
    "debug.inspect.server.entity": "\u670d\u52a1\u7aef\u5b9e\u4f53\u6570\u636e\u5df2\u590d\u5236\u5230\u526a\u8d34\u677f",
    "debug.pause.help": "F3 + Esc = \u6682\u505c\u4f46\u4e0d\u663e\u793a\u83dc\u5355\uff08\u5982\u679c\u53ef\u4ee5\u6682\u505c\u7684\u8bdd\uff09",
    "debug.pause_focus.help": "F3 + P = \u5931\u53bb\u7126\u70b9\u65f6\u6682\u505c",
    "debug.pause_focus.off": "\u5931\u53bb\u7126\u70b9\u65f6\u6682\u505c\uff1a\u505c\u7528",
    "debug.pause_focus.on": "\u5931\u53bb\u7126\u70b9\u65f6\u6682\u505c\uff1a\u542f\u7528",
    "debug.prefix": "[\u8c03\u8bd5]\uff1a",
    "debug.profiling.help": "F3 + L = \u5f00\u59cb/\u505c\u6b62\u5206\u6790",
    "debug.profiling.start": "\u5206\u6790\u5df2\u542f\u52a8%s\u79d2\u3002\u4f7f\u7528F3 + L\u4ee5\u63d0\u524d\u7ed3\u675f",
    "debug.profiling.stop": "\u5206\u6790\u5df2\u7ed3\u675f\u3002\u7ed3\u679c\u5df2\u4fdd\u5b58\u81f3%s",
    "debug.reload_chunks.help": "F3 + A = \u91cd\u65b0\u52a0\u8f7d\u533a\u5757",
    "debug.reload_chunks.message": "\u91cd\u65b0\u52a0\u8f7d\u6240\u6709\u533a\u5757\u4e2d",
    "debug.reload_resourcepacks.help": "F3 + T = \u91cd\u65b0\u52a0\u8f7d\u8d44\u6e90\u5305",
    "debug.reload_resourcepacks.message": "\u5df2\u91cd\u65b0\u52a0\u8f7d\u8d44\u6e90\u5305",
    "debug.show_hitboxes.help": "F3 + B = \u663e\u793a\u78b0\u649e\u4f53\u79ef",
    "debug.show_hitboxes.off": "\u5224\u5b9a\u7bb1\uff1a\u9690\u85cf",
    "debug.show_hitboxes.on": "\u5224\u5b9a\u7bb1\uff1a\u663e\u793a",
    "demo.day.1": "\u6b64\u8bd5\u73a9\u7248\u4f1a\u57285\u4e2a\u6e38\u620f\u65e5\u540e\u7ed3\u675f\uff0c\u5c3d\u529b\u800c\u4e3a\u5427\uff01",
    "demo.day.2": "\u7b2c\u4e8c\u5929",
    "demo.day.3": "\u7b2c\u4e09\u5929",
    "demo.day.4": "\u7b2c\u56db\u5929",
    "demo.day.5": "\u8fd9\u662f\u4f60\u6e38\u620f\u5185\u7684\u6700\u540e\u4e00\u5929\uff01",
    "demo.day.6": "\u4f60\u5df2\u7ecf\u5ea6\u8fc7\u4e865\u5929\u7684\u8bd5\u73a9\u65f6\u95f4\uff0c\u6309\u4e0b%s\u6765\u4e3a\u4f60\u7684\u6210\u679c\u622a\u56fe\u7559\u5ff5\u3002",
    "demo.day.warning": "\u4f60\u7684\u8bd5\u73a9\u65f6\u95f4\u5373\u5c06\u7ed3\u675f\uff01",
    "demo.demoExpired": "\u8bd5\u73a9\u7684\u65f6\u95f4\u7ed3\u675f\u4e86\uff01",
    "demo.help.buy": "\u5373\u523b\u8d2d\u4e70\uff01",
    "demo.help.fullWrapped": "\u8fd9\u4e2a\u8bd5\u73a9\u5c06\u4f1a\u6301\u7eed\u6e38\u620f\u51855\u5929\u7684\u65f6\u95f4\uff08\u73b0\u5b9e\u65f6\u95f4\u5927\u7ea6\u4e3a1\u5c0f\u65f640\u5206\u949f\uff09\u3002\u67e5\u770b\u8fdb\u5ea6\u6765\u83b7\u5f97\u63d0\u793a\uff01\u795d\u4f60\u73a9\u5f97\u5f00\u5fc3\uff01",
    "demo.help.inventory": "\u6309%1$s\u6765\u6253\u5f00\u4f60\u7684\u7269\u54c1\u680f",
    "demo.help.jump": "\u6309%1$s\u6765\u8df3\u8dc3",
    "demo.help.later": "\u7ee7\u7eed\u6e38\u73a9\uff01",
    "demo.help.movement": "\u6309%1$s\uff0c%2$s\uff0c%3$s\uff0c%4$s\u4ee5\u53ca\u9f20\u6807\u6765\u79fb\u52a8",
    "demo.help.movementMouse": "\u4f7f\u7528\u9f20\u6807\u6765\u67e5\u770b\u56db\u5468",
    "demo.help.movementShort": "\u901a\u8fc7\u6309\u4e0b%1$s\uff0c%2$s\uff0c%3$s\uff0c%4$s\u6765\u79fb\u52a8",
    "demo.help.title": "Minecraft\u8bd5\u73a9\u6a21\u5f0f",
    "demo.remainingTime": "\u5269\u4f59\u65f6\u95f4\uff1a%s",
    "demo.reminder": "\u8bd5\u73a9\u65f6\u95f4\u5df2\u7ecf\u7ed3\u675f\uff0c\u8bf7\u8d2d\u4e70\u6e38\u620f\u6765\u7ee7\u7eed\u6216\u5f00\u59cb\u4e00\u4e2a\u65b0\u7684\u4e16\u754c\uff01",
    "difficulty.lock.question": "\u4f60\u786e\u5b9a\u4f60\u8981\u9501\u5b9a\u8fd9\u4e2a\u4e16\u754c\u7684\u96be\u5ea6\u5417\uff1f\u8fd9\u4f1a\u5c06\u8fd9\u4e2a\u4e16\u754c\u7684\u96be\u5ea6\u9501\u5b9a\u4e3a%1$s\uff0c\u5e76\u4e14\u6c38\u8fdc\u65e0\u6cd5\u518d\u6b21\u6539\u53d8\u96be\u5ea6\u3002",
    "difficulty.lock.title": "\u9501\u5b9a\u4e16\u754c\u96be\u5ea6",
    "disconnect.closed": "\u8fde\u63a5\u5df2\u5173\u95ed",
    "disconnect.disconnected": "\u88ab\u670d\u52a1\u5668\u4e2d\u65ad\u8fde\u63a5",
    "disconnect.endOfStream": "\u6570\u636e\u6d41\u7ec8\u6b62",
    "disconnect.exceeded_packet_rate": "\u7531\u4e8e\u8d85\u51fa\u5c01\u5305\u901f\u7387\u9650\u5236\u800c\u88ab\u8e22\u51fa\u6e38\u620f",
    "disconnect.genericReason": "%s",
    "disconnect.kicked": "\u4f60\u5df2\u88ab\u8e22\u51fa\u6e38\u620f",
    "disconnect.loginFailed": "\u767b\u5f55\u5931\u8d25",
    "disconnect.loginFailedInfo": "\u767b\u5f55\u5931\u8d25\uff1a%s",
    "disconnect.loginFailedInfo.insufficientPrivileges": "\u591a\u4eba\u6e38\u620f\u5df2\u88ab\u7981\u7528\uff0c\u8bf7\u68c0\u67e5\u4f60\u7684Microsoft\u8d26\u6237\u8bbe\u7f6e\u3002",
    "disconnect.loginFailedInfo.invalidSession": "\u65e0\u6548\u4f1a\u8bdd\uff08\u8bf7\u5c1d\u8bd5\u91cd\u542f\u6e38\u620f\u53ca\u542f\u52a8\u5668\uff09",
    "disconnect.loginFailedInfo.serversUnavailable": "\u6682\u65f6\u65e0\u6cd5\u8fde\u63a5\u5230\u8eab\u4efd\u9a8c\u8bc1\u670d\u52a1\u5668\uff0c\u8bf7\u7a0d\u540e\u518d\u8bd5\u3002",
    "disconnect.lost": "\u8fde\u63a5\u5df2\u4e22\u5931",
    "disconnect.overflow": "\u7f13\u51b2\u533a\u6ea2\u51fa",
    "disconnect.quitting": "\u9000\u51fa",
    "disconnect.spam": "\u7531\u4e8e\u6ee5\u53d1\u6d88\u606f\u800c\u88ab\u8e22\u51fa\u6e38\u620f",
    "disconnect.timeout": "\u8fde\u63a5\u8d85\u65f6",
    "disconnect.unknownHost": "\u672a\u77e5\u7684\u4e3b\u673a",
    "editGamerule.default": "\u9ed8\u8ba4\uff1a%s",
    "editGamerule.title": "\u7f16\u8f91\u6e38\u620f\u89c4\u5219",
    "effect.effectNotFound": "\u672a\u77e5\u7684\u6548\u679c\uff1a%s",
    "effect.minecraft.absorption": "\u4f24\u5bb3\u5438\u6536",
    "effect.minecraft.bad_omen": "\u4e0d\u7965\u4e4b\u5146",
    "effect.minecraft.blindness": "\u5931\u660e",
    "effect.minecraft.conduit_power": "\u6f6e\u6d8c\u80fd\u91cf",
    "effect.minecraft.dolphins_grace": "\u6d77\u8c5a\u7684\u6069\u60e0",
    "effect.minecraft.fire_resistance": "\u9632\u706b",
    "effect.minecraft.glowing": "\u53d1\u5149",
    "effect.minecraft.haste": "\u6025\u8feb",
    "effect.minecraft.health_boost": "\u751f\u547d\u63d0\u5347",
    "effect.minecraft.hero_of_the_village": "\u6751\u5e84\u82f1\u96c4",
    "effect.minecraft.hunger": "\u9965\u997f",
    "effect.minecraft.instant_damage": "\u77ac\u95f4\u4f24\u5bb3",
    "effect.minecraft.instant_health": "\u77ac\u95f4\u6cbb\u7597",
    "effect.minecraft.invisibility": "\u9690\u8eab",
    "effect.minecraft.jump_boost": "\u8df3\u8dc3\u63d0\u5347",
    "effect.minecraft.levitation": "\u98d8\u6d6e",
    "effect.minecraft.luck": "\u5e78\u8fd0",
    "effect.minecraft.mining_fatigue": "\u6316\u6398\u75b2\u52b3",
    "effect.minecraft.nausea": "\u53cd\u80c3",
    "effect.minecraft.night_vision": "\u591c\u89c6",
    "effect.minecraft.poison": "\u4e2d\u6bd2",
    "effect.minecraft.regeneration": "\u751f\u547d\u6062\u590d",
    "effect.minecraft.resistance": "\u6297\u6027\u63d0\u5347",
    "effect.minecraft.saturation": "\u9971\u548c",
    "effect.minecraft.slow_falling": "\u7f13\u964d",
    "effect.minecraft.slowness": "\u7f13\u6162",
    "effect.minecraft.speed": "\u901f\u5ea6",
    "effect.minecraft.strength": "\u529b\u91cf",
    "effect.minecraft.unluck": "\u9709\u8fd0",
    "effect.minecraft.water_breathing": "\u6c34\u4e0b\u547c\u5438",
    "effect.minecraft.weakness": "\u865a\u5f31",
    "effect.minecraft.wither": "\u51cb\u96f6",
    "effect.none": "\u65e0\u6548\u679c",
    "enchantment.level.1": "I",
    "enchantment.level.10": "X",
    "enchantment.level.2": "II",
    "enchantment.level.3": "III",
    "enchantment.level.4": "IV",
    "enchantment.level.5": "V",
    "enchantment.level.6": "VI",
    "enchantment.level.7": "VII",
    "enchantment.level.8": "VIII",
    "enchantment.level.9": "IX",
    "enchantment.minecraft.aqua_affinity": "\u6c34\u4e0b\u901f\u6398",
    "enchantment.minecraft.bane_of_arthropods": "\u8282\u80a2\u6740\u624b",
    "enchantment.minecraft.binding_curse": "\u7ed1\u5b9a\u8bc5\u5492",
    "enchantment.minecraft.blast_protection": "\u7206\u70b8\u4fdd\u62a4",
    "enchantment.minecraft.channeling": "\u5f15\u96f7",
    "enchantment.minecraft.depth_strider": "\u6df1\u6d77\u63a2\u7d22\u8005",
    "enchantment.minecraft.efficiency": "\u6548\u7387",
    "enchantment.minecraft.feather_falling": "\u6454\u843d\u4fdd\u62a4",
    "enchantment.minecraft.fire_aspect": "\u706b\u7130\u9644\u52a0",
    "enchantment.minecraft.fire_protection": "\u706b\u7130\u4fdd\u62a4",
    "enchantment.minecraft.flame": "\u706b\u77e2",
    "enchantment.minecraft.fortune": "\u65f6\u8fd0",
    "enchantment.minecraft.frost_walker": "\u51b0\u971c\u884c\u8005",
    "enchantment.minecraft.impaling": "\u7a7f\u523a",
    "enchantment.minecraft.infinity": "\u65e0\u9650",
    "enchantment.minecraft.knockback": "\u51fb\u9000",
    "enchantment.minecraft.looting": "\u62a2\u593a",
    "enchantment.minecraft.loyalty": "\u5fe0\u8bda",
    "enchantment.minecraft.luck_of_the_sea": "\u6d77\u4e4b\u7737\u987e",
    "enchantment.minecraft.lure": "\u9975\u9493",
    "enchantment.minecraft.mending": "\u7ecf\u9a8c\u4fee\u8865",
    "enchantment.minecraft.multishot": "\u591a\u91cd\u5c04\u51fb",
    "enchantment.minecraft.piercing": "\u7a7f\u900f",
    "enchantment.minecraft.power": "\u529b\u91cf",
    "enchantment.minecraft.projectile_protection": "\u5f39\u5c04\u7269\u4fdd\u62a4",
    "enchantment.minecraft.protection": "\u4fdd\u62a4",
    "enchantment.minecraft.punch": "\u51b2\u51fb",
    "enchantment.minecraft.quick_charge": "\u5feb\u901f\u88c5\u586b",
    "enchantment.minecraft.respiration": "\u6c34\u4e0b\u547c\u5438",
    "enchantment.minecraft.riptide": "\u6fc0\u6d41",
    "enchantment.minecraft.sharpness": "\u950b\u5229",
    "enchantment.minecraft.silk_touch": "\u7cbe\u51c6\u91c7\u96c6",
    "enchantment.minecraft.smite": "\u4ea1\u7075\u6740\u624b",
    "enchantment.minecraft.soul_speed": "\u7075\u9b42\u75be\u884c",
    "enchantment.minecraft.sweeping": "\u6a2a\u626b\u4e4b\u5203",
    "enchantment.minecraft.thorns": "\u8346\u68d8",
    "enchantment.minecraft.unbreaking": "\u8010\u4e45",
    "enchantment.minecraft.vanishing_curse": "\u6d88\u5931\u8bc5\u5492",
    "enchantment.unknown": "\u672a\u77e5\u7684\u9b54\u5492\uff1a%s",
    "entity.minecraft.area_effect_cloud": "\u533a\u57df\u6548\u679c\u4e91",
    "entity.minecraft.armor_stand": "\u76d4\u7532\u67b6",
    "entity.minecraft.arrow": "\u7bad",
    "entity.minecraft.axolotl": "\u7f8e\u897f\u8788",
    "entity.minecraft.bat": "\u8759\u8760",
    "entity.minecraft.bee": "\u871c\u8702",
    "entity.minecraft.blaze": "\u70c8\u7130\u4eba",
    "entity.minecraft.boat": "\u8239",
    "entity.minecraft.cat": "\u732b",
    "entity.minecraft.cave_spider": "\u6d1e\u7a74\u8718\u86db",
    "entity.minecraft.chest_minecart": "\u8fd0\u8f93\u77ff\u8f66",
    "entity.minecraft.chicken": "\u9e21",
    "entity.minecraft.cod": "\u9cd5\u9c7c",
    "entity.minecraft.command_block_minecart": "\u547d\u4ee4\u65b9\u5757\u77ff\u8f66",
    "entity.minecraft.cow": "\u725b",
    "entity.minecraft.creeper": "\u82e6\u529b\u6015",
    "entity.minecraft.dolphin": "\u6d77\u8c5a",
    "entity.minecraft.donkey": "\u9a74",
    "entity.minecraft.dragon_fireball": "\u672b\u5f71\u9f99\u706b\u7403",
    "entity.minecraft.drowned": "\u6eba\u5c38",
    "entity.minecraft.egg": "\u63b7\u51fa\u7684\u9e21\u86cb",
    "entity.minecraft.elder_guardian": "\u8fdc\u53e4\u5b88\u536b\u8005",
    "entity.minecraft.end_crystal": "\u672b\u5f71\u6c34\u6676",
    "entity.minecraft.ender_dragon": "\u672b\u5f71\u9f99",
    "entity.minecraft.ender_pearl": "\u63b7\u51fa\u7684\u672b\u5f71\u73cd\u73e0",
    "entity.minecraft.enderman": "\u672b\u5f71\u4eba",
    "entity.minecraft.endermite": "\u672b\u5f71\u87a8",
    "entity.minecraft.evoker": "\u5524\u9b54\u8005",
    "entity.minecraft.evoker_fangs": "\u5524\u9b54\u8005\u5c16\u7259",
    "entity.minecraft.experience_bottle": "\u63b7\u51fa\u7684\u9644\u9b54\u4e4b\u74f6",
    "entity.minecraft.experience_orb": "\u7ecf\u9a8c\u7403",
    "entity.minecraft.eye_of_ender": "\u672b\u5f71\u4e4b\u773c",
    "entity.minecraft.falling_block": "\u4e0b\u843d\u7684\u65b9\u5757",
    "entity.minecraft.fireball": "\u706b\u7403",
    "entity.minecraft.firework_rocket": "\u70df\u82b1\u706b\u7bad",
    "entity.minecraft.fishing_bobber": "\u6d6e\u6f02",
    "entity.minecraft.fox": "\u72d0\u72f8",
    "entity.minecraft.furnace_minecart": "\u52a8\u529b\u77ff\u8f66",
    "entity.minecraft.ghast": "\u6076\u9b42",
    "entity.minecraft.giant": "\u5de8\u4eba",
    "entity.minecraft.glow_item_frame": "\u8367\u5149\u7269\u54c1\u5c55\u793a\u6846",
    "entity.minecraft.glow_squid": "\u53d1\u5149\u9c7f\u9c7c",
    "entity.minecraft.goat": "\u5c71\u7f8a",
    "entity.minecraft.guardian": "\u5b88\u536b\u8005",
    "entity.minecraft.hoglin": "\u75a3\u732a\u517d",
    "entity.minecraft.hopper_minecart": "\u6f0f\u6597\u77ff\u8f66",
    "entity.minecraft.horse": "\u9a6c",
    "entity.minecraft.husk": "\u5c38\u58f3",
    "entity.minecraft.illusioner": "\u5e7b\u672f\u5e08",
    "entity.minecraft.iron_golem": "\u94c1\u5080\u5121",
    "entity.minecraft.item": "\u7269\u54c1",
    "entity.minecraft.item_frame": "\u7269\u54c1\u5c55\u793a\u6846",
    "entity.minecraft.killer_bunny": "\u6740\u624b\u5154",
    "entity.minecraft.leash_knot": "\u62f4\u7ef3\u7ed3",
    "entity.minecraft.lightning_bolt": "\u95ea\u7535\u675f",
    "entity.minecraft.llama": "\u7f8a\u9a7c",
    "entity.minecraft.llama_spit": "\u7f8a\u9a7c\u553e\u6cab",
    "entity.minecraft.magma_cube": "\u5ca9\u6d46\u602a",
    "entity.minecraft.marker": "\u6807\u8bb0",
    "entity.minecraft.minecart": "\u77ff\u8f66",
    "entity.minecraft.mooshroom": "\u54de\u83c7",
    "entity.minecraft.mule": "\u9aa1",
    "entity.minecraft.ocelot": "\u8c79\u732b",
    "entity.minecraft.painting": "\u753b",
    "entity.minecraft.panda": "\u718a\u732b",
    "entity.minecraft.parrot": "\u9e66\u9e49",
    "entity.minecraft.phantom": "\u5e7b\u7ffc",
    "entity.minecraft.pig": "\u732a",
    "entity.minecraft.piglin": "\u732a\u7075",
    "entity.minecraft.piglin_brute": "\u732a\u7075\u86ee\u5175",
    "entity.minecraft.pillager": "\u63a0\u593a\u8005",
    "entity.minecraft.player": "\u73a9\u5bb6",
    "entity.minecraft.polar_bear": "\u5317\u6781\u718a",
    "entity.minecraft.potion": "\u836f\u6c34",
    "entity.minecraft.pufferfish": "\u6cb3\u8c5a",
    "entity.minecraft.rabbit": "\u5154\u5b50",
    "entity.minecraft.ravager": "\u52ab\u63a0\u517d",
    "entity.minecraft.salmon": "\u9c91\u9c7c",
    "entity.minecraft.sheep": "\u7ef5\u7f8a",
    "entity.minecraft.shulker": "\u6f5c\u5f71\u8d1d",
    "entity.minecraft.shulker_bullet": "\u6f5c\u5f71\u8d1d\u5bfc\u5f39",
    "entity.minecraft.silverfish": "\u8839\u866b",
    "entity.minecraft.skeleton": "\u9ab7\u9ac5",
    "entity.minecraft.skeleton_horse": "\u9ab7\u9ac5\u9a6c",
    "entity.minecraft.slime": "\u53f2\u83b1\u59c6",
    "entity.minecraft.small_fireball": "\u5c0f\u706b\u7403",
    "entity.minecraft.snow_golem": "\u96ea\u5080\u5121",
    "entity.minecraft.snowball": "\u96ea\u7403",
    "entity.minecraft.spawner_minecart": "\u5237\u602a\u7b3c\u77ff\u8f66",
    "entity.minecraft.spectral_arrow": "\u5149\u7075\u7bad",
    "entity.minecraft.spider": "\u8718\u86db",
    "entity.minecraft.squid": "\u9c7f\u9c7c",
    "entity.minecraft.stray": "\u6d41\u6d6a\u8005",
    "entity.minecraft.strider": "\u70bd\u8db3\u517d",
    "entity.minecraft.tnt": "\u88ab\u6fc0\u6d3b\u7684TNT",
    "entity.minecraft.tnt_minecart": "TNT\u77ff\u8f66",
    "entity.minecraft.trader_llama": "\u884c\u5546\u7f8a\u9a7c",
    "entity.minecraft.trident": "\u4e09\u53c9\u621f",
    "entity.minecraft.tropical_fish": "\u70ed\u5e26\u9c7c",
    "entity.minecraft.tropical_fish.predefined.0": "\u6d77\u8475\u9c7c",
    "entity.minecraft.tropical_fish.predefined.1": "\u9ed1\u523a\u5c3e\u9cb7",
    "entity.minecraft.tropical_fish.predefined.10": "\u9570\u9c7c",
    "entity.minecraft.tropical_fish.predefined.11": "\u534e\u4e3d\u8774\u8776\u9c7c",
    "entity.minecraft.tropical_fish.predefined.12": "\u9e66\u5634\u9c7c",
    "entity.minecraft.tropical_fish.predefined.13": "\u989d\u6591\u523a\u8776\u9c7c",
    "entity.minecraft.tropical_fish.predefined.14": "\u7ea2\u4e3d\u9c7c",
    "entity.minecraft.tropical_fish.predefined.15": "\u7ea2\u5507\u771f\u86c7\u9cda",
    "entity.minecraft.tropical_fish.predefined.16": "\u7ea2\u8fb9\u7b1b\u9cb7",
    "entity.minecraft.tropical_fish.predefined.17": "\u9a6c\u9c85",
    "entity.minecraft.tropical_fish.predefined.18": "\u767d\u6761\u53cc\u952f\u9c7c",
    "entity.minecraft.tropical_fish.predefined.19": "\u9cde\u9c80",
    "entity.minecraft.tropical_fish.predefined.2": "\u84dd\u523a\u5c3e\u9cb7",
    "entity.minecraft.tropical_fish.predefined.20": "\u9ad8\u9ccd\u9e66\u5634\u9c7c",
    "entity.minecraft.tropical_fish.predefined.21": "\u9ec4\u523a\u5c3e\u9cb7",
    "entity.minecraft.tropical_fish.predefined.3": "\u8774\u8776\u9c7c",
    "entity.minecraft.tropical_fish.predefined.4": "\u4e3d\u9c7c",
    "entity.minecraft.tropical_fish.predefined.5": "\u5c0f\u4e11\u9c7c",
    "entity.minecraft.tropical_fish.predefined.6": "\u4e94\u5f69\u640f\u9c7c",
    "entity.minecraft.tropical_fish.predefined.7": "\u7ee3\u96c0\u9cb7",
    "entity.minecraft.tropical_fish.predefined.8": "\u5ddd\u7eb9\u7b1b\u9cb7",
    "entity.minecraft.tropical_fish.predefined.9": "\u62df\u7f8a\u9c7c",
    "entity.minecraft.tropical_fish.type.betty": "\u80cc\u8482\u7c7b",
    "entity.minecraft.tropical_fish.type.blockfish": "\u65b9\u8eab\u7c7b",
    "entity.minecraft.tropical_fish.type.brinely": "\u54b8\u6c34\u7c7b",
    "entity.minecraft.tropical_fish.type.clayfish": "\u9676\u9c7c\u7c7b",
    "entity.minecraft.tropical_fish.type.dasher": "\u901f\u8dc3\u7c7b",
    "entity.minecraft.tropical_fish.type.flopper": "\u98de\u7ffc\u7c7b",
    "entity.minecraft.tropical_fish.type.glitter": "\u95ea\u9cde\u7c7b",
    "entity.minecraft.tropical_fish.type.kob": "\u77f3\u9996\u7c7b",
    "entity.minecraft.tropical_fish.type.snooper": "\u7aa5\u4f3a\u7c7b",
    "entity.minecraft.tropical_fish.type.spotty": "\u591a\u6591\u7c7b",
    "entity.minecraft.tropical_fish.type.stripey": "\u6761\u7eb9\u7c7b",
    "entity.minecraft.tropical_fish.type.sunstreak": "\u65e5\u7eb9\u7c7b",
    "entity.minecraft.turtle": "\u6d77\u9f9f",
    "entity.minecraft.vex": "\u607c\u9b3c",
    "entity.minecraft.villager": "\u6751\u6c11",
    "entity.minecraft.villager.armorer": "\u76d4\u7532\u5320",
    "entity.minecraft.villager.butcher": "\u5c60\u592b",
    "entity.minecraft.villager.cartographer": "\u5236\u56fe\u5e08",
    "entity.minecraft.villager.cleric": "\u7267\u5e08",
    "entity.minecraft.villager.farmer": "\u519c\u6c11",
    "entity.minecraft.villager.fisherman": "\u6e14\u592b",
    "entity.minecraft.villager.fletcher": "\u5236\u7bad\u5e08",
    "entity.minecraft.villager.leatherworker": "\u76ae\u5320",
    "entity.minecraft.villager.librarian": "\u56fe\u4e66\u7ba1\u7406\u5458",
    "entity.minecraft.villager.mason": "\u77f3\u5320",
    "entity.minecraft.villager.nitwit": "\u50bb\u5b50",
    "entity.minecraft.villager.none": "\u6751\u6c11",
    "entity.minecraft.villager.shepherd": "\u7267\u7f8a\u4eba",
    "entity.minecraft.villager.toolsmith": "\u5de5\u5177\u5320",
    "entity.minecraft.villager.weaponsmith": "\u6b66\u5668\u5320",
    "entity.minecraft.vindicator": "\u536b\u9053\u58eb",
    "entity.minecraft.wandering_trader": "\u6d41\u6d6a\u5546\u4eba",
    "entity.minecraft.witch": "\u5973\u5deb",
    "entity.minecraft.wither": "\u51cb\u7075",
    "entity.minecraft.wither_skeleton": "\u51cb\u7075\u9ab7\u9ac5",
    "entity.minecraft.wither_skull": "\u51cb\u7075\u4e4b\u9996",
    "entity.minecraft.wolf": "\u72fc",
    "entity.minecraft.zoglin": "\u50f5\u5c38\u75a3\u732a\u517d",
    "entity.minecraft.zombie": "\u50f5\u5c38",
    "entity.minecraft.zombie_horse": "\u50f5\u5c38\u9a6c",
    "entity.minecraft.zombie_villager": "\u50f5\u5c38\u6751\u6c11",
    "entity.minecraft.zombified_piglin": "\u50f5\u5c38\u732a\u7075",
    "entity.notFound": "\u672a\u77e5\u7684\u5b9e\u4f53\uff1a%s",
    "event.minecraft.raid": "\u88ad\u51fb",
    "event.minecraft.raid.defeat": "\u5931\u8d25",
    "event.minecraft.raid.raiders_remaining": "\u5269\u4f59%s\u540d\u88ad\u51fb\u8005",
    "event.minecraft.raid.victory": "\u80dc\u5229",
    "filled_map.buried_treasure": "\u85cf\u5b9d\u56fe",
    "filled_map.id": "\u7f16\u53f7#%s",
    "filled_map.level": "\uff08\u7b49\u7ea7 %s/%s\uff09",
    "filled_map.locked": "\u5df2\u9501\u5b9a",
    "filled_map.mansion": "\u6797\u5730\u63a2\u9669\u5bb6\u5730\u56fe",
    "filled_map.monument": "\u6d77\u6d0b\u63a2\u9669\u5bb6\u5730\u56fe",
    "filled_map.scale": "\u6bd4\u4f8b\u5c3a1:%s",
    "filled_map.unknown": "\u672a\u77e5\u5730\u56fe",
    "gameMode.adventure": "\u5192\u9669\u6a21\u5f0f",
    "gameMode.changed": "\u4f60\u7684\u6e38\u620f\u6a21\u5f0f\u5df2\u88ab\u66f4\u65b0\u4e3a%s",
    "gameMode.creative": "\u521b\u9020\u6a21\u5f0f",
    "gameMode.hardcore": "\u6781\u9650\u6a21\u5f0f\uff01",
    "gameMode.spectator": "\u65c1\u89c2\u6a21\u5f0f",
    "gameMode.survival": "\u751f\u5b58\u6a21\u5f0f",
    "gamerule.announceAdvancements": "\u8fdb\u5ea6\u901a\u77e5",
    "gamerule.category.chat": "\u804a\u5929",
    "gamerule.category.drops": "\u6389\u843d",
    "gamerule.category.misc": "\u6742\u9879",
    "gamerule.category.mobs": "\u751f\u7269",
    "gamerule.category.player": "\u73a9\u5bb6",
    "gamerule.category.spawning": "\u751f\u6210",
    "gamerule.category.updates": "\u4e16\u754c\u66f4\u65b0",
    "gamerule.commandBlockOutput": "\u5e7f\u64ad\u547d\u4ee4\u65b9\u5757\u8f93\u51fa",
    "gamerule.disableElytraMovementCheck": "\u7981\u7528\u9798\u7fc5\u79fb\u52a8\u68c0\u6d4b",
    "gamerule.disableRaids": "\u7981\u7528\u88ad\u51fb",
    "gamerule.doDaylightCycle": "\u6e38\u620f\u5185\u65f6\u95f4\u6d41\u901d",
    "gamerule.doEntityDrops": "\u975e\u751f\u7269\u5b9e\u4f53\u6389\u843d",
    "gamerule.doEntityDrops.description": "\u63a7\u5236\u77ff\u8f66\uff08\u5305\u62ec\u5185\u5bb9\u7269\uff09\u3001\u7269\u54c1\u5c55\u793a\u6846\u3001\u8239\u7b49\u7684\u7269\u54c1\u6389\u843d",
    "gamerule.doFireTick": "\u706b\u7130\u8513\u5ef6",
    "gamerule.doImmediateRespawn": "\u7acb\u5373\u91cd\u751f",
    "gamerule.doInsomnia": "\u751f\u6210\u5e7b\u7ffc",
    "gamerule.doLimitedCrafting": "\u5408\u6210\u9700\u8981\u914d\u65b9",
    "gamerule.doLimitedCrafting.description": "\u82e5\u542f\u7528\uff0c\u73a9\u5bb6\u53ea\u80fd\u4f7f\u7528\u5df2\u89e3\u9501\u7684\u914d\u65b9\u5408\u6210",
    "gamerule.doMobLoot": "\u751f\u7269\u6218\u5229\u54c1\u6389\u843d",
    "gamerule.doMobLoot.description": "\u63a7\u5236\u751f\u7269\u6b7b\u4ea1\u540e\u662f\u5426\u6389\u843d\u8d44\u6e90\uff0c\u5305\u62ec\u7ecf\u9a8c\u7403",
    "gamerule.doMobSpawning": "\u751f\u6210\u751f\u7269",
    "gamerule.doMobSpawning.description": "\u4e00\u4e9b\u5b9e\u4f53\u53ef\u80fd\u6709\u5176\u7279\u5b9a\u7684\u89c4\u5219",
    "gamerule.doPatrolSpawning": "\u751f\u6210\u707e\u5384\u5de1\u903b\u961f",
    "gamerule.doTileDrops": "\u65b9\u5757\u6389\u843d",
    "gamerule.doTileDrops.description": "\u63a7\u5236\u7834\u574f\u65b9\u5757\u540e\u662f\u5426\u6389\u843d\u8d44\u6e90\uff0c\u5305\u62ec\u7ecf\u9a8c\u7403",
    "gamerule.doTraderSpawning": "\u751f\u6210\u6d41\u6d6a\u5546\u4eba",
    "gamerule.doWeatherCycle": "\u5929\u6c14\u66f4\u66ff",
    "gamerule.drowningDamage": "\u6eba\u6c34\u4f24\u5bb3",
    "gamerule.fallDamage": "\u6454\u843d\u4f24\u5bb3",
    "gamerule.fireDamage": "\u706b\u7130\u4f24\u5bb3",
    "gamerule.forgiveDeadPlayers": "\u5bbd\u6055\u6b7b\u4ea1\u73a9\u5bb6",
    "gamerule.forgiveDeadPlayers.description": "\u6124\u6012\u7684\u4e2d\u7acb\u578b\u751f\u7269\u5c06\u5728\u5176\u76ee\u6807\u73a9\u5bb6\u4e8e\u9644\u8fd1\u6b7b\u4ea1\u540e\u606f\u6012\u3002",
    "gamerule.freezeDamage": "\u51b0\u51bb\u4f24\u5bb3",
    "gamerule.keepInventory": "\u6b7b\u4ea1\u540e\u4fdd\u7559\u7269\u54c1\u680f",
    "gamerule.logAdminCommands": "\u901a\u544a\u7ba1\u7406\u5458\u547d\u4ee4",
    "gamerule.maxCommandChainLength": "\u547d\u4ee4\u8fde\u9501\u6267\u884c\u6570\u91cf\u9650\u5236",
    "gamerule.maxCommandChainLength.description": "\u5e94\u7528\u4e8e\u547d\u4ee4\u65b9\u5757\u94fe\u548c\u51fd\u6570",
    "gamerule.maxEntityCramming": "\u5b9e\u4f53\u6324\u538b\u4e0a\u9650",
    "gamerule.mobGriefing": "\u5141\u8bb8\u7834\u574f\u6027\u751f\u7269\u884c\u4e3a",
    "gamerule.naturalRegeneration": "\u751f\u547d\u503c\u81ea\u7136\u6062\u590d",
    "gamerule.playersSleepingPercentage": "\u5165\u7761\u6bd4\u4f8b",
    "gamerule.playersSleepingPercentage.description": "\u8df3\u8fc7\u591c\u665a\u6240\u9700\u7684\u5165\u7761\u73a9\u5bb6\u5360\u6bd4\u3002",
    "gamerule.randomTickSpeed": "\u968f\u673a\u523b\u901f\u7387",
    "gamerule.reducedDebugInfo": "\u7b80\u5316\u8c03\u8bd5\u4fe1\u606f",
    "gamerule.reducedDebugInfo.description": "\u9650\u5236\u8c03\u8bd5\u754c\u9762\u5185\u5bb9",
    "gamerule.sendCommandFeedback": "\u53d1\u9001\u547d\u4ee4\u53cd\u9988",
    "gamerule.showDeathMessages": "\u663e\u793a\u6b7b\u4ea1\u6d88\u606f",
    "gamerule.spawnRadius": "\u91cd\u751f\u70b9\u534a\u5f84",
    "gamerule.spectatorsGenerateChunks": "\u5141\u8bb8\u65c1\u89c2\u8005\u751f\u6210\u5730\u5f62",
    "gamerule.universalAnger": "\u65e0\u5dee\u522b\u6124\u6012",
    "gamerule.universalAnger.description": "\u6124\u6012\u7684\u4e2d\u7acb\u578b\u751f\u7269\u5c06\u653b\u51fb\u9644\u8fd1\u7684\u6240\u6709\u73a9\u5bb6\uff0c\u800c\u4e0d\u9650\u4e8e\u6fc0\u6012\u5b83\u4eec\u7684\u73a9\u5bb6\u3002\u7981\u7528\u201c\u5bbd\u6055\u6b7b\u4ea1\u73a9\u5bb6\u201d\u53ef\u8fbe\u5230\u6700\u4f73\u6548\u679c\u3002",
    "generator.amplified": "\u653e\u5927\u5316",
    "generator.amplified.info": "\u6ce8\u610f\uff1a\u4ec5\u4f9b\u5a31\u4e50\uff01\u9700\u8981\u5f3a\u52b2\u7684\u7535\u8111\u3002",
    "generator.custom": "\u81ea\u5b9a\u4e49",
    "generator.customized": "\u65e7\u7248\u81ea\u5b9a\u4e49",
    "generator.debug_all_block_states": "\u8c03\u8bd5\u6a21\u5f0f",
    "generator.default": "\u9ed8\u8ba4",
    "generator.flat": "\u8d85\u5e73\u5766",
    "generator.large_biomes": "\u5de8\u578b\u751f\u7269\u7fa4\u7cfb",
    "generator.single_biome_caves": "\u6d1e\u7a74",
    "generator.single_biome_floating_islands": "\u6d6e\u5c9b",
    "generator.single_biome_surface": "\u5355\u4e00\u751f\u7269\u7fa4\u7cfb",
    "gui.advancements": "\u8fdb\u5ea6",
    "gui.all": "\u5168\u90e8",
    "gui.back": "\u8fd4\u56de",
    "gui.cancel": "\u53d6\u6d88",
    "gui.done": "\u5b8c\u6210",
    "gui.down": "\u5411\u4e0b",
    "gui.entity_tooltip.type": "\u7c7b\u578b\uff1a%s",
    "gui.narrate.button": "%s\u6309\u94ae",
    "gui.narrate.editBox": "%s\u7f16\u8f91\u6846\uff1a%s",
    "gui.narrate.slider": "%s\u6ed1\u5757",
    "gui.no": "\u5426",
    "gui.none": "\u65e0",
    "gui.ok": "\u786e\u5b9a",
    "gui.proceed": "\u7ee7\u7eed",
    "gui.recipebook.moreRecipes": "\u53f3\u51fb\u4ee5\u83b7\u53d6\u66f4\u591a\u4fe1\u606f",
    "gui.recipebook.search_hint": "\u641c\u7d22\u2026",
    "gui.recipebook.toggleRecipes.all": "\u663e\u793a\u5168\u90e8",
    "gui.recipebook.toggleRecipes.blastable": "\u4ec5\u663e\u793a\u53ef\u51b6\u70bc",
    "gui.recipebook.toggleRecipes.craftable": "\u4ec5\u663e\u793a\u53ef\u5408\u6210",
    "gui.recipebook.toggleRecipes.smeltable": "\u4ec5\u663e\u793a\u53ef\u70e7\u70bc",
    "gui.recipebook.toggleRecipes.smokable": "\u4ec5\u663e\u793a\u53ef\u718f\u5236",
    "gui.socialInteractions.blocking_hint": "\u4f7f\u7528Microsoft\u8d26\u6237\u7ba1\u7406",
    "gui.socialInteractions.empty_blocked": "\u672a\u5c4f\u853d\u4efb\u4f55\u73a9\u5bb6\u7684\u804a\u5929\u6d88\u606f",
    "gui.socialInteractions.empty_hidden": "\u672a\u9690\u85cf\u4efb\u4f55\u73a9\u5bb6\u7684\u804a\u5929\u6d88\u606f",
    "gui.socialInteractions.hidden_in_chat": "%s\u7684\u804a\u5929\u6d88\u606f\u5c06\u4f1a\u88ab\u9690\u85cf",
    "gui.socialInteractions.hide": "\u5728\u804a\u5929\u4e2d\u9690\u85cf",
    "gui.socialInteractions.search_empty": "\u672a\u627e\u5230\u4f7f\u7528\u6b64\u540d\u79f0\u7684\u73a9\u5bb6",
    "gui.socialInteractions.search_hint": "\u641c\u7d22\u2026",
    "gui.socialInteractions.server_label.multiple": "%s - %s\u540d\u73a9\u5bb6",
    "gui.socialInteractions.server_label.single": "%s - %s\u540d\u73a9\u5bb6",
    "gui.socialInteractions.show": "\u5728\u804a\u5929\u4e2d\u663e\u793a",
    "gui.socialInteractions.shown_in_chat": "%s\u7684\u804a\u5929\u6d88\u606f\u5c06\u4f1a\u88ab\u663e\u793a",
    "gui.socialInteractions.status_blocked": "\u5df2\u5c4f\u853d",
    "gui.socialInteractions.status_blocked_offline": "\u5df2\u5c4f\u853d - \u79bb\u7ebf",
    "gui.socialInteractions.status_hidden": "\u9690\u85cf",
    "gui.socialInteractions.status_hidden_offline": "\u9690\u85cf - \u79bb\u7ebf",
    "gui.socialInteractions.status_offline": "\u79bb\u7ebf",
    "gui.socialInteractions.tab_all": "\u5168\u90e8",
    "gui.socialInteractions.tab_blocked": "\u5df2\u5c4f\u853d",
    "gui.socialInteractions.tab_hidden": "\u5df2\u9690\u85cf",
    "gui.socialInteractions.title": "\u793e\u4ea4",
    "gui.socialInteractions.tooltip.hide": "\u9690\u85cf%s\u7684\u804a\u5929\u6d88\u606f",
    "gui.socialInteractions.tooltip.show": "\u663e\u793a%s\u7684\u804a\u5929\u6d88\u606f",
    "gui.stats": "\u7edf\u8ba1\u4fe1\u606f",
    "gui.toMenu": "\u8fd4\u56de\u5230\u670d\u52a1\u5668\u5217\u8868",
    "gui.toTitle": "\u8fd4\u56de\u5230\u6807\u9898\u754c\u9762",
    "gui.up": "\u5411\u4e0a",
    "gui.yes": "\u662f",
    "inventory.binSlot": "\u6467\u6bc1\u7269\u54c1",
    "inventory.hotbarInfo": "\u7528%1$s+%2$s\u6765\u4fdd\u5b58\u5feb\u6377\u680f",
    "inventory.hotbarSaved": "\u5df2\u4fdd\u5b58\u7269\u54c1\u5feb\u6377\u680f\uff08\u7528%1$s+%2$s\u6765\u52a0\u8f7d\uff09",
    "item.canBreak": "\u80fd\u7834\u574f\uff1a",
    "item.canPlace": "\u53ef\u4ee5\u653e\u5728\uff1a",
    "item.color": "\u989c\u8272\uff1a%s",
    "item.durability": "\u8010\u4e45\u5ea6\uff1a%s / %s",
    "item.dyed": "\u5df2\u67d3\u8272",
    "item.minecraft.acacia_boat": "\u91d1\u5408\u6b22\u6728\u8239",
    "item.minecraft.amethyst_shard": "\u7d2b\u6c34\u6676\u788e\u7247",
    "item.minecraft.apple": "\u82f9\u679c",
    "item.minecraft.armor_stand": "\u76d4\u7532\u67b6",
    "item.minecraft.arrow": "\u7bad",
    "item.minecraft.axolotl_bucket": "\u7f8e\u897f\u8788\u6876",
    "item.minecraft.axolotl_spawn_egg": "\u7f8e\u897f\u8788\u5237\u602a\u86cb",
    "item.minecraft.baked_potato": "\u70e4\u9a6c\u94c3\u85af",
    "item.minecraft.bat_spawn_egg": "\u8759\u8760\u5237\u602a\u86cb",
    "item.minecraft.bee_spawn_egg": "\u871c\u8702\u5237\u602a\u86cb",
    "item.minecraft.beef": "\u751f\u725b\u8089",
    "item.minecraft.beetroot": "\u751c\u83dc\u6839",
    "item.minecraft.beetroot_seeds": "\u751c\u83dc\u79cd\u5b50",
    "item.minecraft.beetroot_soup": "\u751c\u83dc\u6c64",
    "item.minecraft.birch_boat": "\u767d\u6866\u6728\u8239",
    "item.minecraft.black_dye": "\u9ed1\u8272\u67d3\u6599",
    "item.minecraft.blaze_powder": "\u70c8\u7130\u7c89",
    "item.minecraft.blaze_rod": "\u70c8\u7130\u68d2",
    "item.minecraft.blaze_spawn_egg": "\u70c8\u7130\u4eba\u5237\u602a\u86cb",
    "item.minecraft.blue_dye": "\u84dd\u8272\u67d3\u6599",
    "item.minecraft.bone": "\u9aa8\u5934",
    "item.minecraft.bone_meal": "\u9aa8\u7c89",
    "item.minecraft.book": "\u4e66",
    "item.minecraft.bow": "\u5f13",
    "item.minecraft.bowl": "\u7897",
    "item.minecraft.bread": "\u9762\u5305",
    "item.minecraft.brewing_stand": "\u917f\u9020\u53f0",
    "item.minecraft.brick": "\u7ea2\u7816",
    "item.minecraft.brown_dye": "\u68d5\u8272\u67d3\u6599",
    "item.minecraft.bucket": "\u6876",
    "item.minecraft.bundle": "\u6536\u7eb3\u888b",
    "item.minecraft.bundle.fullness": "%s/%s",
    "item.minecraft.carrot": "\u80e1\u841d\u535c",
    "item.minecraft.carrot_on_a_stick": "\u80e1\u841d\u535c\u9493\u7aff",
    "item.minecraft.cat_spawn_egg": "\u732b\u5237\u602a\u86cb",
    "item.minecraft.cauldron": "\u70bc\u836f\u9505",
    "item.minecraft.cave_spider_spawn_egg": "\u6d1e\u7a74\u8718\u86db\u5237\u602a\u86cb",
    "item.minecraft.chainmail_boots": "\u9501\u94fe\u9774\u5b50",
    "item.minecraft.chainmail_chestplate": "\u9501\u94fe\u80f8\u7532",
    "item.minecraft.chainmail_helmet": "\u9501\u94fe\u5934\u76d4",
    "item.minecraft.chainmail_leggings": "\u9501\u94fe\u62a4\u817f",
    "item.minecraft.charcoal": "\u6728\u70ad",
    "item.minecraft.chest_minecart": "\u8fd0\u8f93\u77ff\u8f66",
    "item.minecraft.chicken": "\u751f\u9e21\u8089",
    "item.minecraft.chicken_spawn_egg": "\u9e21\u5237\u602a\u86cb",
    "item.minecraft.chorus_fruit": "\u7d2b\u9882\u679c",
    "item.minecraft.clay_ball": "\u9ecf\u571f\u7403",
    "item.minecraft.clock": "\u65f6\u949f",
    "item.minecraft.coal": "\u7164\u70ad",
    "item.minecraft.cocoa_beans": "\u53ef\u53ef\u8c46",
    "item.minecraft.cod": "\u751f\u9cd5\u9c7c",
    "item.minecraft.cod_bucket": "\u9cd5\u9c7c\u6876",
    "item.minecraft.cod_spawn_egg": "\u9cd5\u9c7c\u5237\u602a\u86cb",
    "item.minecraft.command_block_minecart": "\u547d\u4ee4\u65b9\u5757\u77ff\u8f66",
    "item.minecraft.compass": "\u6307\u5357\u9488",
    "item.minecraft.cooked_beef": "\u725b\u6392",
    "item.minecraft.cooked_chicken": "\u719f\u9e21\u8089",
    "item.minecraft.cooked_cod": "\u719f\u9cd5\u9c7c",
    "item.minecraft.cooked_mutton": "\u719f\u7f8a\u8089",
    "item.minecraft.cooked_porkchop": "\u719f\u732a\u6392",
    "item.minecraft.cooked_rabbit": "\u719f\u5154\u8089",
    "item.minecraft.cooked_salmon": "\u719f\u9c91\u9c7c",
    "item.minecraft.cookie": "\u66f2\u5947",
    "item.minecraft.copper_ingot": "\u94dc\u952d",
    "item.minecraft.cow_spawn_egg": "\u725b\u5237\u602a\u86cb",
    "item.minecraft.creeper_banner_pattern": "\u65d7\u5e1c\u56fe\u6848",
    "item.minecraft.creeper_banner_pattern.desc": "\u82e6\u529b\u6015\u76fe\u5fbd",
    "item.minecraft.creeper_spawn_egg": "\u82e6\u529b\u6015\u5237\u602a\u86cb",
    "item.minecraft.crossbow": "\u5f29",
    "item.minecraft.crossbow.projectile": "\u5f39\u5c04\u7269\uff1a",
    "item.minecraft.cyan_dye": "\u9752\u8272\u67d3\u6599",
    "item.minecraft.dark_oak_boat": "\u6df1\u8272\u6a61\u6728\u8239",
    "item.minecraft.debug_stick": "\u8c03\u8bd5\u68d2",
    "item.minecraft.debug_stick.empty": "%s\u4e0d\u5177\u5907\u5c5e\u6027",
    "item.minecraft.debug_stick.select": "\u5df2\u9009\u62e9\u201c%s\u201d\uff08%s\uff09",
    "item.minecraft.debug_stick.update": "\u5c06\"%s\"\u8bbe\u4e3a%s",
    "item.minecraft.diamond": "\u94bb\u77f3",
    "item.minecraft.diamond_axe": "\u94bb\u77f3\u65a7",
    "item.minecraft.diamond_boots": "\u94bb\u77f3\u9774\u5b50",
    "item.minecraft.diamond_chestplate": "\u94bb\u77f3\u80f8\u7532",
    "item.minecraft.diamond_helmet": "\u94bb\u77f3\u5934\u76d4",
    "item.minecraft.diamond_hoe": "\u94bb\u77f3\u9504",
    "item.minecraft.diamond_horse_armor": "\u94bb\u77f3\u9a6c\u94e0",
    "item.minecraft.diamond_leggings": "\u94bb\u77f3\u62a4\u817f",
    "item.minecraft.diamond_pickaxe": "\u94bb\u77f3\u9550",
    "item.minecraft.diamond_shovel": "\u94bb\u77f3\u9539",
    "item.minecraft.diamond_sword": "\u94bb\u77f3\u5251",
    "item.minecraft.dolphin_spawn_egg": "\u6d77\u8c5a\u5237\u602a\u86cb",
    "item.minecraft.donkey_spawn_egg": "\u9a74\u5237\u602a\u86cb",
    "item.minecraft.dragon_breath": "\u9f99\u606f",
    "item.minecraft.dried_kelp": "\u5e72\u6d77\u5e26",
    "item.minecraft.drowned_spawn_egg": "\u6eba\u5c38\u5237\u602a\u86cb",
    "item.minecraft.egg": "\u9e21\u86cb",
    "item.minecraft.elder_guardian_spawn_egg": "\u8fdc\u53e4\u5b88\u536b\u8005\u5237\u602a\u86cb",
    "item.minecraft.elytra": "\u9798\u7fc5",
    "item.minecraft.emerald": "\u7eff\u5b9d\u77f3",
    "item.minecraft.enchanted_book": "\u9644\u9b54\u4e66",
    "item.minecraft.enchanted_golden_apple": "\u9644\u9b54\u91d1\u82f9\u679c",
    "item.minecraft.end_crystal": "\u672b\u5f71\u6c34\u6676",
    "item.minecraft.ender_eye": "\u672b\u5f71\u4e4b\u773c",
    "item.minecraft.ender_pearl": "\u672b\u5f71\u73cd\u73e0",
    "item.minecraft.enderman_spawn_egg": "\u672b\u5f71\u4eba\u5237\u602a\u86cb",
    "item.minecraft.endermite_spawn_egg": "\u672b\u5f71\u87a8\u5237\u602a\u86cb",
    "item.minecraft.evoker_spawn_egg": "\u5524\u9b54\u8005\u5237\u602a\u86cb",
    "item.minecraft.experience_bottle": "\u9644\u9b54\u4e4b\u74f6",
    "item.minecraft.feather": "\u7fbd\u6bdb",
    "item.minecraft.fermented_spider_eye": "\u53d1\u9175\u86db\u773c",
    "item.minecraft.filled_map": "\u5730\u56fe",
    "item.minecraft.fire_charge": "\u706b\u7130\u5f39",
    "item.minecraft.firework_rocket": "\u70df\u82b1\u706b\u7bad",
    "item.minecraft.firework_rocket.flight": "\u98de\u884c\u65f6\u95f4\uff1a",
    "item.minecraft.firework_star": "\u70df\u706b\u4e4b\u661f",
    "item.minecraft.firework_star.black": "\u9ed1\u8272",
    "item.minecraft.firework_star.blue": "\u84dd\u8272",
    "item.minecraft.firework_star.brown": "\u68d5\u8272",
    "item.minecraft.firework_star.custom_color": "\u81ea\u5b9a\u4e49",
    "item.minecraft.firework_star.cyan": "\u9752\u8272",
    "item.minecraft.firework_star.fade_to": "\u6de1\u5316\u81f3",
    "item.minecraft.firework_star.flicker": "\u95ea\u70c1",
    "item.minecraft.firework_star.gray": "\u7070\u8272",
    "item.minecraft.firework_star.green": "\u7eff\u8272",
    "item.minecraft.firework_star.light_blue": "\u6de1\u84dd\u8272",
    "item.minecraft.firework_star.light_gray": "\u6de1\u7070\u8272",
    "item.minecraft.firework_star.lime": "\u9ec4\u7eff\u8272",
    "item.minecraft.firework_star.magenta": "\u54c1\u7ea2\u8272",
    "item.minecraft.firework_star.orange": "\u6a59\u8272",
    "item.minecraft.firework_star.pink": "\u7c89\u7ea2\u8272",
    "item.minecraft.firework_star.purple": "\u7d2b\u8272",
    "item.minecraft.firework_star.red": "\u7ea2\u8272",
    "item.minecraft.firework_star.shape": "\u672a\u77e5\u5f62\u72b6",
    "item.minecraft.firework_star.shape.burst": "\u7206\u88c2\u72b6",
    "item.minecraft.firework_star.shape.creeper": "\u82e6\u529b\u6015\u72b6",
    "item.minecraft.firework_star.shape.large_ball": "\u5927\u578b\u7403\u72b6",
    "item.minecraft.firework_star.shape.small_ball": "\u5c0f\u578b\u7403\u72b6",
    "item.minecraft.firework_star.shape.star": "\u661f\u5f62",
    "item.minecraft.firework_star.trail": "\u8e2a\u8ff9",
    "item.minecraft.firework_star.white": "\u767d\u8272",
    "item.minecraft.firework_star.yellow": "\u9ec4\u8272",
    "item.minecraft.fishing_rod": "\u9493\u9c7c\u7aff",
    "item.minecraft.flint": "\u71e7\u77f3",
    "item.minecraft.flint_and_steel": "\u6253\u706b\u77f3",
    "item.minecraft.flower_banner_pattern": "\u65d7\u5e1c\u56fe\u6848",
    "item.minecraft.flower_banner_pattern.desc": "\u82b1\u6735\u76fe\u5fbd",
    "item.minecraft.flower_pot": "\u82b1\u76c6",
    "item.minecraft.fox_spawn_egg": "\u72d0\u72f8\u5237\u602a\u86cb",
    "item.minecraft.furnace_minecart": "\u52a8\u529b\u77ff\u8f66",
    "item.minecraft.ghast_spawn_egg": "\u6076\u9b42\u5237\u602a\u86cb",
    "item.minecraft.ghast_tear": "\u6076\u9b42\u4e4b\u6cea",
    "item.minecraft.glass_bottle": "\u73bb\u7483\u74f6",
    "item.minecraft.glistering_melon_slice": "\u95ea\u70c1\u7684\u897f\u74dc\u7247",
    "item.minecraft.globe_banner_pattern": "\u65d7\u5e1c\u56fe\u6848",
    "item.minecraft.globe_banner_pattern.desc": "\u5730\u7403",
    "item.minecraft.glow_berries": "\u53d1\u5149\u6d46\u679c",
    "item.minecraft.glow_ink_sac": "\u8367\u5149\u58a8\u56ca",
    "item.minecraft.glow_item_frame": "\u8367\u5149\u7269\u54c1\u5c55\u793a\u6846",
    "item.minecraft.glow_squid_spawn_egg": "\u53d1\u5149\u9c7f\u9c7c\u5237\u602a\u86cb",
    "item.minecraft.glowstone_dust": "\u8367\u77f3\u7c89",
    "item.minecraft.goat_spawn_egg": "\u5c71\u7f8a\u5237\u602a\u86cb",
    "item.minecraft.gold_ingot": "\u91d1\u952d",
    "item.minecraft.gold_nugget": "\u91d1\u7c92",
    "item.minecraft.golden_apple": "\u91d1\u82f9\u679c",
    "item.minecraft.golden_axe": "\u91d1\u65a7",
    "item.minecraft.golden_boots": "\u91d1\u9774\u5b50",
    "item.minecraft.golden_carrot": "\u91d1\u80e1\u841d\u535c",
    "item.minecraft.golden_chestplate": "\u91d1\u80f8\u7532",
    "item.minecraft.golden_helmet": "\u91d1\u5934\u76d4",
    "item.minecraft.golden_hoe": "\u91d1\u9504",
    "item.minecraft.golden_horse_armor": "\u91d1\u9a6c\u94e0",
    "item.minecraft.golden_leggings": "\u91d1\u62a4\u817f",
    "item.minecraft.golden_pickaxe": "\u91d1\u9550",
    "item.minecraft.golden_shovel": "\u91d1\u9539",
    "item.minecraft.golden_sword": "\u91d1\u5251",
    "item.minecraft.gray_dye": "\u7070\u8272\u67d3\u6599",
    "item.minecraft.green_dye": "\u7eff\u8272\u67d3\u6599",
    "item.minecraft.guardian_spawn_egg": "\u5b88\u536b\u8005\u5237\u602a\u86cb",
    "item.minecraft.gunpowder": "\u706b\u836f",
    "item.minecraft.heart_of_the_sea": "\u6d77\u6d0b\u4e4b\u5fc3",
    "item.minecraft.hoglin_spawn_egg": "\u75a3\u732a\u517d\u5237\u602a\u86cb",
    "item.minecraft.honey_bottle": "\u8702\u871c\u74f6",
    "item.minecraft.honeycomb": "\u871c\u813e",
    "item.minecraft.hopper_minecart": "\u6f0f\u6597\u77ff\u8f66",
    "item.minecraft.horse_spawn_egg": "\u9a6c\u5237\u602a\u86cb",
    "item.minecraft.husk_spawn_egg": "\u5c38\u58f3\u5237\u602a\u86cb",
    "item.minecraft.ink_sac": "\u58a8\u56ca",
    "item.minecraft.iron_axe": "\u94c1\u65a7",
    "item.minecraft.iron_boots": "\u94c1\u9774\u5b50",
    "item.minecraft.iron_chestplate": "\u94c1\u80f8\u7532",
    "item.minecraft.iron_helmet": "\u94c1\u5934\u76d4",
    "item.minecraft.iron_hoe": "\u94c1\u9504",
    "item.minecraft.iron_horse_armor": "\u94c1\u9a6c\u94e0",
    "item.minecraft.iron_ingot": "\u94c1\u952d",
    "item.minecraft.iron_leggings": "\u94c1\u62a4\u817f",
    "item.minecraft.iron_nugget": "\u94c1\u7c92",
    "item.minecraft.iron_pickaxe": "\u94c1\u9550",
    "item.minecraft.iron_shovel": "\u94c1\u9539",
    "item.minecraft.iron_sword": "\u94c1\u5251",
    "item.minecraft.item_frame": "\u7269\u54c1\u5c55\u793a\u6846",
    "item.minecraft.jungle_boat": "\u4e1b\u6797\u6728\u8239",
    "item.minecraft.knowledge_book": "\u77e5\u8bc6\u4e4b\u4e66",
    "item.minecraft.lapis_lazuli": "\u9752\u91d1\u77f3",
    "item.minecraft.lava_bucket": "\u7194\u5ca9\u6876",
    "item.minecraft.lead": "\u62f4\u7ef3",
    "item.minecraft.leather": "\u76ae\u9769",
    "item.minecraft.leather_boots": "\u76ae\u9769\u9774\u5b50",
    "item.minecraft.leather_chestplate": "\u76ae\u9769\u5916\u5957",
    "item.minecraft.leather_helmet": "\u76ae\u9769\u5e3d\u5b50",
    "item.minecraft.leather_horse_armor": "\u76ae\u9769\u9a6c\u94e0",
    "item.minecraft.leather_leggings": "\u76ae\u9769\u88e4\u5b50",
    "item.minecraft.light_blue_dye": "\u6de1\u84dd\u8272\u67d3\u6599",
    "item.minecraft.light_gray_dye": "\u6de1\u7070\u8272\u67d3\u6599",
    "item.minecraft.lime_dye": "\u9ec4\u7eff\u8272\u67d3\u6599",
    "item.minecraft.lingering_potion": "\u6ede\u7559\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.awkward": "\u6ede\u7559\u578b\u7c97\u5236\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.empty": "\u4e0d\u53ef\u5408\u6210\u7684\u6ede\u7559\u578b\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.fire_resistance": "\u6ede\u7559\u578b\u6297\u706b\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.harming": "\u6ede\u7559\u578b\u4f24\u5bb3\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.healing": "\u6ede\u7559\u578b\u6cbb\u7597\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.invisibility": "\u6ede\u7559\u578b\u9690\u8eab\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.leaping": "\u6ede\u7559\u578b\u8df3\u8dc3\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.levitation": "\u6ede\u7559\u578b\u98d8\u6d6e\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.luck": "\u6ede\u7559\u578b\u5e78\u8fd0\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.mundane": "\u6ede\u7559\u578b\u5e73\u51e1\u7684\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.night_vision": "\u6ede\u7559\u578b\u591c\u89c6\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.poison": "\u6ede\u7559\u578b\u5267\u6bd2\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.regeneration": "\u6ede\u7559\u578b\u518d\u751f\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.slow_falling": "\u6ede\u7559\u578b\u7f13\u964d\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.slowness": "\u6ede\u7559\u578b\u8fdf\u7f13\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.strength": "\u6ede\u7559\u578b\u529b\u91cf\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.swiftness": "\u6ede\u7559\u578b\u8fc5\u6377\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.thick": "\u6ede\u7559\u578b\u6d53\u7a20\u7684\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.turtle_master": "\u6ede\u7559\u578b\u795e\u9f9f\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.water": "\u6ede\u7559\u578b\u6c34\u74f6",
    "item.minecraft.lingering_potion.effect.water_breathing": "\u6ede\u7559\u578b\u6c34\u80ba\u836f\u6c34",
    "item.minecraft.lingering_potion.effect.weakness": "\u6ede\u7559\u578b\u865a\u5f31\u836f\u6c34",
    "item.minecraft.llama_spawn_egg": "\u7f8a\u9a7c\u5237\u602a\u86cb",
    "item.minecraft.lodestone_compass": "\u78c1\u77f3\u6307\u9488",
    "item.minecraft.magenta_dye": "\u54c1\u7ea2\u8272\u67d3\u6599",
    "item.minecraft.magma_cream": "\u5ca9\u6d46\u818f",
    "item.minecraft.magma_cube_spawn_egg": "\u5ca9\u6d46\u602a\u5237\u602a\u86cb",
    "item.minecraft.map": "\u7a7a\u5730\u56fe",
    "item.minecraft.melon_seeds": "\u897f\u74dc\u79cd\u5b50",
    "item.minecraft.melon_slice": "\u897f\u74dc\u7247",
    "item.minecraft.milk_bucket": "\u5976\u6876",
    "item.minecraft.minecart": "\u77ff\u8f66",
    "item.minecraft.mojang_banner_pattern": "\u65d7\u5e1c\u56fe\u6848",
    "item.minecraft.mojang_banner_pattern.desc": "Mojang\u5fbd\u6807",
    "item.minecraft.mooshroom_spawn_egg": "\u54de\u83c7\u5237\u602a\u86cb",
    "item.minecraft.mule_spawn_egg": "\u9aa1\u5237\u602a\u86cb",
    "item.minecraft.mushroom_stew": "\u8611\u83c7\u7172",
    "item.minecraft.music_disc_11": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_11.desc": "C418 - 11",
    "item.minecraft.music_disc_13": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_13.desc": "C418 - 13",
    "item.minecraft.music_disc_blocks": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_blocks.desc": "C418 - blocks",
    "item.minecraft.music_disc_cat": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_cat.desc": "C418 - cat",
    "item.minecraft.music_disc_chirp": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_chirp.desc": "C418 - chirp",
    "item.minecraft.music_disc_far": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_far.desc": "C418 - far",
    "item.minecraft.music_disc_mall": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_mall.desc": "C418 - mall",
    "item.minecraft.music_disc_mellohi": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_mellohi.desc": "C418 - mellohi",
    "item.minecraft.music_disc_otherside": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_otherside.desc": "Lena Raine - otherside",
    "item.minecraft.music_disc_pigstep": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_pigstep.desc": "Lena Raine - Pigstep",
    "item.minecraft.music_disc_stal": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_stal.desc": "C418 - stal",
    "item.minecraft.music_disc_strad": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_strad.desc": "C418 - strad",
    "item.minecraft.music_disc_wait": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_wait.desc": "C418 - wait",
    "item.minecraft.music_disc_ward": "\u97f3\u4e50\u5531\u7247",
    "item.minecraft.music_disc_ward.desc": "C418 - ward",
    "item.minecraft.mutton": "\u751f\u7f8a\u8089",
    "item.minecraft.name_tag": "\u547d\u540d\u724c",
    "item.minecraft.nautilus_shell": "\u9e66\u9e49\u87ba\u58f3",
    "item.minecraft.nether_brick": "\u4e0b\u754c\u7816",
    "item.minecraft.nether_star": "\u4e0b\u754c\u4e4b\u661f",
    "item.minecraft.nether_wart": "\u4e0b\u754c\u75a3",
    "item.minecraft.netherite_axe": "\u4e0b\u754c\u5408\u91d1\u65a7",
    "item.minecraft.netherite_boots": "\u4e0b\u754c\u5408\u91d1\u9774\u5b50",
    "item.minecraft.netherite_chestplate": "\u4e0b\u754c\u5408\u91d1\u80f8\u7532",
    "item.minecraft.netherite_helmet": "\u4e0b\u754c\u5408\u91d1\u5934\u76d4",
    "item.minecraft.netherite_hoe": "\u4e0b\u754c\u5408\u91d1\u9504",
    "item.minecraft.netherite_ingot": "\u4e0b\u754c\u5408\u91d1\u952d",
    "item.minecraft.netherite_leggings": "\u4e0b\u754c\u5408\u91d1\u62a4\u817f",
    "item.minecraft.netherite_pickaxe": "\u4e0b\u754c\u5408\u91d1\u9550",
    "item.minecraft.netherite_scrap": "\u4e0b\u754c\u5408\u91d1\u788e\u7247",
    "item.minecraft.netherite_shovel": "\u4e0b\u754c\u5408\u91d1\u9539",
    "item.minecraft.netherite_sword": "\u4e0b\u754c\u5408\u91d1\u5251",
    "item.minecraft.oak_boat": "\u6a61\u6728\u8239",
    "item.minecraft.ocelot_spawn_egg": "\u8c79\u732b\u5237\u602a\u86cb",
    "item.minecraft.orange_dye": "\u6a59\u8272\u67d3\u6599",
    "item.minecraft.painting": "\u753b",
    "item.minecraft.panda_spawn_egg": "\u718a\u732b\u5237\u602a\u86cb",
    "item.minecraft.paper": "\u7eb8",
    "item.minecraft.parrot_spawn_egg": "\u9e66\u9e49\u5237\u602a\u86cb",
    "item.minecraft.phantom_membrane": "\u5e7b\u7ffc\u819c",
    "item.minecraft.phantom_spawn_egg": "\u5e7b\u7ffc\u5237\u602a\u86cb",
    "item.minecraft.pig_spawn_egg": "\u732a\u5237\u602a\u86cb",
    "item.minecraft.piglin_banner_pattern": "\u65d7\u5e1c\u56fe\u6848",
    "item.minecraft.piglin_banner_pattern.desc": "\u732a\u9f3b",
    "item.minecraft.piglin_brute_spawn_egg": "\u732a\u7075\u86ee\u5175\u5237\u602a\u86cb",
    "item.minecraft.piglin_spawn_egg": "\u732a\u7075\u5237\u602a\u86cb",
    "item.minecraft.pillager_spawn_egg": "\u63a0\u593a\u8005\u5237\u602a\u86cb",
    "item.minecraft.pink_dye": "\u7c89\u7ea2\u8272\u67d3\u6599",
    "item.minecraft.poisonous_potato": "\u6bd2\u9a6c\u94c3\u85af",
    "item.minecraft.polar_bear_spawn_egg": "\u5317\u6781\u718a\u5237\u602a\u86cb",
    "item.minecraft.popped_chorus_fruit": "\u7206\u88c2\u7d2b\u9882\u679c",
    "item.minecraft.porkchop": "\u751f\u732a\u6392",
    "item.minecraft.potato": "\u9a6c\u94c3\u85af",
    "item.minecraft.potion": "\u836f\u6c34",
    "item.minecraft.potion.effect.awkward": "\u7c97\u5236\u7684\u836f\u6c34",
    "item.minecraft.potion.effect.empty": "\u4e0d\u53ef\u5408\u6210\u7684\u836f\u6c34",
    "item.minecraft.potion.effect.fire_resistance": "\u6297\u706b\u836f\u6c34",
    "item.minecraft.potion.effect.harming": "\u4f24\u5bb3\u836f\u6c34",
    "item.minecraft.potion.effect.healing": "\u6cbb\u7597\u836f\u6c34",
    "item.minecraft.potion.effect.invisibility": "\u9690\u8eab\u836f\u6c34",
    "item.minecraft.potion.effect.leaping": "\u8df3\u8dc3\u836f\u6c34",
    "item.minecraft.potion.effect.levitation": "\u98d8\u6d6e\u836f\u6c34",
    "item.minecraft.potion.effect.luck": "\u5e78\u8fd0\u836f\u6c34",
    "item.minecraft.potion.effect.mundane": "\u5e73\u51e1\u7684\u836f\u6c34",
    "item.minecraft.potion.effect.night_vision": "\u591c\u89c6\u836f\u6c34",
    "item.minecraft.potion.effect.poison": "\u5267\u6bd2\u836f\u6c34",
    "item.minecraft.potion.effect.regeneration": "\u518d\u751f\u836f\u6c34",
    "item.minecraft.potion.effect.slow_falling": "\u7f13\u964d\u836f\u6c34",
    "item.minecraft.potion.effect.slowness": "\u8fdf\u7f13\u836f\u6c34",
    "item.minecraft.potion.effect.strength": "\u529b\u91cf\u836f\u6c34",
    "item.minecraft.potion.effect.swiftness": "\u8fc5\u6377\u836f\u6c34",
    "item.minecraft.potion.effect.thick": "\u6d53\u7a20\u7684\u836f\u6c34",
    "item.minecraft.potion.effect.turtle_master": "\u795e\u9f9f\u836f\u6c34",
    "item.minecraft.potion.effect.water": "\u6c34\u74f6",
    "item.minecraft.potion.effect.water_breathing": "\u6c34\u80ba\u836f\u6c34",
    "item.minecraft.potion.effect.weakness": "\u865a\u5f31\u836f\u6c34",
    "item.minecraft.powder_snow_bucket": "\u7ec6\u96ea\u6876",
    "item.minecraft.prismarine_crystals": "\u6d77\u6676\u7802\u7c92",
    "item.minecraft.prismarine_shard": "\u6d77\u6676\u788e\u7247",
    "item.minecraft.pufferfish": "\u6cb3\u8c5a",
    "item.minecraft.pufferfish_bucket": "\u6cb3\u8c5a\u6876",
    "item.minecraft.pufferfish_spawn_egg": "\u6cb3\u8c5a\u5237\u602a\u86cb",
    "item.minecraft.pumpkin_pie": "\u5357\u74dc\u6d3e",
    "item.minecraft.pumpkin_seeds": "\u5357\u74dc\u79cd\u5b50",
    "item.minecraft.purple_dye": "\u7d2b\u8272\u67d3\u6599",
    "item.minecraft.quartz": "\u4e0b\u754c\u77f3\u82f1",
    "item.minecraft.rabbit": "\u751f\u5154\u8089",
    "item.minecraft.rabbit_foot": "\u5154\u5b50\u811a",
    "item.minecraft.rabbit_hide": "\u5154\u5b50\u76ae",
    "item.minecraft.rabbit_spawn_egg": "\u5154\u5b50\u5237\u602a\u86cb",
    "item.minecraft.rabbit_stew": "\u5154\u8089\u7172",
    "item.minecraft.ravager_spawn_egg": "\u52ab\u63a0\u517d\u5237\u602a\u86cb",
    "item.minecraft.raw_copper": "\u7c97\u94dc",
    "item.minecraft.raw_gold": "\u7c97\u91d1",
    "item.minecraft.raw_iron": "\u7c97\u94c1",
    "item.minecraft.red_dye": "\u7ea2\u8272\u67d3\u6599",
    "item.minecraft.redstone": "\u7ea2\u77f3\u7c89",
    "item.minecraft.rotten_flesh": "\u8150\u8089",
    "item.minecraft.saddle": "\u978d",
    "item.minecraft.salmon": "\u751f\u9c91\u9c7c",
    "item.minecraft.salmon_bucket": "\u9c91\u9c7c\u6876",
    "item.minecraft.salmon_spawn_egg": "\u9c91\u9c7c\u5237\u602a\u86cb",
    "item.minecraft.scute": "\u9cde\u7532",
    "item.minecraft.shears": "\u526a\u5200",
    "item.minecraft.sheep_spawn_egg": "\u7ef5\u7f8a\u5237\u602a\u86cb",
    "item.minecraft.shield": "\u76fe\u724c",
    "item.minecraft.shield.black": "\u9ed1\u8272\u76fe\u724c",
    "item.minecraft.shield.blue": "\u84dd\u8272\u76fe\u724c",
    "item.minecraft.shield.brown": "\u68d5\u8272\u76fe\u724c",
    "item.minecraft.shield.cyan": "\u9752\u8272\u76fe\u724c",
    "item.minecraft.shield.gray": "\u7070\u8272\u76fe\u724c",
    "item.minecraft.shield.green": "\u7eff\u8272\u76fe\u724c",
    "item.minecraft.shield.light_blue": "\u6de1\u84dd\u8272\u76fe\u724c",
    "item.minecraft.shield.light_gray": "\u6de1\u7070\u8272\u76fe\u724c",
    "item.minecraft.shield.lime": "\u9ec4\u7eff\u8272\u76fe\u724c",
    "item.minecraft.shield.magenta": "\u54c1\u7ea2\u8272\u76fe\u724c",
    "item.minecraft.shield.orange": "\u6a59\u8272\u76fe\u724c",
    "item.minecraft.shield.pink": "\u7c89\u7ea2\u8272\u76fe\u724c",
    "item.minecraft.shield.purple": "\u7d2b\u8272\u76fe\u724c",
    "item.minecraft.shield.red": "\u7ea2\u8272\u76fe\u724c",
    "item.minecraft.shield.white": "\u767d\u8272\u76fe\u724c",
    "item.minecraft.shield.yellow": "\u9ec4\u8272\u76fe\u724c",
    "item.minecraft.shulker_shell": "\u6f5c\u5f71\u58f3",
    "item.minecraft.shulker_spawn_egg": "\u6f5c\u5f71\u8d1d\u5237\u602a\u86cb",
    "item.minecraft.sign": "\u544a\u793a\u724c",
    "item.minecraft.silverfish_spawn_egg": "\u8839\u866b\u5237\u602a\u86cb",
    "item.minecraft.skeleton_horse_spawn_egg": "\u9ab7\u9ac5\u9a6c\u5237\u602a\u86cb",
    "item.minecraft.skeleton_spawn_egg": "\u9ab7\u9ac5\u5237\u602a\u86cb",
    "item.minecraft.skull_banner_pattern": "\u65d7\u5e1c\u56fe\u6848",
    "item.minecraft.skull_banner_pattern.desc": "\u5934\u9885\u76fe\u5fbd",
    "item.minecraft.slime_ball": "\u9ecf\u6db2\u7403",
    "item.minecraft.slime_spawn_egg": "\u53f2\u83b1\u59c6\u5237\u602a\u86cb",
    "item.minecraft.snowball": "\u96ea\u7403",
    "item.minecraft.spectral_arrow": "\u5149\u7075\u7bad",
    "item.minecraft.spider_eye": "\u8718\u86db\u773c",
    "item.minecraft.spider_spawn_egg": "\u8718\u86db\u5237\u602a\u86cb",
    "item.minecraft.splash_potion": "\u55b7\u6e85\u836f\u6c34",
    "item.minecraft.splash_potion.effect.awkward": "\u55b7\u6e85\u578b\u7c97\u5236\u836f\u6c34",
    "item.minecraft.splash_potion.effect.empty": "\u4e0d\u53ef\u5408\u6210\u7684\u55b7\u6e85\u578b\u836f\u6c34",
    "item.minecraft.splash_potion.effect.fire_resistance": "\u55b7\u6e85\u578b\u6297\u706b\u836f\u6c34",
    "item.minecraft.splash_potion.effect.harming": "\u55b7\u6e85\u578b\u4f24\u5bb3\u836f\u6c34",
    "item.minecraft.splash_potion.effect.healing": "\u55b7\u6e85\u578b\u6cbb\u7597\u836f\u6c34",
    "item.minecraft.splash_potion.effect.invisibility": "\u55b7\u6e85\u578b\u9690\u8eab\u836f\u6c34",
    "item.minecraft.splash_potion.effect.leaping": "\u55b7\u6e85\u578b\u8df3\u8dc3\u836f\u6c34",
    "item.minecraft.splash_potion.effect.levitation": "\u55b7\u6e85\u578b\u98d8\u6d6e\u836f\u6c34",
    "item.minecraft.splash_potion.effect.luck": "\u55b7\u6e85\u578b\u5e78\u8fd0\u836f\u6c34",
    "item.minecraft.splash_potion.effect.mundane": "\u55b7\u6e85\u578b\u5e73\u51e1\u7684\u836f\u6c34",
    "item.minecraft.splash_potion.effect.night_vision": "\u55b7\u6e85\u578b\u591c\u89c6\u836f\u6c34",
    "item.minecraft.splash_potion.effect.poison": "\u55b7\u6e85\u578b\u5267\u6bd2\u836f\u6c34",
    "item.minecraft.splash_potion.effect.regeneration": "\u55b7\u6e85\u578b\u518d\u751f\u836f\u6c34",
    "item.minecraft.splash_potion.effect.slow_falling": "\u55b7\u6e85\u578b\u7f13\u964d\u836f\u6c34",
    "item.minecraft.splash_potion.effect.slowness": "\u55b7\u6e85\u578b\u8fdf\u7f13\u836f\u6c34",
    "item.minecraft.splash_potion.effect.strength": "\u55b7\u6e85\u578b\u529b\u91cf\u836f\u6c34",
    "item.minecraft.splash_potion.effect.swiftness": "\u55b7\u6e85\u578b\u8fc5\u6377\u836f\u6c34",
    "item.minecraft.splash_potion.effect.thick": "\u55b7\u6e85\u578b\u6d53\u7a20\u7684\u836f\u6c34",
    "item.minecraft.splash_potion.effect.turtle_master": "\u55b7\u6e85\u578b\u795e\u9f9f\u836f\u6c34",
    "item.minecraft.splash_potion.effect.water": "\u55b7\u6e85\u578b\u6c34\u74f6",
    "item.minecraft.splash_potion.effect.water_breathing": "\u55b7\u6e85\u578b\u6c34\u80ba\u836f\u6c34",
    "item.minecraft.splash_potion.effect.weakness": "\u55b7\u6e85\u578b\u865a\u5f31\u836f\u6c34",
    "item.minecraft.spruce_boat": "\u4e91\u6749\u6728\u8239",
    "item.minecraft.spyglass": "\u671b\u8fdc\u955c",
    "item.minecraft.squid_spawn_egg": "\u9c7f\u9c7c\u5237\u602a\u86cb",
    "item.minecraft.stick": "\u6728\u68cd",
    "item.minecraft.stone_axe": "\u77f3\u65a7",
    "item.minecraft.stone_hoe": "\u77f3\u9504",
    "item.minecraft.stone_pickaxe": "\u77f3\u9550",
    "item.minecraft.stone_shovel": "\u77f3\u9539",
    "item.minecraft.stone_sword": "\u77f3\u5251",
    "item.minecraft.stray_spawn_egg": "\u6d41\u6d6a\u8005\u5237\u602a\u86cb",
    "item.minecraft.strider_spawn_egg": "\u70bd\u8db3\u517d\u5237\u602a\u86cb",
    "item.minecraft.string": "\u7ebf",
    "item.minecraft.sugar": "\u7cd6",
    "item.minecraft.suspicious_stew": "\u8ff7\u4e4b\u7096\u83dc",
    "item.minecraft.sweet_berries": "\u751c\u6d46\u679c",
    "item.minecraft.tipped_arrow": "\u836f\u7bad",
    "item.minecraft.tipped_arrow.effect.awkward": "\u836f\u7bad",
    "item.minecraft.tipped_arrow.effect.empty": "\u4e0d\u53ef\u5408\u6210\u7684\u836f\u7bad",
    "item.minecraft.tipped_arrow.effect.fire_resistance": "\u6297\u706b\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.harming": "\u4f24\u5bb3\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.healing": "\u6cbb\u7597\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.invisibility": "\u9690\u8eab\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.leaping": "\u8df3\u8dc3\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.levitation": "\u98d8\u6d6e\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.luck": "\u5e78\u8fd0\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.mundane": "\u836f\u7bad",
    "item.minecraft.tipped_arrow.effect.night_vision": "\u591c\u89c6\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.poison": "\u5267\u6bd2\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.regeneration": "\u518d\u751f\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.slow_falling": "\u7f13\u964d\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.slowness": "\u8fdf\u7f13\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.strength": "\u529b\u91cf\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.swiftness": "\u8fc5\u6377\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.thick": "\u836f\u7bad",
    "item.minecraft.tipped_arrow.effect.turtle_master": "\u795e\u9f9f\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.water": "\u55b7\u6e85\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.water_breathing": "\u6c34\u80ba\u4e4b\u7bad",
    "item.minecraft.tipped_arrow.effect.weakness": "\u865a\u5f31\u4e4b\u7bad",
    "item.minecraft.tnt_minecart": "TNT\u77ff\u8f66",
    "item.minecraft.totem_of_undying": "\u4e0d\u6b7b\u56fe\u817e",
    "item.minecraft.trader_llama_spawn_egg": "\u884c\u5546\u7f8a\u9a7c\u5237\u602a\u86cb",
    "item.minecraft.trident": "\u4e09\u53c9\u621f",
    "item.minecraft.tropical_fish": "\u70ed\u5e26\u9c7c",
    "item.minecraft.tropical_fish_bucket": "\u70ed\u5e26\u9c7c\u6876",
    "item.minecraft.tropical_fish_spawn_egg": "\u70ed\u5e26\u9c7c\u5237\u602a\u86cb",
    "item.minecraft.turtle_helmet": "\u6d77\u9f9f\u58f3",
    "item.minecraft.turtle_spawn_egg": "\u6d77\u9f9f\u5237\u602a\u86cb",
    "item.minecraft.vex_spawn_egg": "\u607c\u9b3c\u5237\u602a\u86cb",
    "item.minecraft.villager_spawn_egg": "\u6751\u6c11\u5237\u602a\u86cb",
    "item.minecraft.vindicator_spawn_egg": "\u536b\u9053\u58eb\u5237\u602a\u86cb",
    "item.minecraft.wandering_trader_spawn_egg": "\u6d41\u6d6a\u5546\u4eba\u5237\u602a\u86cb",
    "item.minecraft.warped_fungus_on_a_stick": "\u8be1\u5f02\u83cc\u9493\u7aff",
    "item.minecraft.water_bucket": "\u6c34\u6876",
    "item.minecraft.wheat": "\u5c0f\u9ea6",
    "item.minecraft.wheat_seeds": "\u5c0f\u9ea6\u79cd\u5b50",
    "item.minecraft.white_dye": "\u767d\u8272\u67d3\u6599",
    "item.minecraft.witch_spawn_egg": "\u5973\u5deb\u5237\u602a\u86cb",
    "item.minecraft.wither_skeleton_spawn_egg": "\u51cb\u7075\u9ab7\u9ac5\u5237\u602a\u86cb",
    "item.minecraft.wolf_spawn_egg": "\u72fc\u5237\u602a\u86cb",
    "item.minecraft.wooden_axe": "\u6728\u65a7",
    "item.minecraft.wooden_hoe": "\u6728\u9504",
    "item.minecraft.wooden_pickaxe": "\u6728\u9550",
    "item.minecraft.wooden_shovel": "\u6728\u9539",
    "item.minecraft.wooden_sword": "\u6728\u5251",
    "item.minecraft.writable_book": "\u4e66\u4e0e\u7b14",
    "item.minecraft.written_book": "\u6210\u4e66",
    "item.minecraft.yellow_dye": "\u9ec4\u8272\u67d3\u6599",
    "item.minecraft.zoglin_spawn_egg": "\u50f5\u5c38\u75a3\u732a\u517d\u5237\u602a\u86cb",
    "item.minecraft.zombie_horse_spawn_egg": "\u50f5\u5c38\u9a6c\u5237\u602a\u86cb",
    "item.minecraft.zombie_spawn_egg": "\u50f5\u5c38\u5237\u602a\u86cb",
    "item.minecraft.zombie_villager_spawn_egg": "\u50f5\u5c38\u6751\u6c11\u5237\u602a\u86cb",
    "item.minecraft.zombified_piglin_spawn_egg": "\u50f5\u5c38\u732a\u7075\u5237\u602a\u86cb",
    "item.modifiers.chest": "\u7a7f\u5728\u8eab\u4e0a\u65f6\uff1a",
    "item.modifiers.feet": "\u7a7f\u5728\u811a\u4e0a\u65f6\uff1a",
    "item.modifiers.head": "\u6234\u5728\u5934\u4e0a\u65f6\uff1a",
    "item.modifiers.legs": "\u7a7f\u5728\u817f\u4e0a\u65f6\uff1a",
    "item.modifiers.mainhand": "\u5728\u4e3b\u624b\u65f6\uff1a",
    "item.modifiers.offhand": "\u5728\u526f\u624b\u65f6\uff1a",
    "item.nbt_tags": "NBT\uff1a%s\u4e2a\u6807\u7b7e",
    "item.unbreakable": "\u65e0\u6cd5\u7834\u574f",
    "itemGroup.brewing": "\u917f\u9020",
    "itemGroup.buildingBlocks": "\u5efa\u7b51\u65b9\u5757",
    "itemGroup.combat": "\u6218\u6597\u7528\u54c1",
    "itemGroup.decorations": "\u88c5\u9970\u6027\u65b9\u5757",
    "itemGroup.food": "\u98df\u7269",
    "itemGroup.hotbar": "\u5df2\u4fdd\u5b58\u7684\u5feb\u6377\u680f",
    "itemGroup.inventory": "\u751f\u5b58\u6a21\u5f0f\u7269\u54c1\u680f",
    "itemGroup.materials": "\u6750\u6599",
    "itemGroup.misc": "\u6742\u9879",
    "itemGroup.redstone": "\u7ea2\u77f3",
    "itemGroup.search": "\u641c\u7d22\u7269\u54c1",
    "itemGroup.tools": "\u5de5\u5177",
    "itemGroup.transportation": "\u4ea4\u901a\u8fd0\u8f93",
    "item_modifier.unknown": "\u672a\u77e5\u7684\u7269\u54c1\u4fee\u9970\u5668\uff1a%s",
    "jigsaw_block.final_state": "\u8f6c\u53d8\u4e3a\uff1a",
    "jigsaw_block.generate": "\u751f\u6210",
    "jigsaw_block.joint.aligned": "\u56fa\u5b9a",
    "jigsaw_block.joint.rollable": "\u53ef\u65cb\u8f6c",
    "jigsaw_block.joint_label": "\u62fc\u63a5\u7c7b\u578b\uff1a",
    "jigsaw_block.keep_jigsaws": "\u4fdd\u7559\u62fc\u56fe",
    "jigsaw_block.levels": "\u5c42\u6570\uff1a%s",
    "jigsaw_block.name": "\u540d\u79f0\uff1a",
    "jigsaw_block.pool": "\u76ee\u6807\u6c60\uff1a",
    "jigsaw_block.target": "\u76ee\u6807\u540d\u79f0\uff1a",
    "key.advancements": "\u8fdb\u5ea6",
    "key.attack": "\u653b\u51fb/\u6467\u6bc1",
    "key.back": "\u5411\u540e\u79fb\u52a8",
    "key.categories.creative": "\u521b\u9020\u6a21\u5f0f",
    "key.categories.gameplay": "\u6e38\u620f\u5185\u5bb9",
    "key.categories.inventory": "\u7269\u54c1\u680f",
    "key.categories.misc": "\u6742\u9879",
    "key.categories.movement": "\u79fb\u52a8",
    "key.categories.multiplayer": "\u591a\u4eba\u6e38\u620f",
    "key.categories.ui": "\u6e38\u620f\u754c\u9762",
    "key.chat": "\u6253\u5f00\u804a\u5929\u680f",
    "key.command": "\u8f93\u5165\u547d\u4ee4",
    "key.drop": "\u4e22\u5f03\u6240\u9009\u7269\u54c1",
    "key.forward": "\u5411\u524d\u79fb\u52a8",
    "key.fullscreen": "\u5168\u5c4f\u663e\u793a\u5207\u6362",
    "key.hotbar.1": "\u5feb\u6377\u680f1",
    "key.hotbar.2": "\u5feb\u6377\u680f2",
    "key.hotbar.3": "\u5feb\u6377\u680f3",
    "key.hotbar.4": "\u5feb\u6377\u680f4",
    "key.hotbar.5": "\u5feb\u6377\u680f5",
    "key.hotbar.6": "\u5feb\u6377\u680f6",
    "key.hotbar.7": "\u5feb\u6377\u680f7",
    "key.hotbar.8": "\u5feb\u6377\u680f8",
    "key.hotbar.9": "\u5feb\u6377\u680f9",
    "key.inventory": "\u5f00\u542f/\u5173\u95ed\u7269\u54c1\u680f",
    "key.jump": "\u8df3\u8dc3",
    "key.keyboard.apostrophe": "'",
    "key.keyboard.backslash": "\\",
    "key.keyboard.backspace": "\u9000\u683c",
    "key.keyboard.caps.lock": "Caps Lock",
    "key.keyboard.comma": ",",
    "key.keyboard.delete": "Delete",
    "key.keyboard.down": "\u4e0b\u65b9\u5411\u952e",
    "key.keyboard.end": "End",
    "key.keyboard.enter": "Enter",
    "key.keyboard.equal": "=",
    "key.keyboard.escape": "Esc",
    "key.keyboard.f1": "F1",
    "key.keyboard.f10": "F10",
    "key.keyboard.f11": "F11",
    "key.keyboard.f12": "F12",
    "key.keyboard.f13": "F13",
    "key.keyboard.f14": "F14",
    "key.keyboard.f15": "F15",
    "key.keyboard.f16": "F16",
    "key.keyboard.f17": "F17",
    "key.keyboard.f18": "F18",
    "key.keyboard.f19": "F19",
    "key.keyboard.f2": "F2",
    "key.keyboard.f20": "F20",
    "key.keyboard.f21": "F21",
    "key.keyboard.f22": "F22",
    "key.keyboard.f23": "F23",
    "key.keyboard.f24": "F24",
    "key.keyboard.f25": "F25",
    "key.keyboard.f3": "F3",
    "key.keyboard.f4": "F4",
    "key.keyboard.f5": "F5",
    "key.keyboard.f6": "F6",
    "key.keyboard.f7": "F7",
    "key.keyboard.f8": "F8",
    "key.keyboard.f9": "F9",
    "key.keyboard.grave.accent": "`",
    "key.keyboard.home": "Home",
    "key.keyboard.insert": "Insert",
    "key.keyboard.keypad.0": "\u5c0f\u952e\u76d8 0",
    "key.keyboard.keypad.1": "\u5c0f\u952e\u76d8 1",
    "key.keyboard.keypad.2": "\u5c0f\u952e\u76d8 2",
    "key.keyboard.keypad.3": "\u5c0f\u952e\u76d8 3",
    "key.keyboard.keypad.4": "\u5c0f\u952e\u76d8 4",
    "key.keyboard.keypad.5": "\u5c0f\u952e\u76d8 5",
    "key.keyboard.keypad.6": "\u5c0f\u952e\u76d8 6",
    "key.keyboard.keypad.7": "\u5c0f\u952e\u76d8 7",
    "key.keyboard.keypad.8": "\u5c0f\u952e\u76d8 8",
    "key.keyboard.keypad.9": "\u5c0f\u952e\u76d8 9",
    "key.keyboard.keypad.add": "\u5c0f\u952e\u76d8 +",
    "key.keyboard.keypad.decimal": "\u5c0f\u952e\u76d8 .",
    "key.keyboard.keypad.divide": "\u5c0f\u952e\u76d8 /",
    "key.keyboard.keypad.enter": "\u5c0f\u952e\u76d8 Enter",
    "key.keyboard.keypad.equal": "\u5c0f\u952e\u76d8 =",
    "key.keyboard.keypad.multiply": "\u5c0f\u952e\u76d8 *",
    "key.keyboard.keypad.subtract": "\u5c0f\u952e\u76d8 -",
    "key.keyboard.left": "\u5de6\u65b9\u5411\u952e",
    "key.keyboard.left.alt": "\u5de6Alt",
    "key.keyboard.left.bracket": "[",
    "key.keyboard.left.control": "\u5de6Ctrl",
    "key.keyboard.left.shift": "\u5de6Shift",
    "key.keyboard.left.win": "\u5de6Win",
    "key.keyboard.menu": "\u83dc\u5355",
    "key.keyboard.minus": "-",
    "key.keyboard.num.lock": "Num Lock",
    "key.keyboard.page.down": "Page Down",
    "key.keyboard.page.up": "Page Up",
    "key.keyboard.pause": "Pause",
    "key.keyboard.period": ".",
    "key.keyboard.print.screen": "Print Screen",
    "key.keyboard.right": "\u53f3\u65b9\u5411\u952e",
    "key.keyboard.right.alt": "\u53f3Alt",
    "key.keyboard.right.bracket": "]",
    "key.keyboard.right.control": "\u53f3Ctrl",
    "key.keyboard.right.shift": "\u53f3Shift",
    "key.keyboard.right.win": "\u53f3Win",
    "key.keyboard.scroll.lock": "Scroll Lock",
    "key.keyboard.semicolon": ";",
    "key.keyboard.slash": "/",
    "key.keyboard.space": "\u7a7a\u683c",
    "key.keyboard.tab": "Tab",
    "key.keyboard.unknown": "\u672a\u6307\u5b9a",
    "key.keyboard.up": "\u4e0a\u65b9\u5411\u952e",
    "key.keyboard.world.1": "World 1",
    "key.keyboard.world.2": "World 2",
    "key.left": "\u5411\u5de6\u79fb\u52a8",
    "key.loadToolbarActivator": "\u52a0\u8f7d\u5feb\u6377\u680f",
    "key.mouse": "\u9f20\u6807\u6309\u952e%1$s",
    "key.mouse.left": "\u5de6\u952e",
    "key.mouse.middle": "\u4e2d\u952e",
    "key.mouse.right": "\u53f3\u952e",
    "key.pickItem": "\u9009\u53d6\u65b9\u5757",
    "key.playerlist": "\u73a9\u5bb6\u5217\u8868",
    "key.right": "\u5411\u53f3\u79fb\u52a8",
    "key.saveToolbarActivator": "\u4fdd\u5b58\u5feb\u6377\u680f",
    "key.screenshot": "\u622a\u56fe",
    "key.smoothCamera": "\u5207\u6362\u7535\u5f71\u89c6\u89d2",
    "key.sneak": "\u6f5c\u884c",
    "key.socialInteractions": "\u793e\u4ea4\u754c\u9762",
    "key.spectatorOutlines": "\u9ad8\u4eae\u73a9\u5bb6\uff08\u65c1\u89c2\u8005\uff09",
    "key.sprint": "\u75be\u8dd1",
    "key.swapOffhand": "\u4e0e\u526f\u624b\u4ea4\u6362\u7269\u54c1",
    "key.togglePerspective": "\u5207\u6362\u89c6\u89d2",
    "key.use": "\u4f7f\u7528\u7269\u54c1/\u653e\u7f6e\u65b9\u5757",
    "lanServer.otherPlayers": "\u5bf9\u5176\u4ed6\u73a9\u5bb6\u7684\u8bbe\u7f6e",
    "lanServer.scanning": "\u6b63\u5728\u4f60\u7684\u672c\u5730\u7f51\u7edc\u4e2d\u5bfb\u627e\u6e38\u620f",
    "lanServer.start": "\u521b\u9020\u4e00\u4e2a\u5c40\u57df\u7f51\u4e16\u754c",
    "lanServer.title": "\u5c40\u57df\u7f51\u4e16\u754c",
    "language.code": "zh_cn",
    "language.name": "\u7b80\u4f53\u4e2d\u6587",
    "language.region": "\u4e2d\u56fd",
    "lectern.take_book": "\u53d6\u4e66",
    "menu.convertingLevel": "\u8f6c\u6362\u4e16\u754c\u4e2d",
    "menu.disconnect": "\u65ad\u5f00\u8fde\u63a5",
    "menu.game": "\u6e38\u620f\u83dc\u5355",
    "menu.generatingLevel": "\u751f\u6210\u4e16\u754c\u4e2d",
    "menu.generatingTerrain": "\u751f\u6210\u5730\u5f62\u4e2d",
    "menu.loadingForcedChunks": "\u52a0\u8f7d%s\u7ef4\u5ea6\u4e2d\u7684\u5f3a\u5236\u52a0\u8f7d\u533a\u5757\u4e2d",
    "menu.loadingLevel": "\u52a0\u8f7d\u4e16\u754c\u4e2d",
    "menu.modded": "\uff08\u5df2\u4fee\u6539\uff09",
    "menu.multiplayer": "\u591a\u4eba\u6e38\u620f",
    "menu.online": "Minecraft Realms",
    "menu.options": "\u9009\u9879\u2026",
    "menu.paused": "\u6e38\u620f\u6682\u505c",
    "menu.playdemo": "\u5f00\u59cb\u8bd5\u73a9\u4e16\u754c",
    "menu.preparingSpawn": "\u51c6\u5907\u751f\u6210\u533a\u57df\u4e2d\uff1a%s%%",
    "menu.quit": "\u9000\u51fa\u6e38\u620f",
    "menu.reportBugs": "\u62a5\u544a\u6f0f\u6d1e",
    "menu.resetdemo": "\u91cd\u7f6e\u8bd5\u73a9\u4e16\u754c",
    "menu.respawning": "\u91cd\u751f\u4e2d",
    "menu.returnToGame": "\u56de\u5230\u6e38\u620f",
    "menu.returnToMenu": "\u4fdd\u5b58\u5e76\u9000\u56de\u5230\u6807\u9898\u753b\u9762",
    "menu.savingChunks": "\u4fdd\u5b58\u533a\u5757\u4e2d",
    "menu.savingLevel": "\u4fdd\u5b58\u4e16\u754c\u4e2d",
    "menu.sendFeedback": "\u63d0\u4f9b\u53cd\u9988",
    "menu.shareToLan": "\u5bf9\u5c40\u57df\u7f51\u5f00\u653e",
    "menu.singleplayer": "\u5355\u4eba\u6e38\u620f",
    "menu.working": "\u5904\u7406\u4e2d\u2026",
    "merchant.current_level": "\u5546\u4eba\u7684\u5f53\u524d\u7b49\u7ea7",
    "merchant.deprecated": "\u6751\u6c11\u6bcf\u5929\u6700\u591a\u8865\u8d27\u4e24\u6b21\u3002",
    "merchant.level.1": "\u65b0\u624b",
    "merchant.level.2": "\u5b66\u5f92",
    "merchant.level.3": "\u8001\u624b",
    "merchant.level.4": "\u4e13\u5bb6",
    "merchant.level.5": "\u5927\u5e08",
    "merchant.next_level": "\u5546\u4eba\u7684\u4e0b\u4e00\u7b49\u7ea7",
    "merchant.trades": "\u4ea4\u6613",
    "mirror.front_back": "\u2191 \u2193",
    "mirror.left_right": "\u2190 \u2192",
    "mirror.none": "|",
    "mount.onboard": "\u6309\u4e0b%1$s\u6765\u8131\u79bb",
    "multiplayer.applyingPack": "\u6b63\u5728\u5e94\u7528\u8d44\u6e90\u5305",
    "multiplayer.disconnect.authservers_down": "\u8eab\u4efd\u9a8c\u8bc1\u670d\u52a1\u5668\u76ee\u524d\u5904\u4e8e\u5b95\u673a\u72b6\u6001\u3002\u8bf7\u7a0d\u540e\u518d\u8bd5\uff0c\u62b1\u6b49\uff01",
    "multiplayer.disconnect.banned": "\u4f60\u5df2\u88ab\u6b64\u670d\u52a1\u5668\u5c01\u7981",
    "multiplayer.disconnect.banned.expiration": "\n\u4f60\u7684\u5c01\u7981\u5c06\u4e8e%s\u89e3\u9664",
    "multiplayer.disconnect.banned.reason": "\u4f60\u5df2\u88ab\u6b64\u670d\u52a1\u5668\u5c01\u7981\u3002\n\u539f\u56e0\uff1a%s",
    "multiplayer.disconnect.banned_ip.expiration": "\n\u4f60\u7684\u5c01\u7981\u5c06\u4e8e%s\u89e3\u9664",
    "multiplayer.disconnect.banned_ip.reason": "\u4f60\u7684IP\u5df2\u88ab\u6b64\u670d\u52a1\u5668\u5c01\u7981\u3002\n\u539f\u56e0\uff1a%s",
    "multiplayer.disconnect.duplicate_login": "\u4f60\u5df2\u5728\u5f02\u5730\u767b\u5f55",
    "multiplayer.disconnect.flying": "\u6b64\u670d\u52a1\u5668\u672a\u542f\u7528\u98de\u884c",
    "multiplayer.disconnect.generic": "\u8fde\u63a5\u4e2d\u6b62",
    "multiplayer.disconnect.idling": "\u4f60\u7684\u672a\u64cd\u4f5c\u65f6\u95f4\u8fc7\u957f\uff01",
    "multiplayer.disconnect.illegal_characters": "\u975e\u6cd5\u804a\u5929\u5b57\u7b26",
    "multiplayer.disconnect.incompatible": "\u5ba2\u6237\u7aef\u4e0d\u517c\u5bb9\uff01\u8bf7\u4f7f\u7528%s",
    "multiplayer.disconnect.invalid_entity_attacked": "\u8bd5\u56fe\u653b\u51fb\u65e0\u6548\u5b9e\u4f53",
    "multiplayer.disconnect.invalid_packet": "\u670d\u52a1\u5668\u53d1\u9001\u4e86\u4e00\u4e2a\u65e0\u6548\u7684\u6570\u636e\u5305",
    "multiplayer.disconnect.invalid_player_data": "\u65e0\u6548\u7684\u73a9\u5bb6\u6570\u636e",
    "multiplayer.disconnect.invalid_player_movement": "\u6536\u5230\u4e86\u5305\u542b\u975e\u6cd5\u73a9\u5bb6\u79fb\u52a8\u7684\u6570\u636e\u5305",
    "multiplayer.disconnect.invalid_vehicle_movement": "\u6536\u5230\u4e86\u5305\u542b\u975e\u6cd5\u8f7d\u5177\u79fb\u52a8\u7684\u6570\u636e\u5305",
    "multiplayer.disconnect.ip_banned": "\u4f60\u7684IP\u5730\u5740\u5df2\u88ab\u6b64\u670d\u52a1\u5668\u5c01\u7981",
    "multiplayer.disconnect.kicked": "\u88ab\u7ba1\u7406\u5458\u8e22\u51fa\u6e38\u620f",
    "multiplayer.disconnect.missing_tags": "\u4ece\u670d\u52a1\u5668\u63a5\u6536\u5230\u4e0d\u5b8c\u6574\u6807\u7b7e\u96c6\u3002\n\u8bf7\u8054\u7cfb\u670d\u52a1\u5668\u7ba1\u7406\u5458\u3002",
    "multiplayer.disconnect.name_taken": "\u6b64\u540d\u79f0\u5df2\u88ab\u5360\u7528",
    "multiplayer.disconnect.not_whitelisted": "\u4f60\u672a\u88ab\u6b64\u670d\u52a1\u5668\u5217\u5165\u767d\u540d\u5355\uff01",
    "multiplayer.disconnect.outdated_client": "\u5ba2\u6237\u7aef\u4e0d\u517c\u5bb9\uff01\u8bf7\u4f7f\u7528%s",
    "multiplayer.disconnect.outdated_server": "\u5ba2\u6237\u7aef\u4e0d\u517c\u5bb9\uff01\u8bf7\u4f7f\u7528%s",
    "multiplayer.disconnect.server_full": "\u670d\u52a1\u5668\u5df2\u6ee1\uff01",
    "multiplayer.disconnect.server_shutdown": "\u670d\u52a1\u5668\u5df2\u5173\u95ed",
    "multiplayer.disconnect.slow_login": "\u767b\u5f55\u8d85\u65f6",
    "multiplayer.disconnect.unexpected_query_response": "\u4ece\u5ba2\u6237\u7aef\u6536\u5230\u672a\u77e5\u7684\u81ea\u5b9a\u4e49\u6570\u636e",
    "multiplayer.disconnect.unverified_username": "\u65e0\u6cd5\u9a8c\u8bc1\u7528\u6237\u540d\uff01",
    "multiplayer.downloadingStats": "\u7edf\u8ba1\u4fe1\u606f\u83b7\u53d6\u4e2d\u2026",
    "multiplayer.downloadingTerrain": "\u52a0\u8f7d\u5730\u5f62\u4e2d\u2026",
    "multiplayer.message_not_delivered": "\u65e0\u6cd5\u53d1\u9001\u804a\u5929\u6d88\u606f\uff0c\u8bf7\u68c0\u67e5\u670d\u52a1\u5668\u65e5\u5fd7\uff1a%s",
    "multiplayer.player.joined": "%s\u52a0\u5165\u4e86\u6e38\u620f",
    "multiplayer.player.joined.renamed": "%s\uff08\u4e4b\u524d\u88ab\u79f0\u4e3a%s\uff09\u52a0\u5165\u4e86\u6e38\u620f",
    "multiplayer.player.left": "%s\u9000\u51fa\u4e86\u6e38\u620f",
    "multiplayer.requiredTexturePrompt.disconnect": "\u670d\u52a1\u5668\u9700\u8981\u81ea\u5b9a\u4e49\u8d44\u6e90\u5305",
    "multiplayer.requiredTexturePrompt.line1": "\u8fd9\u4e2a\u670d\u52a1\u5668\u9700\u8981\u4f7f\u7528\u81ea\u5b9a\u4e49\u7684\u8d44\u6e90\u5305\u3002",
    "multiplayer.requiredTexturePrompt.line2": "\u62d2\u7edd\u4f7f\u7528\u8be5\u81ea\u5b9a\u4e49\u8d44\u6e90\u5305\u5c06\u4f1a\u65ad\u5f00\u4f60\u4e0e\u8be5\u670d\u52a1\u5668\u95f4\u7684\u8fde\u63a5\u3002",
    "multiplayer.socialInteractions.not_available": "\u793e\u4ea4\u754c\u9762\u4ec5\u5728\u591a\u4eba\u6e38\u620f\u4e16\u754c\u4e2d\u53ef\u7528",
    "multiplayer.status.and_more": "\u2026\u53ca\u5176\u4ed6%s\u540d\u73a9\u5bb6\u2026",
    "multiplayer.status.cancelled": "\u5df2\u53d6\u6d88",
    "multiplayer.status.cannot_connect": "\u65e0\u6cd5\u8fde\u63a5\u5230\u670d\u52a1\u5668",
    "multiplayer.status.cannot_resolve": "\u65e0\u6cd5\u89e3\u6790\u4e3b\u673a\u540d",
    "multiplayer.status.finished": "\u5df2\u5b8c\u6210",
    "multiplayer.status.incompatible": "\u7248\u672c\u4e0d\u517c\u5bb9\uff01",
    "multiplayer.status.no_connection": "\uff08\u65e0\u8fde\u63a5\uff09",
    "multiplayer.status.old": "\u65e7\u7248",
    "multiplayer.status.ping": "%s\u6beb\u79d2",
    "multiplayer.status.pinging": "\u68c0\u6d4b\u4e2d\u2026",
    "multiplayer.status.quitting": "\u9000\u51fa",
    "multiplayer.status.request_handled": "\u72b6\u6001\u8bf7\u6c42\u5df2\u5904\u7406",
    "multiplayer.status.unknown": "???",
    "multiplayer.status.unrequested": "\u6536\u5230\u4e86\u672a\u8bf7\u6c42\u7684\u72b6\u6001",
    "multiplayer.stopSleeping": "\u8d77\u5e8a",
    "multiplayer.texturePrompt.failure.line1": "\u65e0\u6cd5\u5e94\u7528\u670d\u52a1\u5668\u8d44\u6e90\u5305",
    "multiplayer.texturePrompt.failure.line2": "\u6240\u6709\u4f9d\u8d56\u81ea\u5b9a\u4e49\u8d44\u6e90\u5305\u7684\u529f\u80fd\u90fd\u6709\u53ef\u80fd\u4e0d\u6309\u9884\u671f\u5de5\u4f5c",
    "multiplayer.texturePrompt.line1": "\u8fd9\u4e2a\u670d\u52a1\u5668\u63a8\u8350\u4f7f\u7528\u81ea\u5b9a\u4e49\u7684\u8d44\u6e90\u5305\u3002",
    "multiplayer.texturePrompt.line2": "\u4f60\u60f3\u8981\u81ea\u52a8\u4e0b\u8f7d\u548c\u5b89\u88c5\u5b83\u5417\uff1f",
    "multiplayer.texturePrompt.serverPrompt": "%s\n\n\u6765\u81ea\u670d\u52a1\u5668\u7684\u6d88\u606f\uff1a\n%s",
    "multiplayer.title": "\u591a\u4eba\u6e38\u620f",
    "multiplayerWarning.check": "\u4e0d\u518d\u663e\u793a\u6b64\u754c\u9762",
    "multiplayerWarning.header": "\u8b66\u544a\uff1a\u5728\u7ebf\u6e38\u620f\u7531\u7b2c\u4e09\u65b9\u63d0\u4f9b",
    "multiplayerWarning.message": "\u8b66\u544a\uff1a\u5728\u7ebf\u6e38\u620f\u7531\u975eMojang Studios\u6216Microsoft\u62e5\u6709\u3001\u8fd0\u4f5c\u548c\u76d1\u7763\u7684\u7b2c\u4e09\u65b9\u670d\u52a1\u5668\u63d0\u4f9b\u3002 \u5728\u7ebf\u6e38\u620f\u7684\u8fc7\u7a0b\u4e2d\uff0c\u4f60\u53ef\u80fd\u4f1a\u6536\u5230\u4e0d\u53d7\u89c4\u5236\u7684\u804a\u5929\u6d88\u606f\u6216\u8005\u5176\u4ed6\u4e0d\u4e00\u5b9a\u9002\u5408\u6240\u6709\u4eba\u7684\u7528\u6237\u751f\u6210\u5185\u5bb9\u3002",
    "narration.button": "\u6309\u94ae\uff1a%s",
    "narration.button.usage.focused": "\u6309\u4e0b\u56de\u8f66\u952e\u4ee5\u6fc0\u6d3b",
    "narration.button.usage.hovered": "\u5355\u51fb\u9f20\u6807\u5de6\u952e\u6fc0\u6d3b",
    "narration.checkbox": "\u590d\u9009\u6846\uff1a%s",
    "narration.checkbox.usage.focused": "\u6309\u4e0b\u56de\u8f66\u952e\u4ee5\u5207\u6362",
    "narration.checkbox.usage.hovered": "\u5355\u51fb\u9f20\u6807\u5de6\u952e\u5207\u6362",
    "narration.component_list.usage": "\u6309\u4e0bTab\u952e\u79fb\u52a8\u5230\u4e0b\u4e00\u5c4f\u5e55\u63a7\u4ef6",
    "narration.cycle_button.usage.focused": "\u6309\u4e0b\u56de\u8f66\u952e\u4ee5\u5207\u6362\u5230%s",
    "narration.cycle_button.usage.hovered": "\u5355\u51fb\u9f20\u6807\u5de6\u952e\u5207\u6362\u5230%s",
    "narration.edit_box": "\u7f16\u8f91\u6846\uff1a%s",
    "narration.recipe": "%s\u7684\u914d\u65b9",
    "narration.recipe.usage": "\u5355\u51fb\u9f20\u6807\u5de6\u952e\u9009\u4e2d",
    "narration.recipe.usage.more": "\u5355\u51fb\u9f20\u6807\u53f3\u952e\u663e\u793a\u66f4\u591a\u914d\u65b9",
    "narration.selection.usage": "\u6309\u4e0b\u4e0a\u952e\u6216\u4e0b\u952e\u4ee5\u79fb\u52a8\u5230\u53e6\u4e00\u9879",
    "narration.slider.usage.focused": "\u6309\u4e0b\u952e\u76d8\u5de6\u952e\u6216\u952e\u76d8\u53f3\u952e\u4ee5\u66f4\u6539\u6570\u503c",
    "narration.slider.usage.hovered": "\u62d6\u52a8\u6ed1\u5757\u4ee5\u66f4\u6539\u6570\u503c",
    "narration.suggestion": "\u9009\u4e2d\u4e86%2$s\u9879\u5efa\u8bae\u4e2d\u7684\u7b2c%1$s\u9879\uff1a%3$s",
    "narration.suggestion.tooltip": "\u9009\u4e2d\u4e86%2$s\u9879\u5efa\u8bae\u4e2d\u7684\u7b2c%1$s\u9879\uff1a%3$s\uff08%4$s\uff09",
    "narrator.button.accessibility": "\u8f85\u52a9\u529f\u80fd",
    "narrator.button.difficulty_lock": "\u96be\u5ea6\u9501",
    "narrator.button.difficulty_lock.locked": "\u5df2\u9501\u5b9a",
    "narrator.button.difficulty_lock.unlocked": "\u672a\u9501\u5b9a",
    "narrator.button.language": "\u8bed\u8a00",
    "narrator.controls.bound": "%s\u5df2\u7ed1\u5b9a\u81f3%s",
    "narrator.controls.reset": "\u91cd\u7f6e%s\u6309\u94ae",
    "narrator.controls.unbound": "%s\u672a\u7ed1\u5b9a",
    "narrator.joining": "\u52a0\u5165\u4e2d",
    "narrator.loading": "\u52a0\u8f7d\u4e2d\uff1a%s",
    "narrator.loading.done": "\u5b8c\u6210",
    "narrator.position.list": "\u5df2\u9009\u4e2d\u5217\u8868\u7684\u7b2c%s\u884c\uff0c\u5171%s\u884c",
    "narrator.position.object_list": "\u5df2\u9009\u4e2d\u4e00\u5217\u63a7\u4ef6\u4e2d\u7684\u7b2c%s\u9879\uff0c\u5171%s\u9879",
    "narrator.position.screen": "\u5c4f\u5e55\u63a7\u4ef6\uff0c\u7b2c%s\u4e2a\uff0c\u5171%s\u4e2a",
    "narrator.screen.title": "\u6807\u9898\u754c\u9762",
    "narrator.screen.usage": "\u4f7f\u7528\u9f20\u6807\u6307\u9488\u6216Tab\u952e\u9009\u62e9\u5c4f\u5e55\u63a7\u4ef6",
    "narrator.select": "\u5df2\u9009\u62e9\uff1a%s",
    "narrator.select.world": "\u5df2\u9009\u62e9%s\uff0c\u4e0a\u6b21\u6e38\u73a9\uff1a%s\uff0c%s\uff0c%s\uff0c\u7248\u672c\uff1a%s",
    "narrator.toast.disabled": "\u590d\u8ff0\u529f\u80fd\u5df2\u5173\u95ed",
    "narrator.toast.enabled": "\u590d\u8ff0\u529f\u80fd\u5df2\u5f00\u542f",
    "optimizeWorld.confirm.description": "\u8fd9\u5c06\u5c1d\u8bd5\u7528\u6700\u65b0\u7684\u6e38\u620f\u683c\u5f0f\u50a8\u5b58\u6240\u6709\u6570\u636e\u6765\u8fbe\u5230\u4f18\u5316\u4e16\u754c\u7684\u6548\u679c\u3002\u53d6\u51b3\u4e8e\u4e16\u754c\u7684\u72b6\u51b5\uff0c\u8fd9\u53ef\u80fd\u4f1a\u82b1\u8d39\u4e0d\u5c11\u65f6\u95f4\u3002\u4e00\u65e6\u5b8c\u6210\uff0c\u6e38\u73a9\u65f6\u53ef\u80fd\u4f1a\u66f4\u6d41\u7545\uff0c\u4f46\u4f60\u7684\u4e16\u754c\u5c06\u4e0d\u518d\u4e0e\u65e7\u7248\u6e38\u620f\u517c\u5bb9\u3002\u4f60\u786e\u5b9a\u8981\u7ee7\u7eed\u5417\uff1f",
    "optimizeWorld.confirm.title": "\u4f18\u5316\u4e16\u754c",
    "optimizeWorld.info.converted": "\u66f4\u65b0\u7684\u533a\u5757\uff1a%s",
    "optimizeWorld.info.skipped": "\u5ffd\u7565\u7684\u533a\u5757\uff1a%s",
    "optimizeWorld.info.total": "\u603b\u8ba1\u533a\u5757\uff1a%s",
    "optimizeWorld.stage.counting": "\u7edf\u8ba1\u533a\u5757\u4e2d\u2026",
    "optimizeWorld.stage.failed": "\u5931\u8d25\u4e86\uff01:(",
    "optimizeWorld.stage.finished": "\u5373\u5c06\u5b8c\u6210\u2026",
    "optimizeWorld.stage.upgrading": "\u66f4\u65b0\u6240\u6709\u533a\u5757\u4e2d\u2026",
    "optimizeWorld.title": "\u6b63\u5728\u4f18\u5316\u4e16\u754c\u201c%s\u201d",
    "options.accessibility.link": "\u8f85\u52a9\u529f\u80fd\u6307\u5357",
    "options.accessibility.text_background": "\u6587\u672c\u80cc\u666f",
    "options.accessibility.text_background.chat": "\u804a\u5929",
    "options.accessibility.text_background.everywhere": "\u5168\u5c40",
    "options.accessibility.text_background_opacity": "\u6587\u672c\u80cc\u666f\u4e0d\u900f\u660e\u5ea6",
    "options.accessibility.title": "\u8f85\u52a9\u529f\u80fd\u8bbe\u7f6e\u2026",
    "options.allowServerListing": "\u5141\u8bb8\u5217\u5165\u670d\u52a1\u5668\u73a9\u5bb6\u5217\u8868",
    "options.allowServerListing.tooltip": "\u670d\u52a1\u5668\u53ef\u80fd\u4f1a\u516c\u5f00\u5217\u51fa\u76ee\u524d\u5728\u7ebf\u7684\u73a9\u5bb6\u3002\n\u82e5\u5173\u95ed\u6b64\u9009\u9879\uff0c\u4f60\u7684\u540d\u5b57\u5c06\u4e0d\u4f1a\u663e\u793a\u5728\u6b64\u5217\u8868\u4e2d\u3002",
    "options.ao": "\u5e73\u6ed1\u5149\u7167",
    "options.ao.max": "\u6700\u5927",
    "options.ao.min": "\u6700\u5c0f",
    "options.ao.off": "\u5173",
    "options.attack.crosshair": "\u5341\u5b57\u51c6\u661f",
    "options.attack.hotbar": "\u5feb\u6377\u680f",
    "options.attackIndicator": "\u653b\u51fb\u6307\u793a\u5668",
    "options.audioDevice": "\u8bbe\u5907",
    "options.audioDevice.default": "\u7cfb\u7edf\u9ed8\u8ba4",
    "options.autoJump": "\u81ea\u52a8\u8df3\u8dc3",
    "options.autoSuggestCommands": "\u547d\u4ee4\u63d0\u793a",
    "options.autosaveIndicator": "\u81ea\u52a8\u4fdd\u5b58\u6307\u793a\u5668",
    "options.biomeBlendRadius": "\u751f\u7269\u7fa4\u7cfb\u8fc7\u6e21\u8ddd\u79bb",
    "options.biomeBlendRadius.1": "\u5173\uff08\u6700\u5feb\uff09",
    "options.biomeBlendRadius.11": "11x11\uff08\u6781\u9ad8\uff09",
    "options.biomeBlendRadius.13": "13x13\uff08\u663e\u8457\uff09",
    "options.biomeBlendRadius.15": "15x15\uff08\u6700\u9ad8\uff09",
    "options.biomeBlendRadius.3": "3x3\uff08\u5feb\uff09",
    "options.biomeBlendRadius.5": "5x5\uff08\u666e\u901a\uff09",
    "options.biomeBlendRadius.7": "7x7\uff08\u9ad8\uff09",
    "options.biomeBlendRadius.9": "9x9\uff08\u5f88\u9ad8\uff09",
    "options.chat.color": "\u989c\u8272",
    "options.chat.delay": "\u804a\u5929\u5ef6\u8fdf\uff1a%s\u79d2",
    "options.chat.delay_none": "\u804a\u5929\u5ef6\u8fdf\uff1a\u65e0",
    "options.chat.height.focused": "\u805a\u7126\u9ad8\u5ea6",
    "options.chat.height.unfocused": "\u6de1\u5316\u9ad8\u5ea6",
    "options.chat.line_spacing": "\u884c\u8ddd",
    "options.chat.links": "\u7f51\u9875\u94fe\u63a5",
    "options.chat.links.prompt": "\u94fe\u63a5\u63d0\u793a",
    "options.chat.opacity": "\u804a\u5929\u6587\u672c\u4e0d\u900f\u660e\u5ea6",
    "options.chat.scale": "\u804a\u5929\u6587\u672c\u5927\u5c0f",
    "options.chat.title": "\u804a\u5929\u8bbe\u7f6e\u2026",
    "options.chat.visibility": "\u804a\u5929",
    "options.chat.visibility.full": "\u663e\u793a",
    "options.chat.visibility.hidden": "\u9690\u85cf",
    "options.chat.visibility.system": "\u4ec5\u9650\u547d\u4ee4",
    "options.chat.width": "\u5bbd\u5ea6",
    "options.chunks": "%s\u4e2a\u533a\u5757",
    "options.clouds.fancy": "\u9ad8\u54c1\u8d28",
    "options.clouds.fast": "\u6d41\u7545",
    "options.controls": "\u63a7\u5236\u2026",
    "options.customizeTitle": "\u81ea\u5b9a\u4e49\u4e16\u754c\u8bbe\u7f6e",
    "options.darkMojangStudiosBackgroundColor": "\u9ed1\u767d\u5fbd\u6807",
    "options.darkMojangStudiosBackgroundColor.tooltip": "\u5c06Mojang Studios\u52a0\u8f7d\u754c\u9762\u7684\u80cc\u666f\u989c\u8272\u66f4\u6539\u4e3a\u9ed1\u8272\u3002",
    "options.difficulty": "\u96be\u5ea6",
    "options.difficulty.easy": "\u7b80\u5355",
    "options.difficulty.hard": "\u56f0\u96be",
    "options.difficulty.hardcore": "\u6781\u9650",
    "options.difficulty.normal": "\u666e\u901a",
    "options.difficulty.online": "\u670d\u52a1\u5668\u96be\u5ea6",
    "options.difficulty.peaceful": "\u548c\u5e73",
    "options.discrete_mouse_scroll": "\u79bb\u6563\u6eda\u52a8",
    "options.entityDistanceScaling": "\u5b9e\u4f53\u6e32\u67d3\u8ddd\u79bb",
    "options.entityShadows": "\u5b9e\u4f53\u9634\u5f71",
    "options.forceUnicodeFont": "\u5f3a\u5236\u4f7f\u7528Unicode\u5b57\u4f53",
    "options.fov": "\u89c6\u573a\u89d2",
    "options.fov.max": "\u5e7f\u89d2",
    "options.fov.min": "\u4e2d",
    "options.fovEffectScale": "\u89c6\u573a\u89d2\u6548\u679c",
    "options.fovEffectScale.tooltip": "\u63a7\u5236\u901f\u5ea6\u548c\u7f13\u6162\u72b6\u6001\u6548\u679c\u6539\u53d8\u89c6\u573a\u89d2\u7684\u7a0b\u5ea6\u3002",
    "options.framerate": "%s fps",
    "options.framerateLimit": "\u6700\u5927\u5e27\u7387",
    "options.framerateLimit.max": "\u65e0\u9650\u5236",
    "options.fullscreen": "\u5168\u5c4f",
    "options.fullscreen.current": "\u5f53\u524d\u5206\u8fa8\u7387",
    "options.fullscreen.resolution": "\u5168\u5c4f\u5206\u8fa8\u7387",
    "options.fullscreen.unavailable": "\u8bbe\u7f6e\u4e0d\u53ef\u7528",
    "options.gamma": "\u4eae\u5ea6",
    "options.gamma.default": "\u9ed8\u8ba4",
    "options.gamma.max": "\u660e\u4eae",
    "options.gamma.min": "\u660f\u6697",
    "options.generic_value": "%s\uff1a%s",
    "options.graphics": "\u56fe\u50cf\u54c1\u8d28",
    "options.graphics.fabulous": "\u6781\u4f73\uff01",
    "options.graphics.fabulous.tooltip": "%s\u753b\u8d28\u4f7f\u7528\u5c4f\u5e55\u7740\u8272\u5668\u7ed8\u5236\u5929\u6c14\u3001\u4e91\u4ee5\u53ca\u534a\u900f\u660e\u65b9\u5757\u548c\u6c34\u540e\u9762\u7684\u7c92\u5b50\u3002\n\u8fd9\u4e5f\u8bb8\u4f1a\u5728\u4fbf\u643a\u8bbe\u5907\u548c4K\u663e\u793a\u5c4f\u4e0a\u9020\u6210\u4e25\u91cd\u7684\u6027\u80fd\u8d1f\u62c5\u3002",
    "options.graphics.fancy": "\u9ad8\u54c1\u8d28",
    "options.graphics.fancy.tooltip": "\u9ad8\u54c1\u8d28\u753b\u8d28\u4f1a\u4e3a\u5927\u591a\u6570\u8bbe\u5907\u5e73\u8861\u6027\u80fd\u548c\u8d28\u91cf\u3002\n\u5929\u6c14\u3001\u4e91\u548c\u7c92\u5b50\u53ef\u80fd\u4e0d\u4f1a\u5728\u534a\u900f\u660e\u65b9\u5757\u6216\u6c34\u7684\u540e\u9762\u663e\u793a\u3002",
    "options.graphics.fast": "\u6d41\u7545",
    "options.graphics.fast.tooltip": "\u6d41\u7545\u753b\u8d28\u5c06\u51cf\u5c11\u53ef\u89c1\u7684\u96e8\u96ea\u6570\u91cf\u3002\n\u6811\u53f6\u7b49\u65b9\u5757\u7684\u900f\u660e\u6548\u679c\u5c06\u88ab\u7981\u7528\u3002",
    "options.graphics.warning.accept": "\u5728\u4e0d\u53d7\u652f\u6301\u7684\u60c5\u51b5\u4e0b\u7ee7\u7eed",
    "options.graphics.warning.cancel": "\u5e26\u6211\u8fd4\u56de",
    "options.graphics.warning.message": "\u68c0\u6d4b\u5230\u4f60\u7684\u56fe\u5f62\u8bbe\u5907\u4e0d\u652f\u6301%s\u753b\u8d28\u9009\u9879\u3002\n\n\u4f60\u53ef\u4ee5\u5ffd\u7565\u6b64\u63d0\u793a\u5e76\u7ee7\u7eed\uff0c\u4f46\u5982\u679c\u4f60\u4f9d\u7136\u9009\u62e9\u4f7f\u7528%s\u753b\u8d28\uff0c\u4f60\u7684\u8bbe\u5907\u5c06\u4e0d\u4f1a\u53d7\u5230\u652f\u6301\u3002",
    "options.graphics.warning.renderer": "\u68c0\u6d4b\u5230\u6e32\u67d3\u5668\uff1a[%s]",
    "options.graphics.warning.title": "\u56fe\u5f62\u8bbe\u5907\u4e0d\u53d7\u652f\u6301",
    "options.graphics.warning.vendor": "\u68c0\u6d4b\u5230\u5382\u5546\uff1a[%s]",
    "options.graphics.warning.version": "\u68c0\u6d4b\u5230OpenGL\u7248\u672c\uff1a[%s]",
    "options.guiScale": "\u754c\u9762\u5c3a\u5bf8",
    "options.guiScale.auto": "\u81ea\u52a8",
    "options.hidden": "\u9690\u85cf",
    "options.hideLightningFlashes": "\u9690\u85cf\u95ea\u7535\u7684\u95ea\u70c1\u6548\u679c",
    "options.hideLightningFlashes.tooltip": "\u5173\u95ed\u95ea\u7535\u9020\u6210\u7684\u5929\u7a7a\u95ea\u70c1\u6548\u679c\u3002\u95ea\u7535\u672c\u8eab\u4ecd\u5c06\u53ef\u89c1\u3002",
    "options.hideMatchedNames": "\u9690\u85cf\u5339\u914d\u7684\u540d\u79f0",
    "options.hideMatchedNames.tooltip": "\u7b2c\u4e09\u65b9\u670d\u52a1\u5668\u4e5f\u8bb8\u4f1a\u53d1\u9001\u975e\u6807\u51c6\u683c\u5f0f\u7684\u804a\u5929\u6d88\u606f\u3002\n\u6b64\u8bbe\u7f6e\u6253\u5f00\u540e\uff0c\u88ab\u9690\u85cf\u7684\u73a9\u5bb6\u5c06\u4f1a\u88ab\u6839\u636e\u53d1\u8a00\u8005\u7684\u540d\u5b57\u5339\u914d\u3002",
    "options.invertMouse": "\u9f20\u6807\u53cd\u8f6c",
    "options.key.hold": "\u6309\u4f4f",
    "options.key.toggle": "\u5207\u6362",
    "options.language": "\u8bed\u8a00\u2026",
    "options.languageWarning": "\u8bed\u8a00\u7ffb\u8bd1\u4e0d\u4e00\u5b9a100%%\u51c6\u786e",
    "options.mainHand": "\u4e3b\u624b",
    "options.mainHand.left": "\u5de6\u624b",
    "options.mainHand.right": "\u53f3\u624b",
    "options.mipmapLevels": "Mipmap \u7ea7\u522b",
    "options.modelPart.cape": "\u62ab\u98ce",
    "options.modelPart.hat": "\u5e3d\u5b50",
    "options.modelPart.jacket": "\u8863\u7269",
    "options.modelPart.left_pants_leg": "\u5de6\u88e4\u817f",
    "options.modelPart.left_sleeve": "\u5de6\u8896",
    "options.modelPart.right_pants_leg": "\u53f3\u88e4\u817f",
    "options.modelPart.right_sleeve": "\u53f3\u8896",
    "options.mouseWheelSensitivity": "\u6eda\u8f6e\u7075\u654f\u5ea6",
    "options.mouse_settings": "\u9f20\u6807\u8bbe\u7f6e\u2026",
    "options.mouse_settings.title": "\u9f20\u6807\u8bbe\u7f6e",
    "options.multiplayer.title": "\u591a\u4eba\u6e38\u620f\u8bbe\u7f6e\u2026",
    "options.narrator": "\u590d\u8ff0\u529f\u80fd",
    "options.narrator.all": "\u590d\u8ff0\u6240\u6709",
    "options.narrator.chat": "\u590d\u8ff0\u804a\u5929\u6d88\u606f",
    "options.narrator.notavailable": "\u4e0d\u53ef\u7528",
    "options.narrator.off": "\u5173",
    "options.narrator.system": "\u590d\u8ff0\u7cfb\u7edf\u6d88\u606f",
    "options.off": "\u5173",
    "options.off.composed": "%s\uff1a\u5173\u95ed",
    "options.on": "\u5f00",
    "options.on.composed": "%s\uff1a\u5f00\u542f",
    "options.online": "\u5728\u7ebf\u9009\u9879\u2026",
    "options.online.title": "\u5728\u7ebf\u9009\u9879",
    "options.particles": "\u7c92\u5b50\u6548\u679c",
    "options.particles.all": "\u5168\u90e8",
    "options.particles.decreased": "\u5c11\u91cf",
    "options.particles.minimal": "\u6700\u5c11",
    "options.percent_add_value": "%s\uff1a+%s%%",
    "options.percent_value": "%s\uff1a%s%%",
    "options.pixel_value": "%s\uff1a%spx",
    "options.prioritizeChunkUpdates": "\u533a\u5757\u6784\u5efa\u5668",
    "options.prioritizeChunkUpdates.byPlayer": "\u534a\u963b\u585e",
    "options.prioritizeChunkUpdates.byPlayer.tooltip": "\u533a\u5757\u5185\u90e8\u7684\u67d0\u4e9b\u884c\u4e3a\u4f1a\u5bfc\u81f4\u533a\u5757\u7acb\u523b\u91cd\u65b0\u7f16\u8bd1\u3002\u8fd9\u5305\u62ec\u653e\u7f6e\u6216\u7834\u574f\u65b9\u5757\u3002",
    "options.prioritizeChunkUpdates.nearby": "\u5168\u963b\u585e",
    "options.prioritizeChunkUpdates.nearby.tooltip": "\u9644\u8fd1\u7684\u533a\u5757\u603b\u4f1a\u88ab\u7acb\u523b\u7f16\u8bd1\u3002\u8fd9\u53ef\u80fd\u5f71\u54cd\u653e\u7f6e\u6216\u7834\u574f\u65b9\u5757\u65f6\u7684\u6e38\u620f\u6027\u80fd\u3002",
    "options.prioritizeChunkUpdates.none": "\u7ebf\u7a0b\u5316",
    "options.prioritizeChunkUpdates.none.tooltip": "\u9644\u8fd1\u7684\u533a\u5757\u4f1a\u5728\u5e76\u884c\u7684\u7ebf\u7a0b\u4e2d\u7f16\u8bd1\u3002\u8fd9\u53ef\u80fd\u5bfc\u81f4\u7834\u574f\u65b9\u5757\u65f6\u77ed\u6682\u51fa\u73b0\u56fe\u50cf\u7a7a\u6d1e\u3002",
    "options.rawMouseInput": "\u539f\u59cb\u8f93\u5165",
    "options.realmsNotifications": "Realms\u7684\u901a\u77e5",
    "options.reducedDebugInfo": "\u7b80\u5316\u8c03\u8bd5\u4fe1\u606f",
    "options.renderClouds": "\u4e91",
    "options.renderDistance": "\u6e32\u67d3\u8ddd\u79bb",
    "options.resourcepack": "\u8d44\u6e90\u5305\u2026",
    "options.screenEffectScale": "\u5c4f\u5e55\u626d\u66f2\u6548\u679c",
    "options.screenEffectScale.tooltip": "\u53cd\u80c3\u72b6\u6001\u6548\u679c\u548c\u4e0b\u754c\u4f20\u9001\u95e8\u6240\u4f7f\u7528\u7684\u5c4f\u5e55\u626d\u66f2\u6548\u679c\u7684\u5f3a\u5ea6\u3002\n\u503c\u8f83\u4f4e\u65f6\uff0c\u53cd\u80c3\u7684\u626d\u66f2\u6548\u679c\u4f1a\u88ab\u4e00\u5c42\u7eff\u8272\u7684\u89c6\u89c9\u6548\u679c\u66ff\u4ee3\u3002",
    "options.sensitivity": "\u9f20\u6807\u7075\u654f\u5ea6",
    "options.sensitivity.max": "\u8d85\u9ad8\u901f\uff01\uff01\uff01",
    "options.sensitivity.min": "*\u54c8\u6b20*",
    "options.showSubtitles": "\u663e\u793a\u5b57\u5e55",
    "options.simulationDistance": "\u6a21\u62df\u8ddd\u79bb",
    "options.skinCustomisation": "\u81ea\u5b9a\u4e49\u76ae\u80a4\u2026",
    "options.skinCustomisation.title": "\u81ea\u5b9a\u4e49\u76ae\u80a4",
    "options.sounds": "\u97f3\u4e50\u548c\u58f0\u97f3\u2026",
    "options.sounds.title": "\u97f3\u4e50\u548c\u58f0\u97f3\u9009\u9879",
    "options.title": "\u9009\u9879",
    "options.touchscreen": "\u89e6\u5c4f\u6a21\u5f0f",
    "options.video": "\u89c6\u9891\u8bbe\u7f6e\u2026",
    "options.videoTitle": "\u89c6\u9891\u8bbe\u7f6e",
    "options.viewBobbing": "\u89c6\u89d2\u6447\u6643",
    "options.visible": "\u663e\u793a",
    "options.vsync": "\u5782\u76f4\u540c\u6b65",
    "pack.available.title": "\u53ef\u7528",
    "pack.copyFailure": "\u590d\u5236\u538b\u7f29\u5305\u5931\u8d25",
    "pack.dropConfirm": "\u4f60\u786e\u5b9a\u8981\u5c06\u8fd9\u4e9b\u538b\u7f29\u5305\u6dfb\u52a0\u8fdbMinecraft\u4e2d\u5417\uff1f",
    "pack.dropInfo": "\u5c06\u6587\u4ef6\u62d6\u653e\u5230\u8fd9\u4e2a\u7a97\u53e3\u5185\u6765\u6dfb\u52a0\u538b\u7f29\u5305",
    "pack.folderInfo": "\uff08\u5728\u6b64\u653e\u7f6e\u538b\u7f29\u5305\u6587\u4ef6\uff09",
    "pack.incompatible": "\u4e0d\u517c\u5bb9",
    "pack.incompatible.confirm.new": "\u8fd9\u4e2a\u538b\u7f29\u5305\u662f\u4e3a\u66f4\u65b0\u7684Minecraft\u7248\u672c\u6240\u6253\u9020\u7684\uff0c\u5728\u6b64\u7248\u672c\u53ef\u80fd\u4e0d\u4f1a\u6b63\u5e38\u5de5\u4f5c\u3002",
    "pack.incompatible.confirm.old": "\u8fd9\u4e2a\u538b\u7f29\u5305\u662f\u4e3a\u66f4\u65e7\u7684Minecraft\u7248\u672c\u6240\u6253\u9020\u7684\uff0c\u5728\u6b64\u7248\u672c\u53ef\u80fd\u4e0d\u4f1a\u6b63\u5e38\u5de5\u4f5c\u3002",
    "pack.incompatible.confirm.title": "\u4f60\u786e\u5b9a\u8981\u52a0\u8f7d\u6b64\u538b\u7f29\u5305\u5417\uff1f",
    "pack.incompatible.new": "\uff08\u9002\u7528\u4e8e\u65b0\u7248\u672c\u7684Minecraft\uff09",
    "pack.incompatible.old": "\uff08\u9002\u7528\u4e8e\u65e7\u7248\u672c\u7684Minecraft\uff09",
    "pack.nameAndSource": "%s\uff08%s\uff09",
    "pack.openFolder": "\u6253\u5f00\u538b\u7f29\u5305\u6587\u4ef6\u5939",
    "pack.selected.title": "\u5df2\u9009",
    "pack.source.builtin": "\u5185\u7f6e",
    "pack.source.local": "\u672c\u5730",
    "pack.source.server": "\u670d\u52a1\u5668",
    "pack.source.world": "\u4e16\u754c",
    "parsing.bool.expected": "\u9700\u8981\u5e03\u5c14\u578b",
    "parsing.bool.invalid": "\u65e0\u6548\u7684\u5e03\u5c14\u578b\u6570\u636e\uff0c\u9700\u8981'true'\u6216'false'\u5374\u627e\u5230\u4e86'%s'",
    "parsing.double.expected": "\u9700\u8981\u53cc\u7cbe\u5ea6\u6d6e\u70b9\u578b",
    "parsing.double.invalid": "\u65e0\u6548\u7684\u53cc\u7cbe\u5ea6\u6d6e\u70b9\u578b\u6570\u636e'%s'",
    "parsing.expected": "\u9700\u8981'%s'",
    "parsing.float.expected": "\u9700\u8981\u6d6e\u70b9\u578b",
    "parsing.float.invalid": "\u65e0\u6548\u7684\u6d6e\u70b9\u578b\u6570\u636e'%s'",
    "parsing.int.expected": "\u9700\u8981\u6574\u578b",
    "parsing.int.invalid": "\u65e0\u6548\u7684\u6574\u578b\u6570\u636e'%s'",
    "parsing.long.expected": "\u9700\u8981\u957f\u6574\u578b",
    "parsing.long.invalid": "\u65e0\u6548\u7684\u957f\u6574\u578b\u6570\u636e'%s'",
    "parsing.quote.escape": "\u53cc\u5f15\u53f7\u5185\u7684\u5b57\u7b26\u4e32\u5305\u542b\u65e0\u6548\u7684\u8f6c\u4e49\u5e8f\u5217'\\%s'",
    "parsing.quote.expected.end": "\u5b57\u7b26\u4e32\u7684\u53cc\u5f15\u53f7\u4e0d\u6210\u5bf9",
    "parsing.quote.expected.start": "\u5b57\u7b26\u4e32\u7684\u5f00\u5934\u9700\u8981\u53cc\u5f15\u53f7",
    "particle.notFound": "\u672a\u77e5\u7684\u7c92\u5b50\uff1a%s",
    "permissions.requires.entity": "\u9700\u8981\u4e00\u4e2a\u5b9e\u4f53\u6765\u6267\u884c\u6b64\u547d\u4ee4",
    "permissions.requires.player": "\u9700\u8981\u4e00\u540d\u73a9\u5bb6\u6765\u6267\u884c\u6b64\u547d\u4ee4",
    "potion.potency.1": "II",
    "potion.potency.2": "III",
    "potion.potency.3": "IV",
    "potion.potency.4": "V",
    "potion.potency.5": "VI",
    "potion.whenDrank": "\u5f53\u751f\u6548\u540e\uff1a",
    "potion.withAmplifier": "%s %s",
    "potion.withDuration": "%s\uff08%s\uff09",
    "predicate.unknown": "\u672a\u77e5\u7684\u6218\u5229\u54c1\u8868\u6761\u4ef6\uff1a%s",
    "realms.missing.module.error.text": "\u5f53\u524d\u65e0\u6cd5\u6253\u5f00Realms\uff0c\u8bf7\u7a0d\u540e\u518d\u8bd5",
    "realms.missing.snapshot.error.text": "\u5feb\u7167\u5f53\u524d\u4e0d\u652f\u6301Realms",
    "recipe.notFound": "\u672a\u77e5\u7684\u914d\u65b9\uff1a%s",
    "recipe.toast.description": "\u8bf7\u68c0\u67e5\u4f60\u7684\u914d\u65b9\u4e66",
    "recipe.toast.title": "\u65b0\u914d\u65b9\u5df2\u89e3\u9501\uff01",
    "record.nowPlaying": "\u6b63\u5728\u64ad\u653e\uff1a%s",
    "resourcePack.broken_assets": "\u68c0\u6d4b\u5230\u635f\u574f\u7684\u8d44\u6e90\u6587\u4ef6",
    "resourcePack.load_fail": "\u91cd\u8f7d\u8d44\u6e90\u5931\u8d25",
    "resourcePack.server.name": "\u4e16\u754c\u6307\u5b9a\u8d44\u6e90\u5305",
    "resourcePack.title": "\u9009\u62e9\u8d44\u6e90\u5305",
    "resourcePack.vanilla.description": "Minecraft\u7684\u9ed8\u8ba4\u8d44\u6e90\u5305",
    "resourcepack.downloading": "\u6b63\u5728\u4e0b\u8f7d\u8d44\u6e90\u5305\u4e2d",
    "resourcepack.progress": "\u4e0b\u8f7d\u6587\u4ef6\u4e2d\uff08%s MB\uff09\u2026",
    "resourcepack.requesting": "\u6b63\u5728\u53d1\u9001\u8bf7\u6c42\u2026",
    "screenshot.failure": "\u65e0\u6cd5\u4fdd\u5b58\u622a\u56fe\uff1a%s",
    "screenshot.success": "\u5df2\u5c06\u622a\u56fe\u4fdd\u5b58\u4e3a%s",
    "selectServer.add": "\u6dfb\u52a0\u670d\u52a1\u5668",
    "selectServer.defaultName": "Minecraft\u670d\u52a1\u5668",
    "selectServer.delete": "\u5220\u9664",
    "selectServer.deleteButton": "\u5220\u9664",
    "selectServer.deleteQuestion": "\u4f60\u786e\u5b9a\u8981\u5220\u9664\u6b64\u670d\u52a1\u5668\u5417\uff1f",
    "selectServer.deleteWarning": "\u201c%s\u201d\u5c06\u4f1a\u6c38\u4e45\u5931\u53bb\uff01\uff08\u771f\u7684\u5f88\u4e45\uff01\uff09",
    "selectServer.direct": "\u76f4\u63a5\u8fde\u63a5",
    "selectServer.edit": "\u7f16\u8f91",
    "selectServer.hiddenAddress": "\uff08\u9690\u85cf\uff09",
    "selectServer.refresh": "\u5237\u65b0",
    "selectServer.select": "\u52a0\u5165\u670d\u52a1\u5668",
    "selectServer.title": "\u9009\u62e9\u670d\u52a1\u5668",
    "selectWorld.access_failure": "\u8bbf\u95ee\u4e16\u754c\u5931\u8d25",
    "selectWorld.allowCommands": "\u5141\u8bb8\u4f5c\u5f0a",
    "selectWorld.allowCommands.info": "\u4f8b\u5982/gamemode\u3001/experience\u7b49\u547d\u4ee4",
    "selectWorld.backupEraseCache": "\u6e05\u9664\u7f13\u5b58\u6570\u636e",
    "selectWorld.backupJoinConfirmButton": "\u521b\u5efa\u5907\u4efd\u5e76\u8f7d\u5165",
    "selectWorld.backupJoinSkipButton": "\u6211\u77e5\u9053\u6211\u5728\u505a\u4ec0\u4e48\uff01",
    "selectWorld.backupQuestion.customized": "\u81ea\u5b9a\u4e49\u4e16\u754c\u5df2\u4e0d\u53d7\u652f\u6301",
    "selectWorld.backupQuestion.downgrade": "\u4e0d\u652f\u6301\u5bf9\u5b58\u6863\u7248\u672c\u8fdb\u884c\u964d\u7ea7",
    "selectWorld.backupQuestion.experimental": "\u4f7f\u7528\u201c\u5b9e\u9a8c\u6027\u8bbe\u7f6e\u201d\u7684\u4e16\u754c\u5df2\u4e0d\u53d7\u652f\u6301",
    "selectWorld.backupQuestion.snapshot": "\u4f60\u771f\u7684\u60f3\u52a0\u8f7d\u6b64\u4e16\u754c\u5417\uff1f",
    "selectWorld.backupWarning.customized": "\u4e0d\u5de7\uff0c\u6211\u4eec\u5728\u8fd9\u4e2a\u7248\u672c\u7684Minecraft\u4e2d\u4e0d\u652f\u6301\u81ea\u5b9a\u4e49\u4e16\u754c\u3002\u6211\u4eec\u53ef\u4ee5\u7ee7\u7eed\u52a0\u8f7d\u8fd9\u4e2a\u4e16\u754c\u5e76\u4fdd\u6301\u539f\u72b6\uff0c\u4f46\u4efb\u4f55\u65b0\u751f\u6210\u7684\u5730\u5f62\u5c06\u4e0d\u518d\u88ab\u81ea\u5b9a\u4e49\u3002\u62b1\u6b49\u7ed9\u4f60\u5e26\u6765\u4e0d\u4fbf\u3002",
    "selectWorld.backupWarning.downgrade": "\u8fd9\u4e2a\u4e16\u754c\u4e0a\u6b21\u662f\u5728%s\u7248\u672c\u4e2d\u6e38\u73a9\u7684\uff1b\u4f60\u6b63\u5728\u6e38\u73a9\u7684\u7248\u672c\u4e3a%s\u3002\u964d\u4f4e\u4e16\u754c\u7684\u6e38\u620f\u7248\u672c\u53ef\u80fd\u4f1a\u5bfc\u81f4\u5b58\u6863\u635f\u574f\u2014\u2014\u6211\u4eec\u65e0\u6cd5\u4fdd\u8bc1\u5b83\u53ef\u4ee5\u88ab\u52a0\u8f7d\u548c\u8fd0\u884c\u3002\u5982\u679c\u4f60\u4ecd\u8981\u7ee7\u7eed\uff0c\u8bf7\u5907\u4efd\u8be5\u5b58\u6863\uff01",
    "selectWorld.backupWarning.experimental": "\u8fd9\u4e2a\u4e16\u754c\u4f7f\u7528\u7684\u5b9e\u9a8c\u6027\u8bbe\u7f6e\u53ef\u80fd\u4f1a\u968f\u65f6\u505c\u6b62\u8fd0\u4f5c\u3002\u6211\u4eec\u65e0\u6cd5\u4fdd\u8bc1\u8fd9\u4e9b\u8bbe\u7f6e\u5c06\u6765\u80fd\u591f\u52a0\u8f7d\u6216\u8fd0\u4f5c\u3002\u52a1\u5fc5\u8c28\u614e\uff01",
    "selectWorld.backupWarning.snapshot": "\u8fd9\u4e2a\u4e16\u754c\u4e0a\u6b21\u662f\u5728%s\u7248\u672c\u4e2d\u6e38\u73a9\u7684\uff0c\u4f60\u6b63\u5728\u6e38\u73a9%s\u7248\u672c\u3002\u8bf7\u5907\u4efd\u4f60\u7684\u4e16\u754c\uff0c\u4ee5\u514d\u4e16\u754c\u5d29\u6e83\uff01",
    "selectWorld.bonusItems": "\u5956\u52b1\u7bb1",
    "selectWorld.cheats": "\u4f5c\u5f0a",
    "selectWorld.conversion": "\u5fc5\u987b\u8fdb\u884c\u8f6c\u6362\uff01",
    "selectWorld.conversion.tooltip": "\u6b64\u4e16\u754c\u5fc5\u987b\u5728\u8f83\u65e7\u7248\u672c\uff08\u6bd4\u59821.6.4\uff09\u4e2d\u6253\u5f00\uff0c\u4ee5\u4fbf\u5b89\u5168\u8f6c\u6362",
    "selectWorld.create": "\u521b\u5efa\u65b0\u7684\u4e16\u754c",
    "selectWorld.createDemo": "\u8fdb\u5165\u65b0\u7684\u8bd5\u73a9\u4e16\u754c",
    "selectWorld.customizeType": "\u81ea\u5b9a\u4e49",
    "selectWorld.dataPacks": "\u6570\u636e\u5305",
    "selectWorld.data_read": "\u4e16\u754c\u6570\u636e\u8bfb\u53d6\u4e2d\u2026",
    "selectWorld.delete": "\u5220\u9664",
    "selectWorld.deleteButton": "\u5220\u9664",
    "selectWorld.deleteQuestion": "\u4f60\u786e\u5b9a\u8981\u5220\u9664\u8fd9\u4e2a\u4e16\u754c\u5417\uff1f",
    "selectWorld.deleteWarning": "\u201c%s\u201d\u5c06\u4f1a\u6c38\u4e45\u5931\u53bb\uff01\uff08\u771f\u7684\u5f88\u4e45\uff01\uff09",
    "selectWorld.delete_failure": "\u5220\u9664\u4e16\u754c\u5931\u8d25",
    "selectWorld.edit": "\u7f16\u8f91",
    "selectWorld.edit.backup": "\u8fdb\u884c\u5907\u4efd",
    "selectWorld.edit.backupCreated": "\u5df2\u5907\u4efd\uff1a%s",
    "selectWorld.edit.backupFailed": "\u5907\u4efd\u5931\u8d25",
    "selectWorld.edit.backupFolder": "\u6253\u5f00\u5907\u4efd\u6587\u4ef6\u5939",
    "selectWorld.edit.backupSize": "\u5927\u5c0f\uff1a%s MB",
    "selectWorld.edit.export_worldgen_settings": "\u5bfc\u51fa\u4e16\u754c\u751f\u6210\u8bbe\u7f6e",
    "selectWorld.edit.export_worldgen_settings.failure": "\u5bfc\u51fa\u5931\u8d25",
    "selectWorld.edit.export_worldgen_settings.success": "\u5bfc\u51fa\u6210\u529f",
    "selectWorld.edit.openFolder": "\u6253\u5f00\u4e16\u754c\u6587\u4ef6\u5939",
    "selectWorld.edit.optimize": "\u4f18\u5316\u4e16\u754c",
    "selectWorld.edit.resetIcon": "\u91cd\u7f6e\u56fe\u6807",
    "selectWorld.edit.save": "\u4fdd\u5b58",
    "selectWorld.edit.title": "\u7f16\u8f91\u4e16\u754c",
    "selectWorld.enterName": "\u4e16\u754c\u540d\u79f0",
    "selectWorld.enterSeed": "\u4e16\u754c\u751f\u6210\u5668\u7684\u79cd\u5b50",
    "selectWorld.futureworld.error.text": "\u5f53\u6211\u4eec\u8bd5\u56fe\u52a0\u8f7d\u4e00\u4efd\u6765\u81ea\u672a\u6765\u7248\u672c\u7684\u4e16\u754c\u65f6\uff0c\u53d1\u751f\u4e86\u4e00\u4e9b\u9519\u8bef\u3002\u8fd9\u672c\u5c31\u5e76\u975e\u4e07\u65e0\u4e00\u5931\uff0c\u5f88\u62b1\u6b49\u6ca1\u80fd\u6210\u529f\u3002",
    "selectWorld.futureworld.error.title": "\u51fa\u9519\u4e86\uff01",
    "selectWorld.gameMode": "\u6e38\u620f\u6a21\u5f0f",
    "selectWorld.gameMode.adventure": "\u5192\u9669",
    "selectWorld.gameMode.adventure.line1": "\u4e0e\u751f\u5b58\u6a21\u5f0f\u76f8\u540c\uff0c\u4f46\u662f\u65b9\u5757",
    "selectWorld.gameMode.adventure.line2": "\u4e0d\u80fd\u88ab\u6dfb\u52a0\u6216\u8005\u79fb\u9664",
    "selectWorld.gameMode.creative": "\u521b\u9020",
    "selectWorld.gameMode.creative.line1": "\u65e0\u9650\u7684\u8d44\u6e90\u3001\u81ea\u7531\u5730\u98de\u7fd4",
    "selectWorld.gameMode.creative.line2": "\u5e76\u4e14\u80fd\u591f\u77ac\u95f4\u7834\u574f\u65b9\u5757",
    "selectWorld.gameMode.hardcore": "\u6781\u9650",
    "selectWorld.gameMode.hardcore.line1": "\u96be\u5ea6\u9501\u5b9a\u5728\u56f0\u96be\u7684\u751f\u5b58\u6a21\u5f0f",
    "selectWorld.gameMode.hardcore.line2": "\u4e14\u53ea\u6709\u4e00\u6761\u751f\u547d",
    "selectWorld.gameMode.spectator": "\u65c1\u89c2",
    "selectWorld.gameMode.spectator.line1": "\u4f60\u53ef\u4ee5\u65c1\u89c2\uff0c\u4f46\u4e0d\u80fd\u4e92\u52a8",
    "selectWorld.gameMode.survival": "\u751f\u5b58",
    "selectWorld.gameMode.survival.line1": "\u63a2\u7d22\u4e16\u754c\u3001\u6536\u96c6\u8d44\u6e90\u3001\u5408\u6210\u9053\u5177\u3001",
    "selectWorld.gameMode.survival.line2": "\u63d0\u9ad8\u7b49\u7ea7\u3001\u8865\u5145\u4f53\u529b\u548c\u751f\u547d\u503c",
    "selectWorld.gameRules": "\u6e38\u620f\u89c4\u5219",
    "selectWorld.import_worldgen_settings": "\u5bfc\u5165\u8bbe\u7f6e",
    "selectWorld.import_worldgen_settings.deprecated.question": "\u4e00\u4e9b\u4f7f\u7528\u4e2d\u7684\u529f\u80fd\u5df2\u88ab\u6dd8\u6c70\u5e76\u4f1a\u5728\u5c06\u6765\u5931\u6548\u3002\u4f60\u786e\u5b9a\u8981\u7ee7\u7eed\u5417\uff1f",
    "selectWorld.import_worldgen_settings.deprecated.title": "\u8b66\u544a\uff01\u8fd9\u4e9b\u8bbe\u7f6e\u4f7f\u7528\u4e86\u5df2\u6dd8\u6c70\u7684\u529f\u80fd",
    "selectWorld.import_worldgen_settings.experimental.question": "\u8fd9\u4e9b\u8bbe\u7f6e\u662f\u5b9e\u9a8c\u6027\u7684\uff0c\u5c06\u6765\u53ef\u80fd\u505c\u6b62\u8fd0\u4f5c\u3002\u4f60\u786e\u5b9a\u8981\u7ee7\u7eed\u5417\uff1f",
    "selectWorld.import_worldgen_settings.experimental.title": "\u8b66\u544a\uff01\u8fd9\u4e9b\u8bbe\u7f6e\u4f7f\u7528\u5b9e\u9a8c\u6027\u529f\u80fd",
    "selectWorld.import_worldgen_settings.failure": "\u5bfc\u5165\u8bbe\u7f6e\u65f6\u51fa\u9519",
    "selectWorld.import_worldgen_settings.select_file": "\u9009\u62e9\u8bbe\u7f6e\u6587\u4ef6\uff08.json\uff09",
    "selectWorld.incompatible_series": "\u521b\u5efa\u4e8e\u4e0d\u517c\u5bb9\u7684\u7248\u672c",
    "selectWorld.load_folder_access": "\u65e0\u6cd5\u8bfb\u53d6\u6216\u8bbf\u95ee\u6e38\u620f\u4e16\u754c\u5b58\u6863\u6240\u5728\u7684\u6587\u4ef6\u5939\uff01",
    "selectWorld.locked": "\u88ab\u53e6\u4e00\u4e2a\u6b63\u5728\u8fd0\u884c\u7684Minecraft\u5b9e\u4f8b\u9501\u5b9a",
    "selectWorld.mapFeatures": "\u751f\u6210\u5efa\u7b51",
    "selectWorld.mapFeatures.info": "\u6751\u5e84\u3001\u5730\u7262\u7b49\u7b49",
    "selectWorld.mapType": "\u4e16\u754c\u7c7b\u578b",
    "selectWorld.mapType.normal": "\u666e\u901a",
    "selectWorld.moreWorldOptions": "\u66f4\u591a\u4e16\u754c\u7684\u9009\u9879\u2026",
    "selectWorld.newWorld": "\u65b0\u7684\u4e16\u754c",
    "selectWorld.recreate": "\u91cd\u5efa",
    "selectWorld.recreate.customized.text": "\u81ea\u5b9a\u4e49\u4e16\u754c\u5728\u8fd9\u4e2a\u7248\u672c\u7684Minecraft\u4e2d\u5df2\u4e0d\u53d7\u652f\u6301\u3002\u6211\u4eec\u53ef\u4ee5\u5c1d\u8bd5\u7528\u540c\u6837\u7684\u79cd\u5b50\u4e0e\u9009\u9879\u91cd\u5efa\u5b83\uff0c\u4f46\u4efb\u4f55\u81ea\u5b9a\u4e49\u7684\u5730\u5f62\u90fd\u4f1a\u4e22\u5931\u3002\u62b1\u6b49\u7ed9\u4f60\u5e26\u6765\u4e0d\u4fbf\u3002",
    "selectWorld.recreate.customized.title": "\u81ea\u5b9a\u4e49\u4e16\u754c\u5df2\u4e0d\u53d7\u652f\u6301",
    "selectWorld.recreate.error.text": "\u5c1d\u8bd5\u91cd\u5efa\u4e16\u754c\u65f6\u51fa\u9519\u3002",
    "selectWorld.recreate.error.title": "\u51fa\u9519\u4e86\uff01",
    "selectWorld.resultFolder": "\u5c06\u4f1a\u4fdd\u5b58\u4e8e\uff1a",
    "selectWorld.search": "\u641c\u7d22\u4e16\u754c",
    "selectWorld.seedInfo": "\u7559\u7a7a\u4ee5\u751f\u6210\u968f\u673a\u79cd\u5b50",
    "selectWorld.select": "\u8fdb\u5165\u9009\u4e2d\u7684\u4e16\u754c",
    "selectWorld.title": "\u9009\u62e9\u4e16\u754c",
    "selectWorld.tooltip.fromNewerVersion1": "\u4e16\u754c\u662f\u5728\u66f4\u65b0\u7684\u7248\u672c\u4e2d\u88ab\u4fdd\u5b58\u7684\uff0c",
    "selectWorld.tooltip.fromNewerVersion2": "\u52a0\u8f7d\u8fd9\u4e2a\u4e16\u754c\u53ef\u80fd\u4f1a\u4ea7\u751f\u95ee\u9898\uff01",
    "selectWorld.tooltip.snapshot1": "\u4f60\u5728\u8fd9\u4e2a\u5feb\u7167\u4e2d\u52a0\u8f7d\u5b83\u4e4b\u524d\uff0c",
    "selectWorld.tooltip.snapshot2": "\u4e0d\u8981\u5fd8\u4e86\u5907\u4efd\u8fd9\u4e2a\u4e16\u754c\uff01",
    "selectWorld.unable_to_load": "\u65e0\u6cd5\u52a0\u8f7d\u4e16\u754c",
    "selectWorld.version": "\u7248\u672c\uff1a",
    "selectWorld.versionJoinButton": "\u4ecd\u7136\u8f7d\u5165",
    "selectWorld.versionQuestion": "\u4f60\u771f\u7684\u60f3\u52a0\u8f7d\u6b64\u4e16\u754c\u5417\uff1f",
    "selectWorld.versionUnknown": "\u672a\u77e5",
    "selectWorld.versionWarning": "\u6b64\u4e16\u754c\u4e0a\u6b21\u662f\u5728%s\u7248\u672c\u4e2d\u6e38\u73a9\u7684\uff0c\u5728\u6b64\u7248\u672c\u4e2d\u52a0\u8f7d\u53ef\u80fd\u4f1a\u5bfc\u81f4\u6570\u636e\u635f\u574f\uff01",
    "selectWorld.world": "\u4e16\u754c",
    "sign.edit": "\u4fee\u6539\u544a\u793a\u724c\u6d88\u606f",
    "sleep.not_possible": "\u5df2\u5165\u7761\u73a9\u5bb6\u7684\u6570\u91cf\u4e0d\u8db3\u4ee5\u8df3\u8fc7\u591c\u665a",
    "sleep.players_sleeping": "%s/%s\u540d\u73a9\u5bb6\u5df2\u5165\u7761",
    "sleep.skipping_night": "\u4eca\u591c\u5c06\u5728\u7761\u68a6\u4e2d\u5ea6\u8fc7",
    "slot.unknown": "\u672a\u77e5\u7684\u680f\u4f4d'%s'",
    "soundCategory.ambient": "\u73af\u5883",
    "soundCategory.block": "\u65b9\u5757",
    "soundCategory.hostile": "\u654c\u5bf9\u751f\u7269",
    "soundCategory.master": "\u4e3b\u97f3\u91cf",
    "soundCategory.music": "\u97f3\u4e50",
    "soundCategory.neutral": "\u53cb\u597d\u751f\u7269",
    "soundCategory.player": "\u73a9\u5bb6",
    "soundCategory.record": "\u5531\u7247\u673a/\u97f3\u7b26\u76d2",
    "soundCategory.voice": "\u58f0\u97f3/\u8bed\u97f3",
    "soundCategory.weather": "\u5929\u6c14",
    "spectatorMenu.close": "\u5173\u95ed\u83dc\u5355",
    "spectatorMenu.next_page": "\u4e0b\u4e00\u9875",
    "spectatorMenu.previous_page": "\u4e0a\u4e00\u9875",
    "spectatorMenu.root.prompt": "\u6309\u4e0b\u4e00\u4e2a\u952e\u6765\u9009\u62e9\u547d\u4ee4\uff0c\u518d\u6309\u4e00\u6b21\u6765\u4f7f\u7528\u5b83\u3002",
    "spectatorMenu.team_teleport": "\u4f20\u9001\u5230\u961f\u4f0d\u6210\u5458",
    "spectatorMenu.team_teleport.prompt": "\u9009\u62e9\u4e00\u652f\u961f\u4f0d\u4f5c\u4e3a\u4f20\u9001\u76ee\u6807",
    "spectatorMenu.teleport": "\u4f20\u9001\u5230\u73a9\u5bb6",
    "spectatorMenu.teleport.prompt": "\u9009\u62e9\u4e00\u540d\u73a9\u5bb6\u4f5c\u4e3a\u4f20\u9001\u76ee\u6807",
    "stat.generalButton": "\u901a\u7528",
    "stat.itemsButton": "\u7269\u54c1",
    "stat.minecraft.animals_bred": "\u7e41\u6b96\u52a8\u7269\u6b21\u6570",
    "stat.minecraft.aviate_one_cm": "\u9798\u7fc5\u6ed1\u884c\u8ddd\u79bb",
    "stat.minecraft.bell_ring": "\u9e23\u949f\u6b21\u6570",
    "stat.minecraft.boat_one_cm": "\u5750\u8239\u79fb\u52a8\u8ddd\u79bb",
    "stat.minecraft.clean_armor": "\u6e05\u6d17\u76d4\u7532\u6b21\u6570",
    "stat.minecraft.clean_banner": "\u6e05\u6d17\u65d7\u5e1c\u6b21\u6570",
    "stat.minecraft.clean_shulker_box": "\u6f5c\u5f71\u76d2\u6e05\u6d17\u6b21\u6570",
    "stat.minecraft.climb_one_cm": "\u5df2\u6500\u722c\u8ddd\u79bb",
    "stat.minecraft.crouch_one_cm": "\u6f5c\u884c\u8ddd\u79bb",
    "stat.minecraft.damage_absorbed": "\u5438\u6536\u7684\u4f24\u5bb3",
    "stat.minecraft.damage_blocked_by_shield": "\u76fe\u724c\u62b5\u6321\u7684\u4f24\u5bb3",
    "stat.minecraft.damage_dealt": "\u9020\u6210\u4f24\u5bb3",
    "stat.minecraft.damage_dealt_absorbed": "\u9020\u6210\u4f24\u5bb3\uff08\u88ab\u5438\u6536\uff09",
    "stat.minecraft.damage_dealt_resisted": "\u9020\u6210\u4f24\u5bb3\uff08\u88ab\u62b5\u6321\uff09",
    "stat.minecraft.damage_resisted": "\u62b5\u6321\u7684\u4f24\u5bb3",
    "stat.minecraft.damage_taken": "\u53d7\u5230\u4f24\u5bb3",
    "stat.minecraft.deaths": "\u6b7b\u4ea1\u6b21\u6570",
    "stat.minecraft.drop": "\u7269\u54c1\u6389\u843d",
    "stat.minecraft.eat_cake_slice": "\u5403\u6389\u7684\u86cb\u7cd5\u7247\u6570",
    "stat.minecraft.enchant_item": "\u7269\u54c1\u9644\u9b54\u6b21\u6570",
    "stat.minecraft.fall_one_cm": "\u6454\u843d\u9ad8\u5ea6",
    "stat.minecraft.fill_cauldron": "\u70bc\u836f\u9505\u88c5\u6c34\u6b21\u6570",
    "stat.minecraft.fish_caught": "\u6355\u9c7c\u6570",
    "stat.minecraft.fly_one_cm": "\u98de\u884c\u8ddd\u79bb",
    "stat.minecraft.horse_one_cm": "\u9a91\u9a6c\u79fb\u52a8\u8ddd\u79bb",
    "stat.minecraft.inspect_dispenser": "\u641c\u67e5\u53d1\u5c04\u5668\u6b21\u6570",
    "stat.minecraft.inspect_dropper": "\u641c\u67e5\u6295\u63b7\u5668\u6b21\u6570",
    "stat.minecraft.inspect_hopper": "\u641c\u67e5\u6f0f\u6597\u6b21\u6570",
    "stat.minecraft.interact_with_anvil": "\u4e0e\u94c1\u7827\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_beacon": "\u4e0e\u4fe1\u6807\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_blast_furnace": "\u4e0e\u9ad8\u7089\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_brewingstand": "\u4e0e\u917f\u9020\u53f0\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_campfire": "\u4e0e\u8425\u706b\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_cartography_table": "\u4e0e\u5236\u56fe\u53f0\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_crafting_table": "\u4e0e\u5de5\u4f5c\u53f0\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_furnace": "\u4e0e\u7194\u7089\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_grindstone": "\u4e0e\u7802\u8f6e\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_lectern": "\u4e0e\u8bb2\u53f0\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_loom": "\u4e0e\u7ec7\u5e03\u673a\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_smithing_table": "\u4e0e\u953b\u9020\u53f0\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_smoker": "\u4e0e\u70df\u718f\u7089\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.interact_with_stonecutter": "\u4e0e\u5207\u77f3\u673a\u4e92\u52a8\u6b21\u6570",
    "stat.minecraft.jump": "\u8df3\u8dc3\u6b21\u6570",
    "stat.minecraft.junk_fished": "\u9493\u5230\u5783\u573e\u6b21\u6570",
    "stat.minecraft.leave_game": "\u6e38\u620f\u9000\u51fa\u6b21\u6570",
    "stat.minecraft.minecart_one_cm": "\u5750\u77ff\u8f66\u79fb\u52a8\u8ddd\u79bb",
    "stat.minecraft.mob_kills": "\u751f\u7269\u51fb\u6740\u6570",
    "stat.minecraft.open_barrel": "\u6728\u6876\u6253\u5f00\u6b21\u6570",
    "stat.minecraft.open_chest": "\u7bb1\u5b50\u6253\u5f00\u6b21\u6570",
    "stat.minecraft.open_enderchest": "\u672b\u5f71\u7bb1\u6253\u5f00\u6b21\u6570",
    "stat.minecraft.open_shulker_box": "\u6f5c\u5f71\u76d2\u6253\u5f00\u6b21\u6570",
    "stat.minecraft.pig_one_cm": "\u9a91\u732a\u79fb\u52a8\u8ddd\u79bb",
    "stat.minecraft.play_noteblock": "\u97f3\u7b26\u76d2\u64ad\u653e\u6b21\u6570",
    "stat.minecraft.play_record": "\u64ad\u653e\u5531\u7247\u6570",
    "stat.minecraft.play_time": "\u6e38\u73a9\u65f6\u957f",
    "stat.minecraft.player_kills": "\u73a9\u5bb6\u51fb\u6740\u6570",
    "stat.minecraft.pot_flower": "\u76c6\u683d\u79cd\u690d\u6570",
    "stat.minecraft.raid_trigger": "\u89e6\u53d1\u88ad\u51fb\u6b21\u6570",
    "stat.minecraft.raid_win": "\u88ad\u51fb\u80dc\u5229\u6b21\u6570",
    "stat.minecraft.ring_bell": "\u9e23\u949f\u6b21\u6570",
    "stat.minecraft.sleep_in_bed": "\u8eba\u5728\u5e8a\u4e0a\u7684\u6b21\u6570",
    "stat.minecraft.sneak_time": "\u6f5c\u884c\u65f6\u95f4",
    "stat.minecraft.sprint_one_cm": "\u75be\u8dd1\u8ddd\u79bb",
    "stat.minecraft.strider_one_cm": "\u9a91\u70bd\u8db3\u517d\u79fb\u52a8\u8ddd\u79bb",
    "stat.minecraft.swim_one_cm": "\u6e38\u6cf3\u8ddd\u79bb",
    "stat.minecraft.talked_to_villager": "\u6751\u6c11\u4ea4\u4e92\u6b21\u6570",
    "stat.minecraft.target_hit": "\u51fb\u4e2d\u6807\u9776\u6b21\u6570",
    "stat.minecraft.time_since_death": "\u81ea\u4e0a\u6b21\u6b7b\u4ea1",
    "stat.minecraft.time_since_rest": "\u81ea\u4e0a\u6b21\u5165\u7720",
    "stat.minecraft.total_world_time": "\u4e16\u754c\u6253\u5f00\u65f6\u95f4",
    "stat.minecraft.traded_with_villager": "\u6751\u6c11\u4ea4\u6613\u6b21\u6570",
    "stat.minecraft.treasure_fished": "\u9493\u5230\u5b9d\u85cf\u6b21\u6570",
    "stat.minecraft.trigger_trapped_chest": "\u9677\u9631\u7bb1\u89e6\u53d1\u6b21\u6570",
    "stat.minecraft.tune_noteblock": "\u97f3\u7b26\u76d2\u8c03\u97f3\u6b21\u6570",
    "stat.minecraft.use_cauldron": "\u4ece\u70bc\u836f\u9505\u53d6\u6c34\u6b21\u6570",
    "stat.minecraft.walk_on_water_one_cm": "\u6c34\u9762\u884c\u8d70\u8ddd\u79bb",
    "stat.minecraft.walk_one_cm": "\u884c\u8d70\u8ddd\u79bb",
    "stat.minecraft.walk_under_water_one_cm": "\u6c34\u4e0b\u884c\u8d70\u8ddd\u79bb",
    "stat.mobsButton": "\u751f\u7269",
    "stat_type.minecraft.broken": "\u635f\u574f\u6b21\u6570",
    "stat_type.minecraft.crafted": "\u5408\u6210\u6b21\u6570",
    "stat_type.minecraft.dropped": "\u4e22\u5f03\u4e2a\u6570",
    "stat_type.minecraft.killed": "\u4f60\u6740\u6b7b\u4e86%s\u53ea%s",
    "stat_type.minecraft.killed.none": "\u4f60\u4ece\u6765\u6ca1\u6709\u6740\u6b7b\u8fc7%s",
    "stat_type.minecraft.killed_by": "%s\u6740\u6b7b\u4e86\u4f60%s\u6b21",
    "stat_type.minecraft.killed_by.none": "\u4f60\u4ece\u6765\u6ca1\u6709\u88ab%s\u6740\u6b7b\u8fc7",
    "stat_type.minecraft.mined": "\u5f00\u91c7\u6b21\u6570",
    "stat_type.minecraft.picked_up": "\u62fe\u8d77\u4e2a\u6570",
    "stat_type.minecraft.used": "\u4f7f\u7528\u6b21\u6570",
    "stats.tooltip.type.statistic": "\u7edf\u8ba1",
    "structure_block.button.detect_size": "\u63a2\u6d4b",
    "structure_block.button.load": "\u52a0\u8f7d",
    "structure_block.button.save": "\u4fdd\u5b58",
    "structure_block.custom_data": "\u81ea\u5b9a\u4e49\u6570\u636e\u6807\u7b7e\u540d",
    "structure_block.detect_size": "\u63a2\u6d4b\u7ed3\u6784\u5927\u5c0f\u548c\u4f4d\u7f6e\uff1a",
    "structure_block.hover.corner": "\u89d2\u843d\uff1a%s",
    "structure_block.hover.data": "\u6570\u636e\uff1a%s",
    "structure_block.hover.load": "\u52a0\u8f7d\uff1a%s",
    "structure_block.hover.save": "\u4fdd\u5b58\uff1a%s",
    "structure_block.include_entities": "\u5305\u62ec\u5b9e\u4f53\uff1a",
    "structure_block.integrity": "\u7ed3\u6784\u5b8c\u6574\u6027\u53ca\u79cd\u5b50",
    "structure_block.integrity.integrity": "\u7ed3\u6784\u5b8c\u6574\u6027",
    "structure_block.integrity.seed": "\u7ed3\u6784\u79cd\u5b50",
    "structure_block.invalid_structure_name": "\u65e0\u6548\u7684\u7ed3\u6784\u540d'%s'",
    "structure_block.load_not_found": "\u4e0d\u5b58\u5728\u540d\u4e3a'%s'\u7684\u7ed3\u6784 ",
    "structure_block.load_prepare": "\u7ed3\u6784'%s'\u7684\u52a0\u8f7d\u4f4d\u7f6e\u5df2\u5c31\u7eea",
    "structure_block.load_success": "\u6210\u529f\u4ece'%s'\u4e2d\u52a0\u8f7d\u7ed3\u6784",
    "structure_block.mode.corner": "\u89d2\u843d\u6a21\u5f0f",
    "structure_block.mode.data": "\u6570\u636e\u6a21\u5f0f",
    "structure_block.mode.load": "\u52a0\u8f7d\u6a21\u5f0f",
    "structure_block.mode.save": "\u50a8\u5b58\u6a21\u5f0f",
    "structure_block.mode_info.corner": "\u89d2\u843d\u6a21\u5f0f \u2014 \u4f4d\u7f6e\u548c\u5927\u5c0f\u6807\u8bb0",
    "structure_block.mode_info.data": "\u6570\u636e\u6a21\u5f0f \u2014 \u6e38\u620f\u903b\u8f91\u6807\u8bb0",
    "structure_block.mode_info.load": "\u52a0\u8f7d\u6a21\u5f0f \u2014 \u4ece\u6587\u4ef6\u4e2d\u52a0\u8f7d",
    "structure_block.mode_info.save": "\u50a8\u5b58\u6a21\u5f0f \u2014 \u5199\u5165\u6587\u4ef6",
    "structure_block.position": "\u76f8\u5bf9\u4f4d\u7f6e",
    "structure_block.position.x": "\u76f8\u5bf9X\u5750\u6807",
    "structure_block.position.y": "\u76f8\u5bf9Y\u5750\u6807",
    "structure_block.position.z": "\u76f8\u5bf9Z\u5750\u6807",
    "structure_block.save_failure": "\u65e0\u6cd5\u4fdd\u5b58\u7ed3\u6784'%s'",
    "structure_block.save_success": "\u6210\u529f\u5c06\u7ed3\u6784\u4fdd\u5b58\u4e3a'%s'",
    "structure_block.show_air": "\u663e\u793a\u9690\u5f62\u65b9\u5757\uff1a",
    "structure_block.show_boundingbox": "\u663e\u793a\u8fb9\u6846\uff1a",
    "structure_block.size": "\u7ed3\u6784\u5927\u5c0f",
    "structure_block.size.x": "\u7ed3\u6784X\u8f74\u5927\u5c0f",
    "structure_block.size.y": "\u7ed3\u6784Y\u8f74\u5927\u5c0f",
    "structure_block.size.z": "\u7ed3\u6784Z\u8f74\u5927\u5c0f",
    "structure_block.size_failure": "\u65e0\u6cd5\u68c0\u6d4b\u7ed3\u6784\u5c3a\u5bf8\u3002\u8bf7\u653e\u7f6e\u4e0e\u7ed3\u6784\u540d\u79f0\u5bf9\u5e94\u7684\u89d2\u843d\u7ed3\u6784\u65b9\u5757",
    "structure_block.size_success": "'%s'\u7684\u5c3a\u5bf8\u5df2\u6210\u529f\u68c0\u6d4b",
    "structure_block.structure_name": "\u7ed3\u6784\u540d\u79f0",
    "subtitles.ambient.cave": "\u602a\u5f02\u7684\u566a\u58f0",
    "subtitles.block.amethyst_block.chime": "\u7d2b\u6c34\u6676\uff1a\u53ee\u94c3",
    "subtitles.block.anvil.destroy": "\u94c1\u7827\uff1a\u88ab\u6bc1",
    "subtitles.block.anvil.land": "\u94c1\u7827\uff1a\u7740\u5730",
    "subtitles.block.anvil.use": "\u94c1\u7827\uff1a\u4f7f\u7528",
    "subtitles.block.barrel.close": "\u6728\u6876\uff1a\u5173\u95ed",
    "subtitles.block.barrel.open": "\u6728\u6876\uff1a\u6253\u5f00",
    "subtitles.block.beacon.activate": "\u4fe1\u6807\uff1a\u6fc0\u6d3b",
    "subtitles.block.beacon.ambient": "\u4fe1\u6807\uff1a\u55e1\u55e1\u4f5c\u54cd",
    "subtitles.block.beacon.deactivate": "\u4fe1\u6807\uff1a\u5931\u6548",
    "subtitles.block.beacon.power_select": "\u4fe1\u6807\uff1a\u9009\u62e9\u6548\u679c",
    "subtitles.block.beehive.drip": "\u8702\u871c\uff1a\u6ef4\u843d",
    "subtitles.block.beehive.enter": "\u871c\u8702\uff1a\u5165\u5de2",
    "subtitles.block.beehive.exit": "\u871c\u8702\uff1a\u79bb\u5de2",
    "subtitles.block.beehive.shear": "\u526a\u5200\uff1a\u522e\u524a",
    "subtitles.block.beehive.work": "\u871c\u8702\uff1a\u5de5\u4f5c",
    "subtitles.block.bell.resonate": "\u949f\uff1a\u56de\u54cd",
    "subtitles.block.bell.use": "\u949f\uff1a\u54cd\u8d77",
    "subtitles.block.big_dripleaf.tilt_down": "\u5782\u6ef4\u53f6\uff1a\u6298\u4e0b",
    "subtitles.block.big_dripleaf.tilt_up": "\u5782\u6ef4\u53f6\uff1a\u5347\u8d77",
    "subtitles.block.blastfurnace.fire_crackle": "\u9ad8\u7089\uff1a\u567c\u556a\u4f5c\u54cd",
    "subtitles.block.brewing_stand.brew": "\u917f\u9020\u53f0\uff1a\u5192\u6ce1",
    "subtitles.block.bubble_column.bubble_pop": "\u6c14\u6ce1\uff1a\u7834\u88c2",
    "subtitles.block.bubble_column.upwards_ambient": "\u6c14\u6ce1\uff1a\u4e0a\u6d6e",
    "subtitles.block.bubble_column.upwards_inside": "\u6c14\u6ce1\uff1a\u98de\u5347",
    "subtitles.block.bubble_column.whirlpool_ambient": "\u6c14\u6ce1\uff1a\u65cb\u8f6c",
    "subtitles.block.bubble_column.whirlpool_inside": "\u6c14\u6ce1\uff1a\u9aa4\u964d",
    "subtitles.block.button.click": "\u6309\u94ae\uff1a\u5494\u54d2",
    "subtitles.block.cake.add_candle": "\u86cb\u7cd5\uff1a\u5427\u5527",
    "subtitles.block.campfire.crackle": "\u8425\u706b\uff1a\u567c\u556a\u4f5c\u54cd",
    "subtitles.block.candle.crackle": "\u8721\u70db\uff1a\u567c\u556a\u4f5c\u54cd",
    "subtitles.block.chest.close": "\u7bb1\u5b50\uff1a\u5173\u95ed",
    "subtitles.block.chest.locked": "\u7bb1\u5b50\uff1a\u9501\u4e0a",
    "subtitles.block.chest.open": "\u7bb1\u5b50\uff1a\u5f00\u542f",
    "subtitles.block.chorus_flower.death": "\u7d2b\u9882\u82b1\uff1a\u51cb\u96f6",
    "subtitles.block.chorus_flower.grow": "\u7d2b\u9882\u82b1\uff1a\u751f\u957f",
    "subtitles.block.comparator.click": "\u6bd4\u8f83\u5668\uff1a\u6a21\u5f0f\u53d8\u66f4",
    "subtitles.block.composter.empty": "\u5806\u80a5\u6876\uff1a\u6e05\u7a7a",
    "subtitles.block.composter.fill": "\u5806\u80a5\u6876\uff1a\u586b\u5145",
    "subtitles.block.composter.ready": "\u5806\u80a5\u6876\uff1a\u5806\u80a5",
    "subtitles.block.conduit.activate": "\u6f6e\u6d8c\u6838\u5fc3\uff1a\u6fc0\u6d3b",
    "subtitles.block.conduit.ambient": "\u6f6e\u6d8c\u6838\u5fc3\uff1a\u6d8c\u52a8",
    "subtitles.block.conduit.attack.target": "\u6f6e\u6d8c\u6838\u5fc3\uff1a\u653b\u51fb",
    "subtitles.block.conduit.deactivate": "\u6f6e\u6d8c\u6838\u5fc3\uff1a\u5931\u6548",
    "subtitles.block.dispenser.dispense": "\u53d1\u5c04\u5668\uff1a\u53d1\u5c04\u7269\u54c1",
    "subtitles.block.dispenser.fail": "\u53d1\u5c04\u5668\uff1a\u53d1\u5c04\u5931\u8d25",
    "subtitles.block.door.toggle": "\u95e8\uff1a\u560e\u5431\u4f5c\u54cd",
    "subtitles.block.enchantment_table.use": "\u9644\u9b54\u53f0\uff1a\u4f7f\u7528",
    "subtitles.block.end_portal.spawn": "\u672b\u5730\u4f20\u9001\u95e8\uff1a\u5f00\u542f",
    "subtitles.block.end_portal_frame.fill": "\u672b\u5f71\u4e4b\u773c\uff1a\u5d4c\u5165",
    "subtitles.block.fence_gate.toggle": "\u6805\u680f\u95e8\uff1a\u560e\u5431\u4f5c\u54cd",
    "subtitles.block.fire.ambient": "\u706b\uff1a\u567c\u556a\u4f5c\u54cd",
    "subtitles.block.fire.extinguish": "\u706b\uff1a\u7184\u706d",
    "subtitles.block.furnace.fire_crackle": "\u7194\u7089\uff1a\u567c\u556a\u4f5c\u54cd",
    "subtitles.block.generic.break": "\u65b9\u5757\uff1a\u88ab\u7834\u574f",
    "subtitles.block.generic.footsteps": "\u811a\u6b65\u58f0",
    "subtitles.block.generic.hit": "\u65b9\u5757\uff1a\u635f\u574f\u4e2d",
    "subtitles.block.generic.place": "\u65b9\u5757\uff1a\u88ab\u653e\u7f6e",
    "subtitles.block.grindstone.use": "\u7802\u8f6e\uff1a\u4f7f\u7528",
    "subtitles.block.growing_plant.crop": "\u690d\u7269\uff1a\u88ab\u4fee\u526a",
    "subtitles.block.honey_block.slide": "\u4ece\u8702\u871c\u5757\u6ed1\u4e0b",
    "subtitles.block.iron_trapdoor.close": "\u6d3b\u677f\u95e8\uff1a\u5173\u95ed",
    "subtitles.block.iron_trapdoor.open": "\u6d3b\u677f\u95e8\uff1a\u6253\u5f00",
    "subtitles.block.lava.ambient": "\u7194\u5ca9\uff1a\u8ff8\u88c2",
    "subtitles.block.lava.extinguish": "\u7194\u5ca9\uff1a\u5636\u5636\u58f0",
    "subtitles.block.lever.click": "\u62c9\u6746\uff1a\u62c9\u52a8",
    "subtitles.block.note_block.note": "\u97f3\u7b26\u76d2\uff1a\u64ad\u653e",
    "subtitles.block.piston.move": "\u6d3b\u585e\uff1a\u79fb\u52a8",
    "subtitles.block.pointed_dripstone.drip_lava": "\u7194\u5ca9\uff1a\u6ef4\u843d",
    "subtitles.block.pointed_dripstone.drip_lava_into_cauldron": "\u7194\u5ca9\uff1a\u6ef4\u5165\u70bc\u836f\u9505",
    "subtitles.block.pointed_dripstone.drip_water": "\u6c34\uff1a\u6ef4\u843d",
    "subtitles.block.pointed_dripstone.drip_water_into_cauldron": "\u6c34\uff1a\u6ef4\u5165\u70bc\u836f\u9505",
    "subtitles.block.pointed_dripstone.land": "\u949f\u4e73\u77f3\uff1a\u584c\u843d",
    "subtitles.block.portal.ambient": "\u4f20\u9001\u95e8\uff1a\u547c\u5578",
    "subtitles.block.portal.travel": "\u4f20\u9001\u95e8\uff1a\u566a\u58f0\u6d88\u6563",
    "subtitles.block.portal.trigger": "\u4f20\u9001\u95e8\uff1a\u566a\u58f0\u6e10\u54cd",
    "subtitles.block.pressure_plate.click": "\u538b\u529b\u677f\uff1a\u5494\u54d2",
    "subtitles.block.pumpkin.carve": "\u526a\u5200\uff1a\u96d5\u523b",
    "subtitles.block.redstone_torch.burnout": "\u7ea2\u77f3\u706b\u628a\uff1a\u7184\u706d",
    "subtitles.block.respawn_anchor.ambient": "\u4f20\u9001\u95e8\uff1a\u547c\u5578",
    "subtitles.block.respawn_anchor.charge": "\u91cd\u751f\u951a\uff1a\u5145\u80fd",
    "subtitles.block.respawn_anchor.deplete": "\u91cd\u751f\u951a\uff1a\u8017\u80fd",
    "subtitles.block.respawn_anchor.set_spawn": "\u91cd\u751f\u951a\uff1a\u8bbe\u7f6e\u51fa\u751f\u70b9",
    "subtitles.block.sculk_sensor.clicking": "\u6f5c\u58f0\u4f20\u611f\u5668\uff1a\u5494\u54d2",
    "subtitles.block.sculk_sensor.clicking_stop": "\u6f5c\u58f0\u4f20\u611f\u5668\uff1a\u505c\u606f",
    "subtitles.block.shulker_box.close": "\u6f5c\u5f71\u76d2\uff1a\u5173\u95ed",
    "subtitles.block.shulker_box.open": "\u6f5c\u5f71\u76d2\uff1a\u5f00\u542f",
    "subtitles.block.smithing_table.use": "\u953b\u9020\u53f0\uff1a\u4f7f\u7528",
    "subtitles.block.smoker.smoke": "\u70df\u718f\u7089\uff1a\u70df\u718f",
    "subtitles.block.sweet_berry_bush.pick_berries": "\u6d46\u679c\uff1a\u5f39\u51fa",
    "subtitles.block.trapdoor.toggle": "\u6d3b\u677f\u95e8\uff1a\u560e\u5431\u4f5c\u54cd",
    "subtitles.block.tripwire.attach": "\u7eca\u7ebf\u94a9\uff1a\u8fde\u63a5",
    "subtitles.block.tripwire.click": "\u7eca\u7ebf\u94a9\uff1a\u5494\u54d2",
    "subtitles.block.tripwire.detach": "\u7eca\u7ebf\u94a9\uff1a\u65ad\u5f00",
    "subtitles.block.water.ambient": "\u6c34\uff1a\u6d41\u52a8",
    "subtitles.enchant.thorns.hit": "\u8346\u68d8\uff1a\u53cd\u523a",
    "subtitles.entity.armor_stand.fall": "\u67d0\u7269\uff1a\u7740\u5730",
    "subtitles.entity.arrow.hit": "\u7bad\uff1a\u51fb\u4e2d",
    "subtitles.entity.arrow.hit_player": "\u7bad\uff1a\u51fb\u4e2d\u73a9\u5bb6",
    "subtitles.entity.arrow.shoot": "\u7bad\uff1a\u88ab\u5c04\u51fa",
    "subtitles.entity.axolotl.attack": "\u7f8e\u897f\u8788\uff1a\u653b\u51fb",
    "subtitles.entity.axolotl.death": "\u7f8e\u897f\u8788\uff1a\u6b7b\u4ea1",
    "subtitles.entity.axolotl.hurt": "\u7f8e\u897f\u8788\uff1a\u53d7\u4f24",
    "subtitles.entity.axolotl.idle_air": "\u7f8e\u897f\u8788\uff1a\u557e\u557e",
    "subtitles.entity.axolotl.idle_water": "\u7f8e\u897f\u8788\uff1a\u557e\u557e",
    "subtitles.entity.axolotl.splash": "\u7f8e\u897f\u8788\uff1a\u6e85\u8d77\u6c34\u82b1",
    "subtitles.entity.axolotl.swim": "\u7f8e\u897f\u8788\uff1a\u6e38\u6cf3",
    "subtitles.entity.bat.ambient": "\u8759\u8760\uff1a\u5c16\u58f0\u53eb",
    "subtitles.entity.bat.death": "\u8759\u8760\uff1a\u6b7b\u4ea1",
    "subtitles.entity.bat.hurt": "\u8759\u8760\uff1a\u53d7\u4f24",
    "subtitles.entity.bat.takeoff": "\u8759\u8760\uff1a\u8d77\u98de",
    "subtitles.entity.bee.ambient": "\u871c\u8702\uff1a\u55e1\u55e1",
    "subtitles.entity.bee.death": "\u871c\u8702\uff1a\u6b7b\u4ea1",
    "subtitles.entity.bee.hurt": "\u871c\u8702\uff1a\u53d7\u4f24",
    "subtitles.entity.bee.loop": "\u871c\u8702\uff1a\u55e1\u55e1",
    "subtitles.entity.bee.loop_aggressive": "\u871c\u8702\uff1a\u6124\u6012\u5730\u55e1\u55e1\u53eb",
    "subtitles.entity.bee.pollinate": "\u871c\u8702\uff1a\u9ad8\u5174\u5730\u55e1\u55e1\u53eb",
    "subtitles.entity.bee.sting": "\u871c\u8702\uff1a\u86f0",
    "subtitles.entity.blaze.ambient": "\u70c8\u7130\u4eba\uff1a\u547c\u5438",
    "subtitles.entity.blaze.burn": "\u70c8\u7130\u4eba\uff1a\u567c\u556a\u4f5c\u54cd",
    "subtitles.entity.blaze.death": "\u70c8\u7130\u4eba\uff1a\u6b7b\u4ea1",
    "subtitles.entity.blaze.hurt": "\u70c8\u7130\u4eba\uff1a\u53d7\u4f24",
    "subtitles.entity.blaze.shoot": "\u70c8\u7130\u4eba\uff1a\u5c04\u51fb",
    "subtitles.entity.boat.paddle_land": "\u5212\u8239",
    "subtitles.entity.boat.paddle_water": "\u5212\u8239",
    "subtitles.entity.cat.ambient": "\u732b\uff1a\u55b5~",
    "subtitles.entity.cat.beg_for_food": "\u732b\uff1a\u6c42\u98df",
    "subtitles.entity.cat.death": "\u732b\uff1a\u6b7b\u4ea1",
    "subtitles.entity.cat.eat": "\u732b\uff1a\u8fdb\u98df",
    "subtitles.entity.cat.hiss": "\u732b\uff1a\u5636\u5636\u58f0",
    "subtitles.entity.cat.hurt": "\u732b\uff1a\u53d7\u4f24",
    "subtitles.entity.cat.purr": "\u732b\uff1a\u547c\u565c\u58f0",
    "subtitles.entity.chicken.ambient": "\u9e21\uff1a\u54af\u54af\u53eb",
    "subtitles.entity.chicken.death": "\u9e21\uff1a\u6b7b\u4ea1",
    "subtitles.entity.chicken.egg": "\u9e21\uff1a\u4e0b\u86cb",
    "subtitles.entity.chicken.hurt": "\u9e21\uff1a\u53d7\u4f24",
    "subtitles.entity.cod.death": "\u9cd5\u9c7c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.cod.flop": "\u9cd5\u9c7c\uff1a\u6251\u817e",
    "subtitles.entity.cod.hurt": "\u9cd5\u9c7c\uff1a\u53d7\u4f24",
    "subtitles.entity.cow.ambient": "\u725b\uff1a\u54de~",
    "subtitles.entity.cow.death": "\u725b\uff1a\u6b7b\u4ea1",
    "subtitles.entity.cow.hurt": "\u725b\uff1a\u53d7\u4f24",
    "subtitles.entity.cow.milk": "\u725b\uff1a\u88ab\u6324\u5976",
    "subtitles.entity.creeper.death": "\u82e6\u529b\u6015\uff1a\u6b7b\u4ea1",
    "subtitles.entity.creeper.hurt": "\u82e6\u529b\u6015\uff1a\u53d7\u4f24",
    "subtitles.entity.creeper.primed": "\u82e6\u529b\u6015\uff1a\u5636~",
    "subtitles.entity.dolphin.ambient": "\u6d77\u8c5a\uff1a\u557e\u557e",
    "subtitles.entity.dolphin.ambient_water": "\u6d77\u8c5a\uff1a\u5439\u53e3\u54e8",
    "subtitles.entity.dolphin.attack": "\u6d77\u8c5a\uff1a\u653b\u51fb",
    "subtitles.entity.dolphin.death": "\u6d77\u8c5a\uff1a\u6b7b\u4ea1",
    "subtitles.entity.dolphin.eat": "\u6d77\u8c5a\uff1a\u8fdb\u98df",
    "subtitles.entity.dolphin.hurt": "\u6d77\u8c5a\uff1a\u53d7\u4f24",
    "subtitles.entity.dolphin.jump": "\u6d77\u8c5a\uff1a\u8dc3\u8d77",
    "subtitles.entity.dolphin.play": "\u6d77\u8c5a\uff1a\u5b09\u620f",
    "subtitles.entity.dolphin.splash": "\u6d77\u8c5a\uff1a\u6e85\u8d77\u6c34\u82b1",
    "subtitles.entity.dolphin.swim": "\u6d77\u8c5a\uff1a\u6e38\u6cf3",
    "subtitles.entity.donkey.ambient": "\u9a74\uff1a\u5636\u53eb",
    "subtitles.entity.donkey.angry": "\u9a74\uff1a\u5636\u53eb",
    "subtitles.entity.donkey.chest": "\u9a74\uff1a\u88c5\u5907\u7bb1\u5b50",
    "subtitles.entity.donkey.death": "\u9a74\uff1a\u6b7b\u4ea1",
    "subtitles.entity.donkey.eat": "\u9a74\uff1a\u8fdb\u98df",
    "subtitles.entity.donkey.hurt": "\u9a74\uff1a\u53d7\u4f24",
    "subtitles.entity.drowned.ambient": "\u6eba\u5c38\uff1a\u547b\u541f",
    "subtitles.entity.drowned.ambient_water": "\u6eba\u5c38\uff1a\u547b\u541f",
    "subtitles.entity.drowned.death": "\u6eba\u5c38\uff1a\u6b7b\u4ea1",
    "subtitles.entity.drowned.hurt": "\u6eba\u5c38\uff1a\u53d7\u4f24",
    "subtitles.entity.drowned.shoot": "\u6eba\u5c38\uff1a\u6295\u63b7\u4e09\u53c9\u621f",
    "subtitles.entity.drowned.step": "\u6eba\u5c38\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.drowned.swim": "\u6eba\u5c38\uff1a\u6e38\u6cf3",
    "subtitles.entity.egg.throw": "\u86cb\uff1a\u98de\u51fa",
    "subtitles.entity.elder_guardian.ambient": "\u8fdc\u53e4\u5b88\u536b\u8005\uff1a\u4f4e\u9e23",
    "subtitles.entity.elder_guardian.ambient_land": "\u8fdc\u53e4\u5b88\u536b\u8005\uff1a\u5f39\u8df3",
    "subtitles.entity.elder_guardian.curse": "\u8fdc\u53e4\u5b88\u536b\u8005\uff1a\u8bc5\u5492",
    "subtitles.entity.elder_guardian.death": "\u8fdc\u53e4\u5b88\u536b\u8005\uff1a\u6b7b\u4ea1",
    "subtitles.entity.elder_guardian.flop": "\u8fdc\u53e4\u5b88\u536b\u8005\uff1a\u843d\u5730",
    "subtitles.entity.elder_guardian.hurt": "\u8fdc\u53e4\u5b88\u536b\u8005\uff1a\u53d7\u4f24",
    "subtitles.entity.ender_dragon.ambient": "\u672b\u5f71\u9f99\uff1a\u5486\u54ee",
    "subtitles.entity.ender_dragon.death": "\u672b\u5f71\u9f99\uff1a\u6b7b\u4ea1",
    "subtitles.entity.ender_dragon.flap": "\u672b\u5f71\u9f99\uff1a\u62cd\u6253\u7fc5\u8180",
    "subtitles.entity.ender_dragon.growl": "\u672b\u5f71\u9f99\uff1a\u543c\u53eb",
    "subtitles.entity.ender_dragon.hurt": "\u672b\u5f71\u9f99\uff1a\u53d7\u4f24",
    "subtitles.entity.ender_dragon.shoot": "\u672b\u5f71\u9f99\uff1a\u5c04\u51fb",
    "subtitles.entity.ender_eye.death": "\u672b\u5f71\u4e4b\u773c\uff1a\u6389\u843d",
    "subtitles.entity.ender_eye.launch": "\u672b\u5f71\u4e4b\u773c\uff1a\u5c04\u51fa",
    "subtitles.entity.ender_pearl.throw": "\u672b\u5f71\u73cd\u73e0\uff1a\u98de\u51fa",
    "subtitles.entity.enderman.ambient": "\u672b\u5f71\u4eba\uff1a\u4f4e\u9e23",
    "subtitles.entity.enderman.death": "\u672b\u5f71\u4eba\uff1a\u6b7b\u4ea1",
    "subtitles.entity.enderman.hurt": "\u672b\u5f71\u4eba\uff1a\u53d7\u4f24",
    "subtitles.entity.enderman.stare": "\u672b\u5f71\u4eba\uff1a\u558a\u53eb",
    "subtitles.entity.enderman.teleport": "\u672b\u5f71\u4eba\uff1a\u4f20\u9001",
    "subtitles.entity.endermite.ambient": "\u672b\u5f71\u87a8\uff1a\u53eb\u58f0",
    "subtitles.entity.endermite.death": "\u672b\u5f71\u87a8\uff1a\u6b7b\u4ea1",
    "subtitles.entity.endermite.hurt": "\u672b\u5f71\u87a8\uff1a\u53d7\u4f24",
    "subtitles.entity.evoker.ambient": "\u5524\u9b54\u8005\uff1a\u5495\u54dd",
    "subtitles.entity.evoker.cast_spell": "\u5524\u9b54\u8005\uff1a\u65bd\u6cd5",
    "subtitles.entity.evoker.celebrate": "\u5524\u9b54\u8005\uff1a\u6b22\u547c",
    "subtitles.entity.evoker.death": "\u5524\u9b54\u8005\uff1a\u6b7b\u4ea1",
    "subtitles.entity.evoker.hurt": "\u5524\u9b54\u8005\uff1a\u53d7\u4f24",
    "subtitles.entity.evoker.prepare_attack": "\u5524\u9b54\u8005\uff1a\u51c6\u5907\u653b\u51fb",
    "subtitles.entity.evoker.prepare_summon": "\u5524\u9b54\u8005\uff1a\u51c6\u5907\u53ec\u5524",
    "subtitles.entity.evoker.prepare_wololo": "\u5524\u9b54\u8005\uff1a\u51c6\u5907\u65bd\u5492",
    "subtitles.entity.evoker_fangs.attack": "\u5c16\u7259\uff1a\u54ac\u5408",
    "subtitles.entity.experience_orb.pickup": "\u83b7\u5f97\u7ecf\u9a8c",
    "subtitles.entity.firework_rocket.blast": "\u70df\u82b1\uff1a\u7206\u70b8",
    "subtitles.entity.firework_rocket.launch": "\u70df\u82b1\uff1a\u53d1\u5c04",
    "subtitles.entity.firework_rocket.twinkle": "\u70df\u706b\uff1a\u95ea\u70c1",
    "subtitles.entity.fishing_bobber.retrieve": "\u6d6e\u6f02\uff1a\u6536\u56de",
    "subtitles.entity.fishing_bobber.splash": "\u6d6e\u6f02\uff1a\u6e85\u8d77\u6c34\u82b1",
    "subtitles.entity.fishing_bobber.throw": "\u6d6e\u6f02\uff1a\u7529\u51fa",
    "subtitles.entity.fox.aggro": "\u72d0\u72f8\uff1a\u6124\u6012",
    "subtitles.entity.fox.ambient": "\u72d0\u72f8\uff1a\u5431\u5431\u53eb",
    "subtitles.entity.fox.bite": "\u72d0\u72f8\uff1a\u6495\u54ac",
    "subtitles.entity.fox.death": "\u72d0\u72f8\uff1a\u6b7b\u4ea1",
    "subtitles.entity.fox.eat": "\u72d0\u72f8\uff1a\u8fdb\u98df",
    "subtitles.entity.fox.hurt": "\u72d0\u72f8\uff1a\u53d7\u4f24",
    "subtitles.entity.fox.screech": "\u72d0\u72f8\uff1a\u5c16\u58f0\u53eb",
    "subtitles.entity.fox.sleep": "\u72d0\u72f8\uff1a\u6253\u9f3e",
    "subtitles.entity.fox.sniff": "\u72d0\u72f8\uff1a\u55c5\u63a2",
    "subtitles.entity.fox.spit": "\u72d0\u72f8\uff1a\u5410\u51fa",
    "subtitles.entity.fox.teleport": "\u72d0\u72f8\uff1a\u4f20\u9001",
    "subtitles.entity.generic.big_fall": "\u67d0\u7269\uff1a\u7740\u5730",
    "subtitles.entity.generic.burn": "\u71c3\u70e7",
    "subtitles.entity.generic.death": "\u6fd2\u6b7b",
    "subtitles.entity.generic.drink": "\u555c\u996e",
    "subtitles.entity.generic.eat": "\u8fdb\u98df",
    "subtitles.entity.generic.explode": "\u7206\u70b8",
    "subtitles.entity.generic.extinguish_fire": "\u706b\uff1a\u7184\u706d",
    "subtitles.entity.generic.hurt": "\u67d0\u7269\uff1a\u53d7\u4f24",
    "subtitles.entity.generic.small_fall": "\u67d0\u7269\uff1a\u6454\u5012",
    "subtitles.entity.generic.splash": "\u6e85\u8d77\u6c34\u82b1",
    "subtitles.entity.generic.swim": "\u5b9e\u4f53\uff1a\u6e38\u6cf3",
    "subtitles.entity.ghast.ambient": "\u6076\u9b42\uff1a\u54ed\u6ce3",
    "subtitles.entity.ghast.death": "\u6076\u9b42\uff1a\u6b7b\u4ea1",
    "subtitles.entity.ghast.hurt": "\u6076\u9b42\uff1a\u53d7\u4f24",
    "subtitles.entity.ghast.shoot": "\u6076\u9b42\uff1a\u5c04\u51fb",
    "subtitles.entity.glow_item_frame.add_item": "\u8367\u5149\u7269\u54c1\u5c55\u793a\u6846\uff1a\u586b\u5145",
    "subtitles.entity.glow_item_frame.break": "\u8367\u5149\u7269\u54c1\u5c55\u793a\u6846\uff1a\u88ab\u7834\u574f",
    "subtitles.entity.glow_item_frame.place": "\u8367\u5149\u7269\u54c1\u5c55\u793a\u6846\uff1a\u88ab\u653e\u7f6e",
    "subtitles.entity.glow_item_frame.remove_item": "\u8367\u5149\u7269\u54c1\u5c55\u793a\u6846\uff1a\u6e05\u7a7a",
    "subtitles.entity.glow_item_frame.rotate_item": "\u8367\u5149\u7269\u54c1\u5c55\u793a\u6846\uff1a\u8f6c\u52a8",
    "subtitles.entity.glow_squid.ambient": "\u53d1\u5149\u9c7f\u9c7c\uff1a\u6e38\u6cf3",
    "subtitles.entity.glow_squid.death": "\u53d1\u5149\u9c7f\u9c7c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.glow_squid.hurt": "\u53d1\u5149\u9c7f\u9c7c\uff1a\u53d7\u4f24",
    "subtitles.entity.glow_squid.squirt": "\u53d1\u5149\u9c7f\u9c7c\uff1a\u55b7\u58a8",
    "subtitles.entity.goat.ambient": "\u5c71\u7f8a\uff1a\u54a9~",
    "subtitles.entity.goat.death": "\u5c71\u7f8a\uff1a\u6b7b\u4ea1",
    "subtitles.entity.goat.eat": "\u5c71\u7f8a\uff1a\u8fdb\u98df",
    "subtitles.entity.goat.hurt": "\u5c71\u7f8a\uff1a\u53d7\u4f24",
    "subtitles.entity.goat.long_jump": "\u5c71\u7f8a\uff1a\u8df3\u8dc3",
    "subtitles.entity.goat.milk": "\u5c71\u7f8a\uff1a\u88ab\u6324\u5976",
    "subtitles.entity.goat.prepare_ram": "\u5c71\u7f8a\uff1a\u8dfa\u811a",
    "subtitles.entity.goat.ram_impact": "\u5c71\u7f8a\uff1a\u51b2\u649e",
    "subtitles.entity.goat.screaming.ambient": "\u5c71\u7f8a\uff1a\u558a\u53eb",
    "subtitles.entity.goat.step": "\u5c71\u7f8a\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.guardian.ambient": "\u5b88\u536b\u8005\uff1a\u4f4e\u9e23",
    "subtitles.entity.guardian.ambient_land": "\u5b88\u536b\u8005\uff1a\u5f39\u8df3",
    "subtitles.entity.guardian.attack": "\u5b88\u536b\u8005\uff1a\u5c04\u51fb",
    "subtitles.entity.guardian.death": "\u5b88\u536b\u8005\uff1a\u6b7b\u4ea1",
    "subtitles.entity.guardian.flop": "\u5b88\u536b\u8005\uff1a\u843d\u5730",
    "subtitles.entity.guardian.hurt": "\u5b88\u536b\u8005\uff1a\u53d7\u4f24",
    "subtitles.entity.hoglin.ambient": "\u75a3\u732a\u517d\uff1a\u5486\u54ee",
    "subtitles.entity.hoglin.angry": "\u75a3\u732a\u517d\uff1a\u6012\u543c",
    "subtitles.entity.hoglin.attack": "\u75a3\u732a\u517d\uff1a\u653b\u51fb",
    "subtitles.entity.hoglin.converted_to_zombified": "\u75a3\u732a\u517d\uff1a\u8f6c\u5316\u4e3a\u50f5\u5c38\u75a3\u732a\u517d",
    "subtitles.entity.hoglin.death": "\u75a3\u732a\u517d\uff1a\u6b7b\u4ea1",
    "subtitles.entity.hoglin.hurt": "\u75a3\u732a\u517d\uff1a\u53d7\u4f24",
    "subtitles.entity.hoglin.retreat": "\u75a3\u732a\u517d\uff1a\u9000\u7f29",
    "subtitles.entity.hoglin.step": "\u75a3\u732a\u517d\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.horse.ambient": "\u9a6c\uff1a\u5636\u9e23",
    "subtitles.entity.horse.angry": "\u9a6c\uff1a\u5636\u9e23",
    "subtitles.entity.horse.armor": "\u9a6c\u94e0\uff1a\u88c5\u5907",
    "subtitles.entity.horse.breathe": "\u9a6c\uff1a\u547c\u5438",
    "subtitles.entity.horse.death": "\u9a6c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.horse.eat": "\u9a6c\uff1a\u8fdb\u98df",
    "subtitles.entity.horse.gallop": "\u9a6c\uff1a\u5954\u817e",
    "subtitles.entity.horse.hurt": "\u9a6c\uff1a\u53d7\u4f24",
    "subtitles.entity.horse.jump": "\u9a6c\uff1a\u8df3\u8dc3",
    "subtitles.entity.horse.saddle": "\u978d\uff1a\u88c5\u5907",
    "subtitles.entity.husk.ambient": "\u5c38\u58f3\uff1a\u4f4e\u543c",
    "subtitles.entity.husk.converted_to_zombie": "\u5c38\u58f3\uff1a\u8f6c\u5316\u4e3a\u50f5\u5c38",
    "subtitles.entity.husk.death": "\u5c38\u58f3\uff1a\u6b7b\u4ea1",
    "subtitles.entity.husk.hurt": "\u5c38\u58f3\uff1a\u53d7\u4f24",
    "subtitles.entity.illusioner.ambient": "\u5e7b\u672f\u5e08\uff1a\u5495\u54dd",
    "subtitles.entity.illusioner.cast_spell": "\u5e7b\u672f\u5e08\uff1a\u65bd\u6cd5",
    "subtitles.entity.illusioner.death": "\u5e7b\u672f\u5e08\uff1a\u6b7b\u4ea1",
    "subtitles.entity.illusioner.hurt": "\u5e7b\u672f\u5e08\uff1a\u53d7\u4f24",
    "subtitles.entity.illusioner.mirror_move": "\u5e7b\u672f\u5e08\uff1a\u66ff\u6362",
    "subtitles.entity.illusioner.prepare_blindness": "\u5e7b\u672f\u5e08\uff1a\u5931\u660e\u51c6\u5907",
    "subtitles.entity.illusioner.prepare_mirror": "\u5e7b\u672f\u5e08\uff1a\u955c\u50cf\u51c6\u5907",
    "subtitles.entity.iron_golem.attack": "\u94c1\u5080\u5121\uff1a\u653b\u51fb",
    "subtitles.entity.iron_golem.damage": "\u94c1\u5080\u5121\uff1a\u53d7\u635f",
    "subtitles.entity.iron_golem.death": "\u94c1\u5080\u5121\uff1a\u6b7b\u4ea1",
    "subtitles.entity.iron_golem.hurt": "\u94c1\u5080\u5121\uff1a\u53d7\u4f24",
    "subtitles.entity.iron_golem.repair": "\u94c1\u5080\u5121\uff1a\u4fee\u590d",
    "subtitles.entity.item.break": "\u7269\u54c1\uff1a\u88ab\u7834\u574f",
    "subtitles.entity.item.pickup": "\u7269\u54c1\uff1a\u88ab\u62fe\u8d77",
    "subtitles.entity.item_frame.add_item": "\u7269\u54c1\u5c55\u793a\u6846\uff1a\u586b\u5145",
    "subtitles.entity.item_frame.break": "\u7269\u54c1\u5c55\u793a\u6846\uff1a\u88ab\u7834\u574f",
    "subtitles.entity.item_frame.place": "\u7269\u54c1\u5c55\u793a\u6846\uff1a\u88ab\u653e\u7f6e",
    "subtitles.entity.item_frame.remove_item": "\u7269\u54c1\u5c55\u793a\u6846\uff1a\u6e05\u7a7a",
    "subtitles.entity.item_frame.rotate_item": "\u7269\u54c1\u5c55\u793a\u6846\uff1a\u8f6c\u52a8",
    "subtitles.entity.leash_knot.break": "\u62f4\u7ef3\u7ed3\uff1a\u88ab\u7834\u574f",
    "subtitles.entity.leash_knot.place": "\u62f4\u7ef3\u7ed3\uff1a\u88ab\u7cfb\u4e0a",
    "subtitles.entity.lightning_bolt.impact": "\u7535\u95ea",
    "subtitles.entity.lightning_bolt.thunder": "\u96f7\u9e23",
    "subtitles.entity.llama.ambient": "\u7f8a\u9a7c\uff1a\u543c\u53eb",
    "subtitles.entity.llama.angry": "\u7f8a\u9a7c\uff1a\u6012\u543c",
    "subtitles.entity.llama.chest": "\u7f8a\u9a7c\uff1a\u88c5\u5907\u7bb1\u5b50",
    "subtitles.entity.llama.death": "\u7f8a\u9a7c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.llama.eat": "\u7f8a\u9a7c\uff1a\u8fdb\u98df",
    "subtitles.entity.llama.hurt": "\u7f8a\u9a7c\uff1a\u53d7\u4f24",
    "subtitles.entity.llama.spit": "\u7f8a\u9a7c\uff1a\u55b7\u5c04\u553e\u6cab",
    "subtitles.entity.llama.step": "\u7f8a\u9a7c\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.llama.swag": "\u7f8a\u9a7c\uff1a\u88ab\u88c5\u9970",
    "subtitles.entity.magma_cube.death": "\u5ca9\u6d46\u602a\uff1a\u6b7b\u4ea1",
    "subtitles.entity.magma_cube.hurt": "\u5ca9\u6d46\u602a\uff1a\u53d7\u4f24",
    "subtitles.entity.magma_cube.squish": "\u5ca9\u6d46\u602a\uff1a\u79fb\u52a8",
    "subtitles.entity.minecart.riding": "\u77ff\u8f66\uff1a\u884c\u8fdb",
    "subtitles.entity.mooshroom.convert": "\u54de\u83c7\uff1a\u8f6c\u5316",
    "subtitles.entity.mooshroom.eat": "\u54de\u83c7\uff1a\u8fdb\u98df",
    "subtitles.entity.mooshroom.milk": "\u54de\u83c7\uff1a\u88ab\u6324\u5976",
    "subtitles.entity.mooshroom.suspicious_milk": "\u54de\u83c7\uff1a\u88ab\u53ef\u7591\u5730\u6324\u5976",
    "subtitles.entity.mule.ambient": "\u9aa1\uff1a\u9e23\u53eb",
    "subtitles.entity.mule.angry": "\u9aa1\uff1a\u5636\u9e23",
    "subtitles.entity.mule.chest": "\u9aa1\uff1a\u88c5\u5907\u7bb1\u5b50",
    "subtitles.entity.mule.death": "\u9aa1\uff1a\u6b7b\u4ea1",
    "subtitles.entity.mule.eat": "\u9aa1\uff1a\u8fdb\u98df",
    "subtitles.entity.mule.hurt": "\u9aa1\uff1a\u53d7\u4f24",
    "subtitles.entity.painting.break": "\u753b\uff1a\u88ab\u7834\u574f",
    "subtitles.entity.painting.place": "\u753b\uff1a\u88ab\u653e\u7f6e",
    "subtitles.entity.panda.aggressive_ambient": "\u718a\u732b\uff1a\u53d1\u6012",
    "subtitles.entity.panda.ambient": "\u718a\u732b\uff1a\u5598\u606f",
    "subtitles.entity.panda.bite": "\u718a\u732b\uff1a\u6495\u54ac",
    "subtitles.entity.panda.cant_breed": "\u718a\u732b\uff1a\u54c0\u9e23",
    "subtitles.entity.panda.death": "\u718a\u732b\uff1a\u6b7b\u4ea1",
    "subtitles.entity.panda.eat": "\u718a\u732b\uff1a\u8fdb\u98df",
    "subtitles.entity.panda.hurt": "\u718a\u732b\uff1a\u53d7\u4f24",
    "subtitles.entity.panda.pre_sneeze": "\u718a\u732b\uff1a\u9f3b\u75d2",
    "subtitles.entity.panda.sneeze": "\u718a\u732b\uff1a\u6253\u55b7\u568f",
    "subtitles.entity.panda.step": "\u718a\u732b\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.panda.worried_ambient": "\u718a\u732b\uff1a\u545c\u54bd",
    "subtitles.entity.parrot.ambient": "\u9e66\u9e49\uff1a\u8bf4\u8bdd",
    "subtitles.entity.parrot.death": "\u9e66\u9e49\uff1a\u6b7b\u4ea1",
    "subtitles.entity.parrot.eats": "\u9e66\u9e49\uff1a\u8fdb\u98df",
    "subtitles.entity.parrot.fly": "\u9e66\u9e49\uff1a\u6251\u7ffc",
    "subtitles.entity.parrot.hurts": "\u9e66\u9e49\uff1a\u53d7\u4f24",
    "subtitles.entity.parrot.imitate.blaze": "\u9e66\u9e49\uff1a\u547c\u5438",
    "subtitles.entity.parrot.imitate.creeper": "\u9e66\u9e49\uff1a\u5636~",
    "subtitles.entity.parrot.imitate.drowned": "\u9e66\u9e49\uff1a\u5495\u565c\u58f0",
    "subtitles.entity.parrot.imitate.elder_guardian": "\u9e66\u9e49\uff1a\u5f39\u8df3",
    "subtitles.entity.parrot.imitate.ender_dragon": "\u9e66\u9e49\uff1a\u5486\u54ee",
    "subtitles.entity.parrot.imitate.endermite": "\u9e66\u9e49\uff1a\u87a8\u53eb",
    "subtitles.entity.parrot.imitate.evoker": "\u9e66\u9e49\uff1a\u5495\u54dd",
    "subtitles.entity.parrot.imitate.ghast": "\u9e66\u9e49\uff1a\u54ed\u6ce3",
    "subtitles.entity.parrot.imitate.guardian": "\u9e66\u9e49\uff1a\u4f4e\u9e23",
    "subtitles.entity.parrot.imitate.hoglin": "\u9e66\u9e49\uff1a\u5486\u54ee",
    "subtitles.entity.parrot.imitate.husk": "\u9e66\u9e49\uff1a\u4f4e\u543c",
    "subtitles.entity.parrot.imitate.illusioner": "\u9e66\u9e49\uff1a\u5495\u54dd",
    "subtitles.entity.parrot.imitate.magma_cube": "\u9e66\u9e49\uff1a\u79fb\u52a8\u58f0",
    "subtitles.entity.parrot.imitate.phantom": "\u9e66\u9e49\uff1a\u5c16\u58f0\u53eb",
    "subtitles.entity.parrot.imitate.piglin": "\u9e66\u9e49\uff1a\u54fc\u53eb",
    "subtitles.entity.parrot.imitate.piglin_brute": "\u9e66\u9e49\uff1a\u5927\u58f0\u54fc\u53eb",
    "subtitles.entity.parrot.imitate.pillager": "\u9e66\u9e49\uff1a\u5495\u54dd",
    "subtitles.entity.parrot.imitate.ravager": "\u9e66\u9e49\uff1a\u547c\u565c",
    "subtitles.entity.parrot.imitate.shulker": "\u9e66\u9e49\uff1a\u7aa5\u89c6\u58f0",
    "subtitles.entity.parrot.imitate.silverfish": "\u9e66\u9e49\uff1a\u5636~",
    "subtitles.entity.parrot.imitate.skeleton": "\u9e66\u9e49\uff1a\u54af\u54af\u58f0",
    "subtitles.entity.parrot.imitate.slime": "\u9e66\u9e49\uff1a\u79fb\u52a8\u58f0",
    "subtitles.entity.parrot.imitate.spider": "\u9e66\u9e49\uff1a\u5636~",
    "subtitles.entity.parrot.imitate.stray": "\u9e66\u9e49\uff1a\u54af\u54af\u58f0",
    "subtitles.entity.parrot.imitate.vex": "\u9e66\u9e49\uff1a\u607c\u4eba",
    "subtitles.entity.parrot.imitate.vindicator": "\u9e66\u9e49\uff1a\u4f4e\u8bed",
    "subtitles.entity.parrot.imitate.witch": "\u9e66\u9e49\uff1a\u6697\u7b11",
    "subtitles.entity.parrot.imitate.wither": "\u9e66\u9e49\uff1a\u6124\u6012",
    "subtitles.entity.parrot.imitate.wither_skeleton": "\u9e66\u9e49\uff1a\u54af\u54af\u58f0",
    "subtitles.entity.parrot.imitate.zoglin": "\u9e66\u9e49\uff1a\u5486\u54ee",
    "subtitles.entity.parrot.imitate.zombie": "\u9e66\u9e49\uff1a\u4f4e\u543c",
    "subtitles.entity.parrot.imitate.zombie_villager": "\u9e66\u9e49\uff1a\u4f4e\u543c",
    "subtitles.entity.phantom.ambient": "\u5e7b\u7ffc\uff1a\u5c16\u58f0\u53eb",
    "subtitles.entity.phantom.bite": "\u5e7b\u7ffc\uff1a\u6495\u54ac",
    "subtitles.entity.phantom.death": "\u5e7b\u7ffc\uff1a\u6b7b\u4ea1",
    "subtitles.entity.phantom.flap": "\u5e7b\u7ffc\uff1a\u632f\u7fc5",
    "subtitles.entity.phantom.hurt": "\u5e7b\u7ffc\uff1a\u53d7\u4f24",
    "subtitles.entity.phantom.swoop": "\u5e7b\u7ffc\uff1a\u4fef\u51b2",
    "subtitles.entity.pig.ambient": "\u732a\uff1a\u54fc\u53eb",
    "subtitles.entity.pig.death": "\u732a\uff1a\u6b7b\u4ea1",
    "subtitles.entity.pig.hurt": "\u732a\uff1a\u53d7\u4f24",
    "subtitles.entity.pig.saddle": "\u978d\uff1a\u88c5\u5907",
    "subtitles.entity.piglin.admiring_item": "\u732a\u7075\uff1a\u7aef\u8be6\u7269\u54c1",
    "subtitles.entity.piglin.ambient": "\u732a\u7075\uff1a\u54fc\u53eb",
    "subtitles.entity.piglin.angry": "\u732a\u7075\uff1a\u6124\u6012\u5730\u54fc\u53eb",
    "subtitles.entity.piglin.celebrate": "\u732a\u7075\uff1a\u5e86\u795d",
    "subtitles.entity.piglin.converted_to_zombified": "\u732a\u7075\uff1a\u8f6c\u5316\u4e3a\u50f5\u5c38\u732a\u7075",
    "subtitles.entity.piglin.death": "\u732a\u7075\uff1a\u6b7b\u4ea1",
    "subtitles.entity.piglin.hurt": "\u732a\u7075\uff1a\u53d7\u4f24",
    "subtitles.entity.piglin.jealous": "\u732a\u7075\uff1a\u7fa1\u6155\u5730\u54fc\u53eb",
    "subtitles.entity.piglin.retreat": "\u732a\u7075\uff1a\u9000\u7f29",
    "subtitles.entity.piglin.step": "\u732a\u7075\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.piglin_brute.ambient": "\u732a\u7075\u86ee\u5175\uff1a\u54fc\u53eb",
    "subtitles.entity.piglin_brute.angry": "\u732a\u7075\u86ee\u5175\uff1a\u6124\u6012\u5730\u54fc\u53eb",
    "subtitles.entity.piglin_brute.converted_to_zombified": "\u732a\u7075\u86ee\u5175\uff1a\u8f6c\u5316\u4e3a\u50f5\u5c38\u732a\u7075",
    "subtitles.entity.piglin_brute.death": "\u732a\u7075\u86ee\u5175\uff1a\u6b7b\u4ea1",
    "subtitles.entity.piglin_brute.hurt": "\u732a\u7075\u86ee\u5175\uff1a\u53d7\u4f24",
    "subtitles.entity.piglin_brute.step": "\u732a\u7075\u86ee\u5175\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.pillager.ambient": "\u63a0\u593a\u8005\uff1a\u5495\u54dd",
    "subtitles.entity.pillager.celebrate": "\u63a0\u593a\u8005\uff1a\u6b22\u547c",
    "subtitles.entity.pillager.death": "\u63a0\u593a\u8005\uff1a\u6b7b\u4ea1",
    "subtitles.entity.pillager.hurt": "\u63a0\u593a\u8005\uff1a\u53d7\u4f24",
    "subtitles.entity.player.attack.crit": "\u66b4\u51fb",
    "subtitles.entity.player.attack.knockback": "\u51fb\u9000\u653b\u51fb",
    "subtitles.entity.player.attack.strong": "\u91cd\u51fb",
    "subtitles.entity.player.attack.sweep": "\u6a2a\u626b\u653b\u51fb",
    "subtitles.entity.player.attack.weak": "\u8f7b\u51fb",
    "subtitles.entity.player.burp": "\u6253\u55dd",
    "subtitles.entity.player.death": "\u73a9\u5bb6\uff1a\u6b7b\u4ea1",
    "subtitles.entity.player.freeze_hurt": "\u73a9\u5bb6\uff1a\u51bb\u4f24",
    "subtitles.entity.player.hurt": "\u73a9\u5bb6\uff1a\u53d7\u4f24",
    "subtitles.entity.player.hurt_drown": "\u73a9\u5bb6\uff1a\u6eba\u6c34",
    "subtitles.entity.player.hurt_on_fire": "\u73a9\u5bb6\uff1a\u71c3\u70e7",
    "subtitles.entity.player.levelup": "\u73a9\u5bb6\uff1a\u5347\u7ea7",
    "subtitles.entity.polar_bear.ambient": "\u5317\u6781\u718a\uff1a\u4f4e\u543c",
    "subtitles.entity.polar_bear.ambient_baby": "\u5317\u6781\u718a\uff1a\u54fc\u54fc",
    "subtitles.entity.polar_bear.death": "\u5317\u6781\u718a\uff1a\u6b7b\u4ea1",
    "subtitles.entity.polar_bear.hurt": "\u5317\u6781\u718a\uff1a\u53d7\u4f24",
    "subtitles.entity.polar_bear.warning": "\u5317\u6781\u718a\uff1a\u5486\u54ee",
    "subtitles.entity.potion.splash": "\u836f\u74f6\uff1a\u788e\u88c2",
    "subtitles.entity.potion.throw": "\u836f\u74f6\uff1a\u6254\u51fa",
    "subtitles.entity.puffer_fish.blow_out": "\u6cb3\u8c5a\uff1a\u6536\u7f29",
    "subtitles.entity.puffer_fish.blow_up": "\u6cb3\u8c5a\uff1a\u81a8\u80c0",
    "subtitles.entity.puffer_fish.death": "\u6cb3\u8c5a\uff1a\u6b7b\u4ea1",
    "subtitles.entity.puffer_fish.flop": "\u6cb3\u8c5a\uff1a\u6251\u817e",
    "subtitles.entity.puffer_fish.hurt": "\u6cb3\u8c5a\uff1a\u53d7\u4f24",
    "subtitles.entity.puffer_fish.sting": "\u6cb3\u8c5a\uff1a\u523a\u86f0",
    "subtitles.entity.rabbit.ambient": "\u5154\u5b50\uff1a\u5431\u5431\u53eb",
    "subtitles.entity.rabbit.attack": "\u5154\u5b50\uff1a\u653b\u51fb",
    "subtitles.entity.rabbit.death": "\u5154\u5b50\uff1a\u6b7b\u4ea1",
    "subtitles.entity.rabbit.hurt": "\u5154\u5b50\uff1a\u53d7\u4f24",
    "subtitles.entity.rabbit.jump": "\u5154\u5b50\uff1a\u8df3\u52a8",
    "subtitles.entity.ravager.ambient": "\u52ab\u63a0\u517d\uff1a\u547c\u565c",
    "subtitles.entity.ravager.attack": "\u52ab\u63a0\u517d\uff1a\u6495\u54ac",
    "subtitles.entity.ravager.celebrate": "\u52ab\u63a0\u517d\uff1a\u6b22\u547c",
    "subtitles.entity.ravager.death": "\u52ab\u63a0\u517d\uff1a\u6b7b\u4ea1",
    "subtitles.entity.ravager.hurt": "\u52ab\u63a0\u517d\uff1a\u53d7\u4f24",
    "subtitles.entity.ravager.roar": "\u52ab\u63a0\u517d\uff1a\u5486\u54ee",
    "subtitles.entity.ravager.step": "\u52ab\u63a0\u517d\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.ravager.stunned": "\u52ab\u63a0\u517d\uff1a\u7729\u6655",
    "subtitles.entity.salmon.death": "\u9c91\u9c7c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.salmon.flop": "\u9c91\u9c7c\uff1a\u6251\u817e",
    "subtitles.entity.salmon.hurt": "\u9c91\u9c7c\uff1a\u53d7\u4f24",
    "subtitles.entity.sheep.ambient": "\u7ef5\u7f8a\uff1a\u54a9~",
    "subtitles.entity.sheep.death": "\u7ef5\u7f8a\uff1a\u6b7b\u4ea1",
    "subtitles.entity.sheep.hurt": "\u7ef5\u7f8a\uff1a\u53d7\u4f24",
    "subtitles.entity.shulker.ambient": "\u6f5c\u5f71\u8d1d\uff1a\u7aa5\u89c6",
    "subtitles.entity.shulker.close": "\u6f5c\u5f71\u8d1d\uff1a\u5173\u95ed",
    "subtitles.entity.shulker.death": "\u6f5c\u5f71\u8d1d\uff1a\u6b7b\u4ea1",
    "subtitles.entity.shulker.hurt": "\u6f5c\u5f71\u8d1d\uff1a\u53d7\u4f24",
    "subtitles.entity.shulker.open": "\u6f5c\u5f71\u8d1d\uff1a\u6253\u5f00",
    "subtitles.entity.shulker.shoot": "\u6f5c\u5f71\u8d1d\uff1a\u5c04\u51fb",
    "subtitles.entity.shulker.teleport": "\u6f5c\u5f71\u8d1d\uff1a\u4f20\u9001",
    "subtitles.entity.shulker_bullet.hit": "\u6f5c\u5f71\u8d1d\u5bfc\u5f39\uff1a\u7206\u70b8",
    "subtitles.entity.shulker_bullet.hurt": "\u6f5c\u5f71\u8d1d\u5bfc\u5f39\uff1a\u788e\u88c2",
    "subtitles.entity.silverfish.ambient": "\u8839\u866b\uff1a\u5636\u5636",
    "subtitles.entity.silverfish.death": "\u8839\u866b\uff1a\u6b7b\u4ea1",
    "subtitles.entity.silverfish.hurt": "\u8839\u866b\uff1a\u53d7\u4f24",
    "subtitles.entity.skeleton.ambient": "\u9ab7\u9ac5\uff1a\u54af\u54af\u58f0",
    "subtitles.entity.skeleton.converted_to_stray": "\u9ab7\u9ac5\uff1a\u8f6c\u5316\u4e3a\u6d41\u6d6a\u8005",
    "subtitles.entity.skeleton.death": "\u9ab7\u9ac5\uff1a\u6b7b\u4ea1",
    "subtitles.entity.skeleton.hurt": "\u9ab7\u9ac5\uff1a\u53d7\u4f24",
    "subtitles.entity.skeleton.shoot": "\u9ab7\u9ac5\uff1a\u5c04\u51fb",
    "subtitles.entity.skeleton_horse.ambient": "\u9ab7\u9ac5\u9a6c\uff1a\u5636\u53eb",
    "subtitles.entity.skeleton_horse.death": "\u9ab7\u9ac5\u9a6c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.skeleton_horse.hurt": "\u9ab7\u9ac5\u9a6c\uff1a\u53d7\u4f24",
    "subtitles.entity.skeleton_horse.swim": "\u9ab7\u9ac5\u9a6c\uff1a\u6e38\u6cf3",
    "subtitles.entity.slime.attack": "\u53f2\u83b1\u59c6\uff1a\u653b\u51fb",
    "subtitles.entity.slime.death": "\u53f2\u83b1\u59c6\uff1a\u6b7b\u4ea1",
    "subtitles.entity.slime.hurt": "\u53f2\u83b1\u59c6\uff1a\u53d7\u4f24",
    "subtitles.entity.slime.squish": "\u53f2\u83b1\u59c6\uff1a\u79fb\u52a8",
    "subtitles.entity.snow_golem.death": "\u96ea\u5080\u5121\uff1a\u6b7b\u4ea1",
    "subtitles.entity.snow_golem.hurt": "\u96ea\u5080\u5121\uff1a\u53d7\u4f24",
    "subtitles.entity.snowball.throw": "\u96ea\u7403\uff1a\u98de\u51fa",
    "subtitles.entity.spider.ambient": "\u8718\u86db\uff1a\u5636\u5636",
    "subtitles.entity.spider.death": "\u8718\u86db\uff1a\u6b7b\u4ea1",
    "subtitles.entity.spider.hurt": "\u8718\u86db\uff1a\u53d7\u4f24",
    "subtitles.entity.squid.ambient": "\u9c7f\u9c7c\uff1a\u6e38\u52a8",
    "subtitles.entity.squid.death": "\u9c7f\u9c7c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.squid.hurt": "\u9c7f\u9c7c\uff1a\u53d7\u4f24",
    "subtitles.entity.squid.squirt": "\u9c7f\u9c7c\uff1a\u55b7\u58a8",
    "subtitles.entity.stray.ambient": "\u6d41\u6d6a\u8005\uff1a\u54af\u54af\u58f0",
    "subtitles.entity.stray.death": "\u6d41\u6d6a\u8005\uff1a\u6b7b\u4ea1",
    "subtitles.entity.stray.hurt": "\u6d41\u6d6a\u8005\uff1a\u53d7\u4f24",
    "subtitles.entity.strider.death": "\u70bd\u8db3\u517d\uff1a\u6b7b\u4ea1",
    "subtitles.entity.strider.eat": "\u70bd\u8db3\u517d\uff1a\u8fdb\u98df",
    "subtitles.entity.strider.happy": "\u70bd\u8db3\u517d\uff1a\u98a4\u9e23",
    "subtitles.entity.strider.hurt": "\u70bd\u8db3\u517d\uff1a\u53d7\u4f24",
    "subtitles.entity.strider.idle": "\u70bd\u8db3\u517d\uff1a\u557e\u557e",
    "subtitles.entity.strider.retreat": "\u70bd\u8db3\u517d\uff1a\u9000\u7f29",
    "subtitles.entity.tnt.primed": "TNT\uff1a\u5636\u5636\u4f5c\u54cd",
    "subtitles.entity.tropical_fish.death": "\u70ed\u5e26\u9c7c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.tropical_fish.flop": "\u70ed\u5e26\u9c7c\uff1a\u6251\u817e",
    "subtitles.entity.tropical_fish.hurt": "\u70ed\u5e26\u9c7c\uff1a\u53d7\u4f24",
    "subtitles.entity.turtle.ambient_land": "\u6d77\u9f9f\uff1a\u557e\u557e",
    "subtitles.entity.turtle.death": "\u6d77\u9f9f\uff1a\u6b7b\u4ea1",
    "subtitles.entity.turtle.death_baby": "\u5e7c\u5e74\u6d77\u9f9f\uff1a\u6b7b\u4ea1",
    "subtitles.entity.turtle.egg_break": "\u6d77\u9f9f\u86cb\uff1a\u7834\u88c2",
    "subtitles.entity.turtle.egg_crack": "\u6d77\u9f9f\u86cb\uff1a\u88c2\u5f00",
    "subtitles.entity.turtle.egg_hatch": "\u6d77\u9f9f\u86cb\uff1a\u5b75\u5316",
    "subtitles.entity.turtle.hurt": "\u6d77\u9f9f\uff1a\u53d7\u4f24",
    "subtitles.entity.turtle.hurt_baby": "\u5e7c\u5e74\u6d77\u9f9f\uff1a\u53d7\u4f24",
    "subtitles.entity.turtle.lay_egg": "\u6d77\u9f9f\uff1a\u4ea7\u5375",
    "subtitles.entity.turtle.shamble": "\u6d77\u9f9f\uff1a\u722c\u884c",
    "subtitles.entity.turtle.shamble_baby": "\u5e7c\u5e74\u6d77\u9f9f\uff1a\u722c\u884c",
    "subtitles.entity.turtle.swim": "\u6d77\u9f9f\uff1a\u6e38\u52a8",
    "subtitles.entity.vex.ambient": "\u607c\u9b3c\uff1a\u607c\u4eba",
    "subtitles.entity.vex.charge": "\u607c\u9b3c\uff1a\u5c16\u53eb",
    "subtitles.entity.vex.death": "\u607c\u9b3c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.vex.hurt": "\u607c\u9b3c\uff1a\u53d7\u4f24",
    "subtitles.entity.villager.ambient": "\u6751\u6c11\uff1a\u5583\u5583\u81ea\u8bed",
    "subtitles.entity.villager.celebrate": "\u6751\u6c11\uff1a\u6b22\u547c",
    "subtitles.entity.villager.death": "\u6751\u6c11\uff1a\u6b7b\u4ea1",
    "subtitles.entity.villager.hurt": "\u6751\u6c11\uff1a\u53d7\u4f24",
    "subtitles.entity.villager.no": "\u6751\u6c11\uff1a\u53cd\u5bf9",
    "subtitles.entity.villager.trade": "\u6751\u6c11\uff1a\u4ea4\u6613",
    "subtitles.entity.villager.work_armorer": "\u76d4\u7532\u5320\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_butcher": "\u5c60\u592b\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_cartographer": "\u5236\u56fe\u5e08\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_cleric": "\u7267\u5e08\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_farmer": "\u519c\u6c11\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_fisherman": "\u6e14\u592b\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_fletcher": "\u5236\u7bad\u5e08\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_leatherworker": "\u76ae\u5320\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_librarian": "\u56fe\u4e66\u7ba1\u7406\u5458\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_mason": "\u77f3\u5320\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_shepherd": "\u7267\u7f8a\u4eba\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_toolsmith": "\u5de5\u5177\u5320\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.work_weaponsmith": "\u6b66\u5668\u5320\uff1a\u5de5\u4f5c",
    "subtitles.entity.villager.yes": "\u6751\u6c11\uff1a\u8d5e\u540c",
    "subtitles.entity.vindicator.ambient": "\u536b\u9053\u58eb\uff1a\u4f4e\u8bed",
    "subtitles.entity.vindicator.celebrate": "\u536b\u9053\u58eb\uff1a\u6b22\u547c",
    "subtitles.entity.vindicator.death": "\u536b\u9053\u58eb\uff1a\u6b7b\u4ea1",
    "subtitles.entity.vindicator.hurt": "\u536b\u9053\u58eb\uff1a\u53d7\u4f24",
    "subtitles.entity.wandering_trader.ambient": "\u6d41\u6d6a\u5546\u4eba\uff1a\u5583\u5583\u81ea\u8bed",
    "subtitles.entity.wandering_trader.death": "\u6d41\u6d6a\u5546\u4eba\uff1a\u6b7b\u4ea1",
    "subtitles.entity.wandering_trader.disappeared": "\u6d41\u6d6a\u5546\u4eba\uff1a\u9690\u8eab",
    "subtitles.entity.wandering_trader.drink_milk": "\u6d41\u6d6a\u5546\u4eba\uff1a\u559d\u5976",
    "subtitles.entity.wandering_trader.drink_potion": "\u6d41\u6d6a\u5546\u4eba\uff1a\u996e\u7528\u836f\u6c34",
    "subtitles.entity.wandering_trader.hurt": "\u6d41\u6d6a\u5546\u4eba\uff1a\u53d7\u4f24",
    "subtitles.entity.wandering_trader.no": "\u6d41\u6d6a\u5546\u4eba\uff1a\u62d2\u7edd",
    "subtitles.entity.wandering_trader.reappeared": "\u6d41\u6d6a\u5546\u4eba\uff1a\u73b0\u8eab",
    "subtitles.entity.wandering_trader.trade": "\u6d41\u6d6a\u5546\u4eba\uff1a\u4ea4\u6613",
    "subtitles.entity.wandering_trader.yes": "\u6d41\u6d6a\u5546\u4eba\uff1a\u540c\u610f",
    "subtitles.entity.witch.ambient": "\u5973\u5deb\uff1a\u6697\u7b11",
    "subtitles.entity.witch.celebrate": "\u5973\u5deb\uff1a\u6b22\u547c",
    "subtitles.entity.witch.death": "\u5973\u5deb\uff1a\u6b7b\u4ea1",
    "subtitles.entity.witch.drink": "\u5973\u5deb\uff1a\u996e\u7528\u836f\u6c34",
    "subtitles.entity.witch.hurt": "\u5973\u5deb\uff1a\u53d7\u4f24",
    "subtitles.entity.witch.throw": "\u5973\u5deb\uff1a\u6295\u63b7",
    "subtitles.entity.wither.ambient": "\u51cb\u7075\uff1a\u6124\u6012",
    "subtitles.entity.wither.death": "\u51cb\u7075\uff1a\u6b7b\u4ea1",
    "subtitles.entity.wither.hurt": "\u51cb\u7075\uff1a\u53d7\u4f24",
    "subtitles.entity.wither.shoot": "\u51cb\u7075\uff1a\u653b\u51fb",
    "subtitles.entity.wither.spawn": "\u51cb\u7075\uff1a\u89e3\u653e",
    "subtitles.entity.wither_skeleton.ambient": "\u51cb\u7075\u9ab7\u9ac5\uff1a\u54af\u54af\u58f0",
    "subtitles.entity.wither_skeleton.death": "\u51cb\u7075\u9ab7\u9ac5\uff1a\u6b7b\u4ea1",
    "subtitles.entity.wither_skeleton.hurt": "\u51cb\u7075\u9ab7\u9ac5\uff1a\u53d7\u4f24",
    "subtitles.entity.wolf.ambient": "\u72fc\uff1a\u5598\u606f",
    "subtitles.entity.wolf.death": "\u72fc\uff1a\u6b7b\u4ea1",
    "subtitles.entity.wolf.growl": "\u72fc\uff1a\u568e\u53eb",
    "subtitles.entity.wolf.hurt": "\u72fc\uff1a\u53d7\u4f24",
    "subtitles.entity.wolf.shake": "\u72fc\uff1a\u6447\u52a8",
    "subtitles.entity.zoglin.ambient": "\u50f5\u5c38\u75a3\u732a\u517d\uff1a\u5486\u54ee",
    "subtitles.entity.zoglin.angry": "\u50f5\u5c38\u75a3\u732a\u517d\uff1a\u6012\u543c",
    "subtitles.entity.zoglin.attack": "\u50f5\u5c38\u75a3\u732a\u517d\uff1a\u653b\u51fb",
    "subtitles.entity.zoglin.death": "\u50f5\u5c38\u75a3\u732a\u517d\uff1a\u6b7b\u4ea1",
    "subtitles.entity.zoglin.hurt": "\u50f5\u5c38\u75a3\u732a\u517d\uff1a\u53d7\u4f24",
    "subtitles.entity.zoglin.step": "\u50f5\u5c38\u75a3\u732a\u517d\uff1a\u811a\u6b65\u58f0",
    "subtitles.entity.zombie.ambient": "\u50f5\u5c38\uff1a\u4f4e\u543c",
    "subtitles.entity.zombie.attack_wooden_door": "\u95e8\uff1a\u6643\u52a8",
    "subtitles.entity.zombie.break_wooden_door": "\u95e8\uff1a\u6bc1\u574f",
    "subtitles.entity.zombie.converted_to_drowned": "\u50f5\u5c38\uff1a\u8f6c\u5316\u4e3a\u6eba\u5c38",
    "subtitles.entity.zombie.death": "\u50f5\u5c38\uff1a\u6b7b\u4ea1",
    "subtitles.entity.zombie.destroy_egg": "\u6d77\u9f9f\u86cb\uff1a\u88ab\u8e29\u8e0f",
    "subtitles.entity.zombie.hurt": "\u50f5\u5c38\uff1a\u53d7\u4f24",
    "subtitles.entity.zombie.infect": "\u50f5\u5c38\uff1a\u611f\u67d3",
    "subtitles.entity.zombie_horse.ambient": "\u50f5\u5c38\u9a6c\uff1a\u5636\u53eb",
    "subtitles.entity.zombie_horse.death": "\u50f5\u5c38\u9a6c\uff1a\u6b7b\u4ea1",
    "subtitles.entity.zombie_horse.hurt": "\u50f5\u5c38\u9a6c\uff1a\u53d7\u4f24",
    "subtitles.entity.zombie_villager.ambient": "\u50f5\u5c38\u6751\u6c11\uff1a\u4f4e\u543c",
    "subtitles.entity.zombie_villager.converted": "\u50f5\u5c38\u6751\u6c11\uff1a\u54c0\u568e",
    "subtitles.entity.zombie_villager.cure": "\u50f5\u5c38\u6751\u6c11\uff1a\u6495\u5fc3\u88c2\u80ba",
    "subtitles.entity.zombie_villager.death": "\u50f5\u5c38\u6751\u6c11\uff1a\u6b7b\u4ea1",
    "subtitles.entity.zombie_villager.hurt": "\u50f5\u5c38\u6751\u6c11\uff1a\u53d7\u4f24",
    "subtitles.entity.zombified_piglin.ambient": "\u50f5\u5c38\u732a\u7075\uff1a\u547c\u565c",
    "subtitles.entity.zombified_piglin.angry": "\u50f5\u5c38\u732a\u7075\uff1a\u6124\u6012\u5730\u547c\u565c",
    "subtitles.entity.zombified_piglin.death": "\u50f5\u5c38\u732a\u7075\uff1a\u6b7b\u4ea1",
    "subtitles.entity.zombified_piglin.hurt": "\u50f5\u5c38\u732a\u7075\uff1a\u53d7\u4f24",
    "subtitles.event.raid.horn": "\u4e0d\u7965\u53f7\u89d2\uff1a\u9e23\u54cd",
    "subtitles.item.armor.equip": "\u76d4\u7532\uff1a\u88c5\u5907",
    "subtitles.item.armor.equip_chain": "\u9501\u94fe\u76d4\u7532\uff1a\u78b0\u64e6",
    "subtitles.item.armor.equip_diamond": "\u94bb\u77f3\u76d4\u7532\uff1a\u78b0\u64e6",
    "subtitles.item.armor.equip_elytra": "\u9798\u7fc5\uff1a\u6c99\u6c99\u4f5c\u54cd",
    "subtitles.item.armor.equip_gold": "\u9ec4\u91d1\u76d4\u7532\uff1a\u53ee\u5f53",
    "subtitles.item.armor.equip_iron": "\u94c1\u8d28\u76d4\u7532\uff1a\u94ff\u9535",
    "subtitles.item.armor.equip_leather": "\u76ae\u9769\u62a4\u7532\uff1a\u6469\u64e6",
    "subtitles.item.armor.equip_netherite": "\u4e0b\u754c\u5408\u91d1\u76d4\u7532\uff1a\u94ff\u9535",
    "subtitles.item.armor.equip_turtle": "\u6d77\u9f9f\u58f3\uff1a\u5495\u549a",
    "subtitles.item.axe.scrape": "\u65a7\uff1a\u522e\u524a",
    "subtitles.item.axe.strip": "\u65a7\uff1a\u524a\u76ae",
    "subtitles.item.axe.wax_off": "\u8131\u8721",
    "subtitles.item.bone_meal.use": "\u9aa8\u7c89\uff1a\u6c99\u6c99\u4f5c\u54cd",
    "subtitles.item.book.page_turn": "\u4e66\u9875\uff1a\u6c99\u6c99\u4f5c\u54cd",
    "subtitles.item.book.put": "\u4e66\uff1a\u653e\u7f6e",
    "subtitles.item.bottle.empty": "\u73bb\u7483\u74f6\uff1a\u5012\u7a7a",
    "subtitles.item.bottle.fill": "\u6c34\u74f6\uff1a\u88c5\u6ee1",
    "subtitles.item.bucket.empty": "\u6876\uff1a\u5012\u7a7a",
    "subtitles.item.bucket.fill": "\u6876\uff1a\u88c5\u6ee1",
    "subtitles.item.bucket.fill_axolotl": "\u7f8e\u897f\u8788\uff1a\u88ab\u88c5\u8d77",
    "subtitles.item.bucket.fill_fish": "\u9c7c\uff1a\u88ab\u6355\u83b7",
    "subtitles.item.bundle.drop_contents": "\u6536\u7eb3\u888b\uff1a\u5012\u7a7a",
    "subtitles.item.bundle.insert": "\u7269\u54c1\uff1a\u88c5\u5165\u888b\u4e2d",
    "subtitles.item.bundle.remove_one": "\u7269\u54c1\uff1a\u4ece\u888b\u4e2d\u53d6\u51fa",
    "subtitles.item.chorus_fruit.teleport": "\u73a9\u5bb6\uff1a\u4f20\u9001",
    "subtitles.item.crop.plant": "\u4f5c\u7269\uff1a\u79cd\u690d",
    "subtitles.item.crossbow.charge": "\u5f29\uff1a\u84c4\u529b",
    "subtitles.item.crossbow.hit": "\u7bad\uff1a\u51fb\u4e2d",
    "subtitles.item.crossbow.load": "\u5f29\uff1a\u88c5\u586b",
    "subtitles.item.crossbow.shoot": "\u5f29\uff1a\u53d1\u5c04",
    "subtitles.item.dye.use": "\u67d3\u6599\uff1a\u67d3\u8272",
    "subtitles.item.firecharge.use": "\u706b\u7130\u5f39\uff1a\u547c\u5578",
    "subtitles.item.flintandsteel.use": "\u6253\u706b\u77f3\uff1a\u751f\u706b",
    "subtitles.item.glow_ink_sac.use": "\u8367\u5149\u58a8\u56ca\uff1a\u6d82\u62b9",
    "subtitles.item.hoe.till": "\u9504\u5934\uff1a\u7281\u5730",
    "subtitles.item.honey_bottle.drink": "\u541e\u54bd",
    "subtitles.item.honeycomb.wax_on": "\u6d82\u8721",
    "subtitles.item.ink_sac.use": "\u58a8\u56ca\uff1a\u6d82\u62b9",
    "subtitles.item.lodestone_compass.lock": "\u78c1\u77f3\u6307\u9488\uff1a\u7ed1\u5b9a\u78c1\u77f3",
    "subtitles.item.nether_wart.plant": "\u4f5c\u7269\uff1a\u79cd\u690d",
    "subtitles.item.shears.shear": "\u526a\u5200\uff1a\u526a\u65ad",
    "subtitles.item.shield.block": "\u76fe\u724c\uff1a\u683c\u6321",
    "subtitles.item.shovel.flatten": "\u9539\uff1a\u538b\u5730",
    "subtitles.item.spyglass.stop_using": "\u671b\u8fdc\u955c\uff1a\u7f29\u5c0f",
    "subtitles.item.spyglass.use": "\u671b\u8fdc\u955c\uff1a\u653e\u5927",
    "subtitles.item.totem.use": "\u56fe\u817e\uff1a\u53d1\u52a8",
    "subtitles.item.trident.hit": "\u4e09\u53c9\u621f\uff1a\u7a81\u523a",
    "subtitles.item.trident.hit_ground": "\u4e09\u53c9\u621f\uff1a\u632f\u52a8",
    "subtitles.item.trident.return": "\u4e09\u53c9\u621f\uff1a\u6536\u56de",
    "subtitles.item.trident.riptide": "\u4e09\u53c9\u621f\uff1a\u7a81\u8fdb",
    "subtitles.item.trident.throw": "\u4e09\u53c9\u621f\uff1a\u94ff\u9535",
    "subtitles.item.trident.thunder": "\u4e09\u53c9\u621f\uff1a\u7535\u95ea\u96f7\u9e23",
    "subtitles.particle.soul_escape": "\u7075\u9b42\uff1a\u9038\u6563",
    "subtitles.ui.cartography_table.take_result": "\u5730\u56fe\uff1a\u7ed8\u5236",
    "subtitles.ui.loom.take_result": "\u7ec7\u5e03\u673a\uff1a\u4f7f\u7528",
    "subtitles.ui.stonecutter.take_result": "\u5207\u77f3\u673a\uff1a\u4f7f\u7528",
    "subtitles.weather.rain": "\u96e8\uff1a\u843d\u4e0b",
    "team.collision.always": "\u603b\u662f\u78b0\u649e",
    "team.collision.never": "\u7981\u7528\u78b0\u649e",
    "team.collision.pushOtherTeams": "\u961f\u4f0d\u95f4\u78b0\u649e",
    "team.collision.pushOwnTeam": "\u961f\u4f0d\u5185\u78b0\u649e",
    "team.notFound": "\u672a\u77e5\u7684\u961f\u4f0d'%s'",
    "team.visibility.always": "\u59cb\u7ec8\u663e\u793a",
    "team.visibility.hideForOtherTeams": "\u5bf9\u522b\u961f\u9690\u85cf",
    "team.visibility.hideForOwnTeam": "\u5bf9\u672c\u961f\u9690\u85cf",
    "team.visibility.never": "\u59cb\u7ec8\u9690\u85cf",
    "title.multiplayer.disabled": "\u591a\u4eba\u6e38\u620f\u5df2\u88ab\u7981\u7528\uff0c\u8bf7\u68c0\u67e5\u4f60\u7684Microsoft\u8d26\u6237\u8bbe\u7f6e\u3002",
    "title.multiplayer.lan": "\u591a\u4eba\u6e38\u620f\uff08\u5c40\u57df\u7f51\uff09",
    "title.multiplayer.other": "\u591a\u4eba\u6e38\u620f\uff08\u7b2c\u4e09\u65b9\u670d\u52a1\u5668\uff09",
    "title.multiplayer.realms": "\u591a\u4eba\u6e38\u620f\uff08Realms\uff09",
    "title.oldgl.deprecation.line1": "\u68c0\u6d4b\u5230\u8fc7\u65f6\u7684\u663e\u5361\uff0c\u7531\u4e8e\u9700\u6c42OpenGL 3.2\uff0c",
    "title.oldgl.deprecation.line2": "OpenGL 3.2\uff0c\u8fd9\u4f1a\u5728\u672a\u6765\u963b\u6b62\u4f60\u8fdb\u884c\u6e38\u620f\u3002",
    "title.oldgl.eol.line1": "\u68c0\u6d4b\u5230\u8fc7\u65f6\u7684\u663e\u5361\uff0c\u7531\u4e8e\u9700\u6c42OpenGL 3.2\uff0c",
    "title.oldgl.eol.line2": "OpenGL 2.0\uff0c\u8fd9\u4f1a\u963b\u6b62\u4f60\u5728\u672a\u6765\u7248\u672c\u8fdb\u884c\u6e38\u620f\u3002",
    "title.singleplayer": "\u5355\u4eba\u6e38\u620f",
    "translation.test.args": "%s %s",
    "translation.test.complex": "\u524d\u7f00\uff0c%s%2$s \u7136\u540e\u662f %s \u548c %1$s \u6700\u540e\u662f %s \u8fd8\u6709 %1$s\uff01",
    "translation.test.escape": "%%s %%%s %%%%s %%%%%s",
    "translation.test.invalid": "% \u4f60\u597d",
    "translation.test.invalid2": "%s \u4f60\u597d",
    "translation.test.none": "\u4f60\u597d\uff0c\u4e16\u754c\uff01",
    "translation.test.world": "\u4e16\u754c",
    "tutorial.bundleInsert.description": "\u53f3\u51fb\u6536\u7eb3\u7269\u54c1",
    "tutorial.bundleInsert.title": "\u4f7f\u7528\u6536\u7eb3\u888b",
    "tutorial.craft_planks.description": "\u914d\u65b9\u4e66\u80fd\u63d0\u4f9b\u5e2e\u52a9",
    "tutorial.craft_planks.title": "\u5408\u6210\u6728\u677f",
    "tutorial.find_tree.description": "\u6572\u51fb\u6728\u5934\u6765\u6536\u96c6\u5b83",
    "tutorial.find_tree.title": "\u627e\u5230\u4e00\u68f5\u6811",
    "tutorial.look.description": "\u4f7f\u7528\u4f60\u7684\u9f20\u6807\u6765\u8f6c\u52a8",
    "tutorial.look.title": "\u89c2\u5bdf\u56db\u5468",
    "tutorial.move.description": "\u7528%s\u6765\u8df3\u8dc3",
    "tutorial.move.title": "\u7528%s\u3001%s\u3001%s\u548c%s\u6765\u79fb\u52a8",
    "tutorial.open_inventory.description": "\u6309\u4e0b%s",
    "tutorial.open_inventory.title": "\u6253\u5f00\u4f60\u7684\u7269\u54c1\u680f",
    "tutorial.punch_tree.description": "\u6309\u4f4f%s",
    "tutorial.punch_tree.title": "\u6467\u6bc1\u6811\u6728",
    "tutorial.socialInteractions.description": "\u6309%s\u6253\u5f00",
    "tutorial.socialInteractions.title": "\u793e\u4ea4"
}