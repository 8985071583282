{
  "name": "nekomaid",
  "version": "0.2.5",
  "description": "A plugin can use Web to manage your server.",
  "main": "index.js",
  "private": true,
  "scripts": {
    "test": "eslint",
    "start": "parcel web/index.html",
    "gen": "npm run gen:material:icons && npm run gen:minecraft:language && npm run gen:minecraft:assets && npm run gen:minecraft:icons && npm run gen:minecraft:icons:item && npm run gen:minecraft:icons:list",
    "gen:material:icons": "ts-node scripts/generateMaterialIcons.ts",
    "gen:minecraft:icons": "minecraft-render client.jar icons/minecraft -w 40 -t 40 -d 13 -A",
    "gen:minecraft:icons:list": "ts-node scripts/generateMinecraftIconsList.ts",
    "gen:minecraft:assets": "ts-node scripts/generateMinecraftAssets.ts",
    "gen:minecraft:icons:item": "ts-node scripts/generateMinecraftAssets.ts itemOnly",
    "gen:minecraft:language": "ts-node scripts/downloadMinecraftLanguage.ts",
    "npm:types": "tsc",
    "npm:package": "ts-node scripts/generateNPMPackage.ts",
    "npm:clean": "del-cli npm/types",
    "npm:copy": "ncp README.md npm/README.md && ncp LICENSE npm/LICENSE",
    "npm": "npm run npm:clean && npm run npm:types && npm run npm:package && npm run npm:copy",
    "publish": "npm run npm && cd npm && npm publish",
    "build": "parcel build web/index.html --public-url .",
    "analyzer": "parcel build web/index.html --reporter @parcel/reporter-bundle-analyzer",
    "copyIcons": "ncp icons dist/icons && ncp vscode-material-icon-theme/icons dist/icons/material"
  },
  "targets": {
    "main": false
  },
  "engines": {
    "browsers": "Chrome 69"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/neko-craft/NekoMaid.git"
  },
  "keywords": [
    "minecraft",
    "server",
    "manager"
  ],
  "author": "Shirasawa",
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/neko-craft/NekoMaid/issues"
  },
  "homepage": "https://github.com/neko-craft/NekoMaid#readme",
  "dependencies": {
    "@emotion/react": "^0.2.5",
    "@emotion/styled": "^0.2.5",
    "@fontsource/roboto": "^0.2.5",
    "@fontsource/roboto-mono": "^0.2.5",
    "@mui/material": "^0.2.5",
    "@mui/icons-material": "^0.2.5",
    "@mui/lab": "^0.2.5-alpha.60",
    "@mui/styles": "^0.2.5",
    "@mui/x-data-grid": "^0.2.5",
    "codemirror": "^0.2.5",
    "dayjs": "^0.2.5",
    "decamelize": "^0.2.5",
    "echarts": "^0.2.5",
    "echarts-for-react": "^0.2.5",
    "lodash": "^0.2.5",
    "material-ui-cron": "^0.2.5-beta.4",
    "mdi-material-ui": "^0.2.5",
    "nbt-ts": "^0.2.5",
    "pretty-bytes": "^0.2.5",
    "react": "^0.2.5",
    "react-codemirror2": "^0.2.5",
    "react-copy-to-clipboard": "^0.2.5",
    "react-dom": "^0.2.5",
    "react-router-dom": "^0.2.5",
    "skinview3d": "^0.2.5",
    "socket.io-client": "^0.2.5",
    "valid-filename": "^0.2.5"
  },
  "devDependencies": {
    "@parcel/reporter-bundle-analyzer": "^0.2.5",
    "@parcel/transformer-image": "^0.2.5",
    "@parcel/transformer-less": "^0.2.5",
    "@types/lodash": "^0.2.5",
    "@types/react": "^0.2.5",
    "@types/react-copy-to-clipboard": "^0.2.5",
    "@types/react-dom": "^0.2.5",
    "@types/react-router-dom": "^0.2.5",
    "@types/request": "^0.2.5",
    "@types/yauzl": "^0.2.5",
    "@typescript-eslint/eslint-plugin": "^0.2.5",
    "@typescript-eslint/parser": "^0.2.5",
    "babel-plugin-transform-imports": "^0.2.5",
    "del-cli": "^0.2.5",
    "eslint": "^0.2.5",
    "eslint-config-standard": "^0.2.5",
    "eslint-plugin-import": "^0.2.5",
    "eslint-plugin-node": "^0.2.5",
    "eslint-plugin-promise": "^0.2.5",
    "eslint-plugin-react": "^0.2.5",
    "minecraft-render": "^0.2.5",
    "ncp": "^0.2.5",
    "nugget": "^0.2.5",
    "parcel": "^0.2.5",
    "ts-node": "^0.2.5",
    "typescript": "^0.2.5",
    "yauzl": "^0.2.5"
  }
}
