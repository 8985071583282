const enLocale = {
  everyText: '每',
  periodLabel: '时间间隔',
  atEveryText: '在/每隔',
  minuteLabel: '分钟',
  atOptionLabel: '在',
  everyOptionLabel: '每隔',
  betweenText: '从',
  inText: '在',
  onText: '在',
  andText: '到',
  monthLabel: '月',
  dayOfWeekLabel: '一周内的哪几天',
  onEveryText: '在/每隔',
  multiDayOfMonthLabel: '一个月内的哪几天',
  dayOfMonthLabel: '天',
  hourLabel: '几点',
  weekDaysOptions: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
  periodOptions: ['小时', '天', '星期', '月', '年'],
  shortMonthOptions: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  onOptionLabel: '在',
  lastDayOfMonthLabel: '这个月的最后一天',
  cronDescriptionText: 'zh_CN'
};
export default enLocale;